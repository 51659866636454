import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AgentInformation } from '@zc/common/core/models/agent-information';
import { TeamMember } from '@zc/common/core/models/team-member';

/** Represent agent home page navbar. */
@Component({
  selector: 'zcw-agent-navbar',
  templateUrl: './agent-navbar.component.html',
  styleUrls: ['./agent-navbar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentNavbarComponent {
  /** Company of agent. */
  @Input()
  public company: AgentInformation.Company | null = null;

  /** Agent to display. */
  @Input()
  public agent: TeamMember | null = null;

  /** Sidebar open state. */
  public showSidebar = false;

  /** Toggle sidebar open state. */
  public toggleSidebar(): void {
    this.showSidebar = !this.showSidebar;
  }
}
