import { ChangeDetectionStrategy, Component, QueryList, ViewChildren } from '@angular/core';
import { AppError } from '@zc/common/core/models/app-error';
import { QuestionFields } from '@zc/common/core/models/questionnaire/question-fields';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { Destroyable } from '@zc/common/core/utils/destroyable';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

import Checklist = QuestionFields.Specific.Checklist;

/** Checklist custom control. */
@Destroyable()
@Component({
  selector: 'zc-checklist-field-control',
  templateUrl: './checklist-field-control.component.html',
  styleUrls: ['./checklist-field-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(ChecklistFieldControlComponent)],
})
export class ChecklistFieldControlComponent extends SimpleValueAccessor<Checklist> {

  /** Checklist fields. */
  @ViewChildren(ChecklistFieldControlComponent)
  public checklistFields?: QueryList<ChecklistFieldControlComponent>;

  /** Type-guard for nested option. */
  public readonly isNestedOption = Checklist.isNestedOption;

  /**
   * Obtain the nested checklist data.
   * @param option Option from which to obtain the nested one.
   */
  public getNestedChild(option: Checklist.Option): Checklist.NestedOption {
    assertNonNull(this.controlValue);

    // In case option is selected, look for it in values
    const selectedOption = this.controlValue.value?.find(selected => Checklist.areOptionsEqual(selected, option)) ?? option;

    if (this.isNestedOption(selectedOption)) {
      return selectedOption;
    }

    throw new AppError('Unexpected expression, option is not considered nested');
  }

  /** @inheritdoc */
  public override writeValue(value: Checklist | null): void {
    super.writeValue(value);
    if (value != null && value.value == null) {
      assertNonNull(this.controlValue);
      this.controlValue = {
        ...this.controlValue,
        value: [],
      };
    }
  }

  /**
   * Toggle option.
   * @param option Option to toggle.
   * @param childFieldControl Child field control.
   */
  public onToggle(option: Checklist.Option, childFieldControl?: ChecklistFieldControlComponent): void {
    if (this.isChecked(option)) {
      this.uncheck(option, childFieldControl);
    } else {
      this.check(option);
    }
  }

  /**
   * Handle custom field change.
   * @param customValueField Custom value field.
   */
  public onCustomFieldChange(customValueField: QuestionFields.Specific.Text): void {
    assertNonNull(this.controlValue?.value);
    assertNonNull(customValueField.value);
    if (customValueField.value.length > 0) {
      const otherOption = {
        label: customValueField.label,
        value: customValueField.value,
      };

      this.controlValue = {
        ...this.controlValue,
        value: this.controlValue.value.filter(val => val.label !== customValueField.label).concat(otherOption as Checklist.Option),
        customValueField,
      };
    } else {
      this.controlValue = {
        ...this.controlValue,
        value: this.controlValue.value.filter(val => !val.excluding && val.label !== customValueField.label),
        customValueField,
      };
    }

  }

  /**
   * Handles child change.
   * @param option Option changed.
   * @param value Changed child checklist.
   * @param childFieldControl Child field control.
   */
  public onChildChange(option: Checklist.NestedOption, value: Checklist, childFieldControl: ChecklistFieldControlComponent): void {
    if (this.isChecked(option)) {
      this.uncheck(option);
    }
    if (!childFieldControl.hasChecked()) {
      return;
    }

    this.check({
      ...option,
      child: value,
    });
  }

  /**
   * Check whether the option is checked or not.
   * @param option Option.
   */
  public isChecked(option: Checklist.Option): boolean {
    assertNonNull(this.controlValue?.value);

    return this.controlValue.value.find(
      (selectedOption: Checklist.Option) => Checklist.areOptionsEqual(selectedOption, option),
    ) != null;
  }

  private check(option: Checklist.Option): void {
    assertNonNull(this.controlValue?.value);

    if (this.isChecked(option)) {
      throw new AppError('Tried to check option that is already checked.');
    }

    if (option.excluding) {
      this.controlValue = {
        ...this.controlValue,
        customValueField: this.controlValue.customValueField ? {
          ...this.controlValue.customValueField,
          value: void 0,
        } : void 0,
        value: [option],
      };
    } else {
      this.controlValue = {
        ...this.controlValue,
        value: this.controlValue.value.filter(selectedOption => !selectedOption.excluding).concat(option),
      };
    }

  }

  private uncheck(option: Checklist.Option, childFieldControl?: ChecklistFieldControlComponent): void {
    assertNonNull(this.controlValue?.value);

    if (!this.isChecked(option)) {
      throw new AppError('Tried to uncheck option that is not selected.');
    }

    this.controlValue = {
      ...this.controlValue,
      value: this.controlValue.value.filter(
        (selectedOption: Checklist.Option) =>
          !Checklist.areOptionsEqual(selectedOption, option),
      ),
    };
    childFieldControl?.uncheckAll();
  }

  private checkFirst(childFieldControl: ChecklistFieldControlComponent): void {
    assertNonNull(childFieldControl?.controlValue?.value);
    childFieldControl.controlValue = {
      ...childFieldControl.controlValue,
      value: childFieldControl.controlValue.value.concat(childFieldControl.controlValue.options[0]),
    };

    // this.checklistFields?.first.checkAll();

  }

  private uncheckAll(): void {
    assertNonNull(this.controlValue);

    this.controlValue = {
      ...this.controlValue,
      value: [],
    };
    this.checklistFields?.forEach(checklistField => checklistField.uncheckAll());
  }

  private checkAll(): void {
    assertNonNull(this.controlValue?.value);

    this.controlValue = {
      ...this.controlValue,
      value: this.controlValue.options,
    };
    this.checklistFields?.forEach(checklistField => checklistField.checkAll());
  }

  private hasChecked(): boolean {
    assertNonNull(this.controlValue?.value);

    return this.controlValue.value.length > 0 || !!this.controlValue.customValueField?.value;
  }
}
