<nav class="navbar">
  <zcw-agent-nav-list
    *ngIf="shouldShowNavbar"
    class="navbar__nav-list"
  ></zcw-agent-nav-list>
  <a
    class="zincasa-logo"
    *ngIf="company?.logo; else skeleton"
    [routerLink]="['/', agent?.id]"
  >
    <img class="zincasa-logo__image" [src]="company?.logo" alt="Zincasa Logo" />
  </a>
</nav>

<ng-template #skeleton>
  <a
    [routerLink]="['/', agent?.id]"
    class="zincasa-logo zincasa-logo_skeleton"
    [zcSkeletonMinWidth]="10"
    [zcSkeletonMaxWidth]="20"
    zcSkeleton
  ></a>
</ng-template>
