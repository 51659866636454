import { Injectable } from '@angular/core';

/**
 * Helper service for work with files.
 */
@Injectable({
  providedIn: 'root',
})
export class FileService {

  /**
   * Get file name without its extension.
   * Example:
   * drowpdown.png -> dropdown
   * my.doc.png -> my.doc
   * etc.
   * @param file File.
   */
  public extractName(file: File): string {
    return file.name
      .split('.')
      .slice(0, -1)
      .join('.');
  }

  /**
   * Extract extension of the file.
   * Example:
   * dropdown.png -> png
   * my.doc.pdf -> pdf
   * etc.
   * @param file File.
   */
  public extractExtension(file: File): string {
    return file.name
      .split('.')
      .slice(-1)
      .join('');
  }
}
