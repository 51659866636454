import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormGroupTyped, Validators } from '@angular/forms';
import { QuestionnaireData } from '@zc/common/core/models/questionnaire/questionnaire';
import { catchValidationData } from '@zc/common/core/rxjs/catch-validation-error';
import { toggleExecutionState } from '@zc/common/core/rxjs/toggle-execution-state';
import { QuestionnaireService } from '@zc/common/core/services/questionnaire.service';
import {
  Destroyable,
  takeUntilDestroy,
} from '@zc/common/core/utils/destroyable';
import { BehaviorSubject } from 'rxjs';

type VerificationData = Pick<QuestionnaireData, 'sellerPhone'> & {

  /** Whether re-captcha valid or not. */
  readonly isReCaptchaValid: boolean;
};

/** Text field control. */
@Destroyable()
@Component({
  selector: 'zc-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneInputComponent {
  /** Whether the form is being submitted. */
  public readonly isLoading$ = new BehaviorSubject<boolean>(false);

  /** Phone input form. */
  public readonly form: FormGroupTyped<VerificationData>;

  public constructor(
    private readonly fb: FormBuilder,
    private readonly questionnaireService: QuestionnaireService,
  ) {
    this.form = this.initForm();
  }

  /** Handle submit action. */
  public onFormSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.questionnaireService
      .updateSellerPhone(this.form.value.sellerPhone)
      .pipe(
        toggleExecutionState(this.isLoading$),
        catchValidationData(this.form),
        takeUntilDestroy(this),
      )
      .subscribe();
  }

  private initForm(): FormGroupTyped<VerificationData> {
    return this.fb.groupTyped<VerificationData>({
      sellerPhone: ['', [Validators.required, Validators.minLength(10)]],
      isReCaptchaValid: [false, Validators.requiredTrue],
    });
  }
}
