import { PaginatorDirective } from '@zc/common/shared/directives/paginator.directive';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';

/**
 * Table paginator component.
 */
@Component({
  selector: 'zc-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TablePaginatorComponent extends PaginatorDirective {

  /** Number of the first element on the page. */
  public get firstElementNumber(): number {
    assertNonNull(this.pagination);
    return this.pagination.page * this.pagination.size + 1;
  }

  /** Number of the last element on the page. */
  public get lastElementNumber(): number {
    assertNonNull(this.pagination);
    return Math.min((this.pagination.page + 1) * this.pagination.size, this.pagination.totalCount);
  }
}
