import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { TabButtonComponent } from '@zc/common/shared/modules/tabs/tab-button/tab-button.component';
import { TabContentComponent } from '@zc/common/shared/modules/tabs/tab-content/tab-content.component';
import { Observable, ReplaySubject } from 'rxjs';

/**
 * Tab component.
 */
@Component({
  selector: 'zc-tab',
  template: ``,
  styleUrls: ['./tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {

  /** Tab button ref. */
  @ContentChild(TabButtonComponent)
  public button!: TabButtonComponent;

  /** Tab content ref. */
  @ContentChild(TabContentComponent)
  public content!: TabContentComponent;

  /** Whether the tab should be preselected. */
  @Input()
  public set selected(val: boolean | string | null | undefined) {
    this.isSelectedValue$.next(val === '' || !!val);
  }

  /** Emit select action. */
  @Output()
  public readonly tabSelection = new EventEmitter<void>();

  /** Whether the tab is selected. */
  public readonly isSelected$: Observable<boolean>;

  private readonly isSelectedValue$: ReplaySubject<boolean>;

  public constructor() {
    this.isSelectedValue$ = new ReplaySubject<boolean>(1);
    this.isSelected$ = this.isSelectedValue$.asObservable();
  }

  /** Select tab. */
  public select(): void {
    this.isSelectedValue$.next(true);
    this.tabSelection.emit();
  }

  /** Unselect tab. */
  public unselect(): void {
    this.isSelectedValue$.next(false);
  }
}
