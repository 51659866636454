<zcw-agent-section>
  <h2 class="visually-hidden">Sale Options</h2>
  <zcw-agent-section-title>
    <ng-content select="title"></ng-content>
  </zcw-agent-section-title>
  <zcw-agent-section-description>
    <ng-content select="description"></ng-content>
  </zcw-agent-section-description>

  <ul class="sale-options-container">
    <li class="sale-option">
      <p class="sale-option__title">Instant Sale</p>
      <ol class="sale-option__list">
        <li class="sale-option__list-item">
          Get the cash proceeds in days rather than dealing with the unknown of
          when you will receive it
        </li>
        <li class="sale-option__list-item">
          No need to worry about any repairs the home may need
        </li>
        <li class="sale-option__list-item">
          Don’t waste your time staging the property
        </li>
        <li class="sale-option__list-item">
          Moving on your schedule removes the stress from selling your home
        </li>
        <li class="sale-option__list-item">
          Avoid having to worry about double moves or interim housing
          requirements
        </li>
        <li class="sale-option__list-item">Lower Net Proceeds (cash)</li>
      </ol>
    </li>

    <li class="sale-option">
      <p class="sale-option__title">Traditional Sale</p>
      <ol class="sale-option__list">
        <li class="sale-option__list-item">
          Maximize your sale price and cash
        </li>
        <li class="sale-option__list-item">
          May require some negotiations in finding a mutually agreeable closing
          date
        </li>
        <li class="sale-option__list-item">
          Staging and prepping the home can potentially provide higher returns
        </li>
        <li class="sale-option__list-item">
          Buyer may dictate closing and moving dates
        </li>
        <li class="sale-option__list-item">
          A double move may be required to realize top selling price
        </li>
        <li class="sale-option__list-item">Higher Net Proceeds (cash)</li>
      </ol>
    </li>
  </ul>
</zcw-agent-section>
