import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AgentResolver } from '@zc/common/core/resolvers/agent.resolver';
import { ORIGIN_PARAM_KEY } from '@zc/common/shared/features/questionnaire/questionnaire.module';
import { QuestionnairesPageComponent } from '@zc/common/shared/features/questionnaire/questionnaires-page/questionnaires-page.component';

import { AgentPortalComponent } from './agent-portal.component';
import { PageNotFoundComponent } from './not-found/not-found.component';

export const AGENT_ID_PARAM_KEY = 'agent';

const routes: Routes = [
  {
    path: `not-found`,
    component: PageNotFoundComponent,
  },
  {
    path: `:${AGENT_ID_PARAM_KEY}`,
    component: AgentPortalComponent,
    resolve: { agent: AgentResolver },
  },
  {
    path: `:${AGENT_ID_PARAM_KEY}/:${ORIGIN_PARAM_KEY}/questionnaires`,
    component: QuestionnairesPageComponent,
    resolve: { agent: AgentResolver },
  },

];

/** Routing module for agent portal. */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AgentPortalRoutingModule { }
