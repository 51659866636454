import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { User } from '@zc/common/core/models/user';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';

/** Username component. */
@Component({
  selector: 'zc-username',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsernameComponent {

  /** User to show the name. */
  @Input()
  public user: User | null = null;

  /** Full name of the user. */
  public get fullName(): string {
    assertNonNull(this.user);

    return User.toReadable(this.user);
  }
}
