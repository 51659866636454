<div
  class="form"
  [class.form_disabled]="_disabled$ | async"
  [class.form_hidden]="(_disabled$ | async) && placeholderOnDisabled"
>
  <!-- Anchors for Stripe to mount on -->
  <zc-label [errorText]="error$ | async" labelText="Card Number">
    <div class="form__control" #cardNumber></div>
  </zc-label>
  <zc-label labelText="Expiration Date">
    <div class="form__control" #cardExpiry></div>
  </zc-label>
  <zc-label labelText="Card Code (CVC)">
    <div class="form__control" #cardCvc></div>
  </zc-label>
</div>

<div
  class="form form_placeholder"
  *ngIf="(_disabled$ | async) && placeholderOnDisabled"
>
  <zc-label [errorText]="error$ | async" labelText="Card Number">
    <input disabled [value]="toCardNumberPlaceholder(placeholderOnDisabled)" />
  </zc-label>
  <zc-label labelText="Expiration Date">
    <input
      disabled
      [value]="toExpirationDatePlaceholder(placeholderOnDisabled)"
    />
  </zc-label>
  <zc-label labelText="Card Code (CVC)">
    <input disabled value="XXX" />
  </zc-label>
</div>
