import { Component, ChangeDetectionStrategy } from '@angular/core';

/** Represents sale options. */
@Component({
  selector: 'zcw-agent-sale-options',
  templateUrl: './agent-sale-options.component.html',
  styleUrls: ['./agent-sale-options.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentSaleOptionsComponent {}
