import { DOCUMENT } from '@angular/common';
import { Component, ChangeDetectionStrategy, AfterViewInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { first } from 'rxjs';
import { AppConfigService } from '@zc/common/core/services/app-config.service';

/** Represents frequently asked questions. */
@Destroyable()
@Component({
  selector: 'zcw-agent-faq',
  templateUrl: './agent-faq.component.html',
  styleUrls: ['./agent-faq.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentFaqComponent implements AfterViewInit {

  /** Privacy policy url. */
  public readonly privacyPolicyUrl: URL;

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly activatedRoute: ActivatedRoute,
    appConfigService: AppConfigService,
  ) { 
    this.privacyPolicyUrl = new URL(appConfigService.privacyPolicyUrl);
  }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    this.activatedRoute.fragment.pipe(
      first(),
      takeUntilDestroy(this),
    ).subscribe(fragment => {
      if (typeof fragment === 'string') {
        const element = this.document.getElementById(fragment);
        if (element) {
          element.scrollIntoView();
        }
      }
    });
  }
}
