<section class="agent-card">
  <zc-avatar
    class="agent-card__avatar"
    [src]="agent?.avatarUrl ?? null"
    [round]="true"
  ></zc-avatar>
  <h2 class="agent-card__name">
    <zc-username [user]="agent"></zc-username>
  </h2>

  <span
    class="agent-card__title"
    [zcSkeleton]="agent"
    [zcSkeletonMinWidth]="9"
    [zcSkeletonMaxWidth]="15"
  >
    <ng-container *ngIf="agent">
      {{ agent.title }}
    </ng-container>
  </span>

  <div class="agent-card__info-group">
    <address>
      <a
        class="agent-card__phone"
        [zcSkeleton]="agent"
        [zcSkeletonMinWidth]="11"
        [zcSkeletonMaxWidth]="11"
        href="tel:{{ agent?.phone }}"
      >
        <ng-container *ngIf="agent">
          {{ agent.phone | zcPhone: 'C' }}
        </ng-container>
      </a>
    </address>

    <hr class="agent-card__vertical-separator" />

    <address class="agent-card__email" *ngIf="company">
      <a
        class="agent-card__email-link"
        href="mailto:{{ company.companyEmail ?? '' }}"
        [zcSkeleton]="agent"
        [zcSkeletonMinWidth]="15"
        [zcSkeletonMaxWidth]="20"
      >
        <ng-container>
          {{ company.companyEmail }}
        </ng-container>
      </a>
    </address>

    <hr class="agent-card__vertical-separator" />

    <p
      class="agent-card__license"
      [zcSkeleton]="agent"
      [zcSkeletonMinWidth]="15"
      [zcSkeletonMaxWidth]="15"
    >
      <ng-container *ngIf="agent"> {{ licenseToReadable(agent) }} </ng-container>
    </p>
  </div>

  <hr class="agent-card__separator" />

  <img
    *ngIf="company && company.logo; else logoSkeleton"
    class="agent-card__company-logo"
    [src]="company.logo"
    alt=""
  />

  <ng-template #logoSkeleton>
    <div
      class="agent-card__company-logo agent-card__company-logo_skeleton"
      [zcSkeletonMinWidth]="10"
      [zcSkeletonMaxWidth]="20"
      zcSkeleton
    ></div>
  </ng-template>

  <address class="agent-card__address">
    <span
      class="agent-card__place"
      [zcSkeleton]="company"
      [zcSkeletonMinWidth]="30"
      [zcSkeletonMaxWidth]="35"
    >
      <ng-container *ngIf="company">
        {{ prepareCompanyAddress(company) }}
      </ng-container>
    </span>

    <div class="agent-card__phone-group">
      <a
        class="agent-card__phone"
        [zcSkeleton]="company"
        [zcSkeletonMinWidth]="11"
        [zcSkeletonMaxWidth]="11"
        href="tel:{{ company?.phoneNumber }}"
      >
        <ng-container *ngIf="company">
          {{ company.phoneNumber | zcPhone: 'O' }}
        </ng-container>
      </a>
    </div>
  </address>
  <a
    class="agent-card__link"
    *ngIf="company?.brokerageServiceUrl as brokerageServiceUrl"
    [href]="brokerageServiceUrl"
    target="_blank"
    >Real Estate Commission Information About Brokerage Services</a
  >
  <a
    class="agent-card__link"
    *ngIf="company?.protectionNoticeUrl as protectionNoticeUrl"
    [href]="protectionNoticeUrl"
    target="_blank"
    >Real Estate Consumer Protection Notice</a
  >
</section>
