import { Component, ChangeDetectionStrategy } from '@angular/core';

/** Section description for section on agent home page. */
@Component({
  selector: 'zcw-agent-section-description',
  templateUrl: './agent-section-description.component.html',
  styleUrls: ['./agent-section-description.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentSectionDescriptionComponent {

}
