import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concat, forkJoin, iif, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, mapTo, shareReplay, switchMap, switchMapTo, toArray } from 'rxjs/operators';

import { SiteOrigin } from '../enums/site-origin';

import { AgentInformation } from '../models/agent-information';
import { AgentLanding } from '../models/agent-landing';
import { AppError, AppValidationError } from '../models/app-error';
import { CardSetupSecret } from '../models/card-setup-secret';
import { Gender } from '../models/gender';
import { SubscriptionInterval, SubscriptionProduct } from '../models/subscription-product';
import { SubscriptionPurchaseData } from '../models/subscription-purchase-data';
import { TeamMember } from '../models/team-member';
import { User } from '../models/user';
import { catchValidationError } from '../rxjs/catch-validation-error';
import { filterNull } from '../rxjs/filter-null';

import { AppConfigService } from './app-config.service';
import { CompanyLogoUploadService } from './company-logo-upload.service';
import { AgentCompanyInformationMapper } from './mappers/agent-company-information.mapper';
import { AgentExtraInfoMapper } from './mappers/agent-founding.mapper';
import { AgentLandingConfigurationMapper } from './mappers/agent-landing-configuration.mapper';
import { AgentRegistrationInformationMapper } from './mappers/agent-registration-information.mapper';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { CardSetupSecretMapper } from './mappers/card-setup-secret.mapper';
import { AgentProfileDto } from './mappers/dto/agent-profile.dto';
import { AgentRegistrationDto } from './mappers/dto/agent-registration.dto';
import { AuthDto } from './mappers/dto/auth.dto';
import { CardSetupSecretDto } from './mappers/dto/card-setup-secret.dto';
import { TeamMemberCreationDto } from './mappers/dto/team-member.dto';
import { TeamMemberMapper } from './mappers/team-member.mapper';
import { UserSecretDataMapper } from './mappers/user-secret-data.mapper';
import { RegistrationAssetUploadService } from './registration-asset-upload.service';
import { SubscriptionsService } from './subscriptions.service';
import { TeamService } from './team.service';
import { UserSecretStorageService } from './user-secret-storage.service';

const VALID_YEAR_FOR_PROMO_CODE = 2024;

/** Service allowing to configure/obtain the agent-related info. */
@Injectable({
  providedIn: 'root',
})
export class AgentService {

  /** Url to agent's home page. */
  public readonly currentAgentAddress$: Observable<AgentInformation.WebsiteAddress>;

  private readonly agentProfileUrl: URL;

  private readonly agentRegistrationUrl: URL;

  public constructor(
    private readonly appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly agentRegistrationInformationMapper: AgentRegistrationInformationMapper,
    private readonly agentLandingConfigurationMapper: AgentLandingConfigurationMapper,
    private readonly agentCompanyInformationMapper: AgentCompanyInformationMapper,
    private readonly agentExtraInfoMapper: AgentExtraInfoMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly teamService: TeamService,
    private readonly companyLogoUploadService: CompanyLogoUploadService,
    private readonly registrationAssetUploadService: RegistrationAssetUploadService,
    private readonly cardSetupSecretMapper: CardSetupSecretMapper,
    private readonly userSecretMapper: UserSecretDataMapper,
    private readonly subscriptionService: SubscriptionsService,
    private readonly teamMemberMapper: TeamMemberMapper,
    private readonly userSecretStorage: UserSecretStorageService,
  ) {
    this.agentProfileUrl = new URL('users/agent_profile/', appConfig.apiUrl);
    this.agentRegistrationUrl = new URL('auth/agent_register/', appConfig.apiUrl);
    this.currentAgentAddress$ = this.teamService.currentTeamMember$.pipe(
      filterNull(),
      map(({ id }) => ({
        questionnaireUrl: new URL(`${id}/${SiteOrigin.ViaMarketing}/questionnaires/`, appConfig.sellersAppUrl),
        profileUrl: new URL(`${id}/`, appConfig.sellersAppUrl),
        questionnaireProfileUrl: new URL(`${id}/${SiteOrigin.ViaAgent}/questionnaires/`, appConfig.sellersAppUrl),
      })),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /**
   * Returns a template type for agent home page based on the agent id provided.
   * @param agentId Agent id.
   */
  public getLandingConfigurationByAgentId(agentId: TeamMember['id']): Observable<AgentLanding.Configuration> {
    return this.fetchAgentProfile(agentId).pipe(
      map(dto => this.agentLandingConfigurationMapper.fromDto(dto)),
      map(data => ({
        ...data,

        // TODO: remove the mock when it's integrated on the BE
        video: 'https://vjs.zencdn.net/v/oceans.mp4',
      })),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          return of(AgentLanding.DEFAULT_CONFIGURATION);
        }

        return throwError(() => error);
      }),
    );
  }

  /**
   * Updates personal info for current agent user.
   * @param template Template to set.
   * @throws In case current agent is not an agent.
   */
  public updateWebsiteInformation(template: AgentLanding.Configuration): Observable<void> {
    return this.teamService.currentTeamMember$.pipe(
      first(),
      switchMap(user => this.patchAgentProfile(user.id, this.agentLandingConfigurationMapper.toDto(template))),
    );
  }

  /** Obtains website info. */
  public getWebsiteInformation(): Observable<AgentLanding.Configuration> {
    return this.teamService.currentTeamMember$.pipe(
      first(),
      switchMap(user => this.getLandingConfigurationByAgentId(user.id)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /**
   * Updates personal info.
   * @param information Personal information.
   */
  public updatePersonalInformation(information: AgentInformation.Personal): Observable<void> {
    return this.teamService.currentTeamMember$.pipe(
      first(),
      switchMap(agent => this.teamService.editTeamMember({
        ...agent,
        ...information.profile,
      })),
      catchValidationError(error =>
        throwError(() => new AppValidationError<AgentInformation.Personal>(error.message, { profile: error.validationData }))),
    );
  }

  /** Obtains personal info. */
  public getPersonalInformation(): Observable<AgentInformation.Personal> {
    return this.teamService.currentTeamMember$.pipe(
      first(),
      map<TeamMember, AgentInformation.Personal>(data => ({
        profile: { ...data, title: data.title ?? '', avatar: data.avatarUrl, gender: data.gender ?? Gender.Male },
        account: { email: data.email, password: 'mocked' },
      })),
    );
  }

  /** Obtains company info. */
  public getCompanyInformation(): Observable<AgentInformation.Company> {
    return this.teamService.currentTeamMember$.pipe(
      first(),
      switchMap(({ id }) => this.fetchAgentProfile(id)),
      map(companyInfoDto => this.agentCompanyInformationMapper.fromDto(companyInfoDto)),
    );
  }

  /** Obtains agent extra info. */
  public getExtraInfo(): Observable<AgentInformation.ExtraInfo> {
    return this.teamService.currentTeamMember$.pipe(
      first(),
      switchMap(({ id }) => this.fetchAgentProfile(id)),
      map(dto => this.agentExtraInfoMapper.fromDto(dto)),
    );
  }

  /**
   * Obtains information about company of specific agent.
   * @param id Agent id.
   */
  public getCompanyInformationByAgentId(id: User['id']): Observable<AgentInformation.Company> {
    return this.fetchAgentProfile(id).pipe(
      map(companyInfoDto => this.agentCompanyInformationMapper.fromDto(companyInfoDto)),
    );
  }

  /**
   * Updates company info.
   * @param companyInfo Info to update.
   */
  public updateCompanyInformation(companyInfo: AgentInformation.Company): Observable<void> {
    const currentTeamMember$ = this.teamService.currentTeamMember$.pipe(first());

    return currentTeamMember$.pipe(
      map(({ id }) => id),
      switchMap(agentId => this.prepareFile(
        companyInfo.logo,
        file => this.companyLogoUploadService.uploadLogo(file, agentId),
      ).pipe(
        map(uploadedCompanyLogo => ({ ...companyInfo, logo: uploadedCompanyLogo })),
        switchMap(data => this.patchAgentProfile(agentId, this.agentCompanyInformationMapper.toDto(data))),
        switchMapTo(currentTeamMember$),
        switchMap(teamMember => this.patchAgentTerritories(teamMember, companyInfo)),
        mapTo(void 0),
      )),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.agentCompanyInformationMapper),
    );
  }

  /**
   * Get promo code based on subscription product.
   * @param product Subscription product.
   */
  public getPromoCode(product: SubscriptionProduct | null): string | null {
    const shouldGetPromoCode = product != null && new Date().getFullYear() <= VALID_YEAR_FOR_PROMO_CODE;
    if (shouldGetPromoCode) {
      if (product.interval === SubscriptionInterval.Year) {
        return this.appConfig.annualPromoCode;
      }
      if (product.interval === SubscriptionInterval.Month) {
        return this.appConfig.monthlyPromoCode;
      }
    }

    return null;
  }

  /**
   * Registers an agent.
   * @param information Info for registration.
   * @param setUpCardMethod Set up card method.
   */
  public registerAgent(
    information: AgentInformation,
    setUpCardMethod: (cardSetupSecret: CardSetupSecret) => Observable<unknown>,
  ): Observable<void> {
    const { promocode, product } = information.subscriptionPurchase;

    const agentCreation$ = this.createAgent(information, setUpCardMethod);

    const agentCreationWithCouponValidation$ = this.subscriptionService.validateCoupon(promocode ?? '', product).pipe(
      catchError((error: AppError) => throwError(() => new AppValidationError<AgentInformation>(
        error.message,
        { subscriptionPurchase: { promocode: error.message } },
      ))),
      switchMapTo(agentCreation$),
    );

    return iif(() =>
      SubscriptionPurchaseData.hasPromocode(information.subscriptionPurchase),
    agentCreationWithCouponValidation$,
    agentCreation$);
  }

  private createAgent(
    information: AgentInformation,
    setUpCardMethod: (cardSetupSecret: CardSetupSecret) => Observable<unknown>,
  ): Observable<void> {
    return forkJoin({
      preparedAvatarUrl: this.prepareFile(
        information.personal.profile.avatar,
        file => this.registrationAssetUploadService.upload(file),
      ),
      preparedLogoUrl: this.prepareFile(
        information.company.logo,
        file => this.registrationAssetUploadService.upload(file),
      ),
    }).pipe(
      switchMap(({ preparedAvatarUrl, preparedLogoUrl }) => this.postRegisterAgent(this.agentRegistrationInformationMapper.toDto({
        subscriptionPurchase: information.subscriptionPurchase,
        company: {
          ...information.company,
          logo: preparedLogoUrl,
        },
        personal: {
          account: information.personal.account,
          profile: {
            ...information.personal.profile,
            avatar: preparedAvatarUrl,
          },
        },
      }))),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.agentRegistrationInformationMapper),
      switchMap(dto => concat(
        this.userSecretStorage.saveSecret(this.userSecretMapper.fromDto(dto))
          .pipe(
            first(),
          ),
        setUpCardMethod(this.cardSetupSecretMapper.fromDto(dto)),
        this.subscriptionService.createSubscriptionAsUnauthorized(
          information.subscriptionPurchase,
          this.userSecretMapper.fromDto(dto),
        ).pipe(
          catchError((error: AppValidationError<SubscriptionPurchaseData>) =>
            throwError(() => new AppValidationError<AgentInformation>(error.message, { subscriptionPurchase: error.validationData }))),
        ),
      )),

      toArray(),
      mapTo(void 0),

    );
  }

  private prepareFile(
    file: File | string | null,
    handler: (file: File) => Observable<string>,
  ): Observable<string | null> {
    if (file instanceof File) {
      return handler(file);
    }
    return of(file);
  }

  // API
  private patchAgentProfile(agentId: User['id'], fields: Partial<AgentProfileDto>): Observable<void> {
    return this.httpClient.patch<void>(this.getAgentProfileUrl(agentId).toString(), fields);
  }

  private fetchAgentProfile(agentId: User['id']): Observable<AgentProfileDto> {
    return this.httpClient.get<AgentProfileDto>(this.getAgentProfileUrl(agentId).toString());
  }

  private getAgentProfileUrl(id: User['id']): URL {
    return new URL(`${id}/`, this.agentProfileUrl);
  }

  /**
   *
   * @param information Agent registration.
   * @returns Payment intent token.
   */
  private postRegisterAgent(information: AgentRegistrationDto): Observable<CardSetupSecretDto & AuthDto.UserSecret> {
    return this.httpClient.post<CardSetupSecretDto & AuthDto.UserSecret>(
      this.agentRegistrationUrl.toString(),
      information,
    );
  }

  private patchAgentTerritories(
    teamMember: TeamMember,
    companyInfo: AgentInformation.Company,
  ): Observable<TeamMemberCreationDto> {
    return this.httpClient.patch<TeamMemberCreationDto>(
      this.teamService.getTeamMemberUrl(teamMember.id).toString(),
      this.teamMemberMapper.editDataToDto({
        ...teamMember,
        avatar: teamMember.avatarUrl,
        territories: companyInfo.territories ?? [],
      }),
    );
  }
}
