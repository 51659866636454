import { TeamMember } from '@zc/common/core/models/team-member';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AgentInformation } from '@zc/common/core/models/agent-information';

/** Represents agent home page header content. */
@Component({
  selector: 'zcw-agent-header',
  templateUrl: './agent-header.component.html',
  styleUrls: ['./agent-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentHeaderComponent {
  /** Whether the content should be overlapped. */
  @Input()
  public contentOverlap = false;

  /** Agent company. */
  @Input()
  public company: AgentInformation.Company | null = null;

  /** Agent to display. */
  @Input()
  public agent: TeamMember | null = null;

}
