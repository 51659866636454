import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AgentInformation } from '@zc/common/core/models/agent-information';
import { TeamMember } from '@zc/common/core/models/team-member';

/** Represent navbar on questionnaire page. */
@Component({
  selector: 'zc-questionnaire-header',
  templateUrl: './questionnaire-header.component.html',
  styleUrls: ['./questionnaire-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnaireHeaderComponent {
  /** Whether show navbar. */
  @Input()
  public shouldShowNavbar = true;

  /** Company of agent. */
  @Input()
  public company: AgentInformation.Company | null = null;

  /** Agent. */
  @Input()
  public agent: TeamMember | null = null;

}
