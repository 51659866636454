import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FileFormat } from '@zc/common/core/enums/file-format';

import { BaseFilePreviewDirective } from '../base-photo-preview/base-photo-preview.directive';

export namespace DocumentImage {
  const MAP_TO_IMAGE_PATH: Readonly<Record<FileFormat, string>> = {
    [FileFormat.DOC]: '/assets/document-formats/docs-document.svg',
    [FileFormat.DOCS]: '/assets/document-formats/docs-document.svg',
    [FileFormat.DOCX]: '/assets/document-formats/docs-document.svg',
    [FileFormat.PDF]: '/assets/document-formats/pdf-document.svg',
    [FileFormat.XLS]: '/assets/document-formats/empty-document.svg',
    [FileFormat.XLSX]: '/assets/document-formats/empty-document.svg',
    [FileFormat.CSV]: '/assets/document-formats/empty-document.svg',
    [FileFormat.JPEG]: '/assets/document-formats/jpeg-document.svg',
    [FileFormat.JPG]: '/assets/document-formats/jpeg-document.svg',
    [FileFormat.PNG]: '/assets/document-formats/png-document.svg',
    [FileFormat.Unknown]: '/assets/document-formats/empty-document.svg',
    [FileFormat.MP4]: '/assets/document-formats/video-player.svg',
    [FileFormat.MOV]: '/assets/document-formats/empty-document.svg',
    [FileFormat.MKV]: '/assets/document-formats/empty-document.svg',
    [FileFormat.AVI]: '/assets/document-formats/empty-document.svg',
    [FileFormat.WMV]: '/assets/document-formats/empty-document.svg',
    [FileFormat.GIF]: '/assets/document-formats/empty-document.svg',
    [FileFormat.WEBP]: '/assets/document-formats/empty-document.svg',
    [FileFormat.SVG]: '/assets/document-formats/empty-document.svg',
  };

  /** Images formats that may have preview. */
  export const IMAGES_WITH_PREVIEW: readonly FileFormat[] = [
    FileFormat.JPEG,
    FileFormat.JPG,
    FileFormat.PNG,
  ];

  /**
   * Get image path for supported type.
   * @param file File.
   */
  export function getPath(file: string): string {
    return MAP_TO_IMAGE_PATH[FileFormat.fromFile(file)] ?? MAP_TO_IMAGE_PATH[FileFormat.Unknown];
  }

  /** Get path to default placeholder image. */
  export function getPlaceholderPath(): string {
    return MAP_TO_IMAGE_PATH[FileFormat.Unknown];
  }
}

/**
 * Component for image of documents.
 */
@Component({
  selector: 'zc-document-image',
  templateUrl: './document-image.component.html',
  styleUrls: ['./document-image.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentImageComponent extends BaseFilePreviewDirective {

  /** Whether image is shown for preview or not. */
  @Input()
  public withPreview = false;

  /** Supported file formats. */
  public readonly FileFormat = FileFormat;

  /**
   * @param format File format.
   * @returns Path to the image.
   */
  public getImagePath(format: FileFormat): string {
    if (this.safeSourceUrl == null) {
      return DocumentImage.getPlaceholderPath();
    }

    if (this.withPreview && DocumentImage.IMAGES_WITH_PREVIEW.includes(format)) {
      return this.safeSourceUrl ?? DocumentImage.getPath(this.safeSourceUrl);
    }
    return DocumentImage.getPath(format);
  }
}
