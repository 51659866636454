import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { FiveStarRating } from '../../models/five-star-rating';
import { Gender } from '../../models/gender';
import { Project } from '../../models/project';

import { ProjectAssignees } from '../../models/project-assignment';
import { SellerEditData, TeamMember, TeamMemberCreationData, TeamMemberEditData } from '../../models/team-member';
import { assertFileUploaded } from '../../utils/assert-file-uploaded';

import { AgentCompanyInformationMapper } from './agent-company-information.mapper';
import { AgentProfileDto } from './dto/agent-profile.dto';

import { ProjectAssigneesDto, ProjectAssignmentDto } from './dto/project-assignment.dto';
import { SellerEditDto, TeamMemberCreationDto, TeamMemberDto, TeamMemberEditDto } from './dto/team-member.dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperFromDtoWithErrors } from './mappers';
import { PhoneMapper } from './phone.mapper';
import { UserMapper } from './user.mapper';

/** Team member mapper. */
@Injectable({ providedIn: 'root' })
export class TeamMemberMapper implements IMapperFromDtoWithErrors<TeamMemberDto, TeamMember> {

  public constructor(
    private readonly userMapper: UserMapper,
    private readonly phoneMapper: PhoneMapper,
    private readonly agentCompanyInformationMapper: AgentCompanyInformationMapper,
  ) { }

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<TeamMemberDto>): EntityValidationErrors<TeamMember> {
    const agentProfileDto = errorDto.agent_profile as ValidationErrorDto<AgentProfileDto> | undefined;

    return {
      email: extractErrorMessage(errorDto.email),
      avatarUrl: extractErrorMessage(errorDto.avatar),
      firstName: extractErrorMessage(errorDto.first_name),
      lastName: extractErrorMessage(errorDto.last_name),
      phone: extractErrorMessage(errorDto.phone),
      profession: extractErrorMessage(errorDto.role),
      territories: extractErrorMessage(errorDto.territories),
      referringAgent: extractErrorMessage(errorDto.referring_agent),
      companyName: extractErrorMessage(errorDto.company_name),
      projectsCount: extractErrorMessage(errorDto.project_count),
      rating: extractErrorMessage(errorDto.rating),
      agentProfile: agentProfileDto && this.agentCompanyInformationMapper.validationErrorFromDto(agentProfileDto),
    };
  }

  /** @inheritdoc */
  public fromDto(data: TeamMemberDto): TeamMember {
    const agentProfile = data.agent_profile ? this.agentCompanyInformationMapper.fromDto(data.agent_profile) : null;

    return {
      ...this.userMapper.fromDto(data),
      territories: data.territories,
      projectsCount: data.project_count,
      rating: new FiveStarRating(data.rating ?? 0),
      companyName: data.company_name,
      agentProfile,
      license: data.license,
      title: data.title,
      gender: data.gender && Gender.is(data.gender) ? data.gender : null,
      referringAgent: data.referring_agent ? this.userMapper.fromDto(data.referring_agent) : null,
      isExpired: data.is_expired ?? true,
      isSubscribed: data.is_subscribed ?? false,
      joinedAt: new Date(data.created),
      isActive: data.is_active,
      isEmailVerified: data.is_email_verified,
    };
  }

  /**
   * Maps creation data to dto.
   * @param data Data to map.
   */
  public creationDataToDto(data: TeamMemberCreationData): TeamMemberCreationDto {
    const phone = data.phone ? this.phoneMapper.toDto(data.phone) : null;

    assertFileUploaded(data.avatar);
    return {
      license: data.license ?? null,
      company_name: data.companyName,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      phone,
      role: data.profession.id,
      territories: data.territories ? data.territories.map(t => t.id) : [],
      referring_agent: data.referringAgent?.id ?? null,
      title: data.title,
      avatar: data.avatar ?? null,
      gender: data.gender,
      is_active: data.isActive ?? null,
      is_email_verified: data.isEmailVerified ?? null,
      agent_profile: data.agentProfile ? this.agentCompanyInformationMapper.toDto(data.agentProfile) : null,
    };
  }

  /**
   * Maps edit data to dto.
   * @param data Data to map.
   */
  public editDataToDto(data: TeamMemberEditData): TeamMemberEditDto {
    const phone = data.phone ? this.phoneMapper.toDto(data.phone) : null;
    assertFileUploaded(data.avatar);
    return {
      company_name: data.companyName,
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      phone,
      role: data.profession?.id,
      territories: data.territories ? data.territories.map(t => t.id) : [],
      referring_agent: data.referringAgent?.id,
      avatar: data.avatar,
      license: data.license,
      title: data.title,
      gender: data.gender,
      address1: data.location?.addressLine1,
      address2: data.location?.addressLine2,
      city: data.location?.city,
      state: data.location?.state ? data.location.state.abbreviation : void 0,
      zip_code: data.location?.zipCode,
      is_active: data.isActive ?? void 0,
      is_email_verified: data.isEmailVerified ?? void 0,
      agent_profile: data.agentProfile ? this.agentCompanyInformationMapper.toDto(data.agentProfile) : void 0,
    };
  }

  /**
   * Maps seller data to dto.
   * @param data Seller Data to map.
   */
  public editSellerDataToDto(data: SellerEditData): SellerEditDto {
    return {
      first_name: data.firstName,
    };
  }

  /**
   * Maps project assignment dto to data.
   * @param dto Project assignment dto.
   * @returns Project assignment dto.
   */
  public projectAssigneesFromDto(dto: ProjectAssigneesDto): ProjectAssignees {
    return {
      projectManager: this.fromDto(dto.project_manager),
      dataAnalyst: this.fromDto(dto.data_analyst),
      agent: this.fromDto(dto.agent),
    };
  }

  /**
   * Maps project assignment data to array dto.
   * @param project Project Identifier.
   * @param data Project assignment data.
   */
  public projectAssignmentToArrayDto(project: Project['id'], data: ProjectAssignees): ProjectAssignmentDto[] {
    const arrValueFromObject = Object.values(data).filter(item => item?.id);
    return arrValueFromObject.map(user => ({ project, user_id: user.id }));
  }
}
