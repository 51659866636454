import { ChangeDetectionStrategy, Component, ContentChildren, QueryList, ViewEncapsulation } from '@angular/core';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';

import { CarouselContentDirective } from './carousel-content.directive';

SwiperCore.use([Pagination]);

/** Carousel component. */
@Component({
  selector: 'zc-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent {

  /** Child. */
  @ContentChildren(CarouselContentDirective, { descendants: true })

  /** Contents. */
  public contents: QueryList<CarouselContentDirective> | null = null;

  /** Carousel options. */
  public readonly options: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 20,
    cssMode: true,
    keyboard: true,
    pagination: {
      dynamicBullets: true,
      dynamicMainBullets: 1,
      clickable: true,
      bulletActiveClass: 'active-bullet',
    },
  };
}
