import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { Subscription } from '../../models/subscription';
import { SubscriptionPurchaseData } from '../../models/subscription-purchase-data';

import { SubscriptionPurchaseDto, SubscriptionUpgradeDto } from './dto/subscription-purchase.dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperToDtoWithErrors } from './mappers';

/** Subscription product mapper. */
@Injectable({
  providedIn: 'root',
})
export class SubscriptionPurchaseDataMapper implements IMapperToDtoWithErrors<SubscriptionPurchaseDto, SubscriptionPurchaseData> {
  /** @inheritdoc */
  public toDto(data: SubscriptionPurchaseData): SubscriptionPurchaseDto {
    return {
      price_id: data.product.priceId,
      coupon: data.promocode ?? void 0,
    };
  }

  /**
   * Maps subscription purchase data to dto.
   * @param data Subscription purchase data.
   * @param currentSubscription Current subscription.
   */
  public upgradeDataToDto(data: SubscriptionPurchaseData, currentSubscription: Subscription): SubscriptionUpgradeDto {
    return {
      ...this.toDto(data),
      subscription_id: currentSubscription.id,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<SubscriptionPurchaseDto>): EntityValidationErrors<SubscriptionPurchaseData> {
    return {
      product: extractErrorMessage(errorDto.price_id),
      promocode: extractErrorMessage(errorDto.coupon),
    };
  }
}
