import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Gender } from '@zc/common/core/models/gender';
import { enumToArray } from '@zc/common/core/utils/enum-to-array';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

/** Gender component. */
@Component({
  selector: 'zc-gender',
  templateUrl: './gender.component.html',
  styleUrls: ['./gender.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(GenderComponent)],
})
export class GenderComponent extends SimpleValueAccessor<Gender> {

  /** Maps gender to human-readable value. */
  public readonly genderToReadable = Gender.toReadable;

  /** Gender options. */
  public readonly genders = enumToArray(Gender);

}
