import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { AuthData } from '../../models/auth-data';

import { AuthDto } from './dto/auth.dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperToDtoWithErrors } from './mappers';

/** Mapper for reset password data. */
@Injectable({ providedIn: 'root' })
export class ResetPasswordMapper implements IMapperToDtoWithErrors<AuthData.PasswordReset, AuthDto.PasswordReset> {
  /** @inheritdoc */
  public toDto(data: AuthDto.PasswordReset): AuthData.PasswordReset {
    return {
      email: data.email,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<AuthData.PasswordReset>): EntityValidationErrors<AuthDto.PasswordReset> {
    return {
      email: extractErrorMessage(errorDto.email) ?? extractErrorMessage(errorDto.non_field_errors),
    };
  }
}
