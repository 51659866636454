/**
 * Content type.
 */
export enum ContentType {
  Image = 'image',
  Video = 'video',
  Document = 'document',
  Unknown = 'unknown',
}

/**
 * All existing content types.
 */
enum ExistingContentTypes {
  Application = 'application',
  Audio = 'audio',
  Font = 'font',
  Image = 'image',
  Message = 'message',
  Model = 'model',
  Multipart = 'multipart',
  Text = 'text',
  Video = 'video',
}

export namespace ContentType {

  /** Map of supported content types. */
  const SUPPORTED_CONTENT_TYPES: Record<ExistingContentTypes, ContentType> = {
    [ExistingContentTypes.Image]: ContentType.Image,
    [ExistingContentTypes.Video]: ContentType.Video,
    [ExistingContentTypes.Application]: ContentType.Document,
    [ExistingContentTypes.Text]: ContentType.Document,

    // Some of this formats may be available in the future.
    [ExistingContentTypes.Font]: ContentType.Unknown,
    [ExistingContentTypes.Message]: ContentType.Unknown,
    [ExistingContentTypes.Audio]: ContentType.Unknown,
    [ExistingContentTypes.Model]: ContentType.Unknown,
    [ExistingContentTypes.Multipart]: ContentType.Unknown,
  };

  /**
   * Get content type from blob.
   * @param blob Blob.
   */
  export function fromBlob(blob: Blob): ContentType {

    /**
     * We are doing so because all file types represented in form like 'image/jpeg' | 'video/mp4'.
     * Because of that first word before slash always represent content type of current file.
     * For more info check https://www.iana.org/assignments/media-types/media-types.xhtml.
     */
    const fileContentType = blob.type.split('/')[0] as ExistingContentTypes;
    return SUPPORTED_CONTENT_TYPES[fileContentType];
  }
}
