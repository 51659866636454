import { combineLatest, concat, Observable, ObservedValueOf, of, timer } from 'rxjs';
import { ignoreElements, map, switchMap } from 'rxjs/operators';

import { Pagination } from '../models/pagination';
import { PaginationOptions } from '../models/pagination-options';

export type AsyncPaginationOptions<T extends PaginationOptions> = {
  readonly [K in keyof T]: Observable<T[K]>;
};

// any is intended so to infer types properly.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncPaginationToSync<T extends AsyncPaginationOptions<any>> = {
  readonly [K in keyof T]: ObservedValueOf<T[K]>;
};

/**
 * Works exactly like `combineLatest` but for objects. Polyfills behavior from rxjs 7.
 * @param sourceObject Object of observable sources.
 */
function combineLatestObject<T extends Record<string, Observable<unknown>>>(sourceObject: T): Observable<AsyncPaginationToSync<T>> {
  const keys = Object.keys(sourceObject);

  return combineLatest(keys.map(k => sourceObject[k])).pipe(
    map(values => values.reduce((acc: AsyncPaginationToSync<T>, val, i) => ({
      ...acc,
      [keys[i]]: val,
    }), {} as AsyncPaginationToSync<T>)),
  );
}

const QUERY_DEBOUNCE_TIME_MS = 500;

/**
 * Allows paginating data based on provided object containing async properties for pagination.
 * @param asyncOptions Async objects with pagination data.
 * @param fetch Fetch function that accepts the pagination options.
 *
 * @example
 * ```ts
 * const searchString$ = new BehaviorSubject('');
 * const $ = new BehaviorSubject('');
 *
 * ```
 */
export function paginate<T, O extends PaginationOptions>(
  asyncOptions: AsyncPaginationOptions<O>,
  fetch: (options: O) => Observable<Pagination<T>>,
): Observable<Pagination<T> | null> {
  return combineLatestObject(asyncOptions).pipe(
    switchMap(syncOptions => concat(

      // First, reset the state
      of(null),

      // Then, debounce the query
      timer(QUERY_DEBOUNCE_TIME_MS).pipe(ignoreElements()),

      // After debounce time, fetch the value
      fetch(syncOptions),
    )),
  );
}
