import { Injectable } from '@angular/core';

import { AuthData } from '../../models/auth-data';

import { AuthDto } from './dto/auth.dto';
import { IMapper } from './mappers';

/** User secret mapper. */
@Injectable({
  providedIn: 'root',
})
export class UserSecretDataMapper implements IMapper<AuthDto.UserSecret, AuthData.UserSecret> {
  /** @inheritdoc */
  public toDto(data: AuthData.UserSecret): AuthDto.UserSecret {
    return {
      token: data.token,
    };
  }

  /** @inheritdoc */
  public fromDto(dto: AuthDto.UserSecret): AuthData.UserSecret {
    return {
      token: dto.token,
    };
  }
}
