import { Injectable } from '@angular/core';
import { Observable, defer, tap, mapTo, ReplaySubject, concat, distinctUntilChanged, race, shareReplay } from 'rxjs';

import { AuthData } from '../models/auth-data';

import { LocalStorageService } from './local-storage.service';

const USER_SECRET_STORAGE_KEY = 'user';

/** User secret storage. */
@Injectable({ providedIn: 'root' })
export class UserSecretStorageService {

  /** Token info for current user. */
  public readonly currentSecret$: Observable<AuthData.UserSecret | null>;

  /** Current user secret. */
  private readonly currentSecretValue$ = new ReplaySubject<AuthData.UserSecret | null>(1);

  public constructor(
    private readonly storageService: LocalStorageService,
  ) {
    this.currentSecret$ = this.initCurrentSecretStream();
  }

  /**
   * Saves a secret.
   * @param secret Secret to save.
   */
  public saveSecret(secret: AuthData.UserSecret): Observable<AuthData.UserSecret> {
    return defer(() => this.storageService.save(USER_SECRET_STORAGE_KEY, secret)).pipe(
      tap(() => this.currentSecretValue$.next(secret)),
      mapTo(secret),
    );
  }

  /** Removes current secret. */
  public removeSecret(): Observable<void> {
    return defer(() => this.storageService.remove(USER_SECRET_STORAGE_KEY)).pipe(
      tap(() => this.currentSecretValue$.next(null)),
    );
  }

  private initCurrentSecretStream(): Observable<AuthData.UserSecret | null> {
    const secretChange$ = this.currentSecretValue$;
    const secretFromStorage$ = concat(
      defer(() => this.storageService.get<AuthData.UserSecret>(USER_SECRET_STORAGE_KEY)),
      secretChange$,
    ).pipe(
      distinctUntilChanged((x, y) => x?.token === y?.token),
    );

    return race(
      secretFromStorage$,
      secretChange$,
    ).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}
