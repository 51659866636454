/* eslint-disable lines-around-comment */
import { enumToArray } from '../../utils/enum-to-array';

export namespace QuestionFields {
  /** Represents an identifier type of questionnaire field. */
  export enum FieldType {
    OrderedFieldList = 'OrderedFieldList',
    Checklist = 'Checklist',
    TileChecklist = 'FramedChecklist',
    Text = 'Text',
    Number = 'Number',
    Select = 'Select',
    ImageUpload = 'ImageUpload',
    Day = 'Day',
    GoogleAutocomplete = 'GoogleAutocomplete',
  }

  /**
   * Field types that are needed to be parsed.
   */
  export enum DefinedFieldType {
    FullName = 'FullName',
    EmailAddress = 'EmailAddress',
    SquareFeetAboveGrade = 'SquareFeetAboveGrade',
    SquareFeetBelowGrade = 'SquareFeetBelowGrade',
    NumberOfBedrooms = 'NumberOfBedrooms',
    NumberOfBathrooms = 'NumberOfBathrooms',
  }

  /** Union of all the available fields. */
  export type AnyField =
    | Specific.Checklist
    | Specific.Text
    | Specific.Number
    | Specific.Select
    | Specific.OrderedFieldList
    | Specific.TileChecklist
    | Specific.ImageUpload
    | Specific.DayField
    | Specific.GoogleAutocomplete;

  export type ExtraField = Specific.Checklist | Specific.Select;

  /** Union of all the available validations. */
  export type AvailableValidation =
    | Specific.Text.Validation
    | Specific.Select.Validation
    | Specific.Checklist.Validation
    | Specific.TileChecklist.Validation
    | Specific.OrderedFieldList.Validation
    | Specific.ImageUpload.Validation
    | Specific.DayField.Validation;

  /**
   * Type guard for AnyField. Object.
   * @param object Any object.
   */
  export function isAnyField(object: unknown): object is AnyField {
    return enumToArray(FieldType).includes((<AnyField>object).type);
  }

  /** Namespace isolating different types of questionnaire fields. */
  export namespace Specific {
    /** Base question field information required to unify several fields into a union. */
    interface BaseQuestionField {
      /** Type of the field. */
      readonly type: FieldType;

      /** Defined type use for parse value when submit. */
      readonly definedType?: DefinedFieldType;
    }

    /** Base text field. For example, it may represent analogue of `<textarea>` or `<input>` depending on the configuration. */
    export interface Text extends BaseQuestionField {
      /** @inheritdoc */
      readonly type: FieldType.Text;

      /** Text validation type. */
      readonly validations?: Text.Validation[];

      /** Placeholder text. Grey text displayed when text field is empty. */
      readonly placeholder: string;

      /** Human-readable label of the field to give user the idea what field represents. */
      readonly label: string;

      /** Whether the text field can be multiline text. */
      readonly multiline?: boolean;

      /** Whether the text field is phone input (mask). */
      readonly mask?: Text.Mask;

      /** Simple text value of the field. */
      readonly value?: string;
    }

    export namespace Text {
      /** Types of validation available for the text field. */
      export enum Validation {
        /** Email validation. */
        Email = 'email',
        /** Required validation. */
        Required = 'required',
        /** Phone validation. */
        Phone = 'phone',
      }

      /** Types of mark for the text field. */
      export enum Mask {
        /** Phone mark. */
        Phone = 'phone',
      }
    }

    /** Base number field. For example, it may represent a `<input>` with type is number. */
    export interface Number extends BaseQuestionField {
      /** @inheritdoc */
      readonly type: FieldType.Number;

      /** Text validation type. */
      readonly validations?: Number.Validation[];

      /** Placeholder text. Grey text displayed when text field is empty. */
      readonly placeholder: string;

      /** Human-readable label of the field to give user the idea what field represents. */
      readonly label: string;

      /** Value of the field. */
      readonly value?: number;
    }

    export namespace Number {
      /** Types of validation available for the text field. */
      export enum Validation {
        /** Required validation. */
        Required = 'required',
      }
    }

    /** Represents an analogue of `<select>` element. Only one of the options may be selected. */
    export interface Select extends BaseQuestionField {
      /** @inheritdoc */
      readonly type: FieldType.Select;

      /** Select validation type. */
      readonly validations?: Select.Validation[];

      /** Placeholder text. */
      readonly placeholder?: string;

      /** Human-readable label of the field. */
      readonly label?: string;

      /** Available options to select. */
      readonly options: readonly Select.Option[];

      /** One of the available options selected by user. */
      readonly value?: Select.Option;
    }

    export namespace Select {
      /** Select option model. */
      export interface Option {
        /** Human-readable representation of option. */
        readonly label: string;

        /** Internal unique value of the option. */
        readonly value: string;
      }

      /** Types of validation available for the select field. */
      export enum Validation {
        /** Required validation. */
        Required = 'required',
      }

      /**
       * Options comparator.
       * @param opt1 Option.
       * @param opt2 Another option.
       */
      export function areOptionsEqual(opt1?: Option, opt2?: Option): boolean {
        return opt1?.value === opt2?.value;
      }
    }

    /** Checklist value. Represents a group of checkboxes that are to be selected. */
    export interface Checklist extends BaseQuestionField {
      /** @inheritdoc */
      readonly type: FieldType.Checklist;

      /** Checklist validation type. */
      readonly validations?: Checklist.Validation[];

      /** Human-readable label of the field. */
      readonly label?: string;

      /** Options available to select. */
      readonly options: readonly Checklist.Option[];

      /** Several (or one) options selected by user. */
      readonly value?: readonly Checklist.Option[];

      /** Custom option field. If present, additional customizable option is added. */
      readonly customValueField?: Text;
    }

    export namespace Checklist {
      /** Union of option types. */
      export type Option = SimpleOption | NestedOption;

      /** Types of validation available for the checkbox field. */
      export enum Validation {
        /** Required validation. */
        Required = 'required',
      }

      /**
       * Options comparator.
       * @param opt1 Option.
       * @param opt2 Another option.
       */
      export function areOptionsEqual(opt1: Option, opt2: Option): boolean {
        return opt1.value === opt2.value;
      }

      /**
       * Util type-guard for the nested option.
       * @param option Option to check.
       */
      export function isNestedOption(option: Checklist.Option): option is Checklist.NestedOption {
        return (<Checklist.NestedOption>option).child != null;
      }

      /** Represents a simple constant option that's available to be checked or not. */
      export interface SimpleOption {
        /** Internal unique value. */
        readonly value: string | number;

        /** Human-readable label. */
        readonly label: string;

        /**
         * Whether option is considered excluding - unique, none of other options can be checked when this one is selected.
         * For example, 'None of the above' option is considered to be kind of "unique".
         */
        readonly excluding?: boolean;
      }

      /** Nested option. Contains a recursive Checklist element so that the option may contain additional sub-options. */
      export interface NestedOption extends SimpleOption {
        /** Child checklist. */
        readonly child: Checklist;
      }
    }

    /** Tile checklist option. Represents a multi or single-selectable options that may be checked by user. */
    export interface TileChecklist extends BaseQuestionField {
      /** @inheritdoc */
      readonly type: FieldType.TileChecklist;

      /** TileChecklist validation type. */
      readonly validations?: TileChecklist.Validation[];

      /** List of available options. */
      readonly options: readonly TileChecklist.Option[];

      /** Whether multiple elements may be checked at the same time. */
      readonly multiple?: boolean;

      /** One or several selected values. */
      readonly value?: readonly TileChecklist.Option[];

      /** Custom option field. If present, additional customizable option is added. */
      readonly customValueField?: Text;

    }

    export namespace TileChecklist {
      /** Checklist option. */
      export interface Option {
        /** Human-readable label. */
        readonly label: string;
        /** Unique value. */
        readonly value: string;
        /** Sub options control. */
        readonly extra?: ExtraField[];
        /** Sub additional control. */
        readonly additional?: Checklist;
        /** Option image. */
        readonly backgroundImageUrl?: string;
        /** Human-readable option description. */
        readonly description?: string;
      }

      /** Types of validation available for the Checklist field. */
      export enum Validation {
        /** Required validation. */
        Required = 'required',
      }

      /**
       * Type-guard for checklist options.
       * @param o1 Option.
       * @param o2 Another option.
       */
      export function areOptionsEqual(o1: Option, o2: Option): boolean {
        return o1.value === o2.value;
      }
    }

    /**
     * Ordered field list control.
     * Represents a list of elements with some of them available to be editable elements.
     * E.g.: https://projects.invisionapp.com/d/#/console/21313976/454127677/preview.
     */
    export interface OrderedFieldList extends BaseQuestionField {
      /** @inheritdoc */
      readonly type: FieldType.OrderedFieldList;

      /** OrderedFieldList validation type. */
      readonly validations?: OrderedFieldList.Validation[];

      /** Field list elements options. */
      readonly options: readonly OrderedFieldList.ListItem[];

      /** Field list elements value. */
      readonly value?: string;

      /** Field google place id. */
      readonly placeId?: string;
    }

    export namespace OrderedFieldList {
      /** Types of validation available for Ordered List Field. */
      export enum Validation {
        /** Required validation. */
        Required = 'required',
      }

      /** List item unification. */
      export type ListItem = EditableListItem | ConstListItem;

      /** Editable list item. */
      export type EditableListItem = GoogleAutocomplete;

      /** Constant list item. Without any value. */
      export interface ConstListItem {
        /** Human-readable item label. */
        readonly label: string;
      }

      /**
       * Type-guard for editable list item.
       * @param item Item to check.
       */
      export function isAutoCompleteItem(item: unknown): item is EditableListItem {
        return (<AnyField>item).type === FieldType.GoogleAutocomplete;
      }
    }

    /**
     * Google Autocomplete control.
     * Represents a input with google autocomplete data.
     * E.g.: https://projects.invisionapp.com/d/main#/console/21313976/454127677/preview.
     */
    export interface GoogleAutocomplete extends BaseQuestionField {
      /** @inheritdoc */
      readonly type: FieldType.GoogleAutocomplete;

      /** OrderedFieldList validation type. */
      readonly validations?: GoogleAutocomplete.Validation[];

      /** Placeholder text. Grey text displayed when text field is empty. */
      readonly placeholder: string;

      /** Human-readable item label. */
      readonly label: string;

      /** Field list elements value. */
      readonly value?: {
        /** Address value. */
        readonly address: string;

        /** Unit value. */
        readonly unit?: string;

      };

      /** Field google place id. */
      readonly placeId?: string;

      /** Adress unit placeholder text. */
      readonly unitPlaceholder?: string;
    }

    export namespace GoogleAutocomplete {
      /** Types of validation available for Google Autocomplete Field. */
      export enum Validation {
        /** Required validation. */
        Required = 'required',
      }

    }

    /** Image upload field. */
    export interface ImageUpload extends BaseQuestionField {
      /** @inheritdoc */
      readonly type: FieldType.ImageUpload;

      /** ImageUpload validation type. */
      readonly validations?: ImageUpload.Validation[];

      /** List of image containers containing the image urls. */
      readonly containers: readonly ImageUpload.ImageContainer[];
    }

    export namespace ImageUpload {
      /** Types of validation available for Image Upload Field. */
      export enum Validation {
        /** Required validation. */
        Required = 'required',
      }

      /**
       * Containers comparator.
       * @param c1 Container.
       * @param c2 Another container.
       * @returns `true` if equal.
       */
      export function compareContainers(c1: ImageContainer, c2: ImageContainer): boolean {
        return c1 === c2;
      }

      /** Container for the image value. */
      export interface ImageContainer {
        /** Image url. */
        readonly value: string | null;
      }
    }

    /** Represents a picker for day in a range of dates. */
    export interface DayField extends BaseQuestionField {
      /** @inheritdoc */
      readonly type: FieldType.Day;

      /** ImageUpload validation type. */
      readonly validations?: DayField.Validation[];

      // So for data to be serializable, it is crucial to store the dates in string.
      /** Range of dates. */
      readonly range: {
        /** Start ISO date. */
        readonly start: string;
        /** End ISO date. */
        readonly end: string;
      };

      /** List selected dates. */
      readonly value?: string[];
    }

    export namespace DayField {
      /** Types of validation available for Day Field. */
      export enum Validation {
        /** Required validation. */
        Required = 'required',

        /** Max select 2 item validation. */
        Max2 = 'max2',
      }

      /**
       * Date Options comparator.
       * @param opt1 Option String.
       * @param opt2 Another option Date.
       */
      export function areOptionsEqual(opt1: string, opt2: Date): boolean {
        return opt1 === opt2.toISOString();
      }
    }
  }
}
