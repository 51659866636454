import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControlTyped } from '@angular/forms';
import { AutocompleteConfiguration } from '@zc/common/core/models/autocomplete-configuration';
import { listenControlChanges } from '@zc/common/core/rxjs/listen-control-changes';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { Destroyable } from '@zc/common/core/utils/destroyable';
import { paginate } from '@zc/common/core/utils/paginate';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

/** Represents a control containing autocomplete for selecting list of items from provided list of options. */
@Destroyable()
@Component({
  selector: 'zc-entity-selector-control',
  templateUrl: './entity-selector-control.component.html',
  styleUrls: ['./entity-selector-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(EntitySelectorControlComponent)],
})
export class EntitySelectorControlComponent<T> extends SimpleValueAccessor<readonly T[]> implements OnInit {

  /** Input element. */
  @ViewChild('input', { read: ElementRef })
  public entityInputElement!: ElementRef;

  /** Placeholder text. */
  @Input()
  public placeholder = '';

  /** Autocomplete configuration. */
  @Input()
  public configuration!: AutocompleteConfiguration<T>;

  /** Fetched objects. */
  public readonly data$: Observable<readonly T[] | null>;

  /** Search value control. */
  public readonly filterControl: FormControlTyped<string>;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    formBuilder: FormBuilder,
  ) {
    super(changeDetectorRef);
    this.filterControl = formBuilder.controlTyped<string>('');
    this.data$ = paginate({
      ...AutocompleteConfiguration.PAGINATION_DEFAULTS,
      searchString: listenControlChanges<string>(this.filterControl),
    }, options => {
        assertNonNull(this.configuration?.fetch);

        return this.configuration?.fetch(options);
      }).pipe(
      map(page => page?.items.slice() ?? null),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    assertNonNull(this.configuration);
  }

  /**
   * Adds option to a list of selected ones.
   * @param option Option to select.
   */
  public onSelect(option: T): void {
    this.controlValue = this.controlValue ? this.controlValue.concat(option) : [option];
    this.filterControl.setValue('');
  }

  /**
   * Removes an option from the list of selected ones.
   * @param item Option to remove.
   */
  public onRemove(item: T): void {
    assertNonNull(this.configuration);
    const { comparator } = this.configuration;
    assertNonNull(comparator);

    if (this.controlValue == null) {
      return;
    }

    this.controlValue = this.controlValue.filter(v => !comparator(v, item));
  }

  /**
   * Checks whether the option is selected.
   * @param option Option to check.
   */
  public isSelected(option: T): boolean {
    const { configuration, controlValue } = this;
    assertNonNull(configuration.comparator);

    if (controlValue == null) {
      return false;
    }

    return controlValue.find(selectedOption => configuration.comparator(option, selectedOption)) != null;
  }

  /** Handle click on option. */
  public onOptionClick(): void {
    this.entityInputElement.nativeElement.blur();
  }

  /** Should show input when not disabled or no tag. */
  public shouldShowInput(): boolean {
    return !this.disabled || this.controlValue?.length === 0;
  }
}
