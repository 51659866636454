import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '../models/user';

import { FileUploadService } from './file-upload';

/** Service for uploading avatars. */
@Injectable({ providedIn: 'root' })
export class AvatarUploadService {

  public constructor(
    private readonly fileUploadService: FileUploadService,
  ) {}

  /**
   * Uploads the avatar into the storage without updating the user entity.
   * @param file Avatar file.
   * @param userId Id of a user for which the avatar should be uploaded.
   */
  public uploadAvatar(file: File, userId: User['id']): Observable<string> {
    return this.fileUploadService.upload(file, this.getStorageDestination(userId));
  }

  private getStorageDestination(userId: User['id']): string {
    return `user/${userId}/`;
  }
}
