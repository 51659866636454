/**
 * Safe transformation of given value to a number.
 * If given value is not valid it will replace it with 0.
 * @param value Value to transform.
 */
export function toNumber(value?: string | number | null): number {
  const result = Number(value);
  if (isNaN(result) || value == null) {
    return 0;
  }
  return result;
}
