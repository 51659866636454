import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@zc/common/shared/common-shared.module';
import { TemplatesSharedModule } from 'projects/sellers-web/src/app/features/agent-portal/templates/templates-shared/templates-shared.module';

import { FieldControlModule } from './field-control/field-control.module';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { QuestionControlComponent } from './question-control/question-control.component';
import { QuestionnaireHeaderComponent } from './questionaire-header/questionnaire-header.component';
import { QuestionnaireStageControlComponent } from './questionnaire-stage-control/questionnaire-stage-control.component';
import { QuestionnaireStepperComponent } from './questionnaire-stepper/questionnaire-stepper.component';
import { QuestionnairesPageComponent } from './questionnaires-page/questionnaires-page.component';

export const ORIGIN_PARAM_KEY = 'site-origin';

/** Question module. */
@NgModule({
  declarations: [
    QuestionnaireStepperComponent,
    QuestionnaireStageControlComponent,
    QuestionControlComponent,
    QuestionnairesPageComponent,
    QuestionnaireHeaderComponent,
    PhoneInputComponent,
  ],
  exports: [QuestionnairesPageComponent],
  imports: [
    CommonModule,
    CommonSharedModule,
    FormsModule,
    ReactiveFormsModule,
    FieldControlModule,
    HttpClientModule,
    TemplatesSharedModule,
    AgmCoreModule.forRoot({
      apiKey: $ENV.ANGULAR_APP_GOOGLE_MAP_API_KEY,
      libraries: ['places'],
    }),
    RouterModule,
  ],
})
export class QuestionnaireModule { }
