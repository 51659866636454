import { UserRoleGroup } from '@zc/common/core/models/user-role-group';

/**
 * Task statuses.
 */
export enum ProjectTaskStatusType {
  NotStarted = 'NotStarted',
  InProgress = 'InProgress',
  ToPm = 'ToPm',
  ToDa = 'ToDa',
  ToAgent = 'ToAgent',
  Done = 'Done',
}

export namespace ProjectTaskStatusType {

  const READABLE_MAP: Readonly<Record<ProjectTaskStatusType, string>> = {
    [ProjectTaskStatusType.Done]: 'Done',
    [ProjectTaskStatusType.InProgress]: 'In Progress',
    [ProjectTaskStatusType.NotStarted]: 'Not Started',
    [ProjectTaskStatusType.ToPm]: 'To Project Manager',
    [ProjectTaskStatusType.ToDa]: 'To Data Analyst',
    [ProjectTaskStatusType.ToAgent]: 'To Agent',
  };

  const TASK_STATUS_TO_ROLE_MAP: Readonly<Record<ProjectTaskStatusType, UserRoleGroup>> = {
    [ProjectTaskStatusType.Done]: UserRoleGroup.ProjectManager,
    [ProjectTaskStatusType.InProgress]: UserRoleGroup.ProjectManager,
    [ProjectTaskStatusType.NotStarted]: UserRoleGroup.ProjectManager,
    [ProjectTaskStatusType.ToPm]: UserRoleGroup.ProjectManager,
    [ProjectTaskStatusType.ToDa]: UserRoleGroup.DataAnalyst,
    [ProjectTaskStatusType.ToAgent]: UserRoleGroup.Agent,
  };

  /**
   * Return human-readable status representation.
   * @param status Task status.
   */
  export function toReadable(status: ProjectTaskStatusType): string {
    return READABLE_MAP[status];
  }

  /**
   * Compare function for task status.
   * @param s1 First status.
   * @param s2 Second status.
   */
  export function compare(s1: ProjectTaskStatusType, s2: ProjectTaskStatusType): boolean {
    return s1 === s2;
  }

  /**
   * Return user role that paired with status.
   * @param status Task status.
   */
  export function toUserRole(status: ProjectTaskStatusType): UserRoleGroup {
    return TASK_STATUS_TO_ROLE_MAP[status];
  }
}
