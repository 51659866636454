import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Pagination } from '@zc/common/core/models/pagination';

/**
 * Base implementation of paginator.
 */
@Directive()
export class PaginatorDirective {

  /** Pagination info. */
  @Input()
  public pagination: Pagination<unknown> | null = null;

  /** Prev page requested. */
  @Output()
  public prev = new EventEmitter<void>();

  /** Next page requested. */
  @Output()
  public next = new EventEmitter<void>();
}
