<!-- To preserve accessibility -->
<progress class="visually-hidden" [max]="max" [value]="value"></progress>

<div class="progress" *ngIf="value !== null && max !== null; else skeleton">
  <div
    class="progress__bar"
    [ngStyle]="{
      width: toPercent(value, max, 2) + '%'
    }"
  ></div>
  <div
    class="progress__helper-bar"
    [ngStyle]="{
      width: toPercent(hoverValue, max) + '%'
    }"
  ></div>
</div>

<ng-template #skeleton>
  <div class="progress progress_skeleton">
    <div class="progress__bar" zcSkeleton></div>
  </div>
</ng-template>
