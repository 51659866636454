import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '../models/user';

import { AvatarUploadService } from './avatar-upload.service';

/** Service for uploading a company logo. */
@Injectable({ providedIn: 'root' })
export class CompanyLogoUploadService {
  public constructor(
    private readonly avatarUploadService: AvatarUploadService,
  ) { }

  /**
   * Uploads company logo.
   * @param file File to upload.
   * @param userId User id.
   */
  public uploadLogo(file: File, userId: User['id']): Observable<string> {
    return this.avatarUploadService.uploadAvatar(file, userId);
  }
}
