<form
  class="address-form"
  [formGroup]="addressForm"
  (ngSubmit)="onSubmit()"
>
  <zc-google-autocomplete-field-control
    class="address-form__input"
    formControlName="address"
    [isStandalone]="true"
  >
  </zc-google-autocomplete-field-control>

  <button class="address-form__submit-button" type="submit">
    Get Your Offer
  </button>
</form>
