import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, HostListener } from '@angular/core';
import { Destroyable } from '@zc/common/core/utils/destroyable';

import { IDialogOptions } from '../dialog';

/**
 * Overlay component for dialog container.
 */
@Destroyable()
@Component({
  selector: 'zc-dialog-overlay-container',
  templateUrl: './dialog-overlay-container.component.html',
  styleUrls: ['./dialog-overlay-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class DialogOverlayContainerComponent {
  /** Bind Angular's animation attribute so that the transition effect would work. */
  @HostBinding('@fadeInOut')
  public fadeInOutAnimation = true;

  /** Dialog options. */
  public options: IDialogOptions | undefined;

  /** Emits when dialog is to be closed. */
  public closed = new EventEmitter<void>();

  /** Whether the dialog content was scrolled. */
  public contentScrolled = false;

  /** Handle backdrop click. */
  @HostListener('document:keydown.escape')
  public tryClose(): void {
    if (this.options?.closable) {
      this.closed.next();
    }
  }

  /**
   * Handles scroll change.
   * @param param0 Scroll event.
   */
  @HostListener('scroll', ['$event'])
  public onScroll({ target }: Event): void {
    this.contentScrolled = (<HTMLElement>target).scrollTop > 0;
  }
}
