import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

/**
 * Dialog header component.
 * Supposed to be used in a dialog component.
 * @example
 * ```html
 * <zc-dialog-header></zc-dialog-header>
 * <!-- ... dialog content -->
 * ```
 */
@Component({
  selector: 'zc-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent {

  /** Dialog title. */
  @Input()
  public title = '';

  /** Block's role. */
  @HostBinding('attr.role')
  public readonly role = 'presentation';
}
