import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'agent-select' },
  { path: 'agent-select', loadChildren: () => import('./features/agent-select/agent-select.module').then(m => m.AgentSelectModule) },
  {
    path: '',
    loadChildren: () => import('./features/agent-portal/agent-portal.module').then(m => m.AgentPortalModule),
  },
];

/** App routing module. */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
