import { IMapperFromDto } from '@zc/common/core/services/mappers/mappers';
import { Injectable } from '@angular/core';
import { ProductDto } from '@zc/common/core/services/mappers/dto/product.dto';
import { SubscriptionInterval, SubscriptionProduct } from '@zc/common/core/models/subscription-product';

import { assertNonNull } from '../../utils/assert-non-null';

import { PriceDto } from './dto/price.dto';
import { Money } from './money';

const INTERVAL_FROM_DTO_MAP: Readonly<Record<PriceDto.Recurring.Interval, SubscriptionInterval>> = {
  day: SubscriptionInterval.Day,
  month: SubscriptionInterval.Month,
  week: SubscriptionInterval.Week,
  year: SubscriptionInterval.Year,
};

/**
 * Product mapper.
 */
@Injectable({
  providedIn: 'root',
})
export class SubscriptionProductMapper implements IMapperFromDto<ProductDto, SubscriptionProduct> {

  /** @inheritDoc */
  public fromDto(data: ProductDto): SubscriptionProduct {
    const price = data.prices.filter(p => p.active)[0];
    assertNonNull(price);
    assertNonNull(price.recurring);

    return new SubscriptionProduct({
      id: data.id,
      active: data.active,
      name: data.name,
      intervalCount: price.recurring.interval_count,
      interval: INTERVAL_FROM_DTO_MAP[price.recurring.interval],
      priceId: price.id,
      price: new Money({
        currency: price.currency,
        unitsAmount: price.unit_amount,
      }),
      descriptionMarkdown: data.description,
    });
  }
}
