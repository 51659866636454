<ng-container *ngIf="controlValue">
  <!-- Need to case field to any, since typeguarding is not working properly on templates -->
  <div class="question-control" [ngSwitch]="controlValue.type">
    <zc-text-field-control
      *ngSwitchCase="fieldType.Text"
      [(ngModel)]="controlValue"
    >
    </zc-text-field-control>

    <zc-number-field-control
      *ngSwitchCase="fieldType.Number"
      [(ngModel)]="controlValue"
    >
    </zc-number-field-control>

    <zc-checklist-field-control
      *ngSwitchCase="fieldType.Checklist"
      [(ngModel)]="controlValue"
    >
    </zc-checklist-field-control>

    <zc-ordered-list-field-control
      *ngSwitchCase="fieldType.OrderedFieldList"
      [(ngModel)]="controlValue"
    >
    </zc-ordered-list-field-control>

    <zc-tile-checklist-field-control
      *ngSwitchCase="fieldType.TileChecklist"
      [(ngModel)]="controlValue"
    >
    </zc-tile-checklist-field-control>

    <zc-image-upload-field-control
      *ngSwitchCase="fieldType.ImageUpload"
      [(ngModel)]="controlValue"
    >
    </zc-image-upload-field-control>

    <zc-day-field-control
      *ngSwitchCase="fieldType.Day"
      [(ngModel)]="controlValue"
    >
    </zc-day-field-control>

    <zc-select-field-control
      *ngSwitchCase="fieldType.Select"
      [(ngModel)]="controlValue"
    >
    </zc-select-field-control>

    <zc-google-autocomplete-field-control
      *ngSwitchCase="fieldType.GoogleAutocomplete"
      [(ngModel)]="controlValue"
    >
    </zc-google-autocomplete-field-control>

    <p *ngSwitchDefault>
      Unexpected Type of Input ({{ controlValue.type }}) 😥
    </p>
  </div>
</ng-container>
