import { IMapperFromDto, IMapperToDtoWithErrors } from '@zc/common/core/services/mappers/mappers';
import { Injectable } from '@angular/core';
import { extractErrorMessage, ValidationErrorDto } from '@zc/common/core/services/mappers/dto/validation-error.dto';
import { EntityValidationErrors } from '@zc/common/core/models/app-error';

import { US_STATES } from '../../models/states';

import { BillingInformation, BillingInformationWithCard } from '../../models/billing-information';

import { BillingInformationDto, BillingInformationWithCardDto } from './dto/billing-information.dto';

/**
 * Billing information mapper.
 */
@Injectable({
  providedIn: 'root',
})
export class BillingInformationMapper
implements IMapperToDtoWithErrors<BillingInformationDto, BillingInformation>,
  IMapperFromDto<BillingInformationWithCardDto, BillingInformationWithCard> {

  /**
   * Maps billing data from dto.
   * @param data Data.
   */
  public fromDto(data: BillingInformationWithCardDto): BillingInformationWithCard {
    return {
      address: {
        addressLine1: data.address1,
        addressLine2: data.address2,
        city: data.city,
        country: '',
        state: US_STATES.find(state => state.abbreviation === data.state) ?? US_STATES[0],
        zipCode: data.zip_code,
      },
      firstName: data.first_name,
      lastName: data.last_name,
      card: {
        expirationMonth: String(data.payment_method.card.exp_month),
        expirationYear: String(data.payment_method.card.exp_year),
        lastFourDigits: data.payment_method.card.last4,
      },
    };
  }

  /**
   * Maps billing data from dto.
   * @param data Data.
   */
  public toDto(data: BillingInformation): BillingInformationDto {
    return {
      address1: data.address.addressLine1,
      address2: data.address.addressLine2,
      zip_code: data.address.zipCode,
      city: data.address.city,
      first_name: data.firstName,
      last_name: data.lastName,
      state: data.address?.state ? data.address.state.abbreviation : null,
    };
  }

  /** @inheritDoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<BillingInformationDto>,
  ): EntityValidationErrors<BillingInformation> {
    return {
      address: {
        addressLine1: extractErrorMessage(errorDto.address1),
        addressLine2: extractErrorMessage(errorDto.address2),
        city: extractErrorMessage(errorDto.city),
        state: extractErrorMessage(errorDto.state),
        zipCode: extractErrorMessage(errorDto.zip_code),
      },
      firstName: extractErrorMessage(errorDto.first_name),
      lastName: extractErrorMessage(errorDto.last_name),
    };
  }
}
