import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { QuestionFields } from '@zc/common/core/models/questionnaire/question-fields';
import { AppConfigService } from '@zc/common/core/services/app-config.service';
import { QuestionnaireDataUploadService } from '@zc/common/core/services/questionnaire-data-upload.service';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { Gallery } from '@zc/common/core/utils/gallery';
import { convertBase64ToBlob } from '@zc/common/core/utils/image-src';
import { createTrackByFunction } from '@zc/common/core/utils/trackby';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';
import { AlertDialogComponent } from '@zc/common/shared/modules/dialogs/alert-dialog/alert-dialog.component';
import { DialogsService } from '@zc/common/shared/modules/dialogs/dialogs.service';
import { InitDetail } from 'lightgallery/lg-events';
import { LightGallerySettings } from 'lightgallery/lg-settings';
import { LightGallery } from 'lightgallery/lightgallery';
import { from, map, mergeMap, Observable } from 'rxjs';

import ImageUpload = QuestionFields.Specific.ImageUpload;

const MAX_IMAGE_SIZE_MB = 10;
const MAX_IMAGE_SIZE_PIXEL = 1920;

// TODO: (Chernodub V.) move validation logic to upload method of file-upload service ?
/**
 * Validates image for size and format.
 * @param blob File.
 */
export function validateImage(blob: Blob): string | null {
  if (!['image/png', 'image/jpeg'].includes(blob.type)) {
    return 'Only jpg/png files are accepted';
  }

  if (blob.size > MAX_IMAGE_SIZE_MB * 1024 * 1024) {
    return `Max size for the image is ${MAX_IMAGE_SIZE_MB}mb`;
  }

  return null;
}

/** Image upload field control. */
@Destroyable()
@Component({
  selector: 'zc-image-upload-field-control',
  templateUrl: './image-upload-field-control.component.html',
  styleUrls: ['./image-upload-field-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(ImageUploadFieldControlComponent)],
})
export class ImageUploadFieldControlComponent extends SimpleValueAccessor<ImageUpload> {

  /** Light gallery settings. */
  public readonly settings: LightGallerySettings = {
    licenseKey: this.appConfig.lightGalleryKey,
    addClass: 'light-gallery light-gallery_large-image light-gallery_black-background',
    counter: false,
    controls: false,
  };

  /** Track by function for files. */
  public readonly trackFile = createTrackByFunction<ImageUpload.ImageContainer>('value');

  private lightGallery: LightGallery | null = null;

  public constructor(
    private readonly questionnaireDataUploadService: QuestionnaireDataUploadService,
    private readonly dialogsService: DialogsService,
    changeDetectorRef: ChangeDetectorRef,
    private readonly appConfig: AppConfigService,
  ) {
    super(changeDetectorRef);
  }

  /**
   * Handles image change by replacing control accessor value.
   * @param fileInput File input containing uploaded blob.
   * @param label Label element to set the loading on.
   */
  public onImageChange(fileInput: HTMLInputElement): void {
    assertNonNull(fileInput.files);

    const files = Array.from(fileInput.files);
    const validatedMessagesFromFiles = files.map(validateImage);
    const invalidMessages = [...new Set(validatedMessagesFromFiles.filter(Boolean))];

    if (invalidMessages.length > 0) {
      this.dialogsService.openDialog(AlertDialogComponent, {
        message: invalidMessages.join('. '),
        title: 'We could not upload your image',
        buttonTitle: 'OK',
      });
    }

    const validImageFiles = this.getValidImageFiles(files, validatedMessagesFromFiles);

    assertNonNull(this.controlValue);

    // Because multiple images can be submitted
    // and to be able to replace the exact index of the placeholder image
    // the starting index must be the length of containers
    const startingIndexForImages = this.controlValue.containers.length;
    this.initializePlaceholderImages(validImageFiles);

    from(validImageFiles).pipe(
      mergeMap(item => this.resizeImage(item)),
      mergeMap((image, index) => this.questionnaireDataUploadService.uploadForQuestionnaire(image).pipe(
        map(url => ({ url, index })),
      )),
      takeUntilDestroy(this),
    )
      .subscribe(({ url, index }) => this.replacePlaceholderImages(url, startingIndexForImages + index));
  }

  private mapToImageContainer(url: string | null): ImageUpload.ImageContainer {
    return { value: url };
  }

  private initializePlaceholderImages(files: readonly File[]): void {
    assertNonNull(this.controlValue);

    this.controlValue = {
      ...this.controlValue,
      containers: [
        ...this.controlValue.containers,
        ...Array(files.length).fill(null)
          .map(this.mapToImageContainer),
      ],
    };
  }

  private replacePlaceholderImages(url: string, indexToReplace: number): void {
    assertNonNull(this.controlValue);

    const updatedContainers = this.controlValue.containers.slice();
    updatedContainers.splice(
      indexToReplace,
      1,
      this.mapToImageContainer(url),
    );
    this.controlValue = {
      ...this.controlValue,
      containers: updatedContainers,
    };
  }

  private getValidImageFiles(files: readonly File[], validatedMessagesFromFiles: readonly (string | null)[]): File[] {
    const updatedFiles = files.slice();
    validatedMessagesFromFiles.forEach((message, index) => {
      if (message != null) {
        const invalidFileIndex = updatedFiles.findIndex(f => f === files[index]);
        updatedFiles.splice(invalidFileIndex, 1);
      }
    });
    return updatedFiles;
  }

  /**
   * Returns a styles object with image background.
   * @param container Container with image.
   * @returns Object for [ngStyle].
   */
  public getImageBackground(container: ImageUpload.ImageContainer): Record<string, string> | null {
    if (container.value) {
      return {
        backgroundImage: `url('${encodeURI(container.value)}')`,
      };
    }
    return null;
  }

  /**
   * Handles image remove.
   * @param container Container with image.
   */
  public onImageRemove(container: ImageUpload.ImageContainer): void {
    assertNonNull(this.controlValue);

    const updatedContainers = this.controlValue.containers.slice();
    updatedContainers.splice(
      updatedContainers.findIndex(c => ImageUpload.compareContainers(c, container)),
      1,
    );
    this.controlValue = {
      ...this.controlValue,
      containers: updatedContainers,
    };
  }

  /**
   * Whether the image can be removed from the container.
   * @param container Container to remove image from.
   */
  public canRemoveImage(container: ImageUpload.ImageContainer): boolean {
    return container.value != null;
  }

  /**
   * Fired only once when lightGallery is initialized.
   * @param detail Init event returns the plugin instance that can be used to call any lightGalley public method.
   */
  public readonly initializeGallery: Gallery.InitializeGalleryCallBack<ImageUploadFieldControlComponent> = (
    detail: InitDetail,
  ) => {
    this.lightGallery = detail.instance;
  };

  /**
   * Helper Resize Image with `MAX_IMAGE_SIZE_PIXEL` value.
   * @param file File input.
   */
  private resizeImage(file: File): Observable<File> {
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');

    const resize = (): File => {
      let { width, height } = image;

      if (width > height) {
        if (width > MAX_IMAGE_SIZE_PIXEL) {
          height *= MAX_IMAGE_SIZE_PIXEL / width;
          width = MAX_IMAGE_SIZE_PIXEL;
        }
      } else if (height > MAX_IMAGE_SIZE_PIXEL) {
        width *= MAX_IMAGE_SIZE_PIXEL / height;
        height = MAX_IMAGE_SIZE_PIXEL;
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
      const dataUrl = canvas.toDataURL('image/jpeg');
      const blob = convertBase64ToBlob(dataUrl);
      return new File([blob], file.name, {
        type: 'image',
      });
    };

    return new Observable(observer => {
      reader.onload = (readerEvent: ProgressEvent<FileReader>) => {
        image.onload = () => observer.next(resize());
        image.src = readerEvent.target?.result?.toString() ?? '';
      };
      reader.readAsDataURL(file);
    });
  }
}
