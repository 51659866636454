import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonSharedModule } from '@zc/common/shared/common-shared.module';

import { TemplatesSharedModule } from '../templates-shared/templates-shared.module';

import { AgentThirdTemplateComponent } from './agent-third-template.component';

/** Third template type. */
@NgModule({
  declarations: [AgentThirdTemplateComponent],
  exports: [AgentThirdTemplateComponent],
  imports: [
    CommonModule,
    TemplatesSharedModule,
    CommonSharedModule,
  ],
})
export class AgentThirdTemplateModule { }
