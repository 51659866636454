import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, first, map, mapTo, shareReplay, switchMap, switchMapTo } from 'rxjs/operators';

import { AppError } from '../models/app-error';
import { AuthData } from '../models/auth-data';
import { User } from '../models/user';
import { UserRoleGroup } from '../models/user-role-group';
import { filterNull } from '../rxjs/filter-null';
import { Destroyable, takeUntilDestroy } from '../utils/destroyable';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { UserDto } from './mappers/dto/user.dto';
import { UserMapper } from './mappers/user.mapper';
import { UserSecretStorageService } from './user-secret-storage.service';

/** Default url to redirect to after authorization. */
const DEFAULT_REDIRECT_URL: Readonly<Record<UserRoleGroup, readonly string[]>> = {
  [UserRoleGroup.Admin]: ['/z-box'],
  [UserRoleGroup.Contractor]: ['/z-box'],
  [UserRoleGroup.DataAnalyst]: ['/z-box'],
  [UserRoleGroup.ProjectManager]: ['/z-box'],
  [UserRoleGroup.TeamMember]: ['/z-box'],
  [UserRoleGroup.Seller]: ['/member-portal'],
  [UserRoleGroup.Agent]: ['/member-portal'],
};

/**
 * Stateful service for storing/managing data about the current user.
 */
@Destroyable()
@Injectable({
  providedIn: 'root',
})
export class UserService {

  /** Current user. Null when user is not logged in. */
  public readonly currentUser$: Observable<User | null>;

  /** Whether the user is authorized. */
  public readonly isAuthorized$: Observable<boolean>;

  private readonly currentUserUrl: URL;

  private readonly marketingSiteUrl: URL;

  public constructor(
    appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly authService: AuthService,
    private readonly userMapper: UserMapper,
    private readonly router: Router,
    private readonly userSecretStorage: UserSecretStorageService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.currentUserUrl = new URL('users/profile/', appConfig.apiUrl);
    this.currentUser$ = this.initCurrentUserStream();
    this.isAuthorized$ = this.currentUser$.pipe(
      map(user => user != null),
    );
    this.marketingSiteUrl = new URL(appConfig.marketingSiteUrl);

  }

  /**
   * Verify account registration.
   * @param verificationToken Account verification token.
   */
  public verifyAccount(verificationToken: string): Observable<void> {
    return this.authService.verifyAccount(verificationToken).pipe(
      switchMap(secret => this.userSecretStorage.saveSecret(secret)),
      switchMapTo(this.currentUser$),
      filterNull(),
      first(),
      mapTo(void 0),
    );
  }

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: AuthData.Login): Observable<void> {
    return this.authService.login(loginData).pipe(
      switchMap(secret => this.userSecretStorage.saveSecret(secret)),
      switchMapTo(this.isAuthorized$),
      filter(isAuthorized => isAuthorized),
      switchMap(() => this.redirectAfterAuthorization()),
    );
  }

  /**
   * Logout current user.
   */
  public logout(): Observable<void> {
    return this.userSecretStorage.removeSecret().pipe(
      finalize(() => this.navigateToMarketingSite()),
    );
  }

  /** Update user secret, supposed to be called when user data is outdated. */
  public refreshSecret(): Observable<void> {
    return this.userSecretStorage.currentSecret$.pipe(
      first(),
      switchMap(secret => secret != null ? this.authService.refreshSecret(secret) : throwError(() => new AppError('Unauthorized'))),

      // In case token is invalid clear the storage and redirect to login page
      catchError(error => this.userSecretStorage.removeSecret().pipe(
        switchMapTo(this.navigateToAuthPage()),
        switchMapTo(throwError(() => error)),
      )),
      switchMap(newSecret => this.userSecretStorage.saveSecret(newSecret)),
      mapTo(void 0),
    );
  }

  /**
   * Requests to reset the password.
   * @param data Data for resetting the password.
   * @returns Message for the user.
   */
  public resetPassword(data: AuthData.PasswordReset): Observable<string> {
    return this.authService.resetPassword(data);
  }

  /**
   * Set new password and confirm resetting.
   * @param data Confirm password reset.
   * @returns Success message.
   */
  public confirmPasswordReset(data: AuthData.PasswordResetConfirmation): Observable<string> {
    return this.authService.confirmPasswordReset(data);
  }

  private initCurrentUserStream(): Observable<User | null> {
    return this.userSecretStorage.currentSecret$.pipe(
      switchMap(secret => secret ? this.getCurrentUser() : of(null)),
      shareReplay(1),
    );
  }

  // eslint-disable-next-line require-await
  private async redirectAfterAuthorization(): Promise<void> {
    this.currentUser$.pipe(
      filterNull(),
      first(),
      map(user => user.roleGroup),
      map(roleGroup => this.router.createUrlTree(DEFAULT_REDIRECT_URL[roleGroup].slice())),
      takeUntilDestroy(this),
    ).subscribe({ next: route => this.router.navigateByUrl(route) });
  }

  private getCurrentUser(): Observable<User> {
    return this.httpClient.get<UserDto>(this.currentUserUrl.toString()).pipe(
      map(user => this.userMapper.fromDto(user)),
    );
  }

  private navigateToAuthPage(): Promise<void> {
    return this.router.navigate(['/auth']).then();
  }

  private navigateToMarketingSite(): void {
    this.document.location.href = this.marketingSiteUrl.toString();
  }
}
