import { Injectable } from '@angular/core';
import { Observable, Subject, switchMap } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { finalize, ignoreElements, share } from 'rxjs/operators';

const DEFAULT_DURATION_MS = 3000;

interface Notification {

  /** Message. */
  readonly message: string;

  /** Duration of notification message. */
  readonly duration: number;

  /** Whenever snackbar is auto-dismissed after specified duration the callback is called. */
  readonly onDismiss: () => void;
}

/** Notification service. */
@Injectable({ providedIn: 'root' })
export class NotificationService {

  /** Whenever the stream is observed, snackbar messages are displayed. */
  public readonly notification$: Observable<never>;

  private readonly _notification$ = new Subject<Notification>();

  private get shouldNotify(): boolean {
    return this._notification$.observed;
  }

  public constructor(
    snackBar: MatSnackBar,
  ) {
    this.notification$ = this._notification$.pipe(
      switchMap(({ message, duration, onDismiss }) => {
        const snackBarRef = snackBar.open(
          message,
          '',
          {
            duration,
            horizontalPosition: 'left',
            panelClass: 'zc-snackbar-notification',
          },
        );
        return snackBarRef.afterDismissed().pipe(finalize(onDismiss));
      }),
      share(),
      ignoreElements(),
    );
  }

  /**
   * Notify a user with a message.
   * @param message Human-readable message.
   * @param duration Duration of notification message.
   */
  public notify(message: string, duration: number = DEFAULT_DURATION_MS): Promise<void> {
    if (this.shouldNotify) {
      return new Promise(resolve => {
        this._notification$.next({
          message,
          duration,
          onDismiss: resolve,
        });
      });
    }
    return Promise.reject(new Error('There is no active subscribers for notifications.'));
  }
}
