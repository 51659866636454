import { Component, ChangeDetectionStrategy, TemplateRef, ViewChild } from '@angular/core';

/**
 * Custom content for overlay menu.
 * Content will always be above menu-items.
 * @example
 * ```html
 * <button (click)="menu.toggle()">
 *   <mat-icon>more_horiz</mat-icon>
 * </button>
 *
 * <zc-overlay-menu #menu>
 *   <zc-overlay-menu-content>
 *    <div>Custom content yay!</div>
 *   </zc-overlay-menu-content>
 * </zc-overlay-menu>
 * ```
 */
@Component({
  styleUrls: [],
  selector: 'zc-overlay-menu-content',
  templateUrl: './overlay-menu-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayMenuContentComponent {
  /** Content ref. */
  @ViewChild('content', { static: true })
  public content!: TemplateRef<unknown>;
}
