<zcw-agent-section>
  <h2 class="visually-hidden">Video Box</h2>
  <zcw-agent-section-title>
    <ng-content select="title"></ng-content>
  </zcw-agent-section-title>
  <zcw-agent-section-description>
    <ng-content select="description"></ng-content>
  </zcw-agent-section-description>
  <a [href]="videoSrc" class="video-box">
    <img
      src="/assets/img/sale-video-background.jpg"
      alt="For Sale video"
      class="video-box__background-img"
    />
  </a>
</zcw-agent-section>
