<ng-container *ngIf="controlValue">
  <label class="number-field__label">
    <span *ngIf="controlValue.label">{{ controlValue.label }}</span>
    <input
      class="form-input"
      type="number"
      [ngModel]="controlValue.value"
      (ngModelChange)="onChange($event)"
      [placeholder]="controlValue.placeholder"
    />
  </label>
</ng-container>
