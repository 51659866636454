import { Injectable } from '@angular/core';

import { AgentInformation } from '../../models/agent-information';
import { EntityValidationErrors } from '../../models/app-error';
import { assertFileUploaded } from '../../utils/assert-file-uploaded';
import { assertNonNull } from '../../utils/assert-non-null';

import { AgentCompanyInformationMapper } from './agent-company-information.mapper';
import { BillingInformationMapper } from './billing-information.mapper';
import { AgentProfileDto } from './dto/agent-profile.dto';
import { AgentRegistrationDto } from './dto/agent-registration.dto';
import { BillingInformationDto } from './dto/billing-information.dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperToDtoWithErrors } from './mappers';
import { PhoneMapper } from './phone.mapper';

/** Registration info mapper. */
@Injectable({
  providedIn: 'root',
})
export class AgentRegistrationInformationMapper implements IMapperToDtoWithErrors<AgentRegistrationDto, AgentInformation> {

  public constructor(
    private readonly agentCompanyInformationMapper: AgentCompanyInformationMapper,
    private readonly billingInformationMapper: BillingInformationMapper,
    private readonly phoneMapper: PhoneMapper,
  ) {}

  /** @inheritdoc */
  public toDto(data: AgentInformation): AgentRegistrationDto {
    assertFileUploaded(data.personal.profile.avatar);
    assertNonNull(data.personal.profile.phone);
    const territories = data.company.territories?.[0] ? [data.company.territories[0].id] : [];
    return {
      first_name: data.personal.profile.firstName,
      last_name: data.personal.profile.lastName,
      avatar: data.personal.profile.avatar,
      email: data.personal.account.email,
      password1: data.personal.account.password,
      password2: data.personal.account.password,
      gender: data.personal.profile.gender,
      license: data.personal.profile.license,
      phone: this.phoneMapper.toDto(data.personal.profile.phone),
      title: data.personal.profile.title,
      agent_info: this.agentCompanyInformationMapper.toDto(data.company),
      billing_info: this.billingInformationMapper.toDto(data.subscriptionPurchase.billing),
      territories,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<AgentRegistrationDto>): EntityValidationErrors<AgentInformation> {
    const agentInfo = errorDto.agent_info as ValidationErrorDto<AgentProfileDto> | undefined;
    const billingInfo = errorDto.billing_info as ValidationErrorDto<BillingInformationDto> | undefined;

    return {
      company: agentInfo && this.agentCompanyInformationMapper.validationErrorFromDto(agentInfo),
      subscriptionPurchase: {
        billing: billingInfo && this.billingInformationMapper.validationErrorFromDto(billingInfo),
      },
      personal: {
        account: {
          email: extractErrorMessage(errorDto.email),
          password: extractErrorMessage(errorDto.password1) ?? extractErrorMessage(errorDto.password2),
        },
        profile: {
          avatar: extractErrorMessage(errorDto.avatar),
          firstName: extractErrorMessage(errorDto.first_name),
          gender: extractErrorMessage(errorDto.gender),
          lastName: extractErrorMessage(errorDto.last_name),
          license: extractErrorMessage(errorDto.license),
          phone: extractErrorMessage(errorDto.phone),
          title: extractErrorMessage(errorDto.title),
        },
      },
    };
  }

}
