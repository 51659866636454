<form [formGroup]="questionnaireForm">
  <zc-progress
    [max]="stageControls.length"
    [value]="(currentStep$ | async) || 0"
    (selected)="onProgressSelected($event)"
    class="progress"
  ></zc-progress>
  <ng-container *ngFor="let stageControl of stageControls; let i = index">
    <section *ngIf="isCurrentStep(i) | async" @fadeInOut>
      <h2 class="visually-hidden">Step {{ i + 1 }}</h2>
      <zc-questionnaire-stage-control
        [formControl]="stageControl"
        [stageValidator]="stageValidator"
        (submitStage)="onStageSubmit(false)"
        (prevStage)="onPrevStep()"
        (forceSubmit)="onStageSubmit(true)"
      ></zc-questionnaire-stage-control>
    </section>
  </ng-container>
</form>
