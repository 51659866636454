import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CommonSharedModule } from '../../common-shared.module';

import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DialogContainerComponent } from './dialog-container/dialog-container.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { DialogOverlayContainerComponent } from './dialog-overlay-container/dialog-overlay-container.component';
import { DialogsService } from './dialogs.service';
import { PromptDialogComponent } from './prompt-dialog/prompt-dialog.component';

/** Dialogs module. */
@NgModule({
  declarations: [
    DialogHeaderComponent,
    DialogContainerComponent,
    DialogOverlayContainerComponent,
    PromptDialogComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
  ],
  imports: [
    CommonModule,
    CommonSharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [DialogsService],
  exports: [
    DialogContainerComponent,
    DialogHeaderComponent,
    ConfirmDialogComponent,
    PromptDialogComponent,
    AlertDialogComponent,
  ],
})
export class DialogsModule { }
