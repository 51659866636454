import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { merge } from 'rxjs';
import { filter, mapTo, tap } from 'rxjs/operators';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';

import { TabComponent } from '../tab/tab.component';

/**
 * Distribution of tabs.
 * Example:
 * Half of distribution means that all tabs will take only half of the width.
 */
type TabsDistribution = 'full' | 'half' | 'oneFourth';

/**
 * Tabs component.
 * @example
 * ```html
 *  <zc-tabs>
 *   <zc-tab selected>
 *     <zc-tab-button>My Tasks</zc-tab-button>
 *     <zc-tab-content>
 *      <ul>
 *        Task list:
 *        ...
 *      </ul>
 *     </zc-tab-content>
 *   </zc-tab>
 * </zc-tabs>
 * ```
 */
@Destroyable()
@Component({
  selector: 'zc-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['../tabs.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements AfterContentInit {

  /** Tabs distribution. */
  @Input()
  public distribution: TabsDistribution = 'full';

  /**
   * Tabs component number.
   * Added this property in order to avoid collisions in elements' ids,
   * so that we would be able to use accessible attributes.
   */
  public static tabsCounter = 0;

  /** Tabs list. */
  @ContentChildren(TabComponent)
  public tabs!: QueryList<TabComponent>;

  /** Tabs component number. Used for HTML id. */
  public readonly componentNum: number;

  public constructor() {
    this.componentNum = TabsComponent.tabsCounter++;
  }

  /**
   * Number of tab.
   * @param index Tab index.
   */
  public getTabNumber(index: number): string {
    return `tab-${index}${this.componentNum}`;
  }

  /**
   * Panel number.
   * @param index Panel index.
   */
  public getPanelNumber(index: number): string {
    return `panel-${index}${this.componentNum}`;
  }

  /** @inheritdoc */
  public ngAfterContentInit(): void {
    if (this.tabs == null) {
      return;
    }

    const tabSelected$ = merge(
      ...this.tabs.map(tab => tab.isSelected$.pipe(filter(selected => selected), mapTo(tab))),
    );

    // Unselect other tabs when one of the tabs selected
    tabSelected$.pipe(
      takeUntilDestroy(this),
      tap(selectedTab => this.tabs.forEach(tab => {
        if (tab !== selectedTab) {
          tab.unselect();
        }
      })),
    ).subscribe();
  }

  /**
   * Get class for current distribution.
   */
  public getDistributionClass(): { [key: string]: boolean; } {
    return {
      'one-fourth-distribution': this.distribution === 'oneFourth',
      'half-distribution': this.distribution === 'half',
      'full-distribution': this.distribution === 'full',
    };
  }
}
