import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validator } from '@angular/forms';
import { Question } from '@zc/common/core/models/questionnaire/question';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { assertIsFormArray, assertIsFormControl } from '@zc/common/core/utils/forms';
import { ZCValidators } from '@zc/common/core/utils/validators';
import { controlProviderFor, validatorProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

/** Question control component. */
@Destroyable()
@Component({
  selector: 'zc-question-control',
  templateUrl: './question-control.component.html',
  styleUrls: ['./question-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    controlProviderFor(QuestionControlComponent),
    validatorProviderFor(QuestionControlComponent),
  ],
})
export class QuestionControlComponent extends SimpleValueAccessor<Question> implements OnInit, Validator {
  /** Question form. */
  public readonly questionForm: FormGroup;

  public constructor(
    private readonly formBuilder: FormBuilder,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
    this.questionForm = this.formBuilder.group({
      fields: this.formBuilder.array([]),
    });
  }

  /** @inheritdoc */
  public validate(): ValidationErrors | null {
    return this.questionForm.invalid ? ZCValidators.buildAppError('Question answer is not valid.') : null;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.questionForm.valueChanges.pipe(
      takeUntilDestroy(this),
    ).subscribe(({ fields }) => {
      assertNonNull(this.controlValue);

      this.controlValue = {
        ...this.controlValue,
        fields,
      };
    });
  }

  /** @inheritdoc */
  public override writeValue(value: Question): void {
    super.writeValue(value);
    const { fields } = this.questionForm.controls;
    assertIsFormArray(fields);

    value.fields.forEach(field => {
      fields.push(this.formBuilder.control({
        value: field,
        disabled: false,
      }));
    });
  }

  /** Field controls array. */
  public get fieldControls(): FormControl[] {
    const { fields } = this.questionForm.controls;
    assertIsFormArray(fields);

    return fields.controls.map(control => {
      assertIsFormControl(control);
      return control;
    });
  }

}
