import { transition, trigger, useAnimation } from '@angular/animations';
import { LocationStrategy } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HISTORY } from '@zc/common/core/injection-tokens/history-token';
import { USER_AGENT_PARSER } from '@zc/common/core/injection-tokens/user-agent-parser';
import { WINDOW } from '@zc/common/core/injection-tokens/window-token';
import { Questionnaire } from '@zc/common/core/models/questionnaire/questionnaire';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { assertIsFormArray, assertIsFormControl } from '@zc/common/core/utils/forms';
import { fadeAnimation } from '@zc/common/shared/animations/fade';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UAParserInstance } from 'ua-parser-js';

import { QuestionnaireStageValidator } from '../questionnaire-stage-control/questionnaire-stage-control.component';

/**
 * OS that are parsed from user agent.
 */
enum UserAgentOS {
  Android = 'Android',
}

const INITIAL_PAGE = 0;

/** Questionnaire page. */
@Destroyable()
@Component({
  selector: 'zc-questionnaire-stepper',
  templateUrl: './questionnaire-stepper.component.html',
  styleUrls: ['./questionnaire-stepper.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('fadeInOut', [transition(':enter', useAnimation(fadeAnimation, { params: { start: 0, end: 1 } }))])],
})
export class QuestionnaireStepperComponent implements OnInit {

  /**
   * Prevent back button at the bottom of android devices navigate to previous page.
   * Instead, the back button will be used to go back to the previous question.
   * Https://stackoverflow.com/questions/45708082/prevent-android-back-button-using-javascript.
   */
  @HostListener('window:popstate')
  public onPopState(): void {
    if (this.isAndroidDevice) {
      this.history.pushState(null, '', this.window.location.href);
      if (this.currentStep$.value > 0) {
        this.currentStep$.next(this.currentStep$.value - 1);
      }
    }
  }

  /** Questionnaire. */
  @Input()
  public set questionnaire(q: Questionnaire | null) {
    if (q) {
      this.questionnaire$.next(q);
    }
  }

  /** Questionnaire submitted. */
  @Output()
  public readonly questionnaireSubmit = new EventEmitter<Questionnaire>();

  /** Questionnaire stage change. Emitted whenever one of the sages is submitted. */
  @Output()
  public readonly questionnaireStageChange = new EventEmitter<Questionnaire>();

  /** Stage validator function. */
  @Input()
  public stageValidator?: QuestionnaireStageValidator | null;

  /** Current index of questionnaire stage. */
  public readonly currentStep$ = new BehaviorSubject<number>(INITIAL_PAGE);

  /** Questionnaire form. */
  public readonly questionnaireForm: FormGroup;

  private readonly questionnaire$ = new ReplaySubject<Questionnaire>(1);

  private readonly isAndroidDevice = this.userAgentParser.getOS().name === UserAgentOS.Android;

  public constructor(
    private readonly formBuilder: FormBuilder,
    @Inject(USER_AGENT_PARSER) private readonly userAgentParser: UAParserInstance,
    @Inject(WINDOW) private readonly window: Window,
    @Inject(HISTORY) private readonly history: History,
    private readonly location: LocationStrategy,
  ) {
    this.questionnaireForm = this.formBuilder.group({
      stages: this.formBuilder.array([]),
    });

    if (this.isAndroidDevice) {
      // Need this line for onPopState method work correctly (https://stackoverflow.com/a/29500804)
      this.history.pushState(null, '', this.window.location.href);
    }
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.questionnaire$.pipe(takeUntilDestroy(this)).subscribe(questionnaire => {
      const stagesFormArray = this.questionnaireForm.controls.stages;
      assertIsFormArray(stagesFormArray);

      stagesFormArray.clear();
      this.currentStep$.next(INITIAL_PAGE);
      questionnaire.stages.forEach(stage => {
        stagesFormArray.push(
          this.formBuilder.control({
            value: stage,
            disabled: false,
          }),
        );
      });
    });
  }

  /** Array of stage controls. */
  public get stageControls(): FormControl[] {
    const { stages: stageControls } = this.questionnaireForm.controls;
    assertIsFormArray(stageControls);

    return stageControls.controls.map(control => {
      assertIsFormControl(control);

      return control;
    });
  }

  /**
   * Check whether the step is the one that user is filling up.
   * @param step Step to check.
   */
  public isCurrentStep(step: number): Observable<boolean> {
    return this.currentStep$.pipe(map(currentStep => currentStep === step));
  }

  /**
   * Handles stage submit.
   * @param isForceSubmit Whether is force submit current quesitonnaire instead of go to next step.
   */
  public onStageSubmit(isForceSubmit: boolean): void {
    const stagesControls = this.questionnaireForm.controls.stages;
    const questionnaire: Questionnaire = {
      ...this.questionnaireForm.value,
      step: this.currentStep$.value,
    };
    assertIsFormArray(stagesControls);

    this.questionnaireStageChange.emit(questionnaire);

    const totalSteps = stagesControls.controls.length;

    // Check if current stage is last step or force submit
    if (totalSteps === this.currentStep$.value + 1 || isForceSubmit) {
      this.questionnaireSubmit.emit(questionnaire);
      return;
    }
    this.currentStep$.next(this.currentStep$.value + 1);
  }

  /** Handle returning to the previous step. */
  public onPrevStep(): void {
    if (this.currentStep$.value > 0) {
      this.currentStep$.next(this.currentStep$.value - 1);
    } else {
      this.questionnaireStageChange.emit({ ...this.questionnaireForm.value, step: this.currentStep$.value - 1 });
    }
  }

  // TODO (Chernodub V.): For debug purposes, remove
  /**
   * Sets selected step.
   * @param progress Progress requested.
   */
  public onProgressSelected(progress: number): void {
    this.currentStep$.next(Math.floor(progress));
  }

}
