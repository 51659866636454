<form class="form" (ngSubmit)="onFormSubmit()" [formGroup]="form">
  <zc-label labelText="Enter Your Phone Number">
    <input
      class="form__input-phone"
      formControlName="sellerPhone"
      placeholder="Enter Your Phone Number"
      mask="(000) 000-0000"
    />
  </zc-label>
  <zc-label>
    <zc-re-captcha formControlName="isReCaptchaValid" class="form__re-catpcha"></zc-re-captcha>
  </zc-label>
  <footer class="form__footer">
    <button
      class="primary form__submit-button"
      type="submit"
      [zcLoading]="isLoading$ | async"
    >
      Next
    </button>
  </footer>
</form>
