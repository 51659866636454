import { AppError } from './app-error';

const min = 0;
const max = 5;

/** Five star rating. */
export class FiveStarRating {
  /** Numeric value. */
  public readonly value: number;

  public constructor(
    value: number,
  ) {
    if (value < min || value > max) {
      throw new AppError(`Unexpected rating. Expected value from ${min} to ${max}`);
    }
    this.value = value;
  }
}
