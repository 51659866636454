import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Pagination, PaginationWithNewCount } from '@zc/common/core/models/pagination';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';

/**
 * Table wrapper containing slots for positioning base elements like pagination, title etc.
 * Placeholder is shown when pagination data or table content area not provided.
 */
@Component({
  selector: 'zc-table-container',
  templateUrl: './table-container.component.html',
  styleUrls: ['./table-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableContainerComponent {

  /** Pagination data. */
  @Input()
  public pagination: Pagination<unknown> | PaginationWithNewCount<unknown> | null = null;

  /** Amount of columns to present a placeholder. */
  @Input()
  public placeholderColumnNum = 6;

  /** Amount of rows to present a placeholder. */
  @Input()
  public placeholderRowNum = 10;

  /** Displays skeleton when true. */
  @Input()
  public skeleton = true;

  /** Whether total items count should be displayed or not. */
  @Input()
  public withTotalCount = true;

  /** Page requested. */
  @Output()
  public readonly page = new EventEmitter<number>();

  /**
   * Helper to create an iterator of a range from 0 to `num`.
   * @param num Number to iterate over.
   */
  public iterateNumber(num: number): Iterable<unknown> {
    return Array(num).fill(null);
  }

  /** Handles selection of prev page. */
  public onPrevPage(): void {
    assertNonNull(this.pagination);
    this.page.emit(this.pagination.page - 1);
  }

  /** Handles selection of next page. */
  public onNextPage(): void {
    assertNonNull(this.pagination);
    this.page.emit(this.pagination.page + 1);
  }

  /** Whether the table have elements. */
  public get isNoData(): boolean {
    return this.pagination == null || this.pagination.items.length === 0;
  }

  /** Amount of new elements. */
  public get newCount(): number | undefined {
    if (this.pagination instanceof PaginationWithNewCount) {
      return this.pagination.newCount;
    }

    return void 0;
  }
}
