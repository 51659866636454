<div class="sidebar-header">
  <button class="sidebar-header__button_close" type="button" (click)="closeSidebar.emit()" title="Close">
    <mat-icon class="material-icons-outlined close-icon"> close </mat-icon>
  </button>
</div>
<div class="sidebar-content">
  <zcw-agent-nav-list class="sidebar-content__nav-list"></zcw-agent-nav-list>

  <div class="nav-button-wrapper">
    <a routerLink="via-marketing/questionnaires" class="nav-button">Get Your Offer </a>
  </div>
</div>
