import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { Pagination } from '../models/pagination';
import { PaginationOptions } from '../models/pagination-options';
import { Project } from '../models/project';
import { ProjectActivity } from '../models/project-activity';

import { AppConfigService } from './app-config.service';
import { PaginationDto } from './mappers/dto/pagination.dto';
import { ProjectActivityDto } from './mappers/dto/project-activity.dto';
import { PaginationMapper } from './mappers/pagination.mapper';
import { ProjectActivityMapper } from './mappers/project-activity.mapper';

/** Pagination options for activity. */
export interface ActivityPaginationOptions extends PaginationOptions {

  /** Id of a project. */
  readonly project: Project['id'];
}

/** Activities service. */
@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {

  private readonly activitiesUrl: URL;

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly appConfig: AppConfigService,
    private readonly activityMapper: ProjectActivityMapper,
    private readonly paginationMapper: PaginationMapper,
  ) {
    this.activitiesUrl = new URL('projects/activity/', appConfig.apiUrl);
  }

  /**
   * Returns a list of activities for the project.
   * @param options Pagination options.
   */
  public getTimelineForProject(options: ActivityPaginationOptions): Observable<Pagination<ProjectActivity>> {
    const params = new HttpParams({
      fromObject: {
        ...this.paginationMapper.mapOptionsToDto(options),
        project: options.project,
      },
    });

    return this.httpClient.get<PaginationDto<ProjectActivityDto>>(this.activitiesUrl.toString(), { params }).pipe(
      map(page => this.paginationMapper.mapPaginationFromDto(page, options, this.activityMapper)),
    );

  }

  /**
   * Puts activity into 'pinned' state.
   * @param id Id of activity.
   */
  public pinActivity(id: ProjectActivity['id']): Observable<void> {
    const url = new URL(`${id}/pin/`, this.activitiesUrl).toString();

    return this.httpClient.post(url, { }).pipe(
      mapTo(void 0),
    );
  }

  /**
   * Cancels 'pinned' state of activity.
   * @param id Id of activity.
   */
  public unpinActivity(id: ProjectActivity['id']): Observable<void> {
    const url = new URL(`${id}/unpin/`, this.activitiesUrl).toString();

    return this.httpClient.post(url, { }).pipe(
      mapTo(void 0),
    );
  }
}
