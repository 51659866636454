<nav>
  <ul class="navbar-nav">
    <li class="navbar-nav__item">
      <button
        class="navbar-nav__item-link"
        type="button"
        (click)="scrollToHowItWorks()"
      >
      How it works
    </button>
    </li>
    <li class="navbar-nav__item">
      <button
        class="navbar-nav__item-link"
        type="button"
        (click)="scrollToHomeSelling()"
      >
      Home Selling
    </button>
    </li>
    <li class="navbar-nav__item">
      <button
        class="navbar-nav__item-link"
        type="button"
        (click)="scrollToFAQ()"
      >
        FAQs
      </button>
    </li>
  </ul>
</nav>
