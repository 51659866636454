import { Injectable } from '@angular/core';

/**
 * App config service.
 * Provides information about current application environment configuration.
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  /** API base URL. */
  public readonly apiUrl = $ENV.ANGULAR_APP_API_URL;

  /** Sellers app url. */
  public readonly sellersAppUrl = $ENV.ANGULAR_APP_SELLERS_APP_URL;

  /** Stripe API key. */
  public readonly stripeApiKey = $ENV.ANGULAR_APP_STRIPE_API_KEY;

  /** Light gallery license key. */
  public readonly lightGalleryKey = $ENV.ANGULAR_APP_LIGHT_GALLERY_KEY;

  /** Marketing site url. */
  public readonly marketingSiteUrl = $ENV.ANGULAR_APP_MARKETING_SITE_URL;

  /** Privacy policy url. */
  public readonly privacyPolicyUrl = $ENV.ANGULAR_APP_PRIVACY_POLICY_URL;

  /** Annual promo code. */
  public readonly annualPromoCode = $ENV.ANGULAR_APP_ANNUAL_PROMO_CODE;

  /** Monthly promo code. */
  public readonly monthlyPromoCode = $ENV.ANGULAR_APP_MONTHLY_PROMO_CODE;

  /** Re-captcha site key. */
  public readonly recaptchaSiteKey = $ENV.ANGULAR_APP_RE_CAPTCHA_SITE_KEY;
}
