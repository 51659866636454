import { SubscriptionProduct } from './subscription-product';

export type SubscriptionConstructorData = Omit<Subscription, 'isCancelled'>;

/**
 * Subscription.
 */
export class Subscription {
  /** Id. */
  public readonly id: string;

  /** Status. */
  public readonly status: string;

  /** Default payment method. */
  public readonly defaultPaymentMethod: string;

  /** Purchasable representation of a subscription. */
  public readonly product: SubscriptionProduct;

  /** Date until the subscription is active. */
  public readonly activeUntil: Date;

  /** Whether the subscription is going to be automatically renewed. */
  public readonly willBeAutoRenewed: boolean;

  /** Next subscription. */
  public readonly nextSubscriptionProduct: SubscriptionProduct | null;

  public constructor(
    data: SubscriptionConstructorData,
  ) {
    this.id = data.id;
    this.status = data.status;
    this.defaultPaymentMethod = data.defaultPaymentMethod;
    this.product = data.product;
    this.activeUntil = data.activeUntil;
    this.willBeAutoRenewed = data.willBeAutoRenewed;
    this.nextSubscriptionProduct = data.nextSubscriptionProduct;
  }

  /** Whether the subscription is going to end won't be auto-renewed. */
  public get isCancelled(): boolean {
    return this.nextSubscriptionProduct == null && this.willBeAutoRenewed === false;
  }
}
