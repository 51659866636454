/** Year quarters. */
export enum Quarter {
  First,
  Second,
  Third,
  Fourth,
}

export namespace Quarter {
  const TO_READABLE_MAP: Readonly<Record<Quarter, string>> = {
    [Quarter.First]: 'Q1',
    [Quarter.Second]: 'Q2',
    [Quarter.Third]: 'Q3',
    [Quarter.Fourth]: 'Q4',
  };

  const TO_NUMBER_MAP: Readonly<Record<Quarter, number>> = {
    [Quarter.First]: 1,
    [Quarter.Second]: 2,
    [Quarter.Third]: 3,
    [Quarter.Fourth]: 4,
  };

  /**
   * Maps quarter to a number.
   * @param q Quarter.
   */
  export function toNumber(q: Quarter): number {
    return TO_NUMBER_MAP[q];
  }

  /**
   * Maps quarter to a human-readable value.
   * @param q Quarter.
   */
  export function toReadable(q: Quarter): string {
    return TO_READABLE_MAP[q];
  }
}
