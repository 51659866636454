import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';

/**
 * Tab content.
 */
@Component({
  selector: 'zc-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabContentComponent {

  /** Tab content ref. */
  @ViewChild('content', { static: true })
  public content!: TemplateRef<unknown>;
}
