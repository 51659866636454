<div class="header" [class.header_content-overlap]="contentOverlap">
  <zcw-agent-navbar [company]="company" [agent]="agent"  class="header__navbar"></zcw-agent-navbar>
  <div class="header__slot">
    <ng-content select="img, zc-video-player"> </ng-content>
  </div>
  <div class="header__secondary-slot">
    <ng-content></ng-content>
  </div>
  <div class="header__content content">
    <h1 class="content__title">
      <ng-content select="title"></ng-content>
    </h1>

    <p class="content__description">
      <ng-content select="description"></ng-content>
    </p>

    <zcw-agent-header-form class="content__form"></zcw-agent-header-form>
  </div>
</div>
