import { Injectable } from '@angular/core';

import { Address } from '../../models/address';
import { US_STATES } from '../../models/states';

import { AddressDto } from './dto/address.dto';
import { IMapperFromDto } from './mappers';

/** Address mapper. */
@Injectable({ providedIn: 'root' })
export class AddressMapper implements IMapperFromDto<AddressDto, Address> {
  /** @inheritdoc */
  public fromDto(data: AddressDto): Address {
    return {
      addressLine1: data.address1 ?? '',
      addressLine2: data.address2 ?? '',
      country: data.country ?? '',
      city: data.city ?? '',
      state: US_STATES.find(state => state.abbreviation === data.state) ?? { name: data.state ?? 'Unknown', abbreviation: '??' },
      zipCode: data.zip_code ?? '',
    };
  }

}
