import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AGENT_ID_PARAM_KEY } from 'projects/sellers-web/src/app/features/agent-portal/agent-portal-routing.module';
import { catchError, EMPTY, Observable, of, switchMap } from 'rxjs';

import { NotificationMessages } from '../constants/notifications';

import { TeamMember } from '../models/team-member';
import { NotificationService } from '../services/notification.service';
import { TeamService } from '../services/team.service';

const NO_AGENT_ID_MESSAGE = 'There is no agent with such id. Please, try again with different one';
const AGENT_WITHOUT_SUBSCRIPTION_MESSAGE = 'The agent doesn\'t have active subscription';

/** Agent resolver. */
@Injectable({
  providedIn: 'root',
})
export class AgentResolver implements Resolve<TeamMember> {

  public constructor(
    private readonly teamService: TeamService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) { }

  /** @inheritdoc */
  public resolve(route: ActivatedRouteSnapshot): Observable<TeamMember> {
    const agentId = route.paramMap.get(AGENT_ID_PARAM_KEY);
    if (agentId == null) {
      this.notificationService.notify(NO_AGENT_ID_MESSAGE);
      return EMPTY;
    }

    return this.teamService.getTeamMemberById(agentId).pipe(
      switchMap(agent => {

        if (TeamMember.isSubscriptionExpired(agent)) {
          this.notificationService.notify(AGENT_WITHOUT_SUBSCRIPTION_MESSAGE);
          this.router.navigate(['/not-found']);
          return EMPTY;
        }
        return of(agent);
      }),
      catchError(() => {
        this.notificationService.notify(NotificationMessages.SOMETHING_WENT_WRONG);
        return EMPTY;
      }),
    );
  }
}
