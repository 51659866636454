import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonSharedModule } from '@zc/common/shared/common-shared.module';

import { TemplatesSharedModule } from '../templates-shared/templates-shared.module';

import { AgentSecondTemplateComponent } from './agent-second-template.component';

/** Module containing second template for agent home page. */
@NgModule({
  declarations: [AgentSecondTemplateComponent],
  imports: [CommonModule, TemplatesSharedModule, CommonSharedModule],
  exports: [AgentSecondTemplateComponent],
})
export class AgentSecondTemplateModule { }
