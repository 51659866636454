import { Injectable } from '@angular/core';

import { Maxa } from '../../models/maxa';

import { MaxaDto } from './dto/maxa.dto';
import { IMapperFromDto } from './mappers';

/** Maxa mapper. */
@Injectable({ providedIn: 'root' })
export class MaxaMapper implements IMapperFromDto<MaxaDto, Maxa> {
  /** @inheritdoc */
  public fromDto(data: MaxaDto): Maxa {
    return {
      redirectUrl: data.redirect_url,
    };
  }
}
