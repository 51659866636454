import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ColorTheme } from './color-theme';

const DEFAULT_THEME: ColorTheme = ColorTheme.ReMax;

/** Store-service for application theme. */
@Injectable({ providedIn: 'root' })
export class AppThemeService {

  /** Theme. */
  public readonly theme$: Observable<ColorTheme>;

  /** Current application theme.  */
  private readonly _theme$: BehaviorSubject<ColorTheme>;

  public constructor() {
    this._theme$ = new BehaviorSubject<ColorTheme>(ColorTheme.ReMax);
    this.theme$ = this._theme$.asObservable();
  }

  /**
   * Sets theme.
   * @param theme Theme to set.
   */
  public setTheme(theme: ColorTheme): void {
    this._theme$.next(theme);
  }

  /** Sets the default application theme. */
  public setDefaultTheme(): void {
    this._theme$.next(DEFAULT_THEME);
  }
}
