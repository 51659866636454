import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import { Component, ChangeDetectionStrategy, ViewChild, ContentChildren, QueryList, Input, ElementRef, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Destroyable } from '@zc/common/core/utils/destroyable';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

import { OverlayMenuContentComponent } from './overlay-menu-content/overlay-menu-content.component';
import { OverlayMenuItemComponent } from './overlay-menu-item/overlay-menu-item.component';

/**
 * Menu component.
 *  * @example
 * ```html
 * <button (click)="menu.toggle()">
 *   <mat-icon>more_horiz</mat-icon>
 * </button>.
 *
 * <zc-overlay-menu #menu>
 *   <zc-overlay-menu-content>
 *    <div>Custom content yay!</div>
 *   </zc-overlay-menu-content>
 * </zc-overlay-menu>
 * ```.
 */
@Destroyable()
@Component({
  selector: 'zc-overlay-menu',
  templateUrl: './overlay-menu.component.html',
  styleUrls: ['./overlay-menu.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatMenuTrigger }],
})
export class OverlayMenuComponent implements OnDestroy {

  /** Origin html element to which the container should be attached. */
  @Input()
  public set origin(o: HTMLElement) {
    const customOriginRef = new ElementRef(o);
    this.overlayOrigin = new CdkOverlayOrigin(customOriginRef || this.elementRef);
  }

  /** List of menu items. */
  @ContentChildren(OverlayMenuItemComponent, { descendants: false })
  public menuItems?: QueryList<OverlayMenuItemComponent>;

  /** Menu content. */
  @ContentChildren(OverlayMenuContentComponent, { descendants: false })
  public menuContents?: QueryList<OverlayMenuContentComponent>;

  /** Cdk overlay element. */
  @ViewChild(CdkConnectedOverlay, { static: true })
  public overlay!: CdkConnectedOverlay;

  /** Cdk overlay origin element. */
  public overlayOrigin: CdkOverlayOrigin;

  /**
   * Menu positions. From most desired to least.
   * [Material API](https://material.angular.io/cdk/overlay/api).
   */
  public readonly positions: ConnectedPosition[] = [
    // Prioritized position below the origin
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },

    // Above the origin
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
  ];

  /** Whether the menu is open. */
  public readonly isOpen$ = new BehaviorSubject(false);

  /**
   * @param elementRef Element ref.
   */
  public constructor(
    private readonly elementRef: ElementRef<OverlayMenuComponent>,
  ) {
    this.overlayOrigin = new CdkOverlayOrigin(elementRef);
  }

  /** Toggle menu open state. */
  public toggle(): void {
    if (this.isOpen$.value) {
      this.close();
    } else {
      this.open();
    }
  }

  /** Close menu. */
  public close(): void {
    this.isOpen$.next(false);
  }

  /** Open menu. */
  public open(): void {
    this.isOpen$.next(true);
    this.overlay.backdropClick.pipe(first()).subscribe(() => this.close());
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    this.close();
  }
}
