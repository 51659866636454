import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef, Inject,
  Input, ViewChild,
} from '@angular/core';
import { AppError } from '@zc/common/core/models/app-error';
import { SecureCardInformation } from '@zc/common/core/models/billing-information';
import { CardSetupSecret } from '@zc/common/core/models/card-setup-secret';
import { BaseUser } from '@zc/common/core/models/user';
import { AppConfigService } from '@zc/common/core/services/app-config.service';
import { assertNonNullWithReturn } from '@zc/common/core/utils/assert-non-null';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { combineLatest, Observable, of, OperatorFunction, ReplaySubject, throwError } from 'rxjs';
import { delay, first, map, mapTo, shareReplay, switchMap, switchMapTo, tap, withLatestFrom } from 'rxjs/operators';

const EVENT_DELAY_MS = 3000;

// Alias for stripe element
type StripeElement = stripe.elements.Element;

/** Stripe form. */
interface StripeForm {

  /** Number. */
  readonly cardNumber: StripeElement;

  /** Expiry. */
  readonly cardExpiry: StripeElement;

  /** Cvc. */
  readonly cardCvc: StripeElement;
}

/** Stripe result. */
interface StripeResponse {

  /** Stripe error. */
  readonly error?: stripe.Error;
}

/**
 * Obtain the styles object for Stripe input.
 * @param globalStyles Global styles object.
 */
function getStylesForInput(globalStyles: CSSStyleDeclaration): stripe.elements.ElementsOptions['style'] {
  return {
    base: {
      'color': globalStyles.getPropertyValue('--primary-font-color'),
      'fontSize': '14px',
      '::placeholder': {
        color: globalStyles.getPropertyValue('--placeholder-color'),
        fontWeight: 'bold',
      },
      ':disabled': {
        backgroundColor: globalStyles.getPropertyValue('--faint-light-color'),
      },
    },
  };
}

/** Information required for displaying as a placeholder. */
export type PaymentFormPlaceholder = SecureCardInformation;

/**
 * Payment method form.
 * Emits `Payments.CardData` on submission.
 * @description
 * Uses Stripe forms under the hood.
 *
 * @example
 * ```html
 * <vpw-credit-card-form (submit)="onSubmit(cardForm)" #cardForm></vpw-credit-card-form>
 *
 * <button (click)="cardForm.submit.emit()"></button>
 * ```
 * And on your `.ts` file
 * ```typescript
 * public onCardDataSubmit(cardForm: CreditCardFormComponent): void {
 *  this.cardForm.pay().pipe(
 *    switchMap(data => this.someService.post(data)),
 *    // Error as a string.
 *    catchError((error: string) => ...),
 *  ).subscribe();
 * }
 * ```
 */
@Destroyable()
@Component({
  selector: 'zc-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentFormComponent implements AfterViewInit {

  /** Card number anchor. */
  @ViewChild('cardNumber')
  public cardNumberAnchor?: ElementRef<HTMLDivElement>;

  /** Card date anchor. */
  @ViewChild('cardExpiry')
  public cardExpiryAnchor?: ElementRef<HTMLDivElement>;

  /** Card cvc anchor. */
  @ViewChild('cardCvc')
  public cardCvcAnchor?: ElementRef<HTMLDivElement>;

  /** Whether the form is disabled or not. */
  @Input()
  public set disabled(state: boolean) {
    this._disabled$.next(state);
  }

  /** Placeholder that will be displayed when a form is disabled. */
  @Input()
  public placeholderOnDisabled: PaymentFormPlaceholder | null = null;

  /** Subject emits when form submission is failed. */
  public readonly error$ = new ReplaySubject<string>(1);

  /** NgAfterViewInit fired. */
  private readonly afterViewInit$ = new ReplaySubject<void>(1);

  /** Stripe instance. */
  private readonly stripeInstance$: Observable<stripe.Stripe>;

  /** Stripe form elements. Instantiated at AfterViewInit hook. */
  private readonly stripeForm$: Observable<StripeForm>;

  /** Whether the form is disabled. */
  public readonly _disabled$ = new ReplaySubject<boolean>(1);

  public constructor(
    private readonly appConfig: AppConfigService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.stripeInstance$ = this.initStripeStream();
    this.stripeForm$ = this.initStripeElements();
  }

  /**
   * Setup card for the user.
   * @param paymentSecret Secret to initiate card set up.
   * @param user User.
   */
  public setUpCard(paymentSecret: CardSetupSecret, user: BaseUser): Observable<void> {
    return this.stripeForm$.pipe(

      // Clear previous error message.
      tap(() => this.error$.next('')),
      withLatestFrom(this.stripeInstance$),
      switchMap(([{ cardNumber }, stripe]) => stripe.confirmCardSetup(paymentSecret.token, {
        payment_method: {
          card: cardNumber,
          billing_details: this.mapUserToBillingDetails(user),
        },
      })),
      this.handleStripeError(),

      // In order to let back-end handle the event.
      delay(EVENT_DELAY_MS),
      mapTo(void 0),
    );
  }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    this.afterViewInit$.next();
    this.afterViewInit$.complete();

    // Mount created stripe elements to DOM
    this.stripeForm$.pipe(
      first(),
      tap(form => this.mountStripeFormToDom(form)),
      takeUntilDestroy(this),
    ).subscribe();

    combineLatest([
      this.stripeForm$,
      this._disabled$,
    ]).pipe(
      tap(([form, disabled]) => Object.values(form).forEach((control: stripe.elements.Element) => control.update({ disabled }))),
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /**
   * Transforms to human-readable placeholder representing expiration date.
   * @param placeholder Placeholder info.
   */
  public toExpirationDatePlaceholder(placeholder: PaymentFormPlaceholder): string {
    return `${placeholder.expirationMonth} / ${placeholder.expirationYear}`;
  }

  /**
   * Transforms to human-readable placeholder representing card number.
   * @param placeholder Placeholder info.
   */
  public toCardNumberPlaceholder(placeholder: PaymentFormPlaceholder): string {
    return `**** **** **** ${placeholder.lastFourDigits}`;
  }

  /** Present stripe error to a user and stop the stream in case there is an error in response. */
  private handleStripeError<T extends StripeResponse>(): OperatorFunction<T, T | never> {
    return source => source.pipe(
      switchMap(stripeResult => {
        if (stripeResult.error) {
          const { message } = stripeResult.error;
          this.error$.next(message ?? '');
          return throwError(() => new AppError(message ?? ''));
        }
        return of(stripeResult);
      }),
    );
  }

  private initStripeElements(): Observable<StripeForm> {
    return this.afterViewInit$.pipe(
      switchMapTo(this.stripeInstance$),
      map(stripe => {
        // Create stripe elements instance and prepare styles
        const elements = stripe.elements();
        const style = getStylesForInput(getComputedStyle(this.document.body));

        // Pass data to next handlers
        return {
          cardNumber: elements.create('cardNumber', { style }),
          cardExpiry: elements.create('cardExpiry', { style }),
          cardCvc: elements.create('cardCvc', { style }),
        } as StripeForm;
      }),
      takeUntilDestroy(this),
      shareReplay(1),
    );
  }

  private mountStripeFormToDom({ cardNumber, cardExpiry, cardCvc }: StripeForm): void {
    cardNumber.mount(assertNonNullWithReturn(this.cardNumberAnchor).nativeElement);
    cardExpiry.mount(assertNonNullWithReturn(this.cardExpiryAnchor).nativeElement);
    cardCvc.mount(assertNonNullWithReturn(this.cardCvcAnchor).nativeElement);
  }

  private initStripeStream(): Observable<stripe.Stripe> {
    return of(Stripe(this.appConfig.stripeApiKey)).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private mapUserToBillingDetails(user: BaseUser): stripe.BillingDetails {
    return {
      name: `${user.firstName} ${user.lastName}`,
      address: {
        city: user.location.city,
        state: user.location.state?.abbreviation,
        line1: user.location.addressLine1,
        line2: user.location.addressLine2,
        postal_code: user.location.zipCode,
      },
    };
  }
}
