import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { QuestionFields } from '@zc/common/core/models/questionnaire/question-fields';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { Destroyable } from '@zc/common/core/utils/destroyable';
import { ZCValidators } from '@zc/common/core/utils/validators';
import { controlProviderFor, validatorProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

import Number = QuestionFields.Specific.Number;

/** Text field control. */
@Destroyable()
@Component({
  selector: 'zc-number-field-control',
  templateUrl: './number-field-control.component.html',
  styleUrls: ['./number-field-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    controlProviderFor(NumberFieldControlComponent),
    validatorProviderFor(NumberFieldControlComponent),
  ],
})
export class NumberFieldControlComponent extends SimpleValueAccessor<Number> implements Validator {
  /**
   * Handle changed value.
   * @param value Value.
   */
  public onChange(value: number): void {
    assertNonNull(this.controlValue);

    this.controlValue = {
      ...this.controlValue,
      value,
    };
  }

  /** @inheritdoc */
  public validate(): ValidationErrors | null {
    const _control = new FormControl();
    _control.setValue(this.controlValue?.value ?? '', { emitEvent: false });

    if (this.controlValue?.validations && this.controlValue?.validations.length > 0) {
      const validaroFnArr: ValidatorFn[] = this.controlValue?.validations.map(item => ZCValidators.getFormFieldValidator(item));
      _control.setValidators(validaroFnArr);
      _control.updateValueAndValidity();
    }
    this.changeDetectorRef.markForCheck();

    return _control.errors;
  }
}
