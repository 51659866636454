import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AgentBaseTemplate } from '../agent-base-template';

/** Third template type for agent. */
@Component({
  selector: 'zcw-agent-third-template',
  templateUrl: './agent-third-template.component.html',
  styleUrls: ['./agent-third-template.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentThirdTemplateComponent extends AgentBaseTemplate {

}
