import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FileUploadService } from './file-upload';

/** Service for uploading assets needed for passing the questionnaire. */
@Injectable({ providedIn: 'root' })
export class QuestionnaireDataUploadService {

  public constructor(
    private readonly fileUploadService: FileUploadService,
  ) {}

  /**
   * Uploads a file related to passing a questionnaire.
   * @param file File to upload.
   */
  public uploadForQuestionnaire(file: File): Observable<string> {
    return this.fileUploadService.upload(file, 'questionnaire/');
  }

}
