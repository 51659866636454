export namespace NotificationMessages {
  export const NOT_IMPLEMENTED = 'The feature is not implemented yet';
  export const SUCCESS_UPDATE = 'The information has been successfully updated';
  export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again';

  /**
   * Get creation message for the item.
   * @param item Item that was created. F.e task, note etc.
   */
  export function getCreationMessageFor(item: string): string {
    return `The ${item} has been successfully created`;
  }
}
