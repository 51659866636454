import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AppError } from '@zc/common/core/models/app-error';
import { QuestionFields } from '@zc/common/core/models/questionnaire/question-fields';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

import TileChecklist = QuestionFields.Specific.TileChecklist;

/** Tile checklist control. */
@Component({
  selector: 'zc-tile-checklist-field-control',
  templateUrl: './tile-checklist-field-control.component.html',
  styleUrls: ['./tile-checklist-field-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(TileChecklistFieldControlComponent)],
})
export class TileChecklistFieldControlComponent extends SimpleValueAccessor<TileChecklist> {
  /** Field type options. */
  public readonly fieldType = QuestionFields.FieldType;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  /**
   * Obtain the background image url from the option.
   * @param option Option.
   */
  public getBackground(option: TileChecklist.Option): string {
    return `url('${option.backgroundImageUrl}')`;
  }

  /** @inheritdoc */
  public override writeValue(value: TileChecklist | null): void {
    super.writeValue(value);
    if (value != null && value.value == null) {
      assertNonNull(this.controlValue);
      this.controlValue = {
        ...this.controlValue,
        value: [],
      };
    }
  }

  /**
   * Handle extra option change.
   * @param option Option.
   * @param newExtraOption New extra option.
   * @param index Index number.
   */
  public onExtraOptionsChange(option: TileChecklist.Option, newExtraOption: QuestionFields.ExtraField, index: number): void {
    assertNonNull(this.controlValue);

    const optionWithChangedExtra: TileChecklist.Option = {
      ...option,
    };
    assertNonNull(optionWithChangedExtra.extra);
    optionWithChangedExtra.extra[index] = newExtraOption;

    if (this.isSelected(option)) {
      this.uncheck(option);
      this.check(optionWithChangedExtra);
    }
  }

  /**
   * Check whether the option is selected.
   * @param option Option.
   */
  public isSelected(option: TileChecklist.Option): boolean {
    assertNonNull(this.controlValue);
    return this.controlValue.value?.find(selectedOption => TileChecklist.areOptionsEqual(selectedOption, option)) != null;
  }

  /**
   * Check extra dropdown is selected.
   * @param extra Select Dropdown.
   */
  public isSelectedDropdown(extra: QuestionFields.ExtraField[]): boolean {
    assertNonNull(extra);
    const dropdown = extra[0] as QuestionFields.Specific.Select;
    if (dropdown?.value) {
      return !!dropdown.value;
    }
    return false;
  }

  /**
   * Toggle option.
   * @param option Option.
   */
  public onToggle(option: TileChecklist.Option): void {
    if (this.isSelected(option)) {
      this.uncheck(option);
    } else {
      this.check(option);
    }
  }

  /** Options for the checklist. */
  public get options(): TileChecklist.Option[] {
    assertNonNull(this.controlValue?.value);

    const selectedOptions = this.controlValue.value;

    return this.controlValue.options.map(optionToSelect => selectedOptions.find(
      selectedOption => TileChecklist.areOptionsEqual(selectedOption, optionToSelect),
    ) ?? optionToSelect);
  }

  /**
   * Handle custom field change.
   * @param customValueField Custom value field.
   */
  public onCustomFieldChange(customValueField: QuestionFields.Specific.Text): void {
    assertNonNull(this.controlValue?.value);
    assertNonNull(customValueField.value);
    if (customValueField.value.length > 0) {
      const otherOption = {
        label: customValueField.label,
        value: customValueField.value,
      };

      this.controlValue = {
        ...this.controlValue,
        value: this.controlValue.value.filter(val => val.label !== customValueField.label).concat(otherOption as TileChecklist.Option),
        customValueField,
      };
    } else {
      this.controlValue = {
        ...this.controlValue,
        value: this.controlValue.value.filter(val => val.label !== customValueField.label),
        customValueField,
      };
    }
  }

  private check(option: TileChecklist.Option): void {
    assertNonNull(this.controlValue?.value);

    if (this.isSelected(option)) {
      throw new AppError('Option is not supposed to be selected when trying to check it.');
    }

    if (!this.controlValue.multiple) {
      this.uncheckAll();
    }

    this.controlValue = {
      ...this.controlValue,
      value: this.controlValue.value.concat(option),
    };
  }

  private uncheck(option: TileChecklist.Option): void {
    assertNonNull(this.controlValue?.value);

    if (!this.isSelected(option)) {
      throw new AppError('Option is supposed to be selected when trying to uncheck it.');
    }

    this.controlValue = {
      ...this.controlValue,
      value: this.controlValue.value.filter(selectedOption => !TileChecklist.areOptionsEqual(selectedOption, option)),
    };
  }

  private uncheckAll(): void {
    assertNonNull(this.controlValue);

    this.controlValue = {
      ...this.controlValue,
      value: [],
    };
  }
}
