import { DOCUMENT } from '@angular/common';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { PageScrollService } from 'ngx-page-scroll-core';

const DEFAULT_SCROLLING_DURATION = 500;

/** Represents navigate links list. */
@Component({
  selector: 'zcw-agent-nav-list',
  templateUrl: './agent-nav-list.component.html',
  styleUrls: ['./agent-nav-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentNavListComponent {
  public constructor(
    private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  /** Scroll to how-it-work section. */
  public scrollToHowItWorks(): void {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '#how-it-works',
      duration: DEFAULT_SCROLLING_DURATION,
    });
  }

  /** Scroll to home-selling section. */
  public scrollToHomeSelling(): void {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '#home-selling',
      duration: DEFAULT_SCROLLING_DURATION,
    });
  }

  /** Scroll to faq section. */
  public scrollToFAQ(): void {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '#faq',
      duration: DEFAULT_SCROLLING_DURATION,
    });
  }
}
