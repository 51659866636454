<ng-container *ngIf="controlValue">
  <ol class="order-list">
    <li
      class="order-list__item"
      *ngFor="
        let option of controlValue.options;
        let i = index;
        trackBy: trackOption
      "
    >
      <ng-container *ngIf="isAutoCompleteItem(option); else textItem">
        <zc-google-autocomplete-field-control
          class="order-list__autocomplete"
          [ngModel]="option"
          (ngModelChange)="onFieldChanged($event)"
        >
        </zc-google-autocomplete-field-control>
      </ng-container>
      <ng-template #textItem>
        {{ option.label }}
      </ng-template>
    </li>
  </ol>
</ng-container>
