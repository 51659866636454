import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NotificationService } from '@zc/common/core/services/notification.service';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { AppThemeService } from '@zc/common/shared/theme/app-theme.service';

/** Base app component. */
@Destroyable()
@Component({
  selector: 'zcw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  public constructor(
    private readonly appThemeService: AppThemeService,
    private readonly notificationService: NotificationService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.appThemeService.theme$.subscribe(theme => {
      this.document.body.setAttribute('data-theme', theme);
    });

    this.notificationService.notification$.pipe(
      takeUntilDestroy(this),
    ).subscribe();
  }
}
