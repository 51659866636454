import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { AuthData } from '../../models/auth-data';

import { PasswordChangeDto } from './dto/password-change.dto';
import {
  extractErrorMessage,
  ValidationErrorDto,
} from './dto/validation-error.dto';
import { IMapperFromDtoWithErrors, IMapperToDto } from './mappers';

/**
 * Mapper for change password process.
 */
@Injectable({ providedIn: 'root' })
export class PasswordChangeMapper implements
  IMapperFromDtoWithErrors<PasswordChangeDto, AuthData.PasswordChange>,
  IMapperToDto<PasswordChangeDto, AuthData.PasswordChange> {

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<PasswordChangeDto>,
  ): EntityValidationErrors<AuthData.PasswordChange> {
    return {
      password: extractErrorMessage(errorDto.old_password),
      newPassword: extractErrorMessage(errorDto.new_password),
      newPasswordConfirmation: extractErrorMessage(
        errorDto.new_password_confirm,
      ),
    };
  }

  /** @inheritdoc */
  public fromDto(dto: PasswordChangeDto): AuthData.PasswordChange {
    return {
      password: dto.old_password,
      newPassword: dto.new_password,
      newPasswordConfirmation: dto.new_password_confirm,
    };
  }

  /** @inheritdoc */
  public toDto(data: AuthData.PasswordChange): PasswordChangeDto {
    return {
      old_password: data.password,
      new_password: data.newPassword,
      new_password_confirm: data.newPasswordConfirmation,
    };
  }
}
