import { Component, ChangeDetectionStrategy, TemplateRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';

/**
 * Item for overlay menu. Supposed to be used only within the overlay-menu component.
 * @example
 * ```html
 * <button (click)="menu.toggle()">
 *   <mat-icon>more_horiz</mat-icon>
 * </button>
 *
 * <zc-overlay-menu #menu>
 *   <zc-overlay-menu-item> Mute Video </zc-overlay-menu-item>
 *   <zc-overlay-menu-item> Mute Audio </zc-overlay-menu-item>
 *   <zc-overlay-menu-item> Leave Seat </zc-overlay-menu-item>
 * </zc-overlay-menu>
 * ```
 */
@Component({
  styleUrls: [],
  selector: 'zc-overlay-menu-item',
  templateUrl: './overlay-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayMenuItemComponent {
  /** Content ref. */
  @ViewChild('content', { static: true })
  public content!: TemplateRef<unknown>;

  /** Emits whenever the menu item is clicked. */
  @Output()

  // Safe override of native input since component is transparent for DOM
  // eslint-disable-next-line @angular-eslint/no-output-native
  public click = new EventEmitter<void>();

  // Since `this` element would disappear when rendering `overlay-menu`, provide router link explicitly
  /** Router link. */
  @Input()
  public routerLink?: string | (string | number)[] | null | undefined;

  /** Whether the item should render a divider. */
  @Input()
  public withDivider = false;

  /** Type of menu item. Danger type uses danger color. */
  @Input()
  public type: 'default' | 'danger' = 'default';
}
