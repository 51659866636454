import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Destroyable } from '@zc/common/core/utils/destroyable';

export const ADDRESS_QUERY_PARAM_KEY = 'address';

/** Represents agent header form. */
@Destroyable()
@Component({
  selector: 'zcw-agent-header-form',
  templateUrl: './agent-header-form.component.html',
  styleUrls: ['./agent-header-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentHeaderFormComponent {
  /** Header form. */
  public readonly addressForm: FormGroup;

  public constructor(
    private readonly router: Router,
    formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    this.addressForm = formBuilder.group({
      address: ['', Validators.required],
    });
  }

  /** Handles form submission. */
  public onSubmit(): void {
    this.router.navigate(['./via-marketing/questionnaires'], {
      relativeTo: this.activatedRoute,
    });
  }
}
