import { Directive, HostBinding, Input } from '@angular/core';

/** Colored indication of a value relative to the projected one. */
@Directive({ selector: '[zcColorIndication]' })
export class ColorIndicationDirective {
  /** Value to compare with projected value. */
  @Input('zcColorIndication')
  public value: number | null = null;

  /** Projected value, needed in order to calculate the difference with real value. */
  @Input('zcColorIndicationProjectedValue')
  public projectedValue: number | null = null;

  /** Color indication strategy. Allows selecting indication behavior. */
  @Input('zcColorIndicationStrategy')
  public strategy = ColorIndicationDirective.defaultColorIndicationStrategy;

  /** Text color. */
  @HostBinding('style.color')
  public get color(): string | undefined {
    const difference = this.getValueDifference();
    if (difference == null) {
      return void 0;
    }
    return this.strategy(difference);
  }

  private getValueDifference(): number | undefined {
    if (this.value == null || this.projectedValue == null) {
      return;
    }
    return this.value - this.projectedValue;
  }
}

export namespace ColorIndicationDirective {

  /** Colors indicating the relation between value and it's projected value. */
  export enum Color {
    Green = '#0aa900',
    Red = '#eb0026',
    Orange = '#a96d00',
    Yellow = '#a0a301',
  }

  /**
   * @param difference Difference between value and projected value.
   */
  export type ColorIndicationStrategy = (difference: number) => Color;

  /**
   * Default color strategy. Returns color based on difference between values.
   * @param difference Difference between values.
   */
  export const defaultColorIndicationStrategy: ColorIndicationStrategy = difference => {
    if (difference < 0) {
      return Color.Red;
    }
    return Color.Green;
  };

  export const reversedColorIndicationStrategy: ColorIndicationStrategy = difference => defaultColorIndicationStrategy(-difference);

  export const warningColorIndicationStrategy: ColorIndicationStrategy = () => Color.Red;
}
