import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FileValidation } from '@zc/common/core/utils/file-validation';
import { controlProviderFor } from '@zc/common/core/utils/value-accessor';
import {
  BaseImageUploaderImplementation,
} from '@zc/common/shared/components/image-uploader/base-image-uploader-implementation';
import { FileFormat } from '@zc/common/core/enums/file-format';
import { DialogsService } from '@zc/common/shared/modules/dialogs/dialogs.service';

/** Logo-specific validations. */
export namespace LogoValidations {
  const MAX_SIZE_MB = 10;
  const ALLOWED_FILE_EXTENSIONS: readonly FileFormat[] = [
    FileFormat.GIF,
    FileFormat.JPEG,
    FileFormat.JPG,
    FileFormat.PNG,
  ];
  const INCORRECT_FILE_SIZE_MESSAGE = `Max size exceeded (${MAX_SIZE_MB.toFixed(2)}mb), please select a smaller image.`;

  /** Human-readable validation tip. */
  export const VALIDATION_TIP = `
  Max size is ${MAX_SIZE_MB}mb. Formats allowed: ${
  ALLOWED_FILE_EXTENSIONS.map(f => f.toUpperCase()).join(', ')})
  `;

  export const VALIDATORS: readonly ValidatorFn[] = [
    FileValidation.validateMaxSize(MAX_SIZE_MB, INCORRECT_FILE_SIZE_MESSAGE),
    FileValidation.validateFormat(ALLOWED_FILE_EXTENSIONS),
  ].map(FileValidation.adaptForForms);
}

/** Uploader for logo. */
@Component({
  selector: 'zw-logo-uploader',
  templateUrl: './logo-uploader.component.html',
  styleUrls: ['./logo-uploader.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(LogoUploaderComponent)],
})
export class LogoUploaderComponent extends BaseImageUploaderImplementation {
  /** Human-readable validation tip. */
  public readonly logoValidationTip = LogoValidations.VALIDATION_TIP;

  /** Whether image is being dragged. */
  public isDragging = false;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    domSanitizer: DomSanitizer,
    dialogService: DialogsService,
  ) {
    super(changeDetectorRef, domSanitizer, dialogService);
  }

  /** Enable dragover class when dragging images into uploader . */
  public handleDrag(): void {
    this.isDragging = true;
  }

  /** Disable dragover class when dragging done . */
  public handlePlace(): void {
    this.isDragging = false;
  }

}
