<ng-container *ngIf="controlValue">
  <label class="text-field__label">
    <span *ngIf="controlValue.label">{{ controlValue.label }}</span>
    <ng-container *ngIf="!controlValue.multiline; else textarea">
      <input
        class="text-field__input"
        [ngModel]="controlValue.value"
        (ngModelChange)="onChange($event)"
        [placeholder]="controlValue.placeholder"
        [mask]="controlValue.mask === textMask.Phone ? '000-000-0000' : ''"
      />
    </ng-container>
  </label>

  <ng-template #textarea>
    <textarea
      class="text-field__textarea"
      [ngModel]="controlValue.value"
      (ngModelChange)="onChange($event)"
      [placeholder]="controlValue.placeholder"
    ></textarea>
  </ng-template>
</ng-container>
