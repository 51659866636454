import { Injectable } from '@angular/core';

import { Profession } from '../../models/profession';

import { UserRoleGroup } from '../../models/user-role-group';
import { assertNonNull } from '../../utils/assert-non-null';

import { UserRoleDto, UserRoleGroupDto } from './dto/user-role.dto';
import { IMapperFromDto } from './mappers';

/** User role mapper. */
@Injectable({ providedIn: 'root' })
export class UserRoleMapper implements IMapperFromDto<UserRoleDto, Profession> {
  private static readonly USER_ROLE_MAP_FROM_DTO: Readonly<Record<UserRoleGroupDto, UserRoleGroup>> = {
    [UserRoleGroupDto.Agents]: UserRoleGroup.Agent,
    [UserRoleGroupDto.Sellers]: UserRoleGroup.Seller,
    [UserRoleGroupDto.Admins]: UserRoleGroup.Admin,
    [UserRoleGroupDto.Contractors]: UserRoleGroup.Contractor,
    [UserRoleGroupDto.Team]: UserRoleGroup.TeamMember,
  };

  /** @inheritdoc */
  public fromDto(data: UserRoleDto): Profession {
    return {
      id: data.id,
      name: data.name,
    };
  }

  /**
   * Profession info.
   * @param professionInfo Profession info.
   * @param roleInfo Role info.
   */
  public toDto(professionInfo: Profession, roleInfo: UserRoleGroup): UserRoleDto {
    return {
      id: professionInfo.id,
      name: professionInfo.name,
      group: this.groupToDto(roleInfo),
    };
  }

  /**
   * Maps role group from role dto.
   * @param data Data to map.
   */
  public groupFromDto(data: UserRoleGroupDto): UserRoleGroup {
    return UserRoleMapper.USER_ROLE_MAP_FROM_DTO[data];
  }

  /**
   * Maps role group to dto representation.
   * @param data Data to map.
   */
  public groupToDto(data: UserRoleGroup): UserRoleGroupDto {
    const entries = Object.entries(UserRoleMapper.USER_ROLE_MAP_FROM_DTO) as [UserRoleGroupDto, UserRoleGroup][];

    const group = entries.find(([_, key]) => key === data);
    assertNonNull(group);

    return group[0];
  }
}
