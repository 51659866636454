import { Directive, Input } from '@angular/core';
import { AgentInformation } from '@zc/common/core/models/agent-information';
import { AgentLanding } from '@zc/common/core/models/agent-landing';
import { Gender } from '@zc/common/core/models/gender';
import { TeamMember } from '@zc/common/core/models/team-member';

/** Base implementation of agent template. */
@Directive()
export abstract class AgentBaseTemplate {
  private readonly defaultHeroImage = AgentLanding.HeroImageType.First;

  /** Agent to present. */
  @Input()
  public agent: TeamMember | null = null;

  /** Template configuration. */
  @Input()
  public configuration?: AgentLanding.Configuration;

  /** Company of agent. */
  @Input()
  public company: AgentInformation.Company | null = null;

  /** Src of hero image. */
  public get heroImageSrc(): string {
    if (this.configuration?.customHeroImage != null) {
      return this.configuration.customHeroImage;
    }
    return AgentLanding.HeroImageType.toAssetSrc(this.configuration?.heroImageType ?? this.defaultHeroImage);
  }

  /** Gender of agent. */
  public get gender(): Gender | null {
    return this.agent?.gender ?? null;
  }
}
