import { AppError } from '../models/app-error';

/**
 * Asserts that a file is uploaded.
 * @param file File.
 */
export function assertFileUploaded(file: string | File | null): asserts file is (string | null) {
  if (file == null) {
    return;
  }

  if (file instanceof File) {
    throw new AppError('File must be uploaded');
  }
}
