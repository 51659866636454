import { Directive, ElementRef, Input } from '@angular/core';

/**
 * Applies disabled state on element.
 * @example
 * ```html
 * <button [zcDisabled]="isDisabled$ | async" (click)="isDisabled$.next(true)">Submit</button>
 * ```
 */
@Directive({ selector: '[zcDisabled]' })
export class DisabledDirective {

  /** Loading beacon. */
  @Input()
  public set zcDisabled(disabled: boolean | null) {
    if (disabled) {
      this.disable();
    } else {
      this.enable();
    }
  }

  /**
   * @param elementRef Element ref.
   */
  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
  ) { }

  /** Enable element. */
  public disable(): void {
    this.elementRef.nativeElement.setAttribute('disabled', 'true');
  }

  /** Disable element. */
  public enable(): void {
    this.elementRef.nativeElement.removeAttribute('disabled');
  }
}
