import { NullableProperties } from '../utils/types/nullable-properties';
import { WithFileProperties } from '../utils/types/with-file-properties';

import { AgentInformation } from './agent-information';

import { AppError } from './app-error';

import { FiveStarRating } from './five-star-rating';
import { Gender } from './gender';
import { OperationTerritory } from './operation-territory';
import { User } from './user';
import { UserRoleGroup } from './user-role-group';

/** Represents a member of back-office team. */
export interface TeamMember extends User {

  /** Member title. */
  readonly title: string | null;

  /** Territories at which user may operate. */
  readonly territories: readonly OperationTerritory[];

  /** Amount of projects user is working on. */
  readonly projectsCount: number;

  /** Company the user belongs to. */
  readonly companyName: string | null;

  /** Agent profile. */
  readonly agentProfile: AgentInformation.Company | null;

  /** 5-based rating. */
  readonly rating: FiveStarRating;

  /** License to work. */
  readonly license: string | null;

  /** Optional gender info. */
  readonly gender: Gender | null;

  /** Agent who referred the user. */
  readonly referringAgent: User | null;

  /** Whether subscription is expired. */
  readonly isExpired: boolean;

  /** Represent status of subscription. */
  readonly isSubscribed: boolean;

  /** The date the agent joined. */
  readonly joinedAt: Date;

  /** Whether account is active. */
  readonly isActive: boolean;

  /** Whether user's email is verified. */
  readonly isEmailVerified: boolean;
}

export namespace TeamMember {

  /**
   * Asserts that provided data is a team member.
   * @param user User.
   */
  export function assertIsTeamMember(user: unknown): asserts user is TeamMember {
    if ((user as TeamMember).isExpired === undefined && (user as TeamMember).isSubscribed === undefined) {
      throw new AppError('User is not a team member.');
    }
  }

  /**
   * Whether team member subscription expired.
   * @param teamMember TeamMember.
   */
  export function isSubscriptionExpired(teamMember: TeamMember): boolean {
    return (teamMember.isExpired || !teamMember.isSubscribed);
  }

  /**
   * Return readable license.
   * @param teamMember TeamMember.
   */
  export function toReadableLicense(teamMember: TeamMember): string {
    return `LIC#${teamMember.license ?? ''}`;
  }

  /**
   * Get territories for agent role.
   * @param territory Territory.
   */
  export function getTerritoriesForAgent(territory: OperationTerritory | null): OperationTerritory[] {
    return territory == null ? [] : [territory];
  }
}

// Other types can not be created on UI
const EDITABLE_TEAM_MEMBER_ROLES = [UserRoleGroup.TeamMember, UserRoleGroup.Contractor, UserRoleGroup.Agent, UserRoleGroup.Admin] as const;
export type EditableTeamMemberRole = (typeof EDITABLE_TEAM_MEMBER_ROLES)[number];

/**
 * Type-guard for editable member role.
 * @param role Role to check.
 */
export function isEditableTeamMemberRole(role: UserRoleGroup): role is EditableTeamMemberRole {
  return EDITABLE_TEAM_MEMBER_ROLES.includes(<EditableTeamMemberRole>role);
}

/** Data required to create a new team member. */
export type TeamMemberCreationData =
  Pick<
    NullableProperties<WithFileProperties<TeamMember, 'avatar'>,
      'avatar' |
      'phone' |
      'location' |
      'isSubscribed' |
      'isActive' |
      'isEmailVerified' |
      'agentProfile'
    >,
    'companyName' |
    'email' |
    'firstName' |
    'lastName' |
    'phone' |
    'territories' |
    'referringAgent' |
    'profession' |
    'license' |
    'title' |
    'roleGroup' |
    'avatar' |
    'gender' |
    'location' |
    'isSubscribed' |
    'isActive' |
    'isEmailVerified' |
    'agentProfile'
  >;

/** Data required to edit the team member. */
export type TeamMemberEditData = TeamMemberCreationData & Pick<TeamMember, 'id'>;

/** Data required to edit seller in project. */
export type SellerEditData = Pick<TeamMemberCreationData, 'firstName'> & Pick<TeamMember, 'id'>;
