import { SubscriptionProduct } from './subscription-product';

/** Subscription purchase information. */
export interface SubscriptionPurchaseData {

  /** Subscription represented as a product entity. */
  readonly product: SubscriptionProduct;

  /** Promocode. */
  readonly promocode: string | null;
}

export namespace SubscriptionPurchaseData {

  /**
   * @param data Subscription purchase data.
   * @returns Whether purchase data contain provocode or not.
   */
  export function hasPromocode(data: SubscriptionPurchaseData): boolean {
    return data.promocode != null && data.promocode !== '';
  }
}
