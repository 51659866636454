import { Money } from '../services/mappers/money';

import { AppError } from './app-error';

type ProductConstructorData = Omit<SubscriptionProduct, 'isBetterThan' | 'getSavingsRelativeTo'>;

/** Subscription interval. */
export enum SubscriptionInterval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

const MULTIPLIER_TO_YEAR_PRICE_MAP: Readonly<Record<SubscriptionInterval, number>> = {

  // Rough multipliers (not taking into account leap years intentionally), since the amounts are expected to be significantly different
  [SubscriptionInterval.Day]: 365,
  [SubscriptionInterval.Week]: 53,
  [SubscriptionInterval.Month]: 12,
  [SubscriptionInterval.Year]: 1,
};

/** Subscription discount info. */
export interface SubscriptionDiscount {

  /** Product to which the discount is applied to. */
  readonly product: SubscriptionProduct;

  /** Price after discount. */
  readonly newPrice: Money;
}

/** Represents relative money savings. */
export class SubscriptionProductSavings {
  /** Price per year. Used as an absolute value that's going to be converted to a needed interval. */
  private readonly pricePerYear: Money;

  public constructor(
    firstComparand: SubscriptionProduct,
    secondComparand: SubscriptionProduct,
  ) {
    if (firstComparand.price.currency !== secondComparand.price.currency) {
      throw new AppError('Currency comparation is not implemented.');
    }
    this.pricePerYear = this.comparandToYearPrice(secondComparand).subtract(this.comparandToYearPrice(firstComparand));
  }

  /** Whether the savings positive. */
  public get isPositive(): boolean {
    return this.pricePerYear.unitsAmount > 0;
  }

  /**
   * Returns an amount of savings for requested `interval`.
   * @param interval Interval.
   */
  public getForInterval(interval: SubscriptionInterval): Money {
    return this.pricePerYear.divide(MULTIPLIER_TO_YEAR_PRICE_MAP[interval]);
  }

  private comparandToYearPrice(comparand: SubscriptionProduct): Money {
    // Assuming it's calculated in the same currentcy due to a precondition in constructor
    return comparand.price.multiply(MULTIPLIER_TO_YEAR_PRICE_MAP[comparand.interval]);
  }
}

/**
 * Representation of a subscription for ZinCasa service as a buyable product.
 */
export class SubscriptionProduct {

  /** Unique id. */
  public readonly id: string;

  // TODO: (Chernodub V.) figure out a way to remove it from the domain model
  /** Unique price id. */
  public readonly priceId: string;

  /** Human-readable name. */
  public readonly name: string;

  /** Whether the product is currently available for purchase. */
  public readonly active: boolean;

  /** HTML markdown representing a product description. */
  public readonly descriptionMarkdown: string;

  /** Product price. */
  public readonly price: Money;

  /** The frequency at which a subscription is billed. */
  public readonly interval: SubscriptionInterval;

  /**
   * The number of intervals (specified in the `interval` attribute) between subscription billings.
   */
  public readonly intervalCount: number;

  public constructor(data: ProductConstructorData) {
    this.id = data.id;
    this.name = data.name;
    this.active = data.active;
    this.descriptionMarkdown = data.descriptionMarkdown;
    this.price = data.price;
    this.interval = data.interval;
    this.intervalCount = data.intervalCount;
    this.priceId = data.priceId;
  }

  /**
   * Provides a savings calculation relative to the provided `product`.
   * @param product Product to compare with.
   */
  public getSavingsRelativeTo(product: SubscriptionProduct): SubscriptionProductSavings {
    return new SubscriptionProductSavings(
      this,
      product,
    );
  }

  /**
   * Compares current product with the provided one and tells whether it's better or not.
   * @param secondProduct Another product.
   */
  public isBetterThan(secondProduct: SubscriptionProduct): boolean {
    return this.getSavingsRelativeTo(secondProduct).isPositive;
  }
}
