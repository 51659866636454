import { Injectable } from '@angular/core';

import { AgentInformation } from '../../models/agent-information';
import { EntityValidationErrors } from '../../models/app-error';
import { US_STATES } from '../../models/states';
import { assertFileUploaded } from '../../utils/assert-file-uploaded';

import { AgentCompanyInformationDto } from './dto/agent-profile.dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperWithErrors } from './mappers';
import { PhoneMapper } from './phone.mapper';

/** Mapper for company information. */
@Injectable({
  providedIn: 'root',
})
export class AgentCompanyInformationMapper implements IMapperWithErrors<AgentCompanyInformationDto, AgentInformation.Company> {

  public constructor(
    private readonly phoneMapper: PhoneMapper,
  ) {}

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<AgentCompanyInformationDto>,
  ): EntityValidationErrors<AgentInformation.Company> {
    return {
      addressLine1: extractErrorMessage(errorDto.company_address1),
      addressLine2: extractErrorMessage(errorDto.company_address2),
      city: extractErrorMessage(errorDto.company_city),
      state: extractErrorMessage(errorDto.company_state),
      zipCode: extractErrorMessage(errorDto.company_zip_code),
      logo: extractErrorMessage(errorDto.company_logo),
      name: extractErrorMessage(errorDto.company_name),
      phoneNumber: extractErrorMessage(errorDto.company_phone),
      websiteAddress: extractErrorMessage(errorDto.company_website),
      companyEmail: extractErrorMessage(errorDto.company_email),
    };
  }

  /** @inheritdoc */
  public fromDto(data: AgentCompanyInformationDto): AgentInformation.Company {
    return {
      addressLine1: data.company_address1,
      addressLine2: data.company_address2,
      city: data.company_city,
      state: US_STATES.find(state => state.abbreviation === data.company_state) ?? US_STATES[0],
      zipCode: data.company_zip_code,
      logo: data.company_logo,
      name: data.company_name,
      phoneNumber: data.company_phone ? this.phoneMapper.fromDto(data.company_phone) : null,
      websiteAddress: data.company_website,
      protectionNoticeUrl: data.protection_notice_url,
      brokerageServiceUrl: data.brokerage_service_url,
      companyEmail: data.company_email,
      territories: data.territories ?? null,
    };
  }

  /** @inheritdoc */
  public toDto(data: AgentInformation.Company): AgentCompanyInformationDto {
    const phone = data.phoneNumber ? this.phoneMapper.toDto(data.phoneNumber) : null;

    assertFileUploaded(data.logo);
    return {
      company_address1: data.addressLine1,
      company_address2: data.addressLine2,
      company_city: data.city,
      company_state: data.state ? data.state.abbreviation : null,
      company_zip_code: data.zipCode,
      company_website: data.websiteAddress?.length ? data.websiteAddress : null,
      company_logo: data.logo,
      company_name: data.name,
      company_phone: phone,
      protection_notice_url: data.protectionNoticeUrl?.length ? data.protectionNoticeUrl : null,
      brokerage_service_url: data.brokerageServiceUrl?.length ? data.brokerageServiceUrl : null,
      company_email: data.companyEmail ?? null,
      territories: data.territories ?? [],
    };
  }
}
