import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgentInformation } from '@zc/common/core/models/agent-information';
import { AgentLanding } from '@zc/common/core/models/agent-landing';
import { TeamMember } from '@zc/common/core/models/team-member';
import { AgentService } from '@zc/common/core/services/agent.service';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { AppThemeService } from '@zc/common/shared/theme/app-theme.service';
import { defer, merge, NEVER, Observable } from 'rxjs';
import { finalize, first, ignoreElements, map, shareReplay, switchMap } from 'rxjs/operators';

/** Agent home page component. */
@Destroyable()
@Component({
  selector: 'zcw-agent-portal',
  templateUrl: './agent-portal.component.html',
  styleUrls: ['./agent-portal.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentPortalComponent implements OnInit {

  /** Selected template type. */
  public readonly landingConfiguration$: Observable<AgentLanding.Configuration>;

  /** Types of templates for agent home page. */
  public readonly agentHomePageTemplates = AgentLanding.TemplateType;

  /** Agent info. */
  public readonly agent$: Observable<TeamMember>;

  /** Information about agent company. */
  public readonly agentCompany$: Observable<AgentInformation.Company>;

  public constructor(
    agentService: AgentService,
    activatedRoute: ActivatedRoute,
    private readonly appThemeService: AppThemeService,
  ) {
    this.agent$ = activatedRoute.data.pipe(
      map(data => {
        const { agent } = data;
        TeamMember.assertIsTeamMember(agent);
        return agent;
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.landingConfiguration$ = this.agent$.pipe(
      switchMap(agent => agentService.getLandingConfigurationByAgentId(agent.id)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.agentCompany$ = this.agent$.pipe(
      switchMap(agent => agentService.getCompanyInformationByAgentId(agent.id)),
    );
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    const setThemeSideEffect$ = this.landingConfiguration$.pipe(
      switchMap(({ theme: agentTheme }) => this.appThemeService.theme$.pipe(
        first(),
        switchMap(prevTheme => defer(() => {
          this.appThemeService.setTheme(agentTheme);

          return NEVER.pipe(
            finalize(() => this.appThemeService.setTheme(prevTheme)),
          );
        })),
      )),
    );

    merge(
      setThemeSideEffect$,
    ).pipe(
      takeUntilDestroy(this),
      ignoreElements(),
    )
      .subscribe();
  }
}
