<div class="backdrop" (click)="tryClose()" role="presentation"></div>
<zc-dialog-container>
  <header *ngIf="options?.title" class="overlay-header" [class.shadow]="contentScrolled">
    <zc-dialog-header
      [title]="options?.title || ''"
    >
    </zc-dialog-header>
    <button
      *ngIf="options?.closeButton"
      (click)="closed.next()"
      title="Close the dialog"
      class="close-button"
      type="button"
    ></button>
  </header>
  <main
    class="dialog-content"
    [class.dialog-content_padding]="options?.withPaddings"
    (scroll)="onScroll($event)"
  >
    <ng-content></ng-content>
  </main>
</zc-dialog-container>
