import { Injectable } from '@angular/core';

import { CardSetupSecret } from '../../models/card-setup-secret';

import { CardSetupSecretDto } from './dto/card-setup-secret.dto';
import { IMapperFromDto } from './mappers';

/** Card setup data mapper. */
@Injectable({
  providedIn: 'root',
})
export class CardSetupSecretMapper implements IMapperFromDto<CardSetupSecretDto, CardSetupSecret> {
  /** @inheritdoc */
  public fromDto(data: CardSetupSecretDto): CardSetupSecret {
    return {
      token: data.client_secret,
    };
  }

}
