/** Result containing the uri for the image. */
interface ImageSourceResult {

  /** URI of a provided `File`. */
  readonly unsanitizedUri: string;

  /** Teardown function, revokes requested URI in order to avoid memory leaks. */
  readonly teardown: () => void;
}

/**
 * Returns a URI to a File. To properly render the .
 * @param file File to return the URI for.
 */
export function getImageSource(
  file: File | string,
): ImageSourceResult {
  const url = typeof file === 'string' ? file : URL.createObjectURL(file);

  return {
    unsanitizedUri: url,
    teardown(this: void) {
      URL.revokeObjectURL(url);
    },
  };
}

/**
 * Helper convert base 64 image string to Blob.
 * @param base64Image Image string.
 */
export function convertBase64ToBlob(base64Image: string): Blob {
  // split into two parts
  const parts = base64Image.split(';base64,');

  // hold the content type
  const imageType = parts[0].split(':')[1];

  // decode base64 string
  const decodedData = window.atob(parts[1]);

  // create unit8array of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // insert all character code into uint8array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // return blob image after conversion
  return new Blob([uInt8Array], { type: imageType });
}
