import { Observable } from 'rxjs';

// Using abstract instead of interface in order to use it as a DI token.
/** Basic service for uploading files. */
export abstract class FileUploadService {

  /**
   * Uploads file to storage and returns the url.
   * @param file Blob to upload.
   * @param path Path in the storage.
   * @returns Url to the file.
   */
  public abstract upload(file: File, path: string): Observable<string>;
}
