import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonSharedModule } from '@zc/common/shared/common-shared.module';

import { DialogsModule } from '@zc/common/shared/modules/dialogs/dialogs.module';

import { NgxMaskModule, IConfig } from 'ngx-mask';
import { LightgalleryModule } from 'lightgallery/angular';

import { FieldControlComponent } from './field-control.component';
import { ChecklistFieldControlComponent } from './components/checklist-field-control/checklist-field-control.component';
import { TextFieldControlComponent } from './components/text-field-control/text-field-control.component';
import { OrderedListControlComponent } from './components/ordered-list-field-control/ordered-list-field-control.component';
import { TileChecklistFieldControlComponent } from './components/tile-checklist-field-control/tile-checklist-field-control.component';
import { SelectFieldControlComponent } from './components/select-field-control/select-field-control.component';
import { ImageUploadFieldControlComponent } from './components/image-upload-field-control/image-upload-field-control.component';
import { DayFieldControlComponent } from './components/day-field-control/day-field-control.component';
import { GoogleAutocompleteControlComponent } from './components/google-autocomplete-field-control/google-autocomplete-field-control.component';
import { NumberFieldControlComponent } from './components/number-field-control/number-field-control.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

export const EXPORTED_DECLARATIONS = [
  FieldControlComponent,
  ChecklistFieldControlComponent,
  TextFieldControlComponent,
  NumberFieldControlComponent,
  OrderedListControlComponent,
  TileChecklistFieldControlComponent,
  SelectFieldControlComponent,
  ImageUploadFieldControlComponent,
  DayFieldControlComponent,
  GoogleAutocompleteControlComponent,
];

/** Field control module. */
@NgModule({
  declarations: [...EXPORTED_DECLARATIONS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonSharedModule,
    DialogsModule,
    NgxMaskModule.forRoot(maskConfig),
    LightgalleryModule,
  ],
  exports: [...EXPORTED_DECLARATIONS],
})
export class FieldControlModule { }
