import { AgentInformation } from './agent-information';
import { State } from './states';

/** Address entity object. */
export interface Address {

  /** City. */
  readonly city: string;

  /** Country state. */
  readonly state: State | null;

  /** First address line. */
  readonly addressLine1: string;

  /** Second address line. */
  readonly addressLine2: string;

  /** Country. */
  readonly country: string;

  /** Zip code. */
  readonly zipCode: string;
}

export namespace Address {

  /** Default country code for the US according to ISO-3166-1 alpha-2 standard. */
  export const DEFAULT_COUNTRY = 'US';

  /**
   * To readable address.
   * @param address Address.
   */
  export function toReadableAddressLine(address: Address): string {
    return `${address.addressLine1} ${address.addressLine2}`;
  }

  /**
   * If the address2 is digits, we will add “#” before the digits.
   * @param address Address.
   */
  export function getUnit(address: Address): string | undefined {
    if (address.addressLine2?.length > 0) {
      const isDigits = Number.isInteger(Number(address.addressLine2));
      return isDigits ? `#${address.addressLine2}` : address.addressLine2;
    }

    return void 0;
  }

  /**
   * Get city, state and zipcode.
   * @param address Address.
   */
  export function getCityStateZipCode(address: Address): string {
    const cityState: (keyof Address)[] = ['city', 'state'];
    const cityStatePropertiesOrder = cityState
      .map(key => address?.[key])
      .map(addressPart => typeof addressPart === 'string' ? addressPart : addressPart?.abbreviation)
      .filter(Boolean)
      .join(', ');

    return [
      cityStatePropertiesOrder,
      address.zipCode,
    ]
      .filter(Boolean)
      .join(' ');
  }

  /**
   * Get formatted address.
   * @param address Address.
   */
  export function getFormattedAddress(address: Address): string {
    return [
      address.addressLine1,
      getUnit(address),
      getCityStateZipCode(address),
    ]
      .filter(Boolean)
      .join(', ');
  }

  /**
   * Prepare address for company.
   * @param company Company information.
   */
  export function prepareCompanyAddress(company: AgentInformation.Company): string {
    return Address.getFormattedAddress({
      addressLine1: company.addressLine1,
      addressLine2: company.addressLine2,
      city: company.city,
      state: company.state,
      zipCode: company.zipCode,
      country: '',
    });
  }
}
