import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AgentBaseTemplate } from '../agent-base-template';

/** First template for agent home page. */
@Component({
  selector: 'zcw-agent-first-template',
  templateUrl: './agent-first-template.component.html',
  styleUrls: ['./agent-first-template.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentFirstTemplateComponent extends AgentBaseTemplate {
}
