import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppError, AppValidationError, EntityValidationErrors, PropValidationMessage } from '../../models/app-error';

import { ValidationErrorMapper } from './validation-error-mapper';

/**
 * Error mapper type declaration.
 * Could be a simple function to transform errors from DTO to errors of domain model
 * or implementation of IMapper with implemented validationErrorFromDto method.
 */
export type ErrorMapper<TDto, TEntity extends object> = ValidationErrorMapper<TDto, TEntity> |
  ValidationErrorMapper<TDto, TEntity>['validationErrorFromDto'];

/**
 * API errors mapper.
 */
@Injectable({ providedIn: 'root' })
export class AppErrorMapper {
  /**
   * Convert default HttpErrorResponse object to custom application error.
   * @param httpError Http error response.
   */
  public fromDto(httpError: HttpErrorResponse): AppError {
    const { message, error } = httpError;
    return new AppError(error?.detail ?? message);
  }

  /**
   * Map HTTP API error response to the appropriate Api error model.
   * @param httpError Http error.
   * @param mapper Mapper function that transform validation DTO errors to the application validation model.
   * @returns AppError if httpError is not "Bad Request" error or AppValidationError if it is "Bad Request"/.
   */
  public fromDtoWithValidationSupport<TDto, TEntity extends object>(
    httpError: HttpErrorResponse,
    mapper: ErrorMapper<TDto, TEntity>,
  ): AppError | AppValidationError<TEntity> {
    if (httpError.status !== 400) {
      // It is not a validation error. Return simple AppError.
      return this.fromDto(httpError);
    }

    if (mapper == null) {
      throw new Error('Provide mapper for API errors.');
    }

    if (typeof mapper !== 'function' && mapper.validationErrorFromDto == null) {
      throw new Error('Provided mapper does not have implementation of validationErrorFromDto');
    }

    // TODO (template preparation): Check that API sends you an error with the same field (detail, data, etc.) and change it if it's needed.

    // This is a validation error => create AppValidationError.
    const message = httpError.error.detail;
    const validationData = typeof mapper === 'function' ?
      mapper(httpError.error.data) :
      mapper.validationErrorFromDto(httpError.error.data);
    return new AppValidationError<TEntity>(message, this.cleanUpValidationData(validationData));
  }

  /**
   * Recursively cleans up the validation data object from the nullable values.
   * @param data Data to clean.
   * @example
   * ```ts
   * const dirtyValidationData = {
   *   personal: {
   *     account: {
   *       email: undefined,
   *       password: undefined,
   *     },
   *     profile: {
   *       avatar: 'Avatar is required',
   *       firstName: undefined,
   *     },
   *   },
   * };
   *
   * // would be transformed to
   *
   * const cleanedUpData = this.cleanUpValidationData(data);
   * console.log(cleanedUpData)
   * // {
   * //   personal: {
   * //     profile: {
   * //       avatar: 'Avatar is required',
   * //     },
   * //   },
   * // };
   * ```
   */
  private cleanUpValidationData<T>(data: EntityValidationErrors<T>): EntityValidationErrors<T> {

    return (Object.entries(data) as [keyof T, PropValidationMessage<T[keyof T]> | undefined][])
      .reduce((acc, [key, value]) => {
        if (value == null) {
          return acc;
        }
        if (typeof value === 'string') {
          return { ...acc, [key]: value };
        }

        const cleanedUpValue = this.cleanUpValidationData(value as EntityValidationErrors<T[keyof T]>);
        if (this.isEmptyValidationData(cleanedUpValue)) {
          return acc;
        }

        return {
          ...acc,
          [key]: cleanedUpValue,
        };
      }, {} as EntityValidationErrors<T>);
  }

  private isEmptyValidationData<T>(data: EntityValidationErrors<T>): boolean {
    return Object.keys(data).length === 0;
  }

  /**
   * Catch Api Validation Error RxJS operator.
   * Catches only AppValidationError<T> errors.
   */
  public catchHttpErrorToAppError<T>(): MonoTypeOperatorFunction<T> {
    return catchError((httpError: HttpErrorResponse) => {
      if (httpError.status === 403) {
        return throwError(() => httpError);
      }
      const appError = this.fromDto(httpError);
      return throwError(() => appError);
    });
  }

  /**
   * RxJS operator to catch and map HTTP API error response to the appropriate Api error model.
   * @param mapper Mapper function that transform validation DTO errors to the application validation model.
   * @returns AppError if httpError is not "Bad Request" error or AppValidationError if it is "Bad Request".
   */
  public catchHttpErrorToAppErrorWithValidationSupport<T, TDto, TEntity extends object>(
    mapper: ErrorMapper<TDto, TEntity>,
  ): MonoTypeOperatorFunction<T> {
    return catchError((httpError: HttpErrorResponse) => {
      if (httpError.status === 403) {
        return throwError(() => httpError);
      }
      const appError = this.fromDtoWithValidationSupport<TDto, TEntity>(httpError, mapper);
      return throwError(() => appError);
    });
  }
}
