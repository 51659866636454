import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AppError } from '@zc/common/core/models/app-error';
import { QuestionFields } from '@zc/common/core/models/questionnaire/question-fields';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

import DayField = QuestionFields.Specific.DayField;

/**
 * Creates an array of days between the range.
 * @param start Start date.
 * @param end End date.
 */
function createDaysRange(start: Date, end: Date): Date[] {
  const range = [];

  const MS_IN_DAY = 86400000;
  const daysDifference = Math.ceil((end.valueOf() - start.valueOf()) / MS_IN_DAY);

  // days to Prepare offer
  const BUFFER_TIME = 2;

  for (let i = 0; i < daysDifference; i++) {
    range.push(new Date(start.getFullYear(), start.getMonth(), start.getDate() + i + BUFFER_TIME));
  }

  return range;
}

/** Field control for the day picker. */
@Component({
  selector: 'zc-day-field-control',
  templateUrl: './day-field-control.component.html',
  styleUrls: ['./day-field-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(DayFieldControlComponent)],
})
export class DayFieldControlComponent extends SimpleValueAccessor<DayField> {

  /** List of day options. */
  public get dayOptions(): Date[] {
    assertNonNull(this.controlValue);
    const { start, end } = this.controlValue.range;
    return createDaysRange(new Date(start), new Date(end));
  }

  /** @inheritdoc */
  public override writeValue(value: DayField | null): void {
    super.writeValue(value);
    if (value != null && value.value == null) {
      assertNonNull(this.controlValue);
      this.controlValue = {
        ...this.controlValue,
        value: [],
      };
    }
  }

  /**
   * Handles date change.
   * @param date Date selected.
   */
  public onToggle(date: Date): void {
    if (this.isSelected(date)) {
      this.uncheck(date);
    } else {
      this.check(date);
    }
  }

  /**
   * Checks whether the date is selected.
   * @param date Date to check.
   */
  public isSelected(date: Date): boolean {
    assertNonNull(this.controlValue);

    return this.controlValue.value?.find(selectedOption => DayField.areOptionsEqual(selectedOption, date)) != null;
  }

  /**
   * Custom TrackByFunction.
   * @param _ Idx.
   * @param option Option.
   * @returns
   */
  public trackBy(_: number, option: Date): string {
    return option.toString();
  }

  /**
   * Handle select an option.
   * @param date Option value.
   */
  private check(date: Date): void {
    assertNonNull(this.controlValue?.value);

    if (this.isSelected(date)) {
      throw new AppError('Option is not supposed to be selected when trying to check it.');
    }

    this.controlValue = {
      ...this.controlValue,
      value: this.controlValue.value.concat(date.toISOString()),
    };
  }

  /**
   * Handle deselect an option.
   * @param date Option value.
   */
  private uncheck(date: Date): void {
    assertNonNull(this.controlValue?.value);

    if (!this.isSelected(date)) {
      throw new AppError('Option is supposed to be selected when trying to uncheck it.');
    }

    this.controlValue = {
      ...this.controlValue,
      value: this.controlValue.value.filter(selectedOption => !DayField.areOptionsEqual(selectedOption, date)),
    };
  }

}
