<ng-template
  #overlay
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="(isOpen$ | async) || false"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'transparent'"
>
  <div class="menu">
    <div class="menu-content" *ngFor="let menuContent of menuContents">
      <ng-container [ngTemplateOutlet]="menuContent.content"></ng-container>
    </div>
    <ul>
      <ng-container *ngFor="let item of menuItems?.toArray()">
        <hr class="menu-divider" *ngIf="item.withDivider" />
        <li class="menu-item" [class]="item.type">
          <ng-container *ngIf="item.routerLink; else button">
            <a
              (click)="close()"
              class="menu-item-active-element"
              [routerLink]="item.routerLink"
            >
              <ng-container [ngTemplateOutlet]="item.content"></ng-container>
            </a>
          </ng-container>
          <ng-template #button>
            <button
              (click)="item.click.next(); close()"
              type="button"
              class="menu-item-active-element"
            >
              <ng-container [ngTemplateOutlet]="item.content"></ng-container>
            </button>
          </ng-template>
        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
