import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  StaticProvider,
  Type,
} from '@angular/core';
import { firstValueFrom, merge } from 'rxjs';
import { delay, first, map, tap } from 'rxjs/operators';

import { IDialog, IDialogOptions } from './dialog';
import { DialogOverlayContainerComponent } from './dialog-overlay-container/dialog-overlay-container.component';

/** Represents an element into which the dialogs are supposed to be injected. */
export const DIALOG_HOST = new InjectionToken<HTMLElement>('DIALOG_HOST');
export const DEFAULT_DIALOG_HOST_PROVIDER: StaticProvider = {
  provide: DIALOG_HOST,
  deps: [DOCUMENT],
  useFactory: (document: Document) => document.body,
};

export const DEFAULT_DIALOG_OPTIONS: IDialogOptions = {
  closable: false,
  withPaddings: true,
};

type DialogType<T> = T extends IDialog<infer _, infer __> ? Type<T> : never;
type DialogProperties<T> = T extends IDialog<infer Options, infer _> ? Options : never;
type DialogResult<T> = T extends IDialog<infer _, infer Result> ? Result : void;

/**
 * Dialogs service.
 * Provides functionality to work with dialogs.
 */
@Injectable()
export class DialogsService {
  /**
   * @param injector Injector.
   * @param applicationRef App ref.
   * @param componentFactoryResolver Component resolver.
   * @param dialogHost Document.
   */
  public constructor(
    private injector: Injector,
    private applicationRef: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(DIALOG_HOST) private dialogHost: HTMLElement,
  ) { }

  /**
   * Creates popup and adds it to DOM.
   * @param component Any component that implements IDialog.
   * @param props Dialog properties.
   *
   * @see IDialog.
   */
  public openDialog<T>(
    component: DialogType<T>,
    props?: DialogProperties<T>,
  ): Promise<DialogResult<T>> {
    // Create element
    const dialogContainerFactory = this.componentFactoryResolver.resolveComponentFactory(
      DialogOverlayContainerComponent,
    );

    // Create the component and wire it up with the element
    const dialogFactory = this.componentFactoryResolver.resolveComponentFactory(
      component,
    );
    const dialogComponentRef = dialogFactory.create(this.injector);

    // Apply options
    if (props != null) {
      dialogComponentRef.instance.props = props;
    }

    // Attach to the view dialog component to init `options`
    this.applicationRef.attachView(dialogComponentRef.hostView);
    dialogComponentRef.changeDetectorRef.detectChanges();

    const dialogContainerRef = dialogContainerFactory.create(this.injector, [[dialogComponentRef.location.nativeElement]]);

    // In order for all the options properties to be lazily evaluated, define getter
    Object.defineProperty(dialogContainerRef.instance, 'options', {
      get: () => ({
        ...DEFAULT_DIALOG_OPTIONS,
        ...dialogComponentRef.instance.options,
      }),
    });

    // Attach to the view so that the change detector knows to run
    this.applicationRef.attachView(dialogContainerRef.hostView);

    const closed$ = merge(
      dialogComponentRef.instance.closed,
      dialogContainerRef.instance.closed.pipe(tap(dialogComponentRef.instance.closed)),
    ).pipe(first());

    // Add to the DOM
    this.dialogHost.appendChild(dialogContainerRef.location.nativeElement);

    return firstValueFrom(closed$.pipe(
      first(),
      tap(() => dialogContainerRef.destroy()),

      // Destroy dialog component after some time to preserve the correct animation
      delay(300),
      tap(() => dialogComponentRef.destroy()),
      map(result => result as DialogResult<T>),
    ));
  }
}
