import { ChangeDetectionStrategy, Component, ContentChild, Input, OnInit } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { EMPTY, merge, Observable, ReplaySubject, tap } from 'rxjs';
import { distinct, mapTo, switchMap } from 'rxjs/operators';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { ZCValidators } from '@zc/common/core/utils/validators';
import { listenControlTouched } from '@zc/common/core/utils/listen-control-touched';

const EMPTY_STRING = '';

/**
 * Label component. Displays error and label for the input component.
 */
@Destroyable()
@Component({
  selector: 'zc-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent implements OnInit {

  /**
   * Error text.
   */
  @Input()
  public set errorText(text: string | null) {
    if (text != null) {
      this.errors$.next(ZCValidators.buildAppError(text));
    }
  }

  /**
   * Text of control's label.
   */
  @Input()
  public labelText: string | null = null;

  /** Whether label should be kept or not. */
  @Input()
  public keepLabel = true;

  /** Whether an additional label should be added or not. */
  @Input()
  public withAdditionalLabel = false;

  /** Whether to prevent label from associating with control or not. */
  @Input()
  public preventAssociatingWithControl = false;

  /** Catch inner input by form control directive. */
  @ContentChild(NgControl)
  public set input(i: NgControl) {
    if (i) {
      this.input$.next(i);
    }
  }

  /** Errors stream. */
  public readonly errors$ = new ReplaySubject<ValidationErrors | null>(1);

  private readonly input$ = new ReplaySubject<NgControl>(1);

  /** @inheritDoc */
  public ngOnInit(): void {
    this.initErrorStreamSideEffect().pipe(
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /** Prevent label from associating with control. */
  public onPreventAssociatingWithControl(): string | undefined {
    if (this.preventAssociatingWithControl) {
      return EMPTY_STRING;
    }
    return void 0;
  }

  private initErrorStreamSideEffect(): Observable<ValidationErrors | null> {
    return this.input$.pipe(
      distinct(),
      switchMap(input => merge(
        input.statusChanges ?? EMPTY,
        input.control ? listenControlTouched(input.control) : EMPTY,
      ).pipe(mapTo(input))),
      tap(input => this.errors$.next(input.errors)),
    );
  }

}
