import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';

/** Context of NgLet. */
class NgLetContext<T = unknown> {
  /** Implicit context element. */
  public $implicit!: T;

  /** NgLet. */
  public ngLet!: T;
}

/**
 * Caches the value passed in the context. Works just like `ngIf` but without rendering functionality.
 * @description
 * We often use *ngIf="stream$ | async as stream" to subscribe to an observable property
 * and rename it to a template variable.
 */
@Directive({

  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngLet]',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class NgLet<T = unknown> implements OnInit {
  private context = new NgLetContext<T>();

  /** Value. */
  @Input()
  public set ngLet(value: T) {
    // eslint-disable-next-line no-multi-assign
    this.context.$implicit = this.context.ngLet = value;
  }

  public constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<NgLetContext<T>>,
  ) {}

  /** @inheritDoc */
  public ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.templateRef, this.context);
  }

  /**
   * Assert the correct type of the expression bound to the `ngLet` input within the template.
   *
   * The presence of this static field is a signal to the Ivy template type check compiler that
   * when the `NgLet` structural directive renders its template, the type of the expression bound
   * to `ngLet` should be narrowed in some way. For `NgLet`, the binding expression itself is used to
   * narrow its type, which allows the strictNullChecks feature of TypeScript to work with `NgLet`.
   */
  public static ngTemplateGuard_ngLet: 'binding';

  /**
   * Asserts the correct type of the context for the template that `NgLet` will render.
   * The presence of this method is a signal to the Ivy template type-check compiler that the
   * `NgLet` structural directive renders its template with a specific context type.
   * @param _dir Directive.
   * @param ctx Context.
   */
  public static ngTemplateContextGuard<T>(_dir: NgLet<T>, ctx: unknown):
      ctx is NgLetContext<T> {
    return true;
  }
}
