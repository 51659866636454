<label class="label" [attr.for]="onPreventAssociatingWithControl()">
  <ng-container *ngIf="withAdditionalLabel === false; else additionalLabel">
    <span class="label__content" *ngIf="keepLabel">{{ labelText }}</span>
  </ng-container>
  <div class="label__input">
    <ng-content></ng-content>
  </div>
  <zc-validation-message class="error" [errors]="errors$ | async">
  </zc-validation-message>
</label>

<ng-template #additionalLabel>
  <div class="label__additional">
    <span class="label__content" *ngIf="keepLabel">{{ labelText }}</span>
    <ng-content select="additionalLabel"></ng-content>
  </div>
</ng-template>
