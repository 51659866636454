<ng-container *ngIf="controlValue">
  <label>
    <span *ngIf="controlValue.label">{{ controlValue.label }}</span>
    <div class="select-container">
      <select
        [ngModel]="controlValue.value"
        (ngModelChange)="onValueChanged($event)"
        [compareWith]="optionComparator"
        [disabled]="disabled"
        [ngClass]="controlValue.value ? 'selected' : 'unselected'"
      >
        <!-- Placeholder -->
        <option [ngValue]="undefined" [disabled]="true">
          {{ controlValue.placeholder || "Select option" }}
        </option>

        <option [ngValue]="option" *ngFor="let option of controlValue.options">
          {{ option.label }}
        </option>
      </select>
    </div>
  </label>
</ng-container>
