import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

/** Represent selling process. */
@Component({
  selector: 'zcw-agent-selling-steps',
  templateUrl: './agent-selling-steps.component.html',
  styleUrls: ['./agent-selling-steps.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentSellingStepsComponent {
  /** Whether the steps should be displayed inline. */
  @Input()
  public inline = true;

  /** Template type for selling steps. */
  @Input()
  public template: 'first' | 'second' = 'first';
}
