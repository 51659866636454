import { Injectable } from '@angular/core';
import { IMapperFromDto } from '@zc/common/core/services/mappers/mappers';

import { ReCaptchaValidationResultDto } from './dto/re-captcha-validation-result.dto';
import { ReCaptchaValidationResult } from './../../models/re-captcha-validation-result';

/** Mapper for re-captcha validation result. */
@Injectable({
  providedIn: 'root',
})
export class ReCaptchaValidationResultMapper implements IMapperFromDto<
  ReCaptchaValidationResultDto,
  ReCaptchaValidationResult
> {

  /** @inheritdoc */
  public fromDto(data: ReCaptchaValidationResultDto): ReCaptchaValidationResult {
    return {
      isValid: data.valid,
    };
  }
}
