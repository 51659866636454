<zc-questionnaire-header
  [company]="agentCompany$ | async"
  [agent]="agent$ | async"
  [shouldShowNavbar]="false"
></zc-questionnaire-header>

<div class="questionnaire-page">
  <ng-container *ngIf="isAuthorized$ | async; else phoneForm">
    <ng-container *ngIf="finished$ | async; else questionnaire">
      <zc-progress [max]="100" [value]="100"></zc-progress>
      <div
        class="question"
        *ngIf="isViaMarketingSite$ | async; else thanksAgent"
      >
        <h2 class="question-text">
          Thank you for completing the Home Offer Questionnaire.
        </h2>
        <p class="question-description">
          I will contact you shortly via email to tell you about next steps. In
          the meantime please contact me if you have any additional questions or
          go to my 'frequently asked questions' for more information.
        </p>
        <footer class="stage-controls">
          <button class="secondary" type="button" (click)="goToHome(true)">
            Done, go to FAQ
          </button>
          <button class="primary" type="button" (click)="goToHome()">
            Done
          </button>
        </footer>
      </div>

      <ng-template #thanksAgent>
        <div class="question">
          <h2 class="question-text">
            Thank you for completing the home questionnaire.
          </h2>
          <p class="question-description">
            I will contact you shortly via email to tell you about next steps.
            In the meantime please contact me if you have any additional
            questions.
          </p>
        </div>
      </ng-template>
    </ng-container>

    <ng-template #questionnaire>
      <zc-questionnaire-stepper
        [questionnaire]="questionnaire$ | async"
        [stageValidator]="stageValidator$ | async"
        (questionnaireSubmit)="onQuestionnaireSubmit($event)"
        (questionnaireStageChange)="onQuestionnaireStageChange($event)"
      ></zc-questionnaire-stepper>
    </ng-template>
  </ng-container>
  <ng-template #phoneForm>
    <zc-progress [max]="100" [value]="0"></zc-progress>
    <div class="question" *ngIf="isViaMarketingSite$ | async; else phoneFormAgent">
      <h2 class="question-text">Enter your phone number to get started</h2>
      <p class="question-description">
        For me to provide you with a 'cash offer' we need to confirm your
        identity. Please provide your cell number and we will text you a
        confirmation code so that you can complete the process.
      </p>
    </div>
    <ng-template #phoneFormAgent>
      <div class="question">
        <h2 class="question-text">Enter your phone number to get started</h2>
        <p class="question-description">
          For me to provide you with the 'highest price market evaluation' on
          your home we need to confirm your identity. Please provide your cell
          number and we will text you a confirmation code so that you can
          complete the process.
        </p>
      </div>
    </ng-template>

    <zc-phone-input></zc-phone-input>
  </ng-template>
</div>
<zcw-agent-footer
  [agent]="agent$ | async"
  [company]="agentCompany$ | async"
></zcw-agent-footer>
