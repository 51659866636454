import { Injectable } from '@angular/core';

import { ProjectActivity } from '../../models/project-activity';

import { ProjectActivityDto } from './dto/project-activity.dto';
import { IMapperFromDto } from './mappers';
import { UserMapper } from './user.mapper';

/** Project activity mapper. */
@Injectable({ providedIn: 'root' })
export class ProjectActivityMapper implements IMapperFromDto<ProjectActivityDto, ProjectActivity> {

  public constructor(
    private readonly userMapper: UserMapper,
  ) {}

  /** @inheritdoc */
  public fromDto(data: ProjectActivityDto): ProjectActivity {
    return {
      id: data.id,
      content: data.content,
      created: new Date(data.created),
      title: data.title,
      user: this.userMapper.fromDto(data.user),
      attachments: data.photos.map(photo => photo.photo_url),
      isPinned: data.is_pinned,
    };
  }

}
