import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SortButtonComponent } from './sort-button/sort-button.component';
import { SortDirective } from './sort.directive';

/** Module for sort components. */
@NgModule({
  declarations: [
    SortDirective,
    SortButtonComponent,
  ],
  exports: [
    SortDirective,
    SortButtonComponent,
  ],
  imports: [CommonModule, MatIconModule],
})
export class SortModule { }
