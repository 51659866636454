<ng-container *ngIf="controlValue">
  <ng-container *ngIf="isStandalone; else autocompleteWithLabel">
    <input
      class="autocomplete-input"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="off"
      type="text"
      #autocompleteInput
      [ngModel]="controlValue.value?.address"
      (ngModelChange)="onAddressChanged($event)"
      placeholder="Enter Your Address"
      zcFixedBody
    />
  </ng-container>

  <ng-template #autocompleteWithLabel>
    <div class="autocomplete-wrapper">
      <zc-label
        class="autocomplete-address label_light"
        [labelText]="controlValue.label"
        [errorText]="error$ | async"
      >
        <input
          class="autocomplete-input"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="off"
          type="text"
          #autocompleteInput
          [ngModel]="controlValue.value?.address"
          (ngModelChange)="onAddressChanged($event)"
          [placeholder]="controlValue.placeholder"
          zcFixedBody
        />
      </zc-label>
      <zc-label class="autocomplete-unit label_light" labelText="Unit">
        <input
          class="autocomplete-input"
          spellcheck="off"
          type="text"
          [ngModel]="controlValue.value?.unit"
          (ngModelChange)="onUnitChanged($event)"
          [placeholder]="controlValue.unitPlaceholder"
        />
      </zc-label>
    </div>
  </ng-template>
</ng-container>
