import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, NgZone, ViewChild } from '@angular/core';
import { FormControl, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { GoogleLocation } from '@zc/common/core/models/questionnaire/google-location';
import { QuestionFields } from '@zc/common/core/models/questionnaire/question-fields';
import { QuestionnaireService } from '@zc/common/core/services/questionnaire.service';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { enumToArray } from '@zc/common/core/utils/enum-to-array';
import { ZCValidators } from '@zc/common/core/utils/validators';
import { controlProviderFor, SimpleValueAccessor, validatorProviderFor } from '@zc/common/core/utils/value-accessor';
import { bindCallback, ReplaySubject, take } from 'rxjs';

import GoogleAutocomplete = QuestionFields.Specific.GoogleAutocomplete;

/** Required Google Location fields. */
enum RequiredGoogleLocationKey {
  CITY = 'city',
  STATE = 'state',
  POSTAL_CODE = 'postalCode',
  STREET_NUMBER = 'streetNumber',
  ROUTE = 'route',
}

const GOOGLE_KEY_TO_READABLE_MAP: Readonly<Record<RequiredGoogleLocationKey, string>> = {
  [RequiredGoogleLocationKey.CITY]: 'City',
  [RequiredGoogleLocationKey.STATE]: 'State',
  [RequiredGoogleLocationKey.POSTAL_CODE]: 'Postal Code',
  [RequiredGoogleLocationKey.STREET_NUMBER]: 'Street Number',
  [RequiredGoogleLocationKey.ROUTE]: 'Route',
};

/** Google Autocomplete field control. */
@Destroyable()
@Component({
  selector: 'zc-google-autocomplete-field-control',
  templateUrl: './google-autocomplete-field-control.component.html',
  styleUrls: ['./google-autocomplete-field-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    controlProviderFor(GoogleAutocompleteControlComponent),
    validatorProviderFor(GoogleAutocompleteControlComponent),
  ],
})
export class GoogleAutocompleteControlComponent extends SimpleValueAccessor<GoogleAutocomplete> implements AfterViewInit, Validator {
  /** Whether component is standalone input or in a form group with label text. */
  @Input()
  public isStandalone = false;

  /** Autocomplete field reference. */
  private searchElementRef!: ElementRef;

  /** Subject emits when manual validation fail. */
  public readonly error$ = new ReplaySubject<string>(1);

  /** Setter for Autocomplete when ngIf change. */
  @ViewChild('autocompleteInput')
  private set content(content: ElementRef) {
    if (content && this.searchElementRef === undefined) {
      // initially setter gets called with undefined
      this.searchElementRef = content;
      this.loadAutocomplete();
      this.parseAddress();
    }
  }

  public constructor(
    private readonly mapsAPILoader: MapsAPILoader,
    private readonly ngZone: NgZone,
    private readonly questionnaireService: QuestionnaireService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  /** @inheritdoc */
  public ngAfterViewInit(): void {

    if (this.isStandalone) {

      // manual trigger for standalone mode (without ngIf change)
      this.getCurrentLocation();
    }
  }

  /**
   * Handle changed field.
   * @param googleLocation Google location data.
   */
  public onAddressChanged(googleLocation: GoogleLocation | string): void {
    assertNonNull(this.controlValue);
    this.error$.next('');

    // Not allow to set location to storage when it is a string
    if (typeof (googleLocation) === 'string') {
      // Clear address value to trigger validation.
      // Only get value from Google Autocomplete selector dropdown (type is object).
      this.controlValue = {
        ...this.controlValue,
        value: {
          address: '',
          unit: this.controlValue.value?.unit ?? undefined,
        },
        placeId: undefined,
      };
      return;
    }

    const invalidFields = this.findInvalidGoogleLocationFields(googleLocation);
    if (invalidFields.length > 0) {
      this.error$.next(`${GOOGLE_KEY_TO_READABLE_MAP[invalidFields[0] as RequiredGoogleLocationKey]} data is not valid!`);
      this.controlValue = {
        ...this.controlValue,
        value: {
          address: '',
          unit: this.controlValue.value?.unit ?? undefined,
        },
        placeId: undefined,
      };
      this.changeDetectorRef.markForCheck();
      return;
    }

    this.questionnaireService.setGoogleLocation(googleLocation).pipe(
      takeUntilDestroy(this),
    )
      .subscribe();

    this.controlValue = {
      ...this.controlValue,
      value: {
        address: googleLocation.address,
        unit: googleLocation.unit,
      },
      placeId: googleLocation.placeId,
    };
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Handle changed unit.
   * @param unit Address unit.
   */
  public onUnitChanged(unit: string): void {
    assertNonNull(this.controlValue);

    this.controlValue = {
      ...this.controlValue,
      value: {
        address: this.controlValue.value?.address ?? '',
        unit,
      },
    };

    this.questionnaireService.setUnit(unit).pipe(
      takeUntilDestroy(this),
    )
      .subscribe();

    this.changeDetectorRef.markForCheck();
  }

  /** @inheritdoc */
  public validate(): ValidationErrors | null {
    if (!this.controlValue) {
      return null;
    }
    if (!this.controlValue.placeId) {
      return null;
    }

    const placeValidate = this.validateByFormControl(this.controlValue?.placeId ?? '', this.controlValue.validations);
    return placeValidate;
  }

  /**
   * Check validation of input by form control validation.
   * @param _value Input value.
   * @param validations List validation.
   * @returns Validation Errors or null.
   */
  private validateByFormControl(_value: string, validations?: GoogleAutocomplete.Validation[]): ValidationErrors | null {
    const _control = new FormControl();
    _control.setValue(_value ?? '', { emitEvent: false });
    if (validations && validations.length > 0) {

      const validatorFnArray: ValidatorFn[] = validations.map(item => ZCValidators.getFormFieldValidator(item));
      _control.setValidators(validatorFnArray);
      _control.updateValueAndValidity();
    }
    return _control.errors;
  }

  /**
   * Helper parse address form URL.
   */
  private parseAddress(): void {
    assertNonNull(this.controlValue);
    if (this.controlValue.value?.address) {
      // If user answered, ignore  step parse
      return;
    }

    this.getCurrentLocation();
  }

  /**
   * Check if there is a google location data existed (from previous step).
   * Parse that data to address field.
   */
  private getCurrentLocation(): void {
    this.questionnaireService.getGoogleLocation.pipe(
      take(1),
    ).subscribe(
      googleLocation => {
        if (googleLocation !== null) {
          this.onAddressChanged(googleLocation);
        }
      },
    );
  }

  /**
   * Handle load google autocomplete.
   */
  private loadAutocomplete(): void {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      const geocoder = new google.maps.Geocoder();
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // Convert Callback to Observable
          const geocoder$ = bindCallback(geocoder.geocode);
          geocoder$({ placeId: place.place_id }).pipe(
            take(1),
          )
            .subscribe(values => {
              const [result] = values;
              this.handleGeoCodeResult(result);
            });
        });
      });
    });
  }

  /**
   * Handle parse Geocoder Result to google Location object.
   * @param results Geocoder Result.
   */
  private handleGeoCodeResult(results: google.maps.GeocoderResult[]): void {
    const [place] = results;

    const streetNumber = this.parseGeocoderAddressComponent(place.address_components, 'street_number');
    const route = this.parseGeocoderAddressComponent(place.address_components, 'route', true);
    const city = this.parseGeocoderAddressComponent(place.address_components, 'locality');
    const state = this.parseGeocoderAddressComponent(place.address_components, 'administrative_area_level_1', true);
    const postalCode = this.parseGeocoderAddressComponent(place.address_components, 'postal_code');
    const country = this.parseGeocoderAddressComponent(place.address_components, 'country');

    const googleLocation = {
      address: place.formatted_address,
      placeId: place.place_id,
      city,
      state,
      postalCode,
      country,
      streetNumber,
      route,
      unit: this.controlValue?.value?.unit,
    };
    this.onAddressChanged(googleLocation);
  }

  /**
   * Helper parse Geocoder Result by field name.
   * @param addressComponents Geocoder Address Component Object.
   * @param fieldName String field name.
   * @param isShortName Check whether get short name value or not, default is 'long_name'.
   */
  private parseGeocoderAddressComponent(
    addressComponents: google.maps.GeocoderAddressComponent[],
    fieldName: string,
    isShortName = false,
  ): string {
    let fieldValue = '';
    for (let i = 0; i < addressComponents.length; i++) {
      for (let j = 0; j < addressComponents[i].types.length; j++) {
        if (addressComponents[i].types[j] === fieldName) {
          fieldValue = isShortName ? addressComponents[i].short_name : addressComponents[i].long_name;
        }
      }
    }
    return fieldValue;
  }

  /**
   * Helper find empty field in GoogleLocation object.
   * @param googleLocation Google Location object to check.
   */
  private findInvalidGoogleLocationFields(googleLocation: GoogleLocation): (string | undefined)[] {
    return enumToArray(RequiredGoogleLocationKey)
      .filter(key => googleLocation[key as keyof GoogleLocation] === '' || googleLocation[key as keyof GoogleLocation] === null);
  }
}
