import { AbstractControlTyped, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ZCValidators } from '@zc/common/core/utils/validators';
import { FileFormat } from '@zc/common/core/enums/file-format';

import { AppError } from '../models/app-error';

export namespace FileValidation {
  const BYTES_IN_MB = 1024 * 1024;

  /**
   * @returns Validation message if a file is invalid.
   */
  export type FileValidatorFn = (file: File) => string | null;

  type ManyFilesValidatorFn = (files: File[]) => string | null;

  /**
   * Adaptor for many files validation.
   * @param validator Validator.
   */
  export function forManyFiles(validator: FileValidatorFn): ManyFilesValidatorFn {
    return files => files.map(validator).filter(m => m != null)[0] ?? null;
  }

  /**
   * File size validator for a file.
   * @param maxSizeMb Max size of a file (MB).
   * @param message Custom error message.
   */
  export function validateMaxSize(maxSizeMb: number, message: string): FileValidatorFn {
    return file => {
      const sizeMb = bytesToMb(file.size);
      if (sizeMb < maxSizeMb) {
        return null;
      }

      return message;
    };
  }

  /**
   * Validates a file for an accepted format.
   * @param formats Formats to validate.
   */
  export function validateFormat(formats: readonly FileFormat[]): FileValidatorFn {
    return file => {
      if (formats.some(extension => file.name.toLowerCase().endsWith(extension.toLowerCase()))) {
        return null;
      }

      return `Invalid format. "${formats.join(', ')}" expected`;
    };
  }

  /**
   * Make validators for many files compatible with Angular forms.
   * @param filesValidatorFn Files validator function.
   */
  export function adaptManyFilesForForms(filesValidatorFn: ManyFilesValidatorFn): ValidatorFn {
    return ({ value: files }: AbstractControlTyped<File[] | string | null>): ValidationErrors | null => {
      if (files == null || typeof files === 'string') {
        return null;
      }

      files.forEach(file => {
        // Since Angular's `ValidatorFn` is not strictly typed, we gotta check for correct types.
        if (!(file instanceof Blob)) {
          throw new AppError('Invalid value for validator. URL or Blob expected');
        }
      });

      const error = filesValidatorFn(files);

      if (error) {
        return ZCValidators.buildAppError(error);
      }

      return null;
    };
  }

  /**
   * Decorates a file validator adapting it to be compatible with Angular Forms API.
   * @param validateFile File validator to adapt.
   */
  export function adaptForForms(validateFile: FileValidatorFn): ValidatorFn {
    return ({ value: file }: AbstractControlTyped<File | string | null>): ValidationErrors | null => {
      if (file == null || typeof file === 'string') {
        return null;
      }

      // Since Angular's `ValidatorFn` is not strictly typed, we gotta check for correct types.
      if (!(file instanceof Blob)) {
        throw new AppError('Invalid value for validator. URL or Blob expected');
      }

      const error = validateFile(file);

      if (error) {
        return ZCValidators.buildAppError(error);
      }

      return null;
    };
  }

  /**
   * Transforms megabytes to bytes.
   * @param bytes Bytes.
   * @returns Megabytes.
   */
  function bytesToMb(bytes: number): number {
    return bytes / BYTES_IN_MB;
  }
}
