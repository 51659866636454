/** User role group. */
export enum UserRoleGroupDto {

  /** Property selling agents. */
  Agents = 'AGENT',

  /** Property seller. */
  Sellers = 'SELLER',

  /** Administrators of the application. */
  Admins = 'ADMIN',

  /** Contractor hired from outside the back-office. */
  Contractors = 'CONTRACTOR',

  /** Back-office team member. */
  Team = 'TEAM',
}

/** User role dto. */
export interface UserRoleDto {

  /** User role id. */
  readonly id: number;

  /** Role name. */
  readonly name: string;

  /** User role name. */
  readonly group: UserRoleGroupDto;
}
