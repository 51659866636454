import { Component, ChangeDetectionStrategy } from '@angular/core';

/** Section for agent home page. */
@Component({
  selector: 'zcw-agent-section',
  templateUrl: './agent-section.component.html',
  styleUrls: ['./agent-section.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentSectionComponent {

}
