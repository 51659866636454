<footer class="footer-content">
  <zcw-agent-footer-card class="agent-card" [agent]="agent" [company]="company">
  </zcw-agent-footer-card>

  <hr class="line" />

  <p class="footer-content__disclaimed-text">
    In Partnership with ZinCasa, Inc. ZinCasa, Inc. is the home purchaser and is
    providing this service to the Agent.<br />
    The Agent subscribes to the ZinCasa, Inc. home buying platform and is not
    purchasing, determining offer price or funding said home purchases.
  </p>

  <img
    class="footer-content__zincasa-logo"
    src="/assets/img/zincasa-powered-by.png"
    alt="Powered by ZinCasa logo"
  />

  <div class="footer-content__copyright-and-nav copyright-and-nav">
    <p class="copyright-and-nav__copyright">
      2021 ZinCasa® All Rights Reserved.
    </p>

    <a class="nav__item-link" [href]="privacyPolicyUrl.toString()" target="_blank">Privacy Policy</a>
  </div>
</footer>

<ng-template #skeleton>
  <zc-avatar class="footer-content__agent-avatar" [round]="true"></zc-avatar>
  <br />
  <zc-username class="footer-content__agent-name"></zc-username>
  <br />
  <p
    class="footer-content__agent-title"
    zcSkeleton
    [zcSkeletonMinWidth]="3"
    [zcSkeletonMaxWidth]="7"
  ></p>

  <address class="footer-content__agent-info agent-info">
    <p
      class="footer-content__agent-title"
      zcSkeleton
      [zcSkeletonMinWidth]="9"
      [zcSkeletonMaxWidth]="12"
    ></p>
  </address>
</ng-template>
