<p class="dialog-message">{{ props.message }}</p>
<div class="controls">
  <button class="secondary" type="button" (click)="closed.next(false)">
    {{ props.refuseButtonTitle || defaultProps.refuseButtonTitle }}
  </button>
  <button
    [class]="applyButtonClass()"
    type="button"
    (click)="closed.next(true)"
    autofocus
  >
    {{ props.confirmButtonTitle || defaultProps.confirmButtonTitle }}
  </button>
</div>
