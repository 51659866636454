import { enumToArray } from '@zc/common/core/utils/enum-to-array';

/** Color theme. */
export enum ColorTheme {
  AllenTate = 'allentate',
  ReMax = 'remax',
  RealtyOne = 'realtyone',
  NextHome = 'nexthome',
  KellerWilliams = 'kellerwilliams',
  Exp = 'exp',
  Compass = 'compass',
  ColdwellBanker = 'coldwellbanker',
  Century21 = 'century21',
  BetterHomesGarden = 'betterhomesgarden',
  BerkshireHathaway = 'berkshirehataway',
  EXITRealty = 'exitrealty',
  HomeSmart = 'homesmart',
  UnitedRealEstate = 'unitedrealestate',
  Grey = 'grey',
  Custom1 = 'custom1',
  Custom2 = 'custom2',
  Custom3 = 'custom3',
  Custom4 = 'custom4',
  Custom5 = 'custom5',
}

export namespace ColorTheme {
  const TO_READABLE_MAP: Record<ColorTheme, string> = {
    [ColorTheme.AllenTate]: 'Allen Tate',
    [ColorTheme.BerkshireHathaway]: 'Berskhire Hathaway',
    [ColorTheme.ReMax]: 'ReMax',
    [ColorTheme.RealtyOne]: 'Realty One',
    [ColorTheme.NextHome]: 'Next Home',
    [ColorTheme.KellerWilliams]: 'Keller Williams',
    [ColorTheme.Exp]: 'Exp',
    [ColorTheme.Compass]: 'Compass',
    [ColorTheme.ColdwellBanker]: 'Coldwell Banker',
    [ColorTheme.Century21]: 'Century21',
    [ColorTheme.BetterHomesGarden]: 'Better Homes Garden',
    [ColorTheme.EXITRealty]: 'EXIT Realty',
    [ColorTheme.HomeSmart]: 'Home Smart',
    [ColorTheme.UnitedRealEstate]: 'United Real Estate',
    [ColorTheme.Grey]: 'grey',
    [ColorTheme.Custom1]: 'Custom 1',
    [ColorTheme.Custom2]: 'Custom 2',
    [ColorTheme.Custom3]: 'Custom 3',
    [ColorTheme.Custom4]: 'Custom 4',
    [ColorTheme.Custom5]: 'Custom 5',
  };

  /**
   * Maps color theme into human-readable value.
   * @param colorTheme Color theme.
   */
  export function toReadable(colorTheme: ColorTheme): string {
    return TO_READABLE_MAP[colorTheme];
  }

  /**
   * Type guard for color theme.
   * @param colorTheme Possible color theme.
   */
  export function isColorTheme(colorTheme: string): colorTheme is ColorTheme {
    return enumToArray(ColorTheme).includes(colorTheme as ColorTheme);
  }
}
