/* eslint-disable line-comment-position */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-len */
// TODO (Chernodub V. 07/15/21): remove when backend is ready.

import { GoogleLocation } from '../models/questionnaire/google-location';
import { QuestionFields } from '../models/questionnaire/question-fields';
import { Questionnaire } from '../models/questionnaire/questionnaire';
import { QuestionnaireStageControls, Section } from '../models/questionnaire/questionnaire-stage';

/** Enum for CSS class of Stage control buttons. */
export enum StageStyleClass {

  /** Style Button as Secondary. */
  ButtonSecondary = 'secondary stage-controls__button-secondary',
}

const NOW = new Date();
const DEFAULT_QUESTIONNAIRE_CONTROLS: QuestionnaireStageControls = {
  nextStageControl: {
    label: 'Next',
  },
  previousStageControl: {
    label: 'Back',
  },
};

/**
 * Helper get default google location.
 */
export function getDefaultLocation(): GoogleLocation {
  return {
    address: '',
    placeId: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    streetNumber: '',
    route: '',
  };
}

/**
 * Helper get mock data.
 * @param imagesPath Prefix for image path.
 */
export function getMockData(imagesPath = '/assets/img/question/'): Questionnaire {
  return {
    stages: [
      // #Intro https://projects.invisionapp.com/d/#/console/21313976/454127677/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'We want to help you with your listing. Let’s start with your home address.',
            fields: [
              {
                type: QuestionFields.FieldType.GoogleAutocomplete,
                validations: [QuestionFields.Specific.GoogleAutocomplete.Validation.Required],
                label: 'Enter Your Home Address',
                placeholder: 'Enter home address',
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.Header,
          key: 'question-intro',
        },
      },

      // #Q0 https://projects.invisionapp.com/d/#/console/21313976/458079029/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Tell us how many bedrooms, bathrooms your home has as well as the square footage',
            fields: [
              {
                type: QuestionFields.FieldType.Select,
                definedType: QuestionFields.DefinedFieldType.NumberOfBedrooms,
                label: 'Number of bedrooms',
                placeholder: 'Select number of bedrooms',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1',
                    value: '1',
                  },
                  {
                    label: '2',
                    value: '2',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                  {
                    label: '4',
                    value: '4',
                  },
                  {
                    label: '5',
                    value: '5',
                  },
                  {
                    label: '6',
                    value: '6',
                  },
                  {
                    label: '7',
                    value: '7',
                  },
                  {
                    label: '8',
                    value: '8',
                  },
                  {
                    label: '9',
                    value: '9',
                  },
                  {
                    label: '10',
                    value: '10',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Select,
                definedType: QuestionFields.DefinedFieldType.NumberOfBathrooms,
                label: 'Number of bathrooms',
                placeholder: 'Select number of bathrooms',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1',
                    value: '1',
                  },
                  {
                    label: '1.5',
                    value: '1.5',
                  },
                  {
                    label: '2',
                    value: '2',
                  },
                  {
                    label: '2.5',
                    value: '2.5',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                  {
                    label: '3.5',
                    value: '3.5',
                  },
                  {
                    label: '4',
                    value: '4',
                  },
                  {
                    label: '4.5',
                    value: '4.5',
                  },
                  {
                    label: '5+',
                    value: '5+',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Number,
                definedType: QuestionFields.DefinedFieldType.SquareFeetAboveGrade,
                label: 'Square Feet - Above Grade Finished', // ZCAPP-262
                placeholder: 'Enter square footage',
                validations: [QuestionFields.Specific.Number.Validation.Required],
              },
              {
                type: QuestionFields.FieldType.Number,
                definedType: QuestionFields.DefinedFieldType.SquareFeetBelowGrade,
                label: 'Square Feet - Below Grade Finished', // ZCAPP-262
                placeholder: 'Enter square footage',
              },
              {
                type: QuestionFields.FieldType.Number,
                label: 'What year was your house built?', // ZCAPP-856
                placeholder: 'Enter year',
                validations: [QuestionFields.Specific.Number.Validation.Required],
              },
              {
                type: QuestionFields.FieldType.Number,
                label: 'Monthly HOA Fee (if applicable)',
                placeholder: 'Enter Monthly HOA Fee',
              },
              {
                type: QuestionFields.FieldType.Select,
                definedType: QuestionFields.DefinedFieldType.NumberOfBathrooms,
                label: 'Do you have any pets?',
                placeholder: 'Select option',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: 'Yes',
                    value: 'Yes',
                  },
                  {
                    label: 'No',
                    value: 'No',
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.Header,
          key: 'home-info-5',
        },
      },

      // #Q1 https://projects.invisionapp.com/d/#/console/21313976/454127678/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Does your home have any of the following?',
            description:
              'These are uncommon but we may not be able to purchase your home if some of these conditions apply. Select all that apply.',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Solar Panels',
                    value: 'Solar Panels',
                  },
                  {
                    label: 'Known foundation issues',
                    value: 'Known foundation issues',
                  },
                  {
                    label: 'Gated Community',
                    value: 'Gated Community',
                  },
                  {
                    label: 'Age-restricted community',
                    value: 'Age-restricted community',
                  },
                  {
                    label: 'Chemical contamination',
                    value: 'Chemical contamination',
                  },
                  {
                    label: 'None of the above',
                    value: 'None of the above',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-1',
        },
      },

      // #Q2 https://projects.invisionapp.com/d/#/console/21313976/454127679/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Does your home have any special features?',
            fields: [
              {
                type: QuestionFields.FieldType.TileChecklist,
                validations: [QuestionFields.Specific.TileChecklist.Validation.Required],
                multiple: true,
                options: [
                  {
                    label: 'Garage',
                    value: 'garage',
                    backgroundImageUrl: `${imagesPath}q2-garage.png`,
                    extra: [
                      {
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Carport',
                            value: 'carport',
                          },
                          {
                            label: 'Single',
                            value: 'single',
                          },
                          {
                            label: 'Double',
                            value: 'double',
                          },
                          {
                            label: 'Triple',
                            value: 'triple',
                          },
                        ],
                      },
                      {
                        type: QuestionFields.FieldType.Checklist,
                        options: [
                          {
                            label: 'Separate Building',
                            value: 'separate_building',
                          },
                        ],
                      },
                      {
                        label: 'What is the condition of your garage',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Carport',
                    value: 'carport',
                    backgroundImageUrl: `${imagesPath}q2-carport.png`,
                    extra: [
                      {
                        label: 'What is the condition of your carport',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Basement',
                    value: 'basement',
                    backgroundImageUrl: `${imagesPath}q2-basement.png`,
                    extra: [
                      {
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Finished',
                            value: 'finished',
                          },
                          {
                            label: 'Partially Finished',
                            value: 'partially finished',
                          },
                          {
                            label: 'Unfinished',
                            value: 'unfinished',
                          },
                        ],
                      },
                      {
                        label: 'What is the condition of your basement',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Hot tub/Spa',
                    value: 'hot_tub',
                    backgroundImageUrl: `${imagesPath}q2-hot-tub.png`,
                    extra: [
                      {
                        label: 'What is the condition of your hot tub/spa',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Swimming Pool',
                    value: 'pool',
                    backgroundImageUrl: `${imagesPath}q2-swimming-pool.png`,
                    extra: [
                      {
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'In-ground',
                            value: 'in_ground',
                          },
                          {
                            label: 'Above-ground',
                            value: 'above_ground',
                          },
                          {
                            label: 'Community',
                            value: 'community',
                          },
                        ],
                      },
                      {
                        label: 'What is the condition of your pool',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Fully enclosed fence',
                    value: 'enclosed_fence',
                    backgroundImageUrl: `${imagesPath}q2-fully-enclosed-fence.png`,
                    extra: [
                      {
                        label: 'What is the condition of your fully enclosed fence',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                ],
                customValueField: {
                  type: QuestionFields.FieldType.Text,
                  label: 'Other',
                  placeholder: 'Enter description',
                  multiline: true,
                },
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-2',
        },
      },

      // #Q3 https://projects.invisionapp.com/d/#/console/21313976/454127680/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Has your home ever had flood damage?',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Yes',
                    value: 'yes',
                    excluding: true,
                  },
                  {
                    label: 'No',
                    value: 'no',
                    excluding: true,
                  },
                  {
                    label: 'I don\'t know',
                    value: 'idk',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-3',
        },
      },

      // #Q4 https://projects.invisionapp.com/d/#/console/21313976/454127681/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Have you made any major improvements?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Renovated kitchen',
                    value: 'renovated_kitchen',
                  },
                  {
                    label: 'Renovated main bathroom',
                    value: 'renovated_main_bathroom',
                  },
                  {
                    label: 'Added finished living space',
                    value: 'added_finished_living_space',
                  },
                  {
                    label: 'Replaced main flooring',
                    value: 'replaced_main_flooring',
                  },
                  {
                    label: 'Fully repainted exterior',
                    value: 'fully_repainted_exterior',
                  },
                  {
                    label: 'None of the above',
                    value: 'none',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-4',
        },
      },

      // #Q5 https://projects.invisionapp.com/d/#/console/21313976/454127682/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Have you or previous owners added any living area to the home since the original construction?',
            description: 'This includes converted garages, sunrooms, bonus rooms, or any other living area extensions',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'No additions have been made',
                    value: 'no_additions',
                    excluding: true,
                  },
                  {
                    label: 'Additions were made with permits',
                    value: 'additions_with_permits',
                    excluding: true,
                  },
                  {
                    label: 'Additions were made without permits',
                    value: 'additions_without_permits',
                    excluding: true,
                  },
                  {
                    label: 'I\'m not sure',
                    value: 'not_sure',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-5',
        },
      },

      // #Q6 https://projects.invisionapp.com/d/#/console/21313976/454127683/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What countertops does your kitchen have?',
            fields: [
              {
                type: QuestionFields.FieldType.TileChecklist,
                validations: [QuestionFields.Specific.TileChecklist.Validation.Required],
                multiple: true,
                options: [
                  {
                    label: 'Granite Slab',
                    value: 'granite_slab',
                    backgroundImageUrl: `${imagesPath}q6-granite-slab.png`,
                  },
                  {
                    label: 'Granite Tile',
                    value: 'granite_tile',
                    backgroundImageUrl: `${imagesPath}q6-granite-tile.png`,
                  },
                  {
                    label: 'Corian',
                    value: 'corian',
                    backgroundImageUrl: `${imagesPath}q6-corian.png`,
                  },
                  {
                    label: 'Formica',
                    value: 'formica',
                    backgroundImageUrl: `${imagesPath}q6-formica.png`,
                  },
                  {
                    label: 'Quartz',
                    value: 'quartz',
                    backgroundImageUrl: `${imagesPath}q6-quartz.png`,
                  },
                  {
                    label: 'Other tile',
                    value: 'other_tile',
                    backgroundImageUrl: `${imagesPath}q6-other-tile.png`,
                  },
                  {
                    label: 'Standard Countertop',
                    value: 'standard_countertop',
                    backgroundImageUrl: `${imagesPath}q6-standard-countertop.png`,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-6',
        },
      },

      // #Q7 https://projects.invisionapp.com/d/#/console/21313976/454127684/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What type of kitchen appliances do you have?',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'All stainless steel',
                    value: 'all_stainless_steel',
                  },
                  {
                    label: 'All black',
                    value: 'all_black',
                  },
                  {
                    label: 'All white',
                    value: 'all_white',
                  },
                  {
                    label: 'Mixed',
                    value: 'mixed',
                  },
                  {
                    label: 'Other / Don\'t know',
                    value: 'other',
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-7',
        },
      },

      // #Q8 https://projects.invisionapp.com/d/#/console/21313976/454127685/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Does your kitchen have any of the following features?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Kitchen island',
                    value: 'kitchen_island',
                  },
                  {
                    label: 'New cabinets',
                    value: 'new_cabinets',
                  },
                  {
                    label: 'Tile backsplash',
                    value: 'tile_backsplash',
                  },
                  {
                    label: 'None of the above',
                    value: 'none',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-8',
        },
      },

      // #Q9 https://projects.invisionapp.com/d/#/console/21313976/454127686/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Have you remodeled your kitchen since you purchased your home?',
            description: 'We consider a remodel to be major upgrade.... etc',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'I\'ve never remodeled my kitchen',
                    value: 'never',
                    excluding: true,
                  },
                  {
                    label: 'Remodeled within the last 5 years',
                    value: 'within_last_5_years',
                    excluding: true,
                  },
                  {
                    label: 'Remodeled 5-10 years ago',
                    value: '5_10_years_ago',
                    excluding: true,
                  },
                  {
                    label: 'Remodeled 10+ years ago',
                    value: 'more_10_years_ago',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-9',
        },
      },

      // #Q10 https://projects.invisionapp.com/d/#/console/21313976/454127687/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What is the overall condition of your kitchen?',
            description: 'We\'re most interested in your appliances, flooring, cabinets, countertops and walls',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Needs work',
                    value: 'needs_work',
                    excluding: true,
                  },
                  {
                    label: 'Average condition',
                    value: 'average_condition',
                    excluding: true,
                  },
                  {
                    label: 'Above average condition',
                    value: 'above_average',
                    excluding: true,
                  },
                  {
                    label: 'Looks brand new',
                    value: 'looks_brand_new',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-10',
        },
      },

      // #Q11 https://projects.invisionapp.com/d/#/console/21313976/454127688/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Does your main bathroom have any of these features?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Double sink',
                    value: 'double_sink',
                  },
                  {
                    label: 'Granite / tile counters',
                    value: 'granite_counters',
                  },
                  {
                    label: 'Separate tub & shower',
                    value: 'separate_tub',
                  },
                  {
                    label: 'Tile shower walls',
                    value: 'tile_shower_valls',
                  },
                  {
                    label: 'Updated tile floors',
                    value: 'updated_tile_floors',
                  },
                  {
                    label: 'None of the above',
                    value: 'none',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-11',
        },
      },

      // #Q12 https://projects.invisionapp.com/d/#/console/21313976/454127689/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Have you remodeled your main bathroom since you purchased your home?',
            description: 'We consider a remodel to be major upgrade... etc',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'I\'ve never remodeled my main bathroom',
                    value: 'never',
                    excluding: true,
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your main bathroom?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Remodeled within the last 5 years',
                    value: 'last_5',
                    excluding: true,
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your main bathroom?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Remodeled 5-10 years ago',
                    value: '5_10_ago',
                    excluding: true,
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your main bathroom?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Remodeled 10+ years ago',
                    value: 'more_10_ago',
                    excluding: true,
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your main bathroom?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-12',
        },
      },

      // #Q14 https://projects.invisionapp.com/d/#/console/21313976/454127691/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What is the condition of your interior paint?',
            description: 'This includes converted garages, sunrooms, bonus rooms, or any other living area extensions',
            fields: [
              {
                type: QuestionFields.FieldType.TileChecklist,
                validations: [QuestionFields.Specific.TileChecklist.Validation.Required],
                options: [
                  {
                    label: 'Needs work',
                    value: 'needs_work',
                    description: 'Visible issues like scratches, scuffs, and holes in the walls',
                    backgroundImageUrl: `${imagesPath}q14-need-work.png`,
                  },
                  {
                    label: 'Average condition',
                    value: 'average',
                    description: 'Some scratches and scuffs, but nothing major',
                    backgroundImageUrl: `${imagesPath}q14-average-condition.png`,
                  },
                  {
                    label: 'Above average condition',
                    value: 'above_average',
                    description: 'Very few scratches or wear',
                    backgroundImageUrl: `${imagesPath}q14-above-average-condition.png`,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-14',
        },
      },

      // #Q15 https://projects.invisionapp.com/d/#/console/21313976/454127692/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What type of flooring do you have?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                customValueField: {
                  type: QuestionFields.FieldType.Text,
                  label: 'Other',
                  placeholder: 'Please specify',
                },
                options: [
                  {
                    label: 'Tile',
                    value: 'tile',
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your tile flooring?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Laminate',
                    value: 'laminate',
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your laminate flooring?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Hardwood',
                    value: 'hardwood',
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your hardwood flooring?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Carpet',
                    value: 'carpet',
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your carpet flooring?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-15',
        },
      },

      // #Q15a https://projects.invisionapp.com/d/main#/console/21741472/460860360/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Please tell us the age of these important items in the home.',
            fields: [
              {
                type: QuestionFields.FieldType.Select,
                label: 'Water Heater',
                placeholder: 'Select age of water heater',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1-5 years',
                    value: '1-5 years',
                  },
                  {
                    label: '6-10 years',
                    value: '6-10 years',
                  },
                  {
                    label: '11-15 years',
                    value: '11-15 years',
                  },
                  {
                    label: '16-20 years',
                    value: '16-20 years',
                  },
                  {
                    label: 'Unknown',
                    value: 'unknown',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Select,
                label: 'Roof',
                placeholder: 'Select age of roof',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1-5 years',
                    value: '1-5 years',
                  },
                  {
                    label: '6-10 years',
                    value: '6-10 years',
                  },
                  {
                    label: '11-15 years',
                    value: '11-15 years',
                  },
                  {
                    label: '16-20 years',
                    value: '16-20 years',
                  },
                  {
                    label: 'Unknown',
                    value: 'unknown',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Select,
                label: 'Furnace',
                placeholder: 'Select age of furnace',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1-5 years',
                    value: '1-5 years',
                  },
                  {
                    label: '6-10 years',
                    value: '6-10 years',
                  },
                  {
                    label: '11-15 years',
                    value: '11-15 years',
                  },
                  {
                    label: '16-20 years',
                    value: '16-20 years',
                  },
                  {
                    label: 'Unknown',
                    value: 'unknown',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Select,
                label: 'Air Conditioning',
                placeholder: 'Select age of air conditioning',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: 'N/A',
                    value: 'N/A',
                  },
                  {
                    label: '1-5 years',
                    value: '1-5 years',
                  },
                  {
                    label: '6-10 years',
                    value: '6-10 years',
                  },
                  {
                    label: '11-15 years',
                    value: '11-15 years',
                  },
                  {
                    label: '16-20 years',
                    value: '16-20 years',
                  },
                  {
                    label: 'Unknown',
                    value: 'unknown',
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-15a',
        },
      },

      // #Q16 https://projects.invisionapp.com/d/#/console/21313976/454127694/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Which floor is your main bedroom on?',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: '1st Floor',
                    value: '1',
                    excluding: true,
                  },
                  {
                    label: '2nd Floor',
                    value: '2',
                    excluding: true,
                  },
                  {
                    label: 'Other',
                    value: 'other',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-16',
        },
      },

      // #Q17 https://projects.invisionapp.com/d/#/console/21313976/454127695/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Does your home have a view?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Mountain view',
                    value: 'mountain',
                  },
                  {
                    label: 'Water view (ocean lake pond)',
                    value: 'water',
                  },
                  {
                    label: 'City view',
                    value: 'city',
                  },
                  {
                    label: 'None of these',
                    value: 'none',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-17',
        },
      },

      // #Q18 https://projects.invisionapp.com/d/#/console/21313976/454127696/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Is your home next to any of the following?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Highways or railways',
                    value: 'highways',
                  },
                  {
                    label: 'Local utilities (water treatment, power)',
                    value: 'local_utilities',
                  },
                  {
                    label: 'Commercial buildings',
                    value: 'commercial_buildings',
                  },
                  {
                    label: 'None of these',
                    value: 'none',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-18',
        },
      },

      // #Q19 https://projects.invisionapp.com/d/#/console/21313976/454127698/preview
      /* ZCAPP-262 - replace #Q19 with a new page
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What is the overall condition of your  exterior facade?',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Needs work',
                    value: 'needs_work',
                    excluding: true,
                  },
                  {
                    label: 'Average condition',
                    value: 'average',
                    excluding: true,
                  },
                  {
                    label: 'Above average condition',
                    value: 'above_average',
                    excluding: true,
                  },
                  {
                    label: 'Looks brand new',
                    value: 'brand_new',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-19',
        },
      } */
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Overall Property Condition',
            fields: [
              {
                type: QuestionFields.FieldType.Select,
                label: 'Please rate the overall condition of the exterior of the home',
                placeholder: 'Select condition of exterior',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1',
                    value: '1',
                  },
                  {
                    label: '2',
                    value: '2',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                  {
                    label: '4',
                    value: '4',
                  },
                  {
                    label: '5',
                    value: '5',
                  },
                  {
                    label: '6',
                    value: '6',
                  },
                  {
                    label: '7',
                    value: '7',
                  },
                  {
                    label: '8',
                    value: '8',
                  },
                  {
                    label: '9',
                    value: '9',
                  },
                  {
                    label: '10',
                    value: '10',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Select,
                label: 'Please rate the overall condition of the interior of the home',
                placeholder: 'Select condition of interior',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1',
                    value: '1',
                  },
                  {
                    label: '2',
                    value: '2',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                  {
                    label: '4',
                    value: '4',
                  },
                  {
                    label: '5',
                    value: '5',
                  },
                  {
                    label: '6',
                    value: '6',
                  },
                  {
                    label: '7',
                    value: '7',
                  },
                  {
                    label: '8',
                    value: '8',
                  },
                  {
                    label: '9',
                    value: '9',
                  },
                  {
                    label: '10',
                    value: '10',
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-19',
        },
      },

      // #Upload Photos: https://projects.invisionapp.com/d/#/console/21313976/453331540/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Upload as many photos of your home as possible for a more accurate evaluation',
            description: 'Adding photos can sometimes increase our offer by more than 10%',
            fields: [
              {
                type: QuestionFields.FieldType.ImageUpload,
                containers: [],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'home-photos',
        },
      },

      // #Comfirmation 1 https://projects.invisionapp.com/d/#/console/21313976/453331537/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Your Contact Information?',
            // eslint-disable-next-line max-len
            description:
              'Please provide your name and email address below so your agent can contact you after reviewing the information',
            fields: [
              {
                type: QuestionFields.FieldType.Text,
                definedType: QuestionFields.DefinedFieldType.FullName,
                label: 'Full Name',
                placeholder: 'Enter your full name',
                validations: [QuestionFields.Specific.Text.Validation.Required],
              },
              {
                type: QuestionFields.FieldType.Text,
                definedType: QuestionFields.DefinedFieldType.EmailAddress,
                label: 'Email Address',
                placeholder: 'Enter email address',
                validations: [QuestionFields.Specific.Text.Validation.Required, QuestionFields.Specific.Text.Validation.Email],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.Header,
          key: 'seller-info-1',
        },
      },
    ],
    step: 0,
  };

}

/**
 * Helper get mock data via marketing.
 * @param imagesPath Prefix for image path.
 */
export function getMockDataMarketing(imagesPath = '/assets/img/question/'): Questionnaire {
  return {
    stages: [
      // #Intro https://projects.invisionapp.com/d/#/console/21313976/454127677/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Interested in getting an instant offer on your home? Easy as 1, 2, 3',
            fields: [
              {
                type: QuestionFields.FieldType.OrderedFieldList,
                validations: [QuestionFields.Specific.OrderedFieldList.Validation.Required],
                options: [
                  {
                    type: QuestionFields.FieldType.GoogleAutocomplete,
                    validations: [QuestionFields.Specific.GoogleAutocomplete.Validation.Required],
                    label: 'Enter Your Home Address',
                    placeholder: 'Enter home address',
                    unitPlaceholder: 'Enter unit',
                  },
                  {
                    label: 'Fill out simple questionnaire',
                    value: void 0,
                  },
                  {
                    label: 'Get Your Offer!',
                    value: void 0,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.Header,
          key: 'question-intro',
        },
      },

      // #Q0 https://projects.invisionapp.com/d/#/console/21313976/458079029/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Tell us how many bedrooms, bathrooms your home has as well as the square footage',
            fields: [
              {
                type: QuestionFields.FieldType.Select,
                definedType: QuestionFields.DefinedFieldType.NumberOfBedrooms,
                label: 'Number of bedrooms',
                placeholder: 'Select number of bedrooms',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1',
                    value: '1',
                  },
                  {
                    label: '2',
                    value: '2',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                  {
                    label: '4',
                    value: '4',
                  },
                  {
                    label: '5',
                    value: '5',
                  },
                  {
                    label: '6',
                    value: '6',
                  },
                  {
                    label: '7',
                    value: '7',
                  },
                  {
                    label: '8',
                    value: '8',
                  },
                  {
                    label: '9',
                    value: '9',
                  },
                  {
                    label: '10',
                    value: '10',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Select,
                definedType: QuestionFields.DefinedFieldType.NumberOfBathrooms,
                label: 'Number of bathrooms',
                placeholder: 'Select number of bathrooms',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1',
                    value: '1',
                  },
                  {
                    label: '1.5',
                    value: '1.5',
                  },
                  {
                    label: '2',
                    value: '2',
                  },
                  {
                    label: '2.5',
                    value: '2.5',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                  {
                    label: '3.5',
                    value: '3.5',
                  },
                  {
                    label: '4',
                    value: '4',
                  },
                  {
                    label: '4.5',
                    value: '4.5',
                  },
                  {
                    label: '5+',
                    value: '5+',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Number,
                definedType: QuestionFields.DefinedFieldType.SquareFeetAboveGrade,
                label: 'Square Feet - Above Grade Finished', // ZCAPP-262
                placeholder: 'Enter square footage',
                validations: [QuestionFields.Specific.Number.Validation.Required],
              },
              {
                type: QuestionFields.FieldType.Number,
                definedType: QuestionFields.DefinedFieldType.SquareFeetBelowGrade,
                label: 'Square Feet - Below Grade Finished', // ZCAPP-262
                placeholder: 'Enter square footage',
              },
              {
                type: QuestionFields.FieldType.Number,
                label: 'What year was your house built?', // ZCAPP-856
                placeholder: 'Enter year',
                validations: [QuestionFields.Specific.Number.Validation.Required],
              },
              {
                type: QuestionFields.FieldType.Number,
                label: 'Monthly HOA Fee (if applicable)',
                placeholder: 'Enter Monthly HOA Fee',
              },
              {
                type: QuestionFields.FieldType.Select,
                definedType: QuestionFields.DefinedFieldType.NumberOfBathrooms,
                label: 'Do you have any pets?',
                placeholder: 'Select option',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: 'Yes',
                    value: 'Yes',
                  },
                  {
                    label: 'No',
                    value: 'No',
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.Header,
          key: 'home-info-5',
        },
      },

      // #Q1 https://projects.invisionapp.com/d/#/console/21313976/454127678/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Does your home have any of the following?',
            description:
              'These are uncommon but we may not be able to purchase your home if some of these conditions apply. Select all that apply.',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Solar Panels',
                    value: 'Solar Panels',
                  },
                  {
                    label: 'Known foundation issues',
                    value: 'Known foundation issues',
                  },
                  {
                    label: 'Gated Community',
                    value: 'Gated Community',
                  },
                  {
                    label: 'Age-restricted community',
                    value: 'Age-restricted community',
                  },
                  {
                    label: 'Chemical contamination',
                    value: 'Chemical contamination',
                  },
                  {
                    label: 'None of the above',
                    value: 'None of the above',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-1',
        },
      },

      // #Q2 https://projects.invisionapp.com/d/#/console/21313976/454127679/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Does your home have any special features?',
            fields: [
              {
                type: QuestionFields.FieldType.TileChecklist,
                validations: [QuestionFields.Specific.TileChecklist.Validation.Required],
                multiple: true,
                options: [
                  {
                    label: 'Garage',
                    value: 'garage',
                    backgroundImageUrl: `${imagesPath}q2-garage.png`,
                    extra: [
                      {
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Carport',
                            value: 'carport',
                          },
                          {
                            label: 'Single',
                            value: 'single',
                          },
                          {
                            label: 'Double',
                            value: 'double',
                          },
                          {
                            label: 'Triple',
                            value: 'triple',
                          },
                        ],
                      },
                      {
                        type: QuestionFields.FieldType.Checklist,
                        options: [
                          {
                            label: 'Separate Building',
                            value: 'separate_building',
                          },
                        ],
                      },
                      {
                        label: 'What is the condition of your garage',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Carport',
                    value: 'carport',
                    backgroundImageUrl: `${imagesPath}q2-carport.png`,
                    extra: [
                      {
                        label: 'What is the condition of your carport',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Basement',
                    value: 'basement',
                    backgroundImageUrl: `${imagesPath}q2-basement.png`,
                    extra: [
                      {
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Finished',
                            value: 'finished',
                          },
                          {
                            label: 'Partially Finished',
                            value: 'partially finished',
                          },
                          {
                            label: 'Unfinished',
                            value: 'unfinished',
                          },
                        ],
                      },
                      {
                        label: 'What is the condition of your basement',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Hot tub/Spa',
                    value: 'hot_tub',
                    backgroundImageUrl: `${imagesPath}q2-hot-tub.png`,
                    extra: [
                      {
                        label: 'What is the condition of your hot tub/spa',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Swimming Pool',
                    value: 'pool',
                    backgroundImageUrl: `${imagesPath}q2-swimming-pool.png`,
                    extra: [
                      {
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'In-ground',
                            value: 'in_ground',
                          },
                          {
                            label: 'Above-ground',
                            value: 'above_ground',
                          },
                          {
                            label: 'Community',
                            value: 'community',
                          },
                        ],
                      },
                      {
                        label: 'What is the condition of your pool',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    label: 'Fully enclosed fence',
                    value: 'enclosed_fence',
                    backgroundImageUrl: `${imagesPath}q2-fully-enclosed-fence.png`,
                    extra: [
                      {
                        label: 'What is the condition of your fully enclosed fence',
                        type: QuestionFields.FieldType.Select,
                        options: [
                          {
                            label: 'Needs work',
                            value: 'needs_work',
                          },
                          {
                            label: 'Average condition',
                            value: 'average',
                          },
                          {
                            label: 'Above average condition',
                            value: 'above_average',
                          },
                          {
                            label: 'Looks brand new',
                            value: 'brand_new',
                          },
                        ],
                      },
                    ],
                  },
                ],
                customValueField: {
                  type: QuestionFields.FieldType.Text,
                  label: 'Other',
                  placeholder: 'Enter description',
                  multiline: true,
                },
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-2',
        },
      },

      // #Q3 https://projects.invisionapp.com/d/#/console/21313976/454127680/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Has your home ever had flood damage?',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Yes',
                    value: 'yes',
                    excluding: true,
                  },
                  {
                    label: 'No',
                    value: 'no',
                    excluding: true,
                  },
                  {
                    label: 'I don\'t know',
                    value: 'idk',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-3',
        },
      },

      // #Q4 https://projects.invisionapp.com/d/#/console/21313976/454127681/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Have you made any major improvements?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Renovated kitchen',
                    value: 'renovated_kitchen',
                  },
                  {
                    label: 'Renovated main bathroom',
                    value: 'renovated_main_bathroom',
                  },
                  {
                    label: 'Added finished living space',
                    value: 'added_finished_living_space',
                  },
                  {
                    label: 'Replaced main flooring',
                    value: 'replaced_main_flooring',
                  },
                  {
                    label: 'Fully repainted exterior',
                    value: 'fully_repainted_exterior',
                  },
                  {
                    label: 'None of the above',
                    value: 'none',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-4',
        },
      },

      // #Q5 https://projects.invisionapp.com/d/#/console/21313976/454127682/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Have you or previous owners added any living area to the home since the original construction?',
            description: 'This includes converted garages, sunrooms, bonus rooms, or any other living area extensions',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'No additions have been made',
                    value: 'no_additions',
                    excluding: true,
                  },
                  {
                    label: 'Additions were made with permits',
                    value: 'additions_with_permits',
                    excluding: true,
                  },
                  {
                    label: 'Additions were made without permits',
                    value: 'additions_without_permits',
                    excluding: true,
                  },
                  {
                    label: 'I\'m not sure',
                    value: 'not_sure',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-5',
        },
      },

      // #Q6 https://projects.invisionapp.com/d/#/console/21313976/454127683/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What countertops does your kitchen have?',
            fields: [
              {
                type: QuestionFields.FieldType.TileChecklist,
                validations: [QuestionFields.Specific.TileChecklist.Validation.Required],
                multiple: true,
                options: [
                  {
                    label: 'Granite Slab',
                    value: 'granite_slab',
                    backgroundImageUrl: `${imagesPath}q6-granite-slab.png`,
                  },
                  {
                    label: 'Granite Tile',
                    value: 'granite_tile',
                    backgroundImageUrl: `${imagesPath}q6-granite-tile.png`,
                  },
                  {
                    label: 'Corian',
                    value: 'corian',
                    backgroundImageUrl: `${imagesPath}q6-corian.png`,
                  },
                  {
                    label: 'Formica',
                    value: 'formica',
                    backgroundImageUrl: `${imagesPath}q6-formica.png`,
                  },
                  {
                    label: 'Quartz',
                    value: 'quartz',
                    backgroundImageUrl: `${imagesPath}q6-quartz.png`,
                  },
                  {
                    label: 'Other tile',
                    value: 'other_tile',
                    backgroundImageUrl: `${imagesPath}q6-other-tile.png`,
                  },
                  {
                    label: 'Standard Countertop',
                    value: 'standard_countertop',
                    backgroundImageUrl: `${imagesPath}q6-standard-countertop.png`,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-6',
        },
      },

      // #Q7 https://projects.invisionapp.com/d/#/console/21313976/454127684/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What type of kitchen appliances do you have?',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'All stainless steel',
                    value: 'all_stainless_steel',
                  },
                  {
                    label: 'All black',
                    value: 'all_black',
                  },
                  {
                    label: 'All white',
                    value: 'all_white',
                  },
                  {
                    label: 'Mixed',
                    value: 'mixed',
                  },
                  {
                    label: 'Other / Don\'t know',
                    value: 'other',
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-7',
        },
      },

      // #Q8 https://projects.invisionapp.com/d/#/console/21313976/454127685/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Does your kitchen have any of the following features?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Kitchen island',
                    value: 'kitchen_island',
                  },
                  {
                    label: 'New cabinets',
                    value: 'new_cabinets',
                  },
                  {
                    label: 'Tile backsplash',
                    value: 'tile_backsplash',
                  },
                  {
                    label: 'None of the above',
                    value: 'none',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-8',
        },
      },

      // #Q9 https://projects.invisionapp.com/d/#/console/21313976/454127686/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Have you remodeled your kitchen since you purchased your home?',
            description: 'We consider a remodel to be major upgrade.... etc',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'I\'ve never remodeled my kitchen',
                    value: 'never',
                    excluding: true,
                  },
                  {
                    label: 'Remodeled within the last 5 years',
                    value: 'within_last_5_years',
                    excluding: true,
                  },
                  {
                    label: 'Remodeled 5-10 years ago',
                    value: '5_10_years_ago',
                    excluding: true,
                  },
                  {
                    label: 'Remodeled 10+ years ago',
                    value: 'more_10_years_ago',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-9',
        },
      },

      // #Q10 https://projects.invisionapp.com/d/#/console/21313976/454127687/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What is the overall condition of your kitchen?',
            description: 'We\'re most interested in your appliances, flooring, cabinets, countertops and walls',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Needs work',
                    value: 'needs_work',
                    excluding: true,
                  },
                  {
                    label: 'Average condition',
                    value: 'average_condition',
                    excluding: true,
                  },
                  {
                    label: 'Above average condition',
                    value: 'above_average',
                    excluding: true,
                  },
                  {
                    label: 'Looks brand new',
                    value: 'looks_brand_new',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-10',
        },
      },

      // #Q11 https://projects.invisionapp.com/d/#/console/21313976/454127688/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Does your main bathroom have any of these features?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Double sink',
                    value: 'double_sink',
                  },
                  {
                    label: 'Granite / tile counters',
                    value: 'granite_counters',
                  },
                  {
                    label: 'Separate tub & shower',
                    value: 'separate_tub',
                  },
                  {
                    label: 'Tile shower walls',
                    value: 'tile_shower_valls',
                  },
                  {
                    label: 'Updated tile floors',
                    value: 'updated_tile_floors',
                  },
                  {
                    label: 'None of the above',
                    value: 'none',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-11',
        },
      },

      // #Q12 https://projects.invisionapp.com/d/#/console/21313976/454127689/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Have you remodeled your main bathroom since you purchased your home?',
            description: 'We consider a remodel to be major upgrade... etc',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'I\'ve never remodeled my main bathroom',
                    value: 'never',
                    excluding: true,
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your main bathroom?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Remodeled within the last 5 years',
                    value: 'last_5',
                    excluding: true,
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your main bathroom?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Remodeled 5-10 years ago',
                    value: '5_10_ago',
                    excluding: true,
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your main bathroom?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Remodeled 10+ years ago',
                    value: 'more_10_ago',
                    excluding: true,
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your main bathroom?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-12',
        },
      },

      // #Q14 https://projects.invisionapp.com/d/#/console/21313976/454127691/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What is the condition of your interior paint?',
            description: 'This includes converted garages, sunrooms, bonus rooms, or any other living area extensions',
            fields: [
              {
                type: QuestionFields.FieldType.TileChecklist,
                validations: [QuestionFields.Specific.TileChecklist.Validation.Required],
                options: [
                  {
                    label: 'Needs work',
                    value: 'needs_work',
                    description: 'Visible issues like scratches, scuffs, and holes in the walls',
                    backgroundImageUrl: `${imagesPath}q14-need-work.png`,
                  },
                  {
                    label: 'Average condition',
                    value: 'average',
                    description: 'Some scratches and scuffs, but nothing major',
                    backgroundImageUrl: `${imagesPath}q14-average-condition.png`,
                  },
                  {
                    label: 'Above average condition',
                    value: 'above_average',
                    description: 'Very few scratches or wear',
                    backgroundImageUrl: `${imagesPath}q14-above-average-condition.png`,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-14',
        },
      },

      // #Q15 https://projects.invisionapp.com/d/#/console/21313976/454127692/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What type of flooring do you have?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                customValueField: {
                  type: QuestionFields.FieldType.Text,
                  label: 'Other',
                  placeholder: 'Please specify',
                },
                options: [
                  {
                    label: 'Tile',
                    value: 'tile',
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your tile flooring?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Laminate',
                    value: 'laminate',
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your laminate flooring?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Hardwood',
                    value: 'hardwood',
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your hardwood flooring?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                  {
                    label: 'Carpet',
                    value: 'carpet',
                    child: {
                      type: QuestionFields.FieldType.Checklist,
                      validations: [QuestionFields.Specific.Checklist.Validation.Required],
                      label: 'What is the condition of your carpet flooring?',
                      options: [
                        {
                          label: 'Needs work',
                          value: 'needs_work',
                          excluding: true,
                        },
                        {
                          label: 'Average condition',
                          value: 'average',
                          excluding: true,
                        },
                        {
                          label: 'Above average condition',
                          value: 'above_average',
                          excluding: true,
                        },
                        {
                          label: 'Looks brand new',
                          value: 'brand_new',
                          excluding: true,
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-15',
        },
      },

      // #Q15a https://projects.invisionapp.com/d/main#/console/21741472/460860360/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Please tell us the age of these important items in the home.',
            fields: [
              {
                type: QuestionFields.FieldType.Select,
                label: 'Water Heater',
                placeholder: 'Select age of water heater',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1-5 years',
                    value: '1-5 years',
                  },
                  {
                    label: '6-10 years',
                    value: '6-10 years',
                  },
                  {
                    label: '11-15 years',
                    value: '11-15 years',
                  },
                  {
                    label: '16-20 years',
                    value: '16-20 years',
                  },
                  {
                    label: 'Unknown',
                    value: 'unknown',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Select,
                label: 'Roof',
                placeholder: 'Select age of roof',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1-5 years',
                    value: '1-5 years',
                  },
                  {
                    label: '6-10 years',
                    value: '6-10 years',
                  },
                  {
                    label: '11-15 years',
                    value: '11-15 years',
                  },
                  {
                    label: '16-20 years',
                    value: '16-20 years',
                  },
                  {
                    label: 'Unknown',
                    value: 'unknown',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Select,
                label: 'Furnace',
                placeholder: 'Select age of furnace',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1-5 years',
                    value: '1-5 years',
                  },
                  {
                    label: '6-10 years',
                    value: '6-10 years',
                  },
                  {
                    label: '11-15 years',
                    value: '11-15 years',
                  },
                  {
                    label: '16-20 years',
                    value: '16-20 years',
                  },
                  {
                    label: 'Unknown',
                    value: 'unknown',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Select,
                label: 'Air Conditioning',
                placeholder: 'Select age of air conditioning',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: 'N/A',
                    value: 'N/A',
                  },
                  {
                    label: '1-5 years',
                    value: '1-5 years',
                  },
                  {
                    label: '6-10 years',
                    value: '6-10 years',
                  },
                  {
                    label: '11-15 years',
                    value: '11-15 years',
                  },
                  {
                    label: '16-20 years',
                    value: '16-20 years',
                  },
                  {
                    label: 'Unknown',
                    value: 'unknown',
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-15a',
        },
      },

      // #Q16 https://projects.invisionapp.com/d/#/console/21313976/454127694/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Which floor is your main bedroom on?',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: '1st Floor',
                    value: '1',
                    excluding: true,
                  },
                  {
                    label: '2nd Floor',
                    value: '2',
                    excluding: true,
                  },
                  {
                    label: 'Other',
                    value: 'other',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-16',
        },
      },

      // #Q17 https://projects.invisionapp.com/d/#/console/21313976/454127695/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Does your home have a view?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Mountain view',
                    value: 'mountain',
                  },
                  {
                    label: 'Water view (ocean lake pond)',
                    value: 'water',
                  },
                  {
                    label: 'City view',
                    value: 'city',
                  },
                  {
                    label: 'None of these',
                    value: 'none',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-17',
        },
      },

      // #Q18 https://projects.invisionapp.com/d/#/console/21313976/454127696/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Is your home next to any of the following?',
            description: 'Select all that apply',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Highways or railways',
                    value: 'highways',
                  },
                  {
                    label: 'Local utilities (water treatment, power)',
                    value: 'local_utilities',
                  },
                  {
                    label: 'Commercial buildings',
                    value: 'commercial_buildings',
                  },
                  {
                    label: 'None of these',
                    value: 'none',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-18',
        },
      },

      // #Q19 https://projects.invisionapp.com/d/#/console/21313976/454127698/preview
      /* ZCAPP-262 - replace #Q19 with a new page
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'What is the overall condition of your  exterior facade?',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Needs work',
                    value: 'needs_work',
                    excluding: true,
                  },
                  {
                    label: 'Average condition',
                    value: 'average',
                    excluding: true,
                  },
                  {
                    label: 'Above average condition',
                    value: 'above_average',
                    excluding: true,
                  },
                  {
                    label: 'Looks brand new',
                    value: 'brand_new',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-19',
        },
      } */
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Overall Property Condition',
            fields: [
              {
                type: QuestionFields.FieldType.Select,
                label: 'Please rate the overall condition of the exterior of the home',
                placeholder: 'Select condition of exterior',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1',
                    value: '1',
                  },
                  {
                    label: '2',
                    value: '2',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                  {
                    label: '4',
                    value: '4',
                  },
                  {
                    label: '5',
                    value: '5',
                  },
                  {
                    label: '6',
                    value: '6',
                  },
                  {
                    label: '7',
                    value: '7',
                  },
                  {
                    label: '8',
                    value: '8',
                  },
                  {
                    label: '9',
                    value: '9',
                  },
                  {
                    label: '10',
                    value: '10',
                  },
                ],
              },
              {
                type: QuestionFields.FieldType.Select,
                label: 'Please rate the overall condition of the interior of the home',
                placeholder: 'Select condition of interior',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1',
                    value: '1',
                  },
                  {
                    label: '2',
                    value: '2',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                  {
                    label: '4',
                    value: '4',
                  },
                  {
                    label: '5',
                    value: '5',
                  },
                  {
                    label: '6',
                    value: '6',
                  },
                  {
                    label: '7',
                    value: '7',
                  },
                  {
                    label: '8',
                    value: '8',
                  },
                  {
                    label: '9',
                    value: '9',
                  },
                  {
                    label: '10',
                    value: '10',
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-19',
        },
      },

      // #Comfirmation 1 https://projects.invisionapp.com/d/#/console/21313976/453331537/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Your Contact Information?',
            // eslint-disable-next-line max-len
            description:
              'Please provide your name and email address below so your agent can contact you after reviewing the information',
            fields: [
              {
                type: QuestionFields.FieldType.Text,
                definedType: QuestionFields.DefinedFieldType.FullName,
                label: 'Full Name',
                placeholder: 'Enter your full name',
                validations: [QuestionFields.Specific.Text.Validation.Required],
              },
              {
                type: QuestionFields.FieldType.Text,
                definedType: QuestionFields.DefinedFieldType.EmailAddress,
                label: 'Email Address',
                placeholder: 'Enter email address',
                validations: [QuestionFields.Specific.Text.Validation.Required, QuestionFields.Specific.Text.Validation.Email],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.Header,
          key: 'seller-info-1',
        },
      },

      // #Upload Photos: https://projects.invisionapp.com/d/#/console/21313976/453331540/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Upload as many photos of your home as possible for a more accurate evaluation',
            description: 'Adding photos can sometimes increase our offer by more than 10%',
            fields: [
              {
                type: QuestionFields.FieldType.ImageUpload,
                containers: [],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'home-photos',
        },
      },

      // #Sale Timeline: https://projects.invisionapp.com/d/#/console/21313976/453331535/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'On a scale of 1-10, 10 being highly motivated, how would you rank your motivation to sell?',
            fields: [
              {
                label: 'Rank',
                type: QuestionFields.FieldType.Select,
                placeholder: 'Select a number',
                validations: [QuestionFields.Specific.Select.Validation.Required],
                options: [
                  {
                    label: '1',
                    value: '1',
                  },
                  {
                    label: '2',
                    value: '2',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                  {
                    label: '4',
                    value: '4',
                  },
                  {
                    label: '5',
                    value: '5',
                  },
                  {
                    label: '6',
                    value: '6',
                  },
                  {
                    label: '7',
                    value: '7',
                  },
                  {
                    label: '8',
                    value: '8',
                  },
                  {
                    label: '9',
                    value: '9',
                  },
                  {
                    label: '10',
                    value: '10',
                  },
                ],
              },
              {
                label: 'Why are you selling?',
                type: QuestionFields.FieldType.Text,
                placeholder: 'Enter the reason',
                multiline: true,
              },
              {
                label: 'What\'s your sale timeline?',
                type: QuestionFields.FieldType.Select,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'ASAP',
                    value: 'asap',
                  },
                  {
                    label: '2 - 4 weeks',
                    value: '2 - 4 weeks',
                  },
                  {
                    label: '4 - 6 weeks',
                    value: '4 - 6 weeks',
                  },
                  {
                    label: '6+ weeks',
                    value: '6+ weeks',
                  },
                  {
                    label: 'Just Browsing',
                    value: 'Just Browsing',
                  },
                ],
              },
            ],
          },

        ],
        questionExtraInfo: {
          section: Section.QuestionnaireList,
          key: 'question-sale-timeline',
        },
      },

      // #Are You Buying A Home: https://projects.invisionapp.com/d/#/console/21313976/453331536/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Are you looking to buy your next home?',
            fields: [
              {
                type: QuestionFields.FieldType.Checklist,
                validations: [QuestionFields.Specific.Checklist.Validation.Required],
                options: [
                  {
                    label: 'Yes, I\'m looking to buy my next home',
                    value: 'yes',
                    excluding: true,
                  },
                  {
                    label: 'I\'ve already found my next home',
                    value: 'already_found',
                    excluding: true,
                  },
                  {
                    label: 'I\'m not looking now, or I\'m planning to rent',
                    value: 'no',
                    excluding: true,
                  },
                ],
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.Header,
          key: 'seller-info-4',
        },
      },

      // #Optional Walk Through: https://projects.invisionapp.com/d/#/console/21313976/453331538/preview
      {
        questionnaireStageControls: {
          nextStageControl: {
            label: 'Schedule Offer Review Now',
            type: StageStyleClass.ButtonSecondary,
          },
          forceSubmitControl: {
            label: 'Schedule Offer Review Later',
          },
        },
        questions: [
          {
            label: 'Set up an appointment to walk through your offer once it\'s ready',
            // eslint-disable-next-line max-len
            description:
              'I will be happy to talk to you on the phone or meet face to face to discuss the details of the offer to ensure all your questions are answered and timelines met.',
            fields: [],
          },
        ],
        questionExtraInfo: {
          section: Section.Ignore,
          key: 'question-optional-walk-through',
        },
      },

      // #Optional Walk Through - Select: https://projects.invisionapp.com/d/#/console/21313976/453331539/preview
      {
        questionnaireStageControls: DEFAULT_QUESTIONNAIRE_CONTROLS,
        questions: [
          {
            label: 'Select a Day',
            description: 'Select two days that will accommodate your schedule to review offer',
            fields: [
              {
                type: QuestionFields.FieldType.Day,
                validations: [QuestionFields.Specific.DayField.Validation.Required, QuestionFields.Specific.DayField.Validation.Max2],
                range: {
                  start: NOW.toString(),
                  end: new Date(NOW.getFullYear(), NOW.getMonth(), NOW.getDate() + 7).toString(),
                },
              },
            ],
          },
        ],
        questionExtraInfo: {
          section: Section.Header,
          key: 'question-optional-walk-through-select',
        },
      },

    ],
    step: 0,
  };
}
