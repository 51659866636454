<div
  class="entity-selector zc-input"
  matAutocompleteOrigin
  #origin="matAutocompleteOrigin"
  [class.entity-selector_disabled]="disabled"
>
  <mat-chip-list #chipList class="entity-selector__chips">
    <mat-basic-chip
      class="chip"
      [class.chip_disabled]="disabled"
      *ngFor="let item of controlValue"
      [removable]="!disabled"
      [disableRipple]="true"
      (removed)="onRemove(item)"
    >
      <span class="chip__text">{{ configuration.toReadable(item) }}</span>

      <!-- Unable to use <button> since click on outer label causes it to triggered/clicked. -->
      <div
        role="button"
        [title]="'Remove &quot;' + configuration.toReadable(item) + '&quot;'"
        class="chip__remove-button"
        matChipRemove
      >
        <mat-icon>close</mat-icon>
      </div>
    </mat-basic-chip>
    <input
      *ngIf="shouldShowInput()"
      class="entity-selector__text-input"
      [placeholder]="controlValue?.length ? '' : placeholder"
      [formControl]="filterControl"
      [matChipInputFor]="chipList"
      [matAutocomplete]="auto"
      [matAutocompleteConnectedTo]="origin"
      #input
      [disabled]="disabled"
    />
  </mat-chip-list>
  <mat-autocomplete
    #auto="matAutocomplete"
    [disableRipple]="true"
    (optionSelected)="onSelect($event.option.value)"
  >
    <mat-option
      [disabled]="isSelected(item)"
      *ngFor="let item of data$ | async"
      [value]="item"
      (click)="onOptionClick()"
    >
      <span class="entity-selector__text">
        {{ configuration.toReadable(item) }}
      </span>
    </mat-option>

    <ng-container *ngIf="data$ | async as data">
      <ng-container *ngIf="data.length === 0">
        <mat-option
          class="entity-selector__no-items-option placeholder"
          disabled
        >
          <span class="entity-selector__no-items-message">No items found</span>
        </mat-option>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="(data$ | async) === null">
      <mat-option disabled *ngFor="let i of [1, 2, 3, 4]">
        <span
          class="entity-selector__text entity-selector__text_skeleton"
          zcSkeleton
          [zcSkeletonMinWidth]="10"
          [zcSkeletonMaxWidth]="20"
        ></span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</div>
