import { SimpleValueAccessor, controlProviderFor } from '@zc/common/core/utils/value-accessor';
import { defer, tap } from 'rxjs';
import { AuthService } from '@zc/common/core/services/auth.service';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { AppConfigService } from '@zc/common/core/services/app-config.service';
import { ChangeDetectionStrategy, Component, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { Router } from '@angular/router';

import { ReCaptchaValidationResult } from './../../../core/models/re-captcha-validation-result';

/** Re-captcha component. */
@Destroyable()
@Component({
  selector: 'zc-re-captcha',
  templateUrl: 're-captcha.component.html',
  styleUrls: ['./re-captcha.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(RecaptchaComponent)],
})
export class RecaptchaComponent extends SimpleValueAccessor<boolean> implements AfterViewInit {

  /** Checkbox element. */
  @ViewChild('checkbox')
  public checkbox?: ElementRef<HTMLDivElement>;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    private readonly appConfigService: AppConfigService,
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {
    super(changeDetectorRef);
  }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    assertNonNull(this.checkbox);
    grecaptcha.enterprise.render(this.checkbox.nativeElement, {
      sitekey: this.appConfigService.recaptchaSiteKey,
      callback: response => {
        defer(() => this.authService.verifyRecaptcha(response)).pipe(
          tap(({ isValid }) => this.onReCaptchaValidation(isValid)),
          takeUntilDestroy(this),
        )
          .subscribe();
      },
    });
  }

  private onReCaptchaValidation(isValid: ReCaptchaValidationResult['isValid']): void {
    if (isValid) {
      this.controlValue = true;
    } else {
      this.router.navigate(['auth', 'login']);
    }
  }
}
