import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonSharedModule } from '@zc/common/shared/common-shared.module';
import { QuestionnaireModule } from '@zc/common/shared/features/questionnaire/questionnaire.module';

import { AgentPortalRoutingModule } from './agent-portal-routing.module';
import { AgentPortalComponent } from './agent-portal.component';
import { AgentFirstTemplateModule } from './templates/agent-first-template/agent-first-template.module';
import { AgentSecondTemplateModule } from './templates/agent-second-template/agent-second-template.module';
import { AgentThirdTemplateModule } from './templates/agent-third-template/agent-third-template.module';

/** Agent home page feature. */
@NgModule({
  declarations: [AgentPortalComponent],
  imports: [
    CommonModule,
    AgentPortalRoutingModule,
    QuestionnaireModule,
    AgentFirstTemplateModule,
    AgentSecondTemplateModule,
    AgentThirdTemplateModule,
    CommonSharedModule,
  ],
})
export class AgentPortalModule {}
