<zcw-agent-section class="faq" id="faq">
  <h2 class="visually-hidden">FAQ</h2>
  <zcw-agent-section-title>
    Frequently Asked Questions
  </zcw-agent-section-title>
  <details class="accordion-item zc-animated">
    <summary class="accordion-item__question">
      Are you, the agent, personally buying my house?
    </summary>

    <p class="accordion-item__answer">No. I am partnered with ZinCasa, Inc.</p>
  </details>

  <details class="accordion-item zc-animated">
    <summary class="accordion-item__question">Who is ZinCasa, Inc.?</summary>

    <p class="accordion-item__answer">
      ZinCasa, Inc. is a white label solution iBuying company based in Irvine,
      CA. My partnership with ZinCasa enables me to offer my clients an
      additional option for selling their home. With my experience and guidance,
      you can make an informed choice as to list your home traditionally or for
      those that are interested, request an instant or cash offer.
    </p>
  </details>

  <details class="accordion-item zc-animated">
    <summary class="accordion-item__question">
      What types of properties does ZinCasa purchase?
    </summary>

    <div class="accordion-item__answer">
      <ul class="list">
        <li>Single family residential homes and town homes</li>
        <li>Homes built after 1970</li>
        <li>Homes with a value of no more than $800,000</li>
      </ul>
    </div>
  </details>

  <details class="accordion-item zc-animated">
    <summary class="accordion-item__question">
      Are there any types of homes you won’t purchase?
    </summary>

    <div class="accordion-item__answer">
      Yes. We will not purchase homes that:
      <ul class="list">
        <li>are outside our current market areas</li>
        <li>are manufactured, pre-fabricated, or mobile</li>
        <li>the seller does not have clear title or ownership to</li>
        <li>
          have significant foundation, structural or other condition issues
        </li>
        <li>are on well and septic</li>
        <li>are condos in certain markets</li>
      </ul>
    </div>
  </details>

  <details class="accordion-item zc-animated">
    <summary class="accordion-item__question">
      How long does the selling process take?
    </summary>

    <div class="accordion-item__answer">
      In some markets, we can close in as little as 72 hours and as far out as
      90 days after accepting an Instant Cash offer.<br />
      <i
        >* Other restrictions apply. Speak to customer service representative
        for details.</i
      >
    </div>
  </details>

  <details class="accordion-item zc-animated">
    <summary class="accordion-item__question">
      How fast will I receive my offer?
    </summary>

    <div class="accordion-item__answer">
      As long as all the required information is provided,I will present an
      offer within 24-48 hours after you submit your request.
    </div>
  </details>

  <details class="accordion-item zc-animated">
    <summary class="accordion-item__question">Does my offer expire?</summary>

    <div class="accordion-item__answer">
      Yes. Home values will often change based on market activity, competition
      and other factors. Our offers will usually expire in four days from the
      date of the offer. However, we are happy to provide you with an updated
      offer. Just click on the Renew button at the top of your expired offer.
      Please be aware that our renewed offer price may be unchanged, higher, or
      lower than your original offer depending upon current market
      conditions. In rare instances, we may conclude that we are unable to renew
      an offer.
    </div>
  </details>

  <details class="accordion-item zc-animated">
    <summary class="accordion-item__question">Are photos required?</summary>

    <div class="accordion-item__answer">
      Yes. Photos help us fully understand the value of your home. Our offers
      are not auto generated or based solely upon our powerful proprietary home
      valuation system. We make it easy for you to upload your photos so our
      team of experts can thoroughly and expediently evaluate your home’s
      features, current condition, and overall value.
    </div>
  </details>

  <details class="accordion-item zc-animated">
    <summary class="accordion-item__question">
      Is my information secure?
    </summary>

    <div class="accordion-item__answer">
      Yes. We do not collect, store, or sell any sensitive information. The
      security of your information is part of our promise to create a better
      experience for you throughout the entire process. For more information,
      please view the
      <a
        class="link"
        [href]="privacyPolicyUrl.toString()" 
        target="_blank"
        >privacy policy</a
      >.<br />
      Additionally, at closing you will be provided with the convenience of
      signing documents electronically with the leading electronic signature
      provider, DocuSign. You can learn about their specific technologies
      <a
        class="link"
        href="https://www.docusign.com/trust/security"
        target="_blank"
        >here</a
      >.
    </div>
  </details>
</zcw-agent-section>
