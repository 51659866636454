import { AppError } from './app-error';

/** User role group. Directly affects behavior of the application. */
export enum UserRoleGroup {
  Admin,
  Agent,
  Seller,
  Contractor,
  TeamMember,
  ProjectManager,
  DataAnalyst,
}

export namespace UserRoleGroup {
  const TO_READABLE_MAP: Readonly<Record<UserRoleGroup, string>> = {
    [UserRoleGroup.Admin]: 'Admin',
    [UserRoleGroup.Agent]: 'Agent',
    [UserRoleGroup.Seller]: 'Seller',
    [UserRoleGroup.TeamMember]: 'Team',
    [UserRoleGroup.Contractor]: 'Contractor',
    [UserRoleGroup.ProjectManager]: 'Project Manager',
    [UserRoleGroup.DataAnalyst]: 'Data Analyst',
  };

  /**
   * Maps role to a human-readable representation.
   * @param role Role.
   */
  export function toReadable(role: UserRoleGroup): string {
    return TO_READABLE_MAP[role];
  }

  /** Admin and team member roles. */
  const MANAGER_ROLES = [
    UserRoleGroup.Admin,
    UserRoleGroup.TeamMember,
  ];

  /**
   * Whether user is admin or team member.
   * @param roleGroup Role to check.
   */
  export function isManagerRoleGroup(roleGroup: UserRoleGroup): boolean {
    return MANAGER_ROLES.includes(roleGroup);
  }

  /** Roles which can edit project/property. */
  const EDIT_PROPERTY_ROLES = [
    UserRoleGroup.Admin,
    UserRoleGroup.Agent,
    UserRoleGroup.TeamMember,
  ];

  /**
   * Whether user can edit project/property.
   * @param roleGroup Role to check.
   */
  export function canEditProjectRoleGroup(roleGroup: UserRoleGroup): boolean {
    return EDIT_PROPERTY_ROLES.includes(roleGroup);
  }
}

/** Roles related to the internal processing of selling the property. */
const BACK_OFFICE_MANAGEMENT_ROLES = [
  UserRoleGroup.Agent,
  UserRoleGroup.Admin,
  UserRoleGroup.TeamMember,
] as const;

/**
 * Type-guard for back office role.
 * @param roleGroup Role to check.
 */
export function isBackOfficeManagementRoleGroup(roleGroup: UserRoleGroup): roleGroup is BackOfficeManagementRoleGroup {
  return BACK_OFFICE_MANAGEMENT_ROLES.includes(<BackOfficeManagementRoleGroup>roleGroup);
}

/**
 * Asserts that the provided role is one belonging to the back-office team.
 * @param roleGroup Role to check.
 */
export function assertBackOfficeManagementRoleGroup(roleGroup: UserRoleGroup): asserts roleGroup is BackOfficeManagementRoleGroup {
  if (!isBackOfficeManagementRoleGroup(roleGroup)) {
    throw new AppError(`Unexpected role: ${UserRoleGroup.toReadable(roleGroup)}`);
  }
}

/** Back-office related roles. */
export type BackOfficeManagementRoleGroup = (typeof BACK_OFFICE_MANAGEMENT_ROLES)[number];
