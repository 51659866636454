import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@matheo/datepicker';
import { MatNativeDateModule } from '@matheo/datepicker/core';
import { CheckboxComponent } from '@zc/common/shared/components/checkbox/checkbox.component';
import { DocumentDropComponent } from '@zc/common/shared/components/document-drop/document-drop.component';
import { DocumentImageComponent } from '@zc/common/shared/components/document-image/document-image.component';
import {
  MaskedNumberControlComponent,
} from '@zc/common/shared/components/masked-number-control/masked-number-control.component';
import {
  MaskedPercentControlComponent,
} from '@zc/common/shared/components/masked-percent-control/masked-percent-control.component';
import { MediaRecorderComponent } from '@zc/common/shared/components/media-recorder/media-recorder.component';
import { PaymentFormComponent } from '@zc/common/shared/components/payment-form/payment-form.component';
import { SwitchControlComponent } from '@zc/common/shared/components/switch-control/switch-control.component';
import { TablePaginatorComponent } from '@zc/common/shared/components/table-paginator/table-paginator.component';
import { AutocompletePositionDirective } from '@zc/common/shared/directives/autocomplete-position.directive';
import { TabsModule } from '@zc/common/shared/modules/tabs/tabs.module';
import { QuarterPipe } from '@zc/common/shared/pipes/quarter.pipe';
import { QRCodeModule } from 'angularx-qrcode';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { SwiperModule } from 'swiper/angular';

import { TaskComponent } from 'projects/zapp-web/src/app/features/z-box/project-management/components/tasks/components/task/task.component';

import { AvatarUploaderComponent } from 'projects/zapp-web/src/app/shared/features/profile-management/avatar-uploader/avatar-uploader.component';

import { LogoUploaderComponent } from 'projects/zapp-web/src/app/shared/features/profile-management/logo-uploader/logo-uploader.component';

import { NgLet } from '../core/utils/ng-let';

import { ActivityListItemComponent } from './components/activity-list-item/activity-list-item.component';
import { AddressComponent } from './components/address/address.component';
import { AsideNavComponent } from './components/aside-nav/aside-nav.component';
import { AutocompleteInputComponent } from './components/autocomplete-input/autocomplete-input.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BurgerMenuComponent } from './components/burger-menu/burger-menu.component';
import { CarouselContentDirective } from './components/carousel/carousel-content.directive';
import { CarouselTemplateDirective } from './components/carousel/carousel-template.directive';
import { CarouselComponent } from './components/carousel/carousel.component';
import { DashboardAddressComponent } from './components/dashboard-address/dashboard-address.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DateRangeSelectComponent } from './components/date-range-select/date-range-select.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { EntitySelectorControlComponent } from './components/entity-selector-control/entity-selector-control.component';
import { FiveStarRatingComponent } from './components/five-star-rating/five-star-rating.component';
import {
  FormControlValidationMessageComponent,
} from './components/form-control-validation-message/form-control-validation-message.component';
import { GenderComponent } from './components/gender/gender.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { LabelComponent } from './components/label/label.component';
import { OverlayMenuModule } from './components/overlay-menu/overlay-menu.module';
import { ProgressComponent } from './components/progress/progress.component';
import { QRCodeComponent } from './components/qrcode/qrcode.component';
import { QuarterPickerComponent } from './components/quarter-picker/quarter-picker.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { RecaptchaComponent } from './components/re-captcha/re-captcha.component';
import { SanitizedHtmlComponent } from './components/sanitized-html/sanitized-html.component';
import { SortModule } from './components/sort/sort.module';
import { TableContainerComponent } from './components/table-container/table-container.component';
import { ToolTipComponent } from './components/tooltip/tooltip.component';
import { UsernameComponent } from './components/username/username.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { AppValidationDirective } from './directives/app-validation.directive';
import { ColorIndicationDirective } from './directives/colored-indication.directive';
import { DisabledDirective } from './directives/disabled.directive';
import { EllipsisDirective } from './directives/ellipsis.directive';
import { FixedBodyDirective } from './directives/fixed-body.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { LoadingDirective } from './directives/loading.directive';
import { SelectPlaceholderDirective } from './directives/select-placeholder.directive';
import { SkeletonDirective } from './directives/skeleton.directive';
import { AddressPipe } from './pipes/address.pipe';
import { CancelReasonPipe } from './pipes/cancel-reason.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { NullablePipe } from './pipes/nullable-string.pipe';
import { NumberPipe } from './pipes/number.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { TerritoriesPipe } from './pipes/territories.pipe';
import { TimePipe } from './pipes/time.pipe';

const EXPORTED_DECLARATIONS = [
  AppValidationDirective,
  FormControlValidationMessageComponent,
  LoadingDirective,
  DisabledDirective,
  SkeletonDirective,
  InfiniteScrollDirective,
  AutocompletePositionDirective,
  FixedBodyDirective,
  SortPipe,
  TimePipe,
  AddressPipe,
  CancelReasonPipe,
  NumberPipe,
  NullablePipe,
  TerritoriesPipe,
  QuarterPipe,
  AsideNavComponent,
  ActivityListItemComponent,
  AvatarComponent,
  UsernameComponent,
  SanitizedHtmlComponent,
  DocumentDropComponent,
  DocumentImageComponent,
  TableContainerComponent,
  FiveStarRatingComponent,
  RadioGroupComponent,
  EntitySelectorControlComponent,
  AutocompleteComponent,
  AutocompleteInputComponent,
  LabelComponent,
  ColorIndicationDirective,
  MaskedNumberControlComponent,
  MaskedPercentControlComponent,
  SwitchControlComponent,
  AddressComponent,
  ProgressComponent,
  ValidationMessageComponent,
  CheckboxComponent,
  EllipsisDirective,
  SelectPlaceholderDirective,
  VideoPlayerComponent,
  DatepickerComponent,
  DateRangePickerComponent,
  ImageUploaderComponent,
  PaymentFormComponent,
  PhonePipe,
  NgLet,
  MoneyPipe,
  DashboardAddressComponent,
  TablePaginatorComponent,
  MediaRecorderComponent,
  CarouselComponent,
  CarouselContentDirective,
  CarouselTemplateDirective,
  TaskComponent,
  JoinPipe,
  AvatarUploaderComponent,
  GenderComponent,
  BurgerMenuComponent,
  LogoUploaderComponent,
  QRCodeComponent,
  RecaptchaComponent,
  ToolTipComponent,
  DateRangeSelectComponent,
];

const EXPORTED_MODULES = [
  MatSnackBarModule,
  MatIconModule,
  MatInputModule,
  CdkStepperModule,
  OverlayMenuModule,
  TabsModule,
  NgxMaskModule,
  SortModule,
  MatSelectModule,
  SwiperModule,
  NgxTippyModule,
];

/**
 * Common shared module.
 * Contains reusable components, directives etc. Can be shared through all apps in the project.
 */
@NgModule({
  declarations: [
    QuarterPickerComponent,
    ...EXPORTED_DECLARATIONS,
  ],
  providers: [

    // For phone pipe
    MaskPipe,

    // For money pipe
    CurrencyPipe,

    // To reuse in TimePipe
    DatePipe,

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    QRCodeModule,
    ...EXPORTED_MODULES,
  ],
  exports: [
    ...EXPORTED_DECLARATIONS,
    ...EXPORTED_MODULES,
  ],
})
export class CommonSharedModule { }
