import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NgModel, ValidationErrors, Validator } from '@angular/forms';
import { QuestionFields } from '@zc/common/core/models/questionnaire/question-fields';
import { controlProviderFor, validatorProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

/** Field control fork. Selects required field control for AnyField. */
@Component({
  selector: 'zc-field-control',
  templateUrl: './field-control.component.html',
  styleUrls: ['./field-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    controlProviderFor(FieldControlComponent),
    validatorProviderFor(FieldControlComponent),
  ],
})
export class FieldControlComponent extends SimpleValueAccessor<QuestionFields.AnyField> implements Validator {
  /** Control model. */
  @ViewChild(NgModel)
  public model?: NgModel;

  /** Field type options. */
  public readonly fieldType = QuestionFields.FieldType;

  /** @inheritdoc */
  public validate(): ValidationErrors | null {
    return this.model?.errors ?? null;
  }
}
