import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

/**
 * Tab button component.
 */
@Component({
  selector: 'zc-tab-button',
  templateUrl: './tab-button.component.html',
  styleUrls: ['./tab-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabButtonComponent {

  /** Content ref. */
  @ViewChild('content', { static: true })
  public content!: TemplateRef<unknown>;

  /** Whether the tab button is disabled. */
  @Input()
  public disabled = false;
}
