import { Component, ChangeDetectionStrategy, Input, ElementRef, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/** Component for rendering dynamic HTML. */
@Component({
  selector: 'zc-sanitized-html',
  template: '',
  styleUrls: ['./sanitized-html.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SanitizedHtmlComponent {
  /** String-serialized html content to serialize. */
  @Input()
  public set content(c: string | null) {
    if (c) {
      this.elementRef.nativeElement.innerHTML = this.applySanitizers(c);
    }
  }

  public constructor(
    private readonly domSanitizer: DomSanitizer,
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {}

  private applySanitizers(content: string): string {

    return this.domSanitizer.sanitize(SecurityContext.HTML, content) ?? '';
  }
}
