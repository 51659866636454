import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ContentType } from '../enums/content-type';
import { AppError } from '../models/app-error';
import { assertNonNull } from '../utils/assert-non-null';

import { AppConfigService } from './app-config.service';
import { FileService } from './file-service';
import { FileUploadService } from './file-upload';

/**
 * Dto of presigned url.
 */
interface S3PresignedUrlDto {

  /** Url. */
  readonly url: string;

  /** Fields. */
  readonly fields: {

    // No need to know the internal s3 data, we are supposed to just copy it to query params.
    readonly [key: string]: number | string;
  };
}

/**
 * Data required to obtain a presigned URL for uploading a file to a bucket.
 */
interface S3PresignedUrlRequestDto {

  /**
   * Destination of the media file.
   * There are all available templates: user/<id>/, project/<id>/, project/<id>/document/<id>/
   * For new file uploading one of the templates should be picked.
   */
  readonly destination: string;

  /**
   * Content type of the uploaded file.
   */
  readonly content_type: ContentType;

  /**
   * Media extension.
   * Extensions should be 5 or less characters.
   * Form of extensions: pdf, png, jpeg etc.
   */
  readonly extension: string;

  /**
   * The actual name of a file.
   */
  readonly name: string;
}

/** Service for uploading files to S3 storage. */
@Injectable({ providedIn: 'root' })
export class S3UploadService implements FileUploadService {
  private readonly presignUrl: URL;

  public constructor(
    appConfigService: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly fileService: FileService,
  ) {
    this.presignUrl = new URL('media/s3-presigned-url/', appConfigService.apiUrl);
  }

  /** @inheritdoc */
  public upload(file: File, destination: string): Observable<string> {
    const body: S3PresignedUrlRequestDto = {
      destination,
      content_type: this.getContentTypeFrom(file),
      extension: this.fileService.extractExtension(file),
      name: this.fileService.extractName(file),
    };

    return this.httpClient.post<S3PresignedUrlDto>(this.presignUrl.toString(), body).pipe(
      switchMap(presignedUrl => this.uploadFileToPresignedUrl(file, presignedUrl)),
    );
  }

  private uploadFileToPresignedUrl(file: File, presignedUrl: S3PresignedUrlDto): Observable<string> {
    // Set response type 'text' because s3 returns XML.
    // Using Object type to avoid errors connected with responseType
    const options: Object = { observe: 'body', responseType: 'text' };

    return this.httpClient.post<string>(
      presignedUrl.url,
      this.createFormDataFromPresignedUrl(presignedUrl, file),
      options,
    ).pipe(
      map(this.parseLocationFromXml),

      // S3 returns encoded URL, decode it as the client expects it
      map(decodeURIComponent),
    );
  }

  private createFormDataFromPresignedUrl(presignedUrl: S3PresignedUrlDto, file: File): FormData {
    const formData = new FormData();

    Object.keys(presignedUrl.fields).forEach(field => {
      formData.append(field, presignedUrl.fields[field].toString());
    });

    // !!! Important to add a file as the last field of form data. https://stackoverflow.com/a/15235866
    formData.append('file', file, file.name);

    return formData;
  }

  private parseLocationFromXml(xmlString: string): string {
    const parser = new DOMParser();
    const xmlDocument = parser.parseFromString(xmlString, 'application/xml').documentElement;
    const location = xmlDocument.querySelector('Location')?.textContent;
    assertNonNull(location);

    return location;
  }

  private getContentTypeFrom(file: File): ContentType {
    const contentType = ContentType.fromBlob(file);
    if (contentType === ContentType.Unknown) {
      throw new AppError(`${file.type} file type is not supported yet`);
    }
    return contentType;
  }
}
