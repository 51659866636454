import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteOrigin } from '@zc/common/core/enums/site-origin';
import { AgentInformation } from '@zc/common/core/models/agent-information';
import { AgentLanding } from '@zc/common/core/models/agent-landing';
import { Questionnaire } from '@zc/common/core/models/questionnaire/questionnaire';
import { TeamMember } from '@zc/common/core/models/team-member';
import { AgentService } from '@zc/common/core/services/agent.service';
import { NotificationService } from '@zc/common/core/services/notification.service';
import { QuestionnaireService } from '@zc/common/core/services/questionnaire.service';
import { TeamService } from '@zc/common/core/services/team.service';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { ConfirmDialogComponent } from '@zc/common/shared/modules/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogsService } from '@zc/common/shared/modules/dialogs/dialogs.service';
import { AppThemeService } from '@zc/common/shared/theme/app-theme.service';
import { BehaviorSubject, defer, merge, NEVER, Observable, of, ReplaySubject, shareReplay } from 'rxjs';
import { filter, finalize, ignoreElements, map, switchMap, take, first } from 'rxjs/operators';

import { QuestionnaireStageValidator } from '../questionnaire-stage-control/questionnaire-stage-control.component';
import { ORIGIN_PARAM_KEY } from '../questionnaire.module';

const AGENT_ID_PARAM_KEY = 'agent';
const DEFAULT_SCROLL_SELECTOR = 'body';

/** Questionnaires page. */
@Destroyable()
@Component({
  selector: 'zc-questionnaires-page',
  templateUrl: './questionnaires-page.component.html',
  styleUrls: ['./questionnaires-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionnairesPageComponent implements OnInit {

  /** Current questionnaire. */
  public readonly questionnaire$: Observable<Questionnaire>;

  /** Stage validator fn. */
  public readonly stageValidator$: Observable<QuestionnaireStageValidator>;

  /** Subject emitted whenever questionnaire is finished. */
  public readonly finished$ = new ReplaySubject<boolean>(1);

  /** Subject emitted whenever user verified phone number. */
  public readonly isAuthorized$: Observable<boolean>;

  /** Agent Identifier. */
  public readonly agentId$ = new BehaviorSubject<string>('');

  /** Agent info. */
  public readonly agent$: Observable<TeamMember>;

  /** Information about agent company. */
  public readonly agentCompany$: Observable<AgentInformation.Company>;

  /** Whether user go to questionnaire via marketing site. */
  public readonly isViaMarketingSite$: Observable<boolean>;

  /** Selected template type. */
  public readonly landingConfiguration$: Observable<AgentLanding.Configuration>;

  public constructor(
    teamService: TeamService,
    agentService: AgentService,
    activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly appThemeService: AppThemeService,
    private readonly questionnaireService: QuestionnaireService,
    private readonly dialogsService: DialogsService,
    private readonly notificationService: NotificationService,
    @Inject(DOCUMENT) private document: Document,
  ) {

    this.agent$ = activatedRoute.paramMap.pipe(
      map(params => params.get(AGENT_ID_PARAM_KEY) as string),
      switchMap(agentId => {
        this.agentId$.next(agentId);
        return teamService.getTeamMemberById((agentId));
      }),
    );

    this.landingConfiguration$ = this.agent$.pipe(
      switchMap(agent => agentService.getLandingConfigurationByAgentId(agent.id)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.agentCompany$ = activatedRoute.paramMap.pipe(
      map(params => {
        const agentId = params.get(AGENT_ID_PARAM_KEY);
        assertNonNull(agentId);
        return agentId;
      }),
      switchMap(agentId => agentService.getCompanyInformationByAgentId(agentId)),
    );

    this.isViaMarketingSite$ = activatedRoute.paramMap.pipe(
      map(params => params.get(ORIGIN_PARAM_KEY) as string),
      filter(siteOrigin => this.isKnownOrigin(siteOrigin)),
      map(siteOrigin => siteOrigin === SiteOrigin.ViaMarketing),

    );

    this.questionnaire$ = this.isViaMarketingSite$.pipe(
      switchMap(isViaMarketingSite => {
        if (isViaMarketingSite) {
          return this.questionnaireService.getQuestionnaireMarketing();
        }
        return this.questionnaireService.getQuestionnaire();
      }),
    );

    this.stageValidator$ = of(() => this.questionnaireService.validateQuestionnaireStage());
    this.isAuthorized$ = this.questionnaireService.isAuthorized$;
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    const removeSecretSideEffect$ = this.questionnaireService.resetAuthorized().pipe(take(1));
    const setThemeSideEffect$ = this.landingConfiguration$.pipe(
      switchMap(({ theme: agentTheme }) => this.appThemeService.theme$.pipe(
        first(),
        switchMap(prevTheme => defer(() => {
          this.appThemeService.setTheme(agentTheme);
          return NEVER.pipe(
            finalize(() => this.appThemeService.setTheme(prevTheme)),
          );
        })),
      )),
    );

    merge(
      removeSecretSideEffect$,
      setThemeSideEffect$,
    ).pipe(
      takeUntilDestroy(this),
      ignoreElements(),
    )
      .subscribe();
  }

  /**
   * Handles reset questionnaire when start.
   */
  public async showResetFormDialog(): Promise<void> {
    const isReset = await this.dialogsService.openDialog(ConfirmDialogComponent, {
      message: 'Do you want to clear the current input and start with a new questionnaire?',
      title: 'Load New Data',
      refuseButtonTitle: 'No, continue last attempt',
      confirmButtonTitle: 'OK',
    });
    if (isReset) {
      this.clearQuestionnaire();
    }
  }

  /**
   * Handle questionnaire submission.
   * @param questionnaire Submitted questionnaire data.
   */
  public onQuestionnaireSubmit(questionnaire: Questionnaire): void {
    this.agent$.pipe(
      take(1),
      map(agent => agent.id),
      switchMap(agentId => this.questionnaireService.finishQuestionnaire(questionnaire, agentId)),
      takeUntilDestroy(this),
    ).subscribe(
      {
        complete: () => {
          this.finished$.next(true);
        },
        error: error => {
          const message = error.error?.detail ?? error.message;
          this.notificationService.notify(message);
        },
      },
    );
  }

  /**
   * Handle questionnaire stage change.
   * @param questionnaire Questionnaire data.
   */
  public onQuestionnaireStageChange(questionnaire: Questionnaire): void {
    assertNonNull(questionnaire.step);

    // Scroll to top
    this.document.querySelector(DEFAULT_SCROLL_SELECTOR)?.scrollTo(0, 0);

    if (questionnaire.step >= 0) {
      this.questionnaireService.saveQuestionnaire(questionnaire).pipe(
        takeUntilDestroy(this),
      )
        .subscribe();
    } else {
      // If user click back on first step, go to home page.
      this.goToHome();
    }
  }

  /**
   * Redirect to Home URL.
   * @param goToFAQ Scroll to FAQ.
   */
  public goToHome(goToFAQ?: boolean): void {
    // Clear questionnaire
    this.clearQuestionnaire();

    // Go to FAQ page after complete submission
    if (goToFAQ) {
      this.router.navigate([`/${this.agentId$.value}`], {
        fragment: 'faq',
      });
    } else {
      this.router.navigateByUrl(`/${this.agentId$.value}`);
    }
  }

  /** Handles clear Questionnaire. */
  private clearQuestionnaire(): void {
    this.questionnaireService.clearQuestionnaire().pipe(
      takeUntilDestroy(this),
    )
      .subscribe({
        complete: () => {
          this.finished$.next(false);
          this.questionnaireService.clearQuestionnaire();
        },
      });
  }

  private isKnownOrigin(siteOrigin: string): siteOrigin is SiteOrigin {
    return siteOrigin === SiteOrigin.ViaMarketing || siteOrigin === SiteOrigin.ViaAgent;
  }
}
