<nav class="navbar">
  <zcw-agent-nav-list class="navbar__nav-list"></zcw-agent-nav-list>
  <button
    class="navbar__menu-button menu-button"
    type="button"
    (click)="toggleSidebar()"
    title="Show Menu"
  >
    <mat-icon class="material-icons-outlined menu-button__icon">
      menu
    </mat-icon>
  </button>
  <a class="logo-link" [routerLink]="['/', agent?.id]">
    <img
      *ngIf="company && company.logo; else skeleton"
      [src]="company.logo"
      class="logo"
      alt=""
    />
  </a>
  <div class="nav-button-wrapper">
    <a
      routerLink="via-marketing/questionnaires"
      class="nav-button"
      type="button"
    >
      Get Your Offer
    </a>
  </div>
  <zcw-sidebar
    [class.sidebar-show]="showSidebar"
    (closeSidebar)="toggleSidebar()"
  ></zcw-sidebar>
  <div class="fade" [hidden]="!showSidebar"></div>
</nav>

<ng-template #skeleton>
  <a
    [routerLink]="['/', agent?.id]"
    class="zincasa-logo"
    [zcSkeletonMinWidth]="10"
    [zcSkeletonMaxWidth]="20"
    zcSkeleton
  ></a>
</ng-template>
