import { ChangeDetectionStrategy, Component } from '@angular/core';

import { AgentBaseTemplate } from '../agent-base-template';

/** Second template for agent home page. */
@Component({
  selector: 'zcw-agent-second-template',
  templateUrl: './agent-second-template.component.html',
  styleUrls: ['./agent-second-template.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentSecondTemplateComponent extends AgentBaseTemplate {

}
