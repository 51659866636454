import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { GoogleLocation } from '../../models/questionnaire/google-location';
import { QuestionFields } from '../../models/questionnaire/question-fields';
import { Questionnaire, QuestionnaireData } from '../../models/questionnaire/questionnaire';
import { toNumber } from '../../utils/to-number';
import { parseQuestionnaireByDefinedField } from '../questionnaire.service';

import { PropertyInfoDto, QuestionnaireDto } from './dto/questionnaire.dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperFromDto, IValidationErrorMapper } from './mappers';
import { PhoneMapper } from './phone.mapper';

/** Mapper for opt generate data. */
@Injectable({ providedIn: 'root' })
export class QuestionnaireMapper implements IValidationErrorMapper<QuestionnaireData, QuestionnaireDto>,
  IMapperFromDto<QuestionnaireDto, QuestionnaireData> {

  public constructor(
    private readonly phoneMapper: PhoneMapper,
  ) { }

  /** @inheritdoc */
  public mappingPropertyInfoToDto(googleLocation: GoogleLocation, questionnaire: Questionnaire): PropertyInfoDto {
    return {
      address1: `${googleLocation.streetNumber} ${googleLocation.route}`,
      address2: googleLocation?.unit,
      city: googleLocation.city,
      state: googleLocation.state,
      zip_code: googleLocation.postalCode,
      country: googleLocation.country,
      above_grade_sqft: toNumber(parseQuestionnaireByDefinedField(questionnaire, QuestionFields.DefinedFieldType.SquareFeetAboveGrade)),
      below_grade_sqft: toNumber(parseQuestionnaireByDefinedField(questionnaire, QuestionFields.DefinedFieldType.SquareFeetBelowGrade)),
      bedroom: toNumber(parseQuestionnaireByDefinedField(questionnaire, QuestionFields.DefinedFieldType.NumberOfBedrooms)),
      bathroom: toNumber(parseQuestionnaireByDefinedField(questionnaire, QuestionFields.DefinedFieldType.NumberOfBathrooms)),
    };
  }

  /** @inheritdoc */
  public fromDto(data: QuestionnaireDto): QuestionnaireData {
    return {
      agent: data.agent,
      sellerName: data.seller_name ?? '',
      sellerEmail: data.seller_email,
      sellerPhone: this.phoneMapper.fromDto(data.seller_phone),
      sellerHomeAddress: (data.content[0].fields[0] as QuestionFields.Specific.OrderedFieldList).value ?? '',

      /** First content element is not needed since it's information related to home address. */
      content: data.content.slice(1),
      note: data.note,
      propertyInfo: '',
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<QuestionnaireDto>): EntityValidationErrors<QuestionnaireData> {
    return {
      agent: extractErrorMessage(errorDto.agent) ?? extractErrorMessage(errorDto.non_field_errors),
      sellerName: extractErrorMessage(errorDto.seller_name) ?? extractErrorMessage(errorDto.non_field_errors),
      sellerEmail: extractErrorMessage(errorDto.seller_email) ?? extractErrorMessage(errorDto.non_field_errors),
      sellerPhone: extractErrorMessage(errorDto.seller_phone) ?? extractErrorMessage(errorDto.non_field_errors),
      content: extractErrorMessage(errorDto.content) ?? extractErrorMessage(errorDto.non_field_errors),
      note: extractErrorMessage(errorDto.note) ?? extractErrorMessage(errorDto.non_field_errors),
      propertyInfo: extractErrorMessage(errorDto.property_info) ?? extractErrorMessage(errorDto.non_field_errors),
    };
  }

}
