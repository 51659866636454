import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Address } from '@zc/common/core/models/address';
import { AgentInformation } from '@zc/common/core/models/agent-information';
import { TeamMember } from '@zc/common/core/models/team-member';

/** Agent card representing short information about an agent. */
@Component({
  selector: 'zcw-agent-card',
  templateUrl: './agent-card.component.html',
  styleUrls: ['./agent-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentCardComponent {
  /** Agent to display. */
  @Input()
  public agent: TeamMember | null = null;

  /** Agent company. */
  @Input()
  public company: AgentInformation.Company | null = null;

  /** Whether the agent card should be linear-looking. */
  @Input()
  public flat = false;

  /** Map license to readable value. */
  public readonly licenseToReadable = TeamMember.toReadableLicense;

  /** Prepare address for company. */
  public prepareCompanyAddress = Address.prepareCompanyAddress;
}
