import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { getImageSource } from '@zc/common/core/utils/image-src';

const UNKNOWN_USER_IMAGE_URL = '/assets/img/unknown-user.jpg';

/** User avatar component. */
@Component({
  selector: 'zc-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
  /** User. */
  @Input()
  public src: File | string | null | undefined = null;

  /** Whether the avatar should be round or not. */
  @Input()
  @HostBinding('class.zc-avatar-round')
  public round = true;

  public constructor(
    private readonly domSanitizer: DomSanitizer,
  ) {}

  /**
   * Returns avatar url.
   * @param imageElement Image element.
   * @returns Safe avatar url.
   */
  public getAvatarUrl(imageElement: HTMLImageElement): string | SafeUrl {
    if (this.src == null || this.src === '') {
      return UNKNOWN_USER_IMAGE_URL;
    }

    if (typeof this.src === 'string') {
      return this.src;
    }

    const imageSource = getImageSource(this.src);
    imageElement.onload = imageSource.teardown;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(imageSource.unsanitizedUri);
  }
}
