import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ProjectActivity } from '@zc/common/core/models/project-activity';
import { toggleExecutionState } from '@zc/common/core/rxjs/toggle-execution-state';
import { ActivitiesService } from '@zc/common/core/services/activities.service';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { BehaviorSubject, first, switchMap, tap } from 'rxjs';

/** Activity list item. */
@Destroyable()
@Component({
  selector: 'zc-activity-list-item',
  templateUrl: './activity-list-item.component.html',
  styleUrls: ['./activity-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityListItemComponent {
  /** Setter for project activity. */
  @Input()
  public set activity(value: ProjectActivity | null) {
    if (value) {
      this.activity$.next(value);
      this.isPinned$.next(value.isPinned);
    }
  }

  /** Project activity. */
  public readonly activity$ = new BehaviorSubject<ProjectActivity | null>(null);

  /** Is activity pinned. */
  public readonly isPinned$ = new BehaviorSubject(false);

  /** Whether state of pin is changing or not. */
  public readonly isPinStateChanging$ = new BehaviorSubject(false);

  /** Id of activity. */
  public get activityId(): number | null {
    return this.activity$.getValue()?.id ?? null;
  }

  public constructor(
    private readonly activitiesService: ActivitiesService,
  ) { }

  /** Handles click on activity pin. */
  public onActivityPinClick(): void {
    const activityPinUpdate$ = this.isPinned$.pipe(
      first(),
      switchMap(isPinned => {
          assertNonNull(this.activityId);

          if (isPinned === true) {
            return this.activitiesService.unpinActivity(this.activityId);
          }

          return this.activitiesService.pinActivity(this.activityId);
        }),
      toggleExecutionState(this.isPinStateChanging$),
    );

    const toggleIsPinnedSideEffect$ = activityPinUpdate$.pipe(
      tap(() => this.isPinned$.next(!this.isPinned$.getValue())),
    );

    toggleIsPinnedSideEffect$.pipe(
      takeUntilDestroy(this),
    )
      .subscribe();
  }
}
