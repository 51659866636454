<ul [ngClass]="getDistributionClass()" role="tablist" class="tab-list">
  <li class="tab-list__item" *ngFor="let tab of tabs.toArray(); let i = index">
    <button
      class="tab-list__button"
      type="button"
      [attr.id]="getTabNumber(i)"
      [attr.aria-controls]="getPanelNumber(i)"
      [attr.aria-selected]="tab.isSelected$ | async"
      role="tab"
      (click)="tab.select()"
      [class.active]="tab.isSelected$ | async"
      [disabled]="tab.button.disabled"
    >
      <ng-container [ngTemplateOutlet]="tab.button.content"></ng-container>
    </button>
  </li>
</ul>
<div
  *ngFor="let tab of tabs; let i = index"
  [attr.id]="getPanelNumber(i)"
  [attr.aria-labelledby]="getTabNumber(i)"
  [attr.hidden]="(tab.isSelected$ | async) === false || null"
  class="tab-container"
  role="tabpanel"
>
  <ng-container
    *ngIf="tab.content?.content as content"
    [ngTemplateOutlet]="content"
  ></ng-container>
</div>
