<section class="activity">
  <ng-container *ngIf="activity$ | async as activity; else skeleton">
    <zc-avatar
      class="activity__user-avatar"
      [round]="true"
      [src]="activity.user.avatarUrl ?? null"
    ></zc-avatar>
    <div class="activity__announce">
      <h3 class="activity__title">
        <zc-username [user]="activity.user"></zc-username>
        <span class="activity__action">{{ activity.title }}</span>
      </h3>
      <span class="divider"> | </span>
      <time class="activity__time" [dateTime]="activity.created">
        {{ activity.created | zcTime }}
      </time>
      <span class="divider"> | </span>
      <button
        class="activity__pin-button"
        *ngLet="isPinned$ | async as isPinned"
        [title]="isPinned === true ? 'Unpin' : 'Pin'"
        (click)="onActivityPinClick()"
        [zcDisabled]="isPinStateChanging$ | async"
        type="button"
      >
        <mat-icon
          class="activity__pin-icon"
          [class.material-icons-outlined]="isPinned !== true"
        >
          push_pin
        </mat-icon>

        {{ isPinned === true ? "Pinned" : "" }}
      </button>
    </div>
    <div class="activity__content">
      <zc-sanitized-html [content]="activity.content"></zc-sanitized-html>
      <div class="activity__attachments">
        <img
          class="activity__image"
          alt=""
          [src]="attachment"
          *ngFor="let attachment of activity.attachments"
        />
      </div>
    </div>
  </ng-container>

  <ng-template #skeleton>
    <zc-avatar [round]="true" class="activity__user-avatar"></zc-avatar>
    <div class="skeleton activity__announce">
      <h3 class="activity__title">
        <zc-username></zc-username>
      </h3>
      <span class="divider"> | </span>
      <time class="activity__time" zcSkeleton></time>
      <span class="divider"> | </span>
      <div class="activity__pin-button">
        <div class="activity__pin-button__icon" zcSkeleton></div>
      </div>
    </div>
    <div class="activity__content">
      <span
        zcSkeleton
        [zcSkeletonMinWidth]="25"
        [zcSkeletonMaxWidth]="30"
      ></span>
    </div>
  </ng-template>
</section>
