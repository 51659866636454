<p class="message">{{ props.message }}</p>
<div class="controls">
  <button
    class="primary controls__confirm"
    type="button"
    (click)="closed.next()"
    autofocus
  >
    {{ props.buttonTitle ?? defaultProps.buttonTitle }}
  </button>
</div>
