import { AbstractControl, AbstractControlTyped, FormArray, FormControl, FormControlTyped, FormGroup, FormGroupTyped } from '@angular/forms';

import { AppError } from '../models/app-error';

/**
 * Type-assertion for form array.
 * @param control Control.
 */
export function assertIsFormArray(control: AbstractControl): asserts control is FormArray {
  if (!(control instanceof FormArray)) {
    throw new AppError('Expected FormArray.');
  }
}

/**
 * Type-assertion for form control.
 * @param control Control.
 */
export function assertIsFormControl(control: AbstractControl): asserts control is FormControl {
  if (!(control instanceof FormControl)) {
    throw new AppError('Expected FormControl');
  }
}

/**
 * Type-assertion for form group.
 * @param control Control.
 */
export function assertIsFormGroup<T>(control: AbstractControlTyped<T>): asserts control is FormGroupTyped<T> {
  if (!(control instanceof FormGroup)) {
    throw new AppError('Expected FormControl');
  }
}

/**
 * Safely casts (with assertion) a control to a form group.
 * @param control Control.
 * @returns Casted control.
 * @throws In case passed `control` is not a form group.
 */
export function toFormGroup<T>(control: AbstractControlTyped<T>): FormGroupTyped<T> {
  if (control instanceof FormGroup) {
    return control as FormGroupTyped<T>;
  }
  throw new AppError('Control is not an instance of `FormGroupTyped`');
}

/**
 * Safely casts an abstract control to a FormControl.
 * @param control Control.
 * @returns Casted control.
 * @throws In case passed `control` is not a FormControl.
 */
export function toFormControl<T>(control: AbstractControlTyped<T>): FormControlTyped<T> {
  if (control instanceof FormControl) {
    return control as FormControlTyped<T>;
  }
  throw new AppError('Control is not an instance of `FormControlTyped`');
}
