import { Injectable } from '@angular/core';

import { Pagination } from '../../models/pagination';
import { PaginationOptions } from '../../models/pagination-options';

import { PaginationDto } from './dto/pagination.dto';
import { PaginationOptionsDto } from './dto/pagination-options.dto';
import { IMapperFromDto } from './mappers';

type MapperFunction<TDto, TDomain> = (dto: TDto) => TDomain;

/** Pagination mapper. */
@Injectable({ providedIn: 'root' })
export class PaginationMapper {
  /**
   * Maps pagination options to dto representation.
   * @param options Pagination options.
   */
  public mapOptionsToDto(options: PaginationOptions): PaginationOptionsDto {
    return {
      limit: options.pageSize,
      offset: (options.page * options.pageSize),
      search: options.searchString,
    };
  }

  /**
   * Map pagination from dto.
   * @param page Dto page.
   * @param options Local requested page.
   * @param mapper Mapper for the items.
   */
  public mapPaginationFromDto<TDto, TDomain>(
    page: PaginationDto<TDto>,
    options: PaginationOptions,
    mapper: IMapperFromDto<TDto, TDomain> | MapperFunction<TDto, TDomain>,
  ): Pagination<TDomain> {
    return new Pagination({
      items: page.results.map(typeof mapper === 'function' ? mapper : mapper.fromDto.bind(mapper)),
      page: options.page,
      totalCount: page.count,
      size: options.pageSize,
    });
  }
}
