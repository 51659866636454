<ol class="selling-steps" [class.inline]="inline">
  <li class="step">
    <div class="step__img">
      <img src="/assets/img/icon-request-an-offer.svg" alt="" />
    </div>
    <p class="step__title">Request Offer</p>
    <p class="step__description">
      <ng-container *ngIf="template === 'first'">
        Request an offer by answering a few questions about your property
      </ng-container>

      <ng-container *ngIf="template === 'second'">
        Simply request an offer and answer a few questions about your property
      </ng-container>
    </p>
  </li>

  <li class="step">
    <div class="step__img">
      <img src="/assets/img/icon-cash-offer.svg" alt="" />
    </div>
    <p class="step__title">Cash Offer</p>
    <p class="step__description">
      We will evaluate your home &amp; present you with a cash offer
    </p>
  </li>
  <li class="step">
    <div class="step__img">
      <img src="/assets/img/icon-inspection.svg" alt="" />
    </div>
    <p class="step__title">Inspection</p>
    <p class="step__description">
      If you accept the offer, we will arrange a home inspection
    </p>
  </li>
  <li class="step">
    <div class="step__img">
      <img src="/assets/img/icon-get-paid.svg" alt="" />
    </div>
    <p class="step__title">Get Paid</p>
    <p class="step__description">
      <ng-container *ngIf="template === 'first'">
        Choose your closing date and get paid
      </ng-container>

      <ng-container *ngIf="template === 'second'">
        Once finalized you chose your closing and get paid
      </ng-container>
    </p>
  </li>
</ol>
