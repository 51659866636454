import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@saritasa/angular-typed-forms';

import { AppModule } from './app/app.module';

if ($ENV.ANGULAR_APP_PRODUCTION.toLowerCase() === 'true') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
