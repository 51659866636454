import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateRange } from '@zc/common/core/models/date-range';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';
import { endOfDay, endOfQuarter, startOfMonth, startOfYear } from 'date-fns';
import { startOfQuarter } from 'date-fns/esm';

export namespace DateRangeSelect {

  /** Available Date range of select. */
  export enum DateRangePeriod {
    ThisMonth = 'thisMonth',
    ThisQuarter = 'thisQuarter',
    YearToDate = 'yearToDate',
  }

  /** Date Range Select option model. */
  export interface Option {

    /** Human-readable representation of option. */
    readonly label: string;

    /** Internal unique value of the option. */
    readonly value: DateRange;
  }

  export const PERIOD_TO_DATE_RANGE_MAP: Readonly<Record<DateRangePeriod, DateRange>> = {
    [DateRangePeriod.ThisMonth]: {
      start: startOfMonth(new Date()),
      end: endOfDay(new Date()),
    },
    [DateRangePeriod.ThisQuarter]: {
      start: startOfQuarter(new Date()),
      end: endOfQuarter(new Date()),
    },
    [DateRangePeriod.YearToDate]: {
      start: startOfYear(new Date()),
      end: endOfDay(new Date()),
    },
  };

  const TO_READABLE_MAP: Readonly<Record<DateRangePeriod, string>> = {
    [DateRangePeriod.ThisMonth]: 'This Month',
    [DateRangePeriod.ThisQuarter]: 'This Quarter',
    [DateRangePeriod.YearToDate]: 'Year to Date',
  };

  export const DEFAULT_OPTIONS: readonly Option[] = [
    {
      label: TO_READABLE_MAP[DateRangePeriod.ThisMonth],
      value: PERIOD_TO_DATE_RANGE_MAP[DateRangePeriod.ThisMonth],
    },
    {
      label: TO_READABLE_MAP[DateRangePeriod.ThisQuarter],
      value: PERIOD_TO_DATE_RANGE_MAP[DateRangePeriod.ThisQuarter],
    },
    {
      label: TO_READABLE_MAP[DateRangePeriod.YearToDate],
      value: PERIOD_TO_DATE_RANGE_MAP[DateRangePeriod.YearToDate],
    },
  ];

  /**
   * Options comparator.
   * @param opt1 Option.
   * @param opt2 Current selected value.
   */
  export function compare(opt1?: Option, opt2?: DateRange): boolean {
    return opt1?.value.start === opt2?.start && opt1?.value.end === opt2?.end;
  }
}

/** Date range selector. */
@Component({
  selector: 'zc-date-range-select',
  templateUrl: './date-range-select.component.html',
  styleUrls: ['./date-range-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(DateRangeSelectComponent)],
})
export class DateRangeSelectComponent extends SimpleValueAccessor<DateRange> {

  /** Datepicker type. */
  @Input()
  public options: readonly DateRangeSelect.Option[] = DateRangeSelect.DEFAULT_OPTIONS;

  /** Option comparator for select. */
  public readonly optionComparator = DateRangeSelect.compare;

  /**
   * Handles selection changing.
   * @param selectedOption Date range option.
   */
  public onValueChanged(selectedOption: DateRangeSelect.Option): void {
    this.controlValue = {
      ...this.controlValue,
      ...selectedOption.value,
    };
  }

  /**
   * Custom TrackByFunction.
   * @param _ Idx.
   * @param option Date range option..
   */
  public trackBy(_: number, option: DateRangeSelect.Option): string {
    return option.value.start.toString() + option.value.end.toString();
  }
}
