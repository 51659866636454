import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { FileValidation } from '@zc/common/core/utils/file-validation';
import { controlProviderFor } from '@zc/common/core/utils/value-accessor';
import { BaseImageUploaderImplementation } from '@zc/common/shared/components/image-uploader/base-image-uploader-implementation';
import { ImageValidation } from '@zc/common/shared/components/image-uploader/image-uploader.component';

/** Avatar-specific validations. */
export namespace AvatarValidations {
  const MAX_WIDTH_PX = 170;
  const MAX_HEIGHT_PX = 170;
  const MAX_SIZE_MB = 10;

  const MESSAGE = `Max size exceeded (${MAX_SIZE_MB.toFixed(2)}mb), please select a smaller image.`;

  /** Human-readable validation tip. */
  export const VALIDATION_TIP = `(Optimal size is ${MAX_WIDTH_PX}x${MAX_HEIGHT_PX}px. Max size is ${MAX_SIZE_MB}mb. Format allowed: ${
    ImageValidation.ALLOWED_FILE_EXTENSIONS.map(f => f.toUpperCase()).join(', ')})`;

  export const VALIDATORS: readonly ValidatorFn[] = [
    FileValidation.validateMaxSize(MAX_SIZE_MB, MESSAGE),
    FileValidation.validateFormat(ImageValidation.ALLOWED_FILE_EXTENSIONS),
  ].map(FileValidation.adaptForForms);
}

/** Uploader for avatar. */
@Component({
  selector: 'zw-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(AvatarUploaderComponent)],
})
export class AvatarUploaderComponent extends BaseImageUploaderImplementation {
  /** Button title. */
  @Input()
  public buttonTitle = 'Upload my photo (optional)';

  /** Whether show avatar validation tip. */
  @Input()
  public withValidationTip = true;

  /** Human-readable validation tip. */
  public readonly avatarValidationTip = AvatarValidations.VALIDATION_TIP;
}
