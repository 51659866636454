import { Component, ChangeDetectionStrategy, Input, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

/** Progress line component. */
@Component({
  selector: 'zc-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressComponent {
  /** Max value. */
  @Input()
  public max: number | null = null;

  /** Current value. Supposed to be less than (or equal to) max. */
  @Input()
  public value: number | null = null;

  /** Emits selected value in the progress when it's clicked. */
  @Output()
  public readonly selected = new EventEmitter<number>();

  /** Progress of element when it's hovered. */
  public hoverValue = 0;

  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {}

  /** Handles click event. */
  @HostListener('click')
  public onClick(): void {
    this.selected.emit(this.hoverValue ?? 0);
  }

  /**
   * Handles mouse move event.
   * @param event Mouse event.
   */
  @HostListener('mousemove', ['$event'])
  public onMouseMove(event: MouseEvent): void {
    if (this.max == null) {
      return;
    }

    const { nativeElement } = this.elementRef;
    const max = nativeElement.clientWidth;
    this.hoverValue = (event.offsetX / max) * this.max;
  }

  /** Handles mouse leave event. */
  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.hoverValue = 0;
  }

  /**
   * Transforms given value to percent of `max`.
   * @param val Value.
   * @param max Max value.
   * @param threshold Initial threshold of percents.
   */
  public toPercent(val: number, max: number, threshold = 0): number | null {

    return (val / max) * 100 + threshold;
  }
}
