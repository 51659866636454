import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { first, map, mapTo, repeatWhen, shareReplay, startWith, switchMap, switchMapTo, tap } from 'rxjs/operators';

import { AgentInformation } from '../models/agent-information';

import { OperationTerritory } from '../models/operation-territory';
import { Pagination } from '../models/pagination';
import { PaginationOptions } from '../models/pagination-options';
import { Profession } from '../models/profession';
import { Project } from '../models/project';
import { SellerEditData, TeamMember, TeamMemberCreationData, TeamMemberEditData } from '../models/team-member';
import { User } from '../models/user';
import { UserRoleGroup } from '../models/user-role-group';
import { filterNull } from '../rxjs/filter-null';
import { mapAssert } from '../rxjs/map-assert';
import { assertNonNull } from '../utils/assert-non-null';
import { NullableProperties } from '../utils/types/nullable-properties';
import { WithFileProperties } from '../utils/types/with-file-properties';

import { AppConfigService } from './app-config.service';
import { AvatarUploadService } from './avatar-upload.service';
import { CompanyLogoUploadService } from './company-logo-upload.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { OperationTerritoryDto } from './mappers/dto/operation-territory.dto';
import { PaginationDto } from './mappers/dto/pagination.dto';
import { TeamMemberCreationDto, TeamMemberDto } from './mappers/dto/team-member.dto';
import { UserRoleDto, UserRoleGroupDto } from './mappers/dto/user-role.dto';
import { UserDto } from './mappers/dto/user.dto';
import { PaginationMapper } from './mappers/pagination.mapper';
import { TeamMemberMapper } from './mappers/team-member.mapper';
import { UserRoleMapper } from './mappers/user-role.mapper';
import { UserService } from './user.service';

/** Profession pagination options. */
export interface ProfessionPaginationOptions extends PaginationOptions {

  /** User role. */
  readonly roleGroup?: readonly UserRoleGroup[];
}

/** Team pagination options. */
export interface TeamPaginationOptions extends PaginationOptions {

  /** User role. */
  readonly role?: UserRoleGroup;

  /** Filter type. */
  readonly profession?: readonly Profession[];

  /** Territory of operation. */
  readonly territory?: readonly OperationTerritory[];

  /** Id of a project. */
  readonly project?: Project['id'];
}

/** Territories pagination options. */
export interface TerritoriesPaginationOptions extends PaginationOptions {

  /** Whether filtered territories is showed for sign up and agent settings page. */
  readonly isAllowForSignup?: boolean;
}

/** Alias to member edit data containing the file-typed avatar property. */
export type MemberEditDataWithFileAvatar = NullableProperties<
  WithFileProperties<Omit<TeamMemberEditData, 'avatarUrl'>, 'avatar'>,
  'avatar' |
  'phone'
>;

export type MemberProfileData = Pick<
  MemberEditDataWithFileAvatar,
  'firstName' |
  'lastName' |
  'title' |
  'phone' |
  'avatar' |
  'license' |
  'gender' |
  'profession'
>;

/** Service allowing to manage back-office team members. */
@Injectable({ providedIn: 'root' })
export class TeamService {
  private readonly teamUrl: URL;

  private readonly rolesUrl: URL;

  private readonly territoriesUrl: URL;

  private readonly contractorUrl: URL;

  private readonly dataAnalystUrl: URL;

  private readonly projectManagerUrl: URL;

  private readonly agentUrl: URL;

  /** Emitted when current team member was updated. */
  private readonly currentTeamMemberUpdated$ = new Subject<void>();

  /** Current team member data. */
  public readonly currentTeamMember$: Observable<TeamMember>;

  /** Whether the current user can edit a team member. If not, they can only edit contractors. */
  public readonly canCurrentUserEditInternalTeam$: Observable<boolean>;

  public constructor(
    appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly teamMemberMapper: TeamMemberMapper,
    private readonly paginationMapper: PaginationMapper,
    private readonly userRoleMapper: UserRoleMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly avatarUploadService: AvatarUploadService,
    private readonly companyLogoUploadService: CompanyLogoUploadService,
    private readonly userService: UserService,
  ) {
    // Even though user the URL is called `/users`, it returns team member objects
    this.teamUrl = new URL('users/', appConfig.apiUrl);
    this.rolesUrl = new URL('users/roles/', appConfig.apiUrl);
    this.territoriesUrl = new URL('users/territories/', appConfig.apiUrl);
    this.contractorUrl = new URL('users/contractor/', appConfig.apiUrl);
    this.dataAnalystUrl = new URL('users/data_analyst/', appConfig.apiUrl);
    this.projectManagerUrl = new URL('users/project_manager/', appConfig.apiUrl);
    this.agentUrl = new URL('users/agent/', appConfig.apiUrl);
    this.currentTeamMember$ = this.userService.currentUser$.pipe(
      filterNull(),
      switchMap(currentUser => this.getTeamMemberById(currentUser.id).pipe(
        repeatWhen(() => this.currentTeamMemberUpdated$),
      )),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.canCurrentUserEditInternalTeam$ = this.userService.currentUser$.pipe(
      mapAssert(assertNonNull),
      switchMap(user => this.canUserEditTeamMember(user)),
      startWith(false),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /**
   * Returns page of roles.
   * @param options Pagination options.
   */
  public getProfessions(
    options: ProfessionPaginationOptions,
  ): Observable<Pagination<Profession>> {
    let params = new HttpParams({
      fromObject: {
        ...this.paginationMapper.mapOptionsToDto(options),
      },
    });

    if (options.roleGroup != null) {
      params = params.set('group__in', options.roleGroup.map(role => this.userRoleMapper.groupToDto(role)).join(','));
    }

    return this.httpClient
      .get<PaginationDto<UserRoleDto>>(this.rolesUrl.toString(), { params })
      .pipe(
        map(page =>
          this.paginationMapper.mapPaginationFromDto(
            page,
            options,
            this.userRoleMapper,
          )),
      );
  }

  /**
   * Create a new team member.
   * @param teamMemberData Data required to create a new team member.
   */
  public addTeamMember(
    teamMemberData: TeamMemberCreationData,
  ): Observable<void> {
    return this.httpClient
      .post<TeamMemberCreationDto>(
      this.teamUrl.toString(),
      this.teamMemberMapper.creationDataToDto(teamMemberData),
    )
      .pipe(
        mapTo(void 0),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.teamMemberMapper,
        ),
      );
  }

  /**
   * Edit a team member.
   * @param teamMemberData Data required to edit a team member.
   */
  public editTeamMember(teamMemberData: MemberEditDataWithFileAvatar): Observable<void> {

    const isCurrentTeamMemberBeingUpdated$ = this.currentTeamMember$.pipe(
      first(),
      map(({ id }) => teamMemberData.id === id),
    );

    const canEditAgent$ = this.currentTeamMember$.pipe(
      first(),
      map(currentTeamMember =>
        UserRoleGroup.isManagerRoleGroup(currentTeamMember.roleGroup) &&
        teamMemberData.id !== currentTeamMember.id &&
        teamMemberData.roleGroup === UserRoleGroup.Agent),
    );
    return combineLatest([
      this.prepareAvatarUrl(teamMemberData.avatar, teamMemberData.id),
      this.prepareCompanyLogoUrl(teamMemberData.agentProfile ? teamMemberData.agentProfile.logo : null, teamMemberData.id),
      canEditAgent$,
    ])
      .pipe(
        switchMap(([preparedAvatarUrl, preparedLogoUrl, canEditAgent]) => this.httpClient.patch<TeamMemberCreationDto>(
          canEditAgent ? this.getAgentUrl(teamMemberData.id).toString() : this.getTeamMemberUrl(teamMemberData.id).toString(),
          this.teamMemberMapper.editDataToDto({
            ...teamMemberData,
            avatar: preparedAvatarUrl,
            agentProfile: this.prepareAgentProfile(teamMemberData, preparedLogoUrl),
          }),
        )),
        switchMapTo(isCurrentTeamMemberBeingUpdated$),
        tap(isCurrentTeamMemberBeingUpdated => isCurrentTeamMemberBeingUpdated && this.currentTeamMemberUpdated$.next()),
        mapTo(void 0),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.teamMemberMapper),
      );
  }

  /**
   * Edit seller data.
   * @param userData Seller's data required to edit.
   */
  public editSellerData(userData: SellerEditData): Observable<void> {
    return this.httpClient.put<void>(
      this.getTeamMemberUrl(userData.id).toString(),
      this.teamMemberMapper.editSellerDataToDto(userData),
    ).pipe(
      mapTo(void 0),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.teamMemberMapper),
    );
  }

  /**
   * Deletes team member.
   * @param teamMember User to delete.
   */
  public removeTeamMember(teamMember: TeamMember): Observable<void> {
    return this.httpClient.delete<void>(
      this.getTeamMemberUrl(teamMember.id).toString(),
    );
  }

  /**
   * Returns page of territories.
   * @param options Pagination options.
   */
  public getOperationTerritories(
    options: TerritoriesPaginationOptions,
  ): Observable<Pagination<OperationTerritory>> {
    let params = new HttpParams({
      fromObject: {
        ...this.paginationMapper.mapOptionsToDto(options),
      },
    });

    if (options.isAllowForSignup) {
      params = params.set('is_allow_for_signup', options.isAllowForSignup);
    }

    return this.httpClient
      .get<PaginationDto<OperationTerritoryDto>>(
      this.territoriesUrl.toString(),
      { params },
    )
      .pipe(
        map(page =>
          this.paginationMapper.mapPaginationFromDto(
            page,
            options,
            this.userRoleMapper,
          )),
      );
  }

  /**
   * Get page of team members.
   * @param options Pagination options.
   */
  public getTeamMembers(
    options: TeamPaginationOptions,
  ): Observable<Pagination<TeamMember>> {
    let params = new HttpParams({
      fromObject: {
        ...this.paginationMapper.mapOptionsToDto(options),
      },
    });

    let url: URL;

    switch (options.role) {
      case UserRoleGroup.DataAnalyst:
        url = this.dataAnalystUrl;
        break;
      case UserRoleGroup.ProjectManager:
        url = this.projectManagerUrl;
        break;
      case UserRoleGroup.Agent:
        url = this.agentUrl;
        break;
      case UserRoleGroup.Contractor:
        url = this.contractorUrl;
        break;
      case UserRoleGroup.TeamMember:
        url = this.teamUrl;
        params = params.set('group__in', UserRoleGroupDto.Team);
        break;
      case UserRoleGroup.Admin:
        url = this.teamUrl;
        params = params.set('group__in', UserRoleGroupDto.Admins);
        break;
      default:
        url = this.teamUrl;
        break;
    }

    if (options.project) {
      params = params.set('project', options.project);
    }

    if (options.profession && options.profession.length > 0) {
      params = params.set(
        'role__in',
        options.profession.map(({ id }) => id).toString(),
      );
    }

    if (options.territory && options.territory.length > 0) {
      params = params.set(
        'territory__in',
        options.territory.map(({ id }) => id).toString(),
      );
    }

    return this.httpClient
      .get<PaginationDto<UserDto>>(url.toString(), { params })
      .pipe(
        map(page =>
          this.paginationMapper.mapPaginationFromDto(
            page,
            options,
            this.teamMemberMapper,
          )),
        map(page => new Pagination({
          ...page,
          items: page.items.map(teamMember => ({
            ...teamMember,
            territories: this.setTeamMemberTerritories(teamMember),
          })),
        })),
      );
  }

  /**
   * Obtains an agent by provided id.
   * @param id Id of the agent.
   */
  public getTeamMemberById(id: TeamMember['id']): Observable<TeamMember> {
    const url = new URL(`${id}/`, this.teamUrl);

    return this.httpClient.get<TeamMemberDto>(url.toString()).pipe(
      map(dto => this.teamMemberMapper.fromDto(dto)),
    );
  }

  private canUserEditTeamMember(user: User): Observable<boolean> {
    return of(user.roleGroup !== UserRoleGroup.Agent);
  }

  /**
   * Get team member by id url.
   * @param userId Team member id.
   */
  public getTeamMemberUrl(userId: TeamMember['id']): URL {
    return new URL(`${userId}/`, this.teamUrl);
  }

  private getAgentUrl(userId: TeamMember['id']): URL {
    return new URL(`${userId}`, this.agentUrl);
  }

  private prepareAvatarUrl(avatar: string | File | null, teamMemberId: TeamMember['id']): Observable<string | null> {
    // If string, avatar is already uploaded and ready.
    if (avatar == null || typeof avatar === 'string') {
      return of(avatar);
    }

    return this.avatarUploadService.uploadAvatar(avatar, teamMemberId);
  }

  private prepareCompanyLogoUrl(logo: string | File | null, teamMemberId: TeamMember['id']): Observable<string | null> {
    // If string, logo is already uploaded and ready.
    if (logo == null || typeof logo === 'string') {
      return of(logo);
    }

    return this.companyLogoUploadService.uploadLogo(logo, teamMemberId);
  }

  /** Handle reset current team member.  */
  public resetCurrentTeamMember(): void {
    this.currentTeamMemberUpdated$.next();
  }

  private setTeamMemberTerritories(teamMember: TeamMember): OperationTerritory[] {

    // Agent should only have 1 territory.
    if (teamMember.roleGroup === UserRoleGroup.Agent) {
      const firstTerritory = teamMember.territories[0];
      return [firstTerritory];
    }
    return teamMember.territories.slice();
  }

  private prepareAgentProfile(
    teamMember: MemberEditDataWithFileAvatar,
    preparedLogoUrl: string | null,
  ): AgentInformation.Company | null {
    return teamMember.agentProfile ? {
      ...teamMember.agentProfile,
      logo: preparedLogoUrl,
    } : null;
  }
}
