<div class="autocomplete">
  <input
    type="text"
    class="zc-select autocomplete__input"
    [ngClass]="{ 'zc-select__clearable': isClearable }"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [ngModel]="controlValue"
    (ngModelChange)="onChange($event)"
    [matAutocomplete]="auto"
    [autocomplete]="autocomplete"
    #autocompleteInput
  />
  <button
    class="autocomplete__button-clear button"
    *ngIf="isClearable && controlValue"
    [disabled]="disabled"
    (click)="onClear()"
    type="button"
  >
    <img
      class="button__icon"
      src="assets/person-remove-dark.svg"
      alt="Person remove icon"
    />
  </button>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="toReadable$ | async"
    [disableRipple]="true"
  >
    <mat-option (click)="onOptionClick()" *ngFor="let item of data$ | async" [value]="item">
      <ng-container *ngIf="toReadable$ | async as toReadable">
        {{ toReadable(item) }}
      </ng-container>
    </mat-option>

    <ng-container *ngIf="(data$ | async)?.length === 0">
      <mat-option class="autocomplete__no-items-option placeholder" disabled>
        <span class="autocomplete__no-items-message"> No items found </span>
      </mat-option>
    </ng-container>

    <ng-container *ngIf="(data$ | async) === null">
      <mat-option disabled *ngFor="let i of [1, 2, 3, 4]">
        <span
          class="autocomplete__text autocomplete__text_skeleton"
          zcSkeleton
          [zcSkeletonMinWidth]="10"
          [zcSkeletonMaxWidth]="20"
        ></span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</div>
