import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, Output,
  ViewChild,
} from '@angular/core';
import { FileFormat } from '@zc/common/core/enums/file-format';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { FileValidation } from '@zc/common/core/utils/file-validation';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';
import { AlertDialogComponent } from '@zc/common/shared/modules/dialogs/alert-dialog/alert-dialog.component';
import { DialogsService } from '@zc/common/shared/modules/dialogs/dialogs.service';

export namespace ImageValidation {
  export const ALLOWED_FILE_EXTENSIONS: readonly FileFormat[] = [
    FileFormat.GIF,
    FileFormat.JPEG,
    FileFormat.JPG,
    FileFormat.PNG,
    FileFormat.SVG,
    FileFormat.WEBP,
    FileFormat.MP4,
    FileFormat.MOV,
  ];
}

/** Component for uploading a new avatar. */
@Component({
  selector: 'zc-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(ImageUploaderComponent)],
})
export class ImageUploaderComponent extends SimpleValueAccessor<File | string> {

  /** File input. */
  @ViewChild('fileInput')
  public fileInput?: ElementRef<HTMLInputElement>;

  /** Emits whenever a file is selected. */
  @Output()
  public readonly fileChange = new EventEmitter<File | null>();

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    private readonly dialogService: DialogsService,
  ) {
    super(changeDetectorRef);
  }

  /** Handles file selection. */
  public _onChange(): void {
    assertNonNull(this.fileInput);
    const { nativeElement } = this.fileInput;

    const file = nativeElement.files ? nativeElement.files[0] : null;

    if (file == null) {
      this.controlValue = null;
      return;
    }

    const isFileFormatValid = FileValidation.validateFormat(ImageValidation.ALLOWED_FILE_EXTENSIONS)(file) == null;
    if (isFileFormatValid) {
      this.controlValue = file;
      this.fileChange.next(file);
      nativeElement.value = '';
    } else {
      this.dialogService.openDialog(AlertDialogComponent, {
        buttonTitle: 'OK',
        message: 'We couldn\'t upload the file, format is not acceptable',
        title: 'Invalid file format',
      });
    }
  }

  /** Call dialog for selecting a file. */
  public selectFile(): void {
    assertNonNull(this.fileInput);
    this.fileInput.nativeElement.removeAttribute('disabled');
    this.fileInput.nativeElement.click();
    this.fileInput.nativeElement.setAttribute('disabled', 'true');
  }

  /** Removes the file. */
  public removeFile(): void {
    assertNonNull(this.fileInput);
    this.fileInput.nativeElement.files = null;
    this.controlValue = null;
    this.fileChange.emit(null);
  }
}
