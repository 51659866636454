import { Injectable } from '@angular/core';
import { ColorTheme } from '@zc/common/shared/theme/color-theme';

import { AgentLanding } from '../../models/agent-landing';
import { AppError, EntityValidationErrors } from '../../models/app-error';
import { enumToArray } from '../../utils/enum-to-array';

import { AgentWebsiteInformationDto } from './dto/agent-profile.dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperWithErrors } from './mappers';

/** Mapper for agent home page template. */
@Injectable({ providedIn: 'root' })
export class AgentLandingConfigurationMapper implements IMapperWithErrors<AgentWebsiteInformationDto, AgentLanding.Configuration> {
  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<AgentWebsiteInformationDto>,
  ): EntityValidationErrors<AgentLanding.Configuration> {
    return {
      templateType: extractErrorMessage(errorDto.template),
      theme: extractErrorMessage(errorDto.color),
    };
  }

  /** @inheritdoc */
  public fromDto({
    template,
    color,
    template_hero_id,
    template_hero_url,
    uploaded_template_hero_url,
  }: AgentWebsiteInformationDto): AgentLanding.Configuration {
    if (template == null || color == null) {
      return AgentLanding.DEFAULT_CONFIGURATION;
    }

    if (!AgentLanding.TemplateType.isAgentHomePageTemplate(template)) {
      throw new AppError(`Unexpected template type - ${template}. ${enumToArray(AgentLanding.TemplateType).join(', ')} expected`);
    }

    if (template_hero_id != null && !AgentLanding.HeroImageType.isHeroImageTemplate(template_hero_id)) {
      throw new AppError(
        `Unexpected hero image type - ${template_hero_id}. ${enumToArray(AgentLanding.HeroImageType).join(', ')} expected`,
      );
    }

    if (!ColorTheme.isColorTheme(color)) {
      throw new AppError(`Unexpected color theme - ${color}.`);
    }

    return {
      templateType: template,
      theme: color,
      heroImageType: template_hero_id,
      customHeroImage: template_hero_url,
      uploadedHeroImage: uploaded_template_hero_url,
    };
  }

  /** @inheritdoc */
  public toDto({
    templateType,
    theme: color,
    heroImageType,
    customHeroImage,
    uploadedHeroImage,
  }: AgentLanding.Configuration): AgentWebsiteInformationDto {
    return {
      template: templateType,
      color,
      template_hero_id: heroImageType,
      template_hero_url: customHeroImage,
      uploaded_template_hero_url: uploadedHeroImage,
    };
  }
}
