import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AutocompleteConfiguration, ToReadableFunction } from '@zc/common/core/models/autocomplete-configuration';
import { filterNull } from '@zc/common/core/rxjs/filter-null';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { paginate } from '@zc/common/core/utils/paginate';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';
import { BehaviorSubject, combineLatest, merge, NEVER, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

export type AutoCompleteValue =
  'off'
  | 'on'
  | 'name'
  | 'email'
  | 'new-password'
  | 'current-password'
  | 'address-line1'
  | 'address-line2';

/**
 * Autocomplete component.
 * @example
 * ```html
 *  <zc-autocomplete
 *    placeholder="Select Agent"
 *    formControlName="referringAgent"
 *    [configuration]="referringAgentAutocompleteConfig"
 *  >
 *  </zc-autocomplete>
 * ```
 * Where `autoCompleteConfig` is
 * ```ts
 * class SomeComponent {
 *  // ...
 *  public readonly referringAgentAutocompleteConfig: AutocompleteConfiguration<User>;
 *  public constructor(...) {
 *   this.professionAutocompleteConfig = {
 *     fetch: options => this.teamService.getProfessions({ options })),
 *     comparator: Profession.compare,
 *     toReadable: Profession.toReadable,
 *   };
 *  }
 * }
 * ```
 */
@Destroyable()
@Component({
  selector: 'zc-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(AutocompleteComponent)],
})
export class AutocompleteComponent<T> extends SimpleValueAccessor<T> implements OnInit {

  /** Autocomplete element. */
  @ViewChild('autocompleteInput', { read: ElementRef })
  public autocompleteElement!: ElementRef;

  /** Placeholder text. */
  @Input()
  public placeholder = '';

  /** Autocomplete configuration. */
  @Input()
  public set configuration(c: AutocompleteConfiguration<T> | null) {
    if (c != null) {
      this.configuration$.next(c);
    }
  }

  /** Whether autocomplete has clear button or not. */
  @Input()
  public isClearable = false;

  /** Whether first item from the items should be picked instantly. */
  @Input()
  public shouldPickFirst = false;

  /** Autocomplete setting. */
  @Input()
  public autocomplete: AutoCompleteValue = 'on';

  /** Search value control. */
  public readonly filterValue$ = new BehaviorSubject<string>('');

  /** Fetched objects. */
  public readonly data$: Observable<readonly T[] | null>;

  /** Function, obtained from configuration, makes T item human-readable. */
  public readonly toReadable$: Observable<ToReadableFunction<T | null>>;

  private readonly configuration$ = new ReplaySubject<AutocompleteConfiguration<T>>(1);

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
    this.data$ = this.configuration$.pipe(
      switchMap(configuration => paginate({
        ...AutocompleteConfiguration.PAGINATION_DEFAULTS,
        searchString: this.filterValue$,
      }, options => {
        assertNonNull(configuration.fetch);

        return configuration.fetch(options);
      })),
      map(page => page?.items.slice() ?? null),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.toReadable$ = this.configuration$.pipe(
      map(({ toReadable }) => (value: T | null) => {
        if (value != null) {
          return toReadable(value);
        }
        return '';
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    const pickFirstSideEffect$ = this.shouldPickFirst ?
      this.data$.pipe(filterNull()).pipe(tap(data => {
        if (this.controlValue != null && this.controlValue instanceof Array) {
          this.controlValue = this.controlValue[0] ?? null;
        } else {
          this.controlValue = data[0] ?? null;
        }
      })) : NEVER;

    const prefillSideEffect$ = this.configuration$.pipe(
      switchMap(({ toReadable }) => combineLatest([this.filterValue$, this.data$.pipe(filterNull())]).pipe(
        map(([searchValue, data]) => data?.find(item => toReadable(item).toLocaleLowerCase() === searchValue.toLocaleLowerCase())),
      )),
      filterNull(),
      tap(valueMatchedByQuery => {
        this.controlValue = valueMatchedByQuery;
      }),
    );

    merge(
      pickFirstSideEffect$,
      prefillSideEffect$,
    ).pipe(
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /**
   * Handles autocomplete change.
   * @param data Autocomplete data.
   */
  public onChange(data: string | T): void {
    if (typeof data === 'string') {
      this.filterValue$.next(data);
      this.emitChange(null);
    } else {
      this.controlValue = data;
    }
  }

  /**
   * Handle clear autocomplete.
   */
  public onClear(): void {
    this.controlValue = null;
    this.emitChange(null);
  }

  /** Handle click on options field. */
  public onOptionClick(): void {
    this.autocompleteElement.nativeElement.blur();
  }
}
