import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TemplatesSharedModule } from '../templates-shared/templates-shared.module';

import { AgentFirstTemplateComponent } from './agent-first-template.component';

/** Module containing first template for agent home page. */
@NgModule({
  declarations: [AgentFirstTemplateComponent],
  imports: [
    CommonModule,
    TemplatesSharedModule,
  ],
  exports: [AgentFirstTemplateComponent],
})
export class AgentFirstTemplateModule { }
