import { NgModule } from '@angular/core';
import { TabsComponent } from '@zc/common/shared/modules/tabs/tabs/tabs.component';
import { CommonModule } from '@angular/common';
import { TabContentComponent } from '@zc/common/shared/modules/tabs/tab-content/tab-content.component';
import { TabComponent } from '@zc/common/shared/modules/tabs/tab/tab.component';
import { TabButtonComponent } from '@zc/common/shared/modules/tabs/tab-button/tab-button.component';

import { RouterModule } from '@angular/router';

import { RoutedTabsComponent } from './routed-tabs/routed-tabs.component';

const EXPORTED_DECLARATIONS = [TabsComponent, TabContentComponent, TabComponent, TabButtonComponent];

/** Tabs module. */
@NgModule({
  declarations: [...EXPORTED_DECLARATIONS, RoutedTabsComponent],
  imports: [CommonModule, RouterModule],
  exports: [...EXPORTED_DECLARATIONS, RoutedTabsComponent],
})
export class TabsModule { }
