import { Decimal } from 'decimal.js';

import { AppError } from '../../models/app-error';

/** Information required to construct money entity. */
export interface MoneyConstructorData {

  /**
   * Amount of units.
   */
  readonly unitsAmount: number;

  /**
   * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
   */
  readonly currency: string;
}

/** Money representation to be able to work with it currency-agnostically. */
export class Money {
  /** Amount of units in decimal representation. */
  private readonly _unitsAmount: Decimal;

  /** Amount of units. */
  public get unitsAmount(): number {
    return this._unitsAmount.toNumber();
  }

  /**
   * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase.
   */
  public readonly currency: string;

  public constructor(
    data: MoneyConstructorData,
  ) {
    this._unitsAmount = new Decimal(data.unitsAmount);
    this.currency = data.currency;
  }

  /**
   * Adds money.
   * @param money Money.
   */
  public add(money: Money): Money {
    this.assertIsSameCurrencyWith(money);

    return this.forkWithUpdatedUnits(this._unitsAmount.plus(money.unitsAmount));
  }

  /**
   * Subtracts provided `money` from the current amount.
   * @param money Money to subtract.
   */
  public subtract(money: Money): Money {
    this.assertIsSameCurrencyWith(money);

    return this.forkWithUpdatedUnits(this._unitsAmount.sub(money.unitsAmount));
  }

  /**
   * Multiplies money,.
   * @param multiplier Multiplier.
   */
  public multiply(multiplier: number): Money {
    return this.forkWithUpdatedUnits(this._unitsAmount.mul(multiplier));
  }

  /**
   * Divides money.
   * @param divider Divider.
   */
  public divide(divider: number): Money {
    return this.forkWithUpdatedUnits(this._unitsAmount.div(divider));
  }

  private forkWithUpdatedUnits(units: Decimal): Money {
    return new Money({
      currency: this.currency,
      unitsAmount: units.toNumber(),
    });
  }

  private assertIsSameCurrencyWith(money: Money): void {
    if (money.currency !== this.currency) {
      throw new AppError('Currency comparation is not implemented.');
    }
  }
}
