<section>
  <header class="header">
    <div class="header__heading">
      <ng-content select="heading"></ng-content>
      <div *ngIf="withTotalCount" class="header__items-count">
        <span
          [zcSkeleton]="pagination"
          [zcSkeletonMinWidth]="2"
          [zcSkeletonMaxWidth]="5"
        >
          {{ pagination?.totalCount }}
        </span>
        Total
      </div>
      <div
        *ngIf="newCount !== undefined"
        class="header__items-count header__items-count_new"
      >
        <span>{{ newCount }}</span>
        New
      </div>
    </div>
    <div class="header__start-slot">
      <ng-content select="start-slot"></ng-content>
    </div>
    <div class="header__end-slot">
      <ng-content select="end-slot"></ng-content>
    </div>
  </header>
  <main class="content">
    <!-- Need wrapper to set up a fallback placeholder for the table content -->
    <div #wrapper>
      <ng-content select="table"> </ng-content>
    </div>

    <ng-container
      *ngIf="pagination === null"
      [ngTemplateOutlet]="skeletonTable"
    ></ng-container>

    <ng-container *ngIf="pagination">
      <p *ngIf="isNoData" class="content__empty-message">No elements found</p>
    </ng-container>
  </main>
  <footer>
    <zc-table-paginator
      (prev)="onPrevPage()"
      (next)="onNextPage()"
      [pagination]="pagination"
    >
    </zc-table-paginator>
  </footer>
</section>

<ng-template #skeletonTable>
  <table class="skeleton">
    <thead>
      <tr>
        <th *ngFor="let i of iterateNumber(placeholderColumnNum)">
          <span
            zcSkeleton
            [zcSkeletonMinWidth]="10"
            [zcSkeletonMaxWidth]="12"
          ></span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let i of iterateNumber(placeholderRowNum)">
        <td *ngFor="let i of iterateNumber(placeholderColumnNum)">
          <span zcSkeleton [zcSkeletonMinWidth]="10" [zcSkeletonMaxWidth]="20">
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
