import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';

import { AuthData } from '../../models/auth-data';

import { AuthDto } from './dto/auth.dto';

import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperToDto } from './mappers';
import { ValidationErrorMapper } from './validation-error-mapper';

/** Login data mapper. */
@Injectable({
  providedIn: 'root',
})
export class LoginDataMapper
implements IMapperToDto<AuthDto.Login, AuthData.Login>,
  ValidationErrorMapper<AuthDto.Login, AuthData.Login> {
  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<AuthDto.Login> | null | undefined,
  ): EntityValidationErrors<AuthData.Login> {
    return {
      email: extractErrorMessage(errorDto?.email),
      password: extractErrorMessage(errorDto?.password) ?? extractErrorMessage(errorDto?.non_field_errors),
    };
  }

  /** @inheritdoc */
  public toDto(data: AuthData.Login): AuthDto.Login {
    return {
      email: data.email,
      password: data.password,
      keep_login: data.shouldKeepLogin,
    };
  }
}
