import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Address } from '@zc/common/core/models/address';
import { AgentInformation } from '@zc/common/core/models/agent-information';
import { TeamMember } from '@zc/common/core/models/team-member';

/** Agent card in footer. */
@Component({
  selector: 'zcw-agent-footer-card',
  templateUrl: './agent-footer-card.component.html',
  styleUrls: ['./agent-footer-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentFooterCardComponent {
  /** Agent to display. */
  @Input()
  public agent: TeamMember | null = null;

  /** Information about company of agent. */
  @Input()
  public company: AgentInformation.Company | null = null;

  /** Map license to readable value. */
  public readonly licenseToReadable = TeamMember.toReadableLicense;

  /** Prepare address for company. */
  public prepareCompanyAddress = Address.prepareCompanyAddress;
}
