import { enumToArray } from '../utils/enum-to-array';

/** Gender. */
export enum Gender {
  Male = 'male',
  Female = 'female',
  DiverseMale = 'diverseMale',
  DiverseFemale = 'diverseFemale',
}

export namespace Gender {
  const TO_READABLE_MAP: Readonly<Record<Gender, string>> = {
    [Gender.Male]: 'Male',
    [Gender.Female]: 'Female',
    [Gender.DiverseMale]: 'Diverse Male',
    [Gender.DiverseFemale]: 'Diverse Female',
  };

  /**
   * Maps gender to human-readable value.
   * @param gender Gender.
   */
  export function toReadable(gender: Gender): string {
    return TO_READABLE_MAP[gender];
  }

  /**
   * Type-guard for gender.
   * @param gender String to type-guard.
   */
  export function is(gender: string): gender is Gender {
    return (enumToArray(Gender) as string[]).includes(gender);
  }
}
