import { SubscriptionDto } from '@zc/common/core/services/mappers/dto/subscription.dto';
import { Subscription } from '@zc/common/core/models/subscription';
import { Injectable } from '@angular/core';

import { SubscriptionProduct } from '../../models/subscription-product';
import { assertNonNullWithReturn } from '../../utils/assert-non-null';

/**
 * Subscription mapper.
 */
@Injectable({
  providedIn: 'root',
})
export class SubscriptionMapper {

  /** @inheritDoc */
  public fromDto(data: SubscriptionDto, products: readonly SubscriptionProduct[]): Subscription | null {
    const { current_subscription: [currentSubscription], next_subscription: nextSubscription } = data;
    if (currentSubscription == null) {
      return null;
    }

    return new Subscription({
      id: currentSubscription.id,
      defaultPaymentMethod: currentSubscription.default_payment_method,
      status: currentSubscription.status,
      product: assertNonNullWithReturn(products.find(product => currentSubscription.product_id === product.id)),
      activeUntil: new Date(currentSubscription.current_period_end),
      willBeAutoRenewed: !currentSubscription.cancel_at_period_end,
      nextSubscriptionProduct: products.find(product => nextSubscription?.product_id === product.id) ?? null,
    });
  }
}
