import { Injectable } from '@angular/core';

import { AgentInformation } from '../../models/agent-information';
import { EntityValidationErrors } from '../../models/app-error';

import { AgentExtraInformationDto } from './dto/agent-profile.dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error.dto';
import { IMapperWithErrors } from './mappers';

/** Mapper for company information. */
@Injectable({
  providedIn: 'root',
})
export class AgentExtraInfoMapper implements IMapperWithErrors<AgentExtraInformationDto, AgentInformation.ExtraInfo> {

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<AgentExtraInformationDto>,
  ): EntityValidationErrors<AgentInformation.ExtraInfo> {
    return {
      isFounding: extractErrorMessage(errorDto.is_founding),
    };
  }

  /** @inheritdoc */
  public fromDto(data: AgentExtraInformationDto): AgentInformation.ExtraInfo {
    return {
      isFounding: data.is_founding,
    };
  }

  /** @inheritdoc */
  public toDto(data: AgentInformation.ExtraInfo): AgentExtraInformationDto {
    return {
      is_founding: data.isFounding,
    };
  }
}
