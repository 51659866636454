import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { QuestionFields } from '@zc/common/core/models/questionnaire/question-fields';
import { QuestionnaireStage } from '@zc/common/core/models/questionnaire/questionnaire-stage';
import { QuestionnaireService } from '@zc/common/core/services/questionnaire.service';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import { assertIsFormArray, assertIsFormControl } from '@zc/common/core/utils/forms';
import { ZCValidators } from '@zc/common/core/utils/validators';
import { controlProviderFor, SimpleValueAccessor, validatorProviderFor } from '@zc/common/core/utils/value-accessor';
import { BehaviorSubject, Observable, of } from 'rxjs';

import AvailableValidation = QuestionFields.AvailableValidation;

export type QuestionnaireStageValidator = (stage: QuestionnaireStage) => Observable<void>;
export const DEFAULT_QUESTIONNAIRE_STAGE_VALIDATOR: QuestionnaireStageValidator = _stage => of(void 0);

/** Questionnaire stage control. */
@Destroyable()
@Component({
  selector: 'zc-questionnaire-stage-control',
  templateUrl: './questionnaire-stage-control.component.html',
  styleUrls: ['./questionnaire-stage-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(QuestionnaireStageControlComponent), validatorProviderFor(QuestionnaireStageControlComponent)],
})
export class QuestionnaireStageControlComponent extends SimpleValueAccessor<QuestionnaireStage> implements OnInit, Validator {
  /** Submit stage event. */
  @Output()
  public readonly submitStage = new EventEmitter<void>();

  /** Prev stage asked. */
  @Output()
  public readonly prevStage = new EventEmitter<void>();

  /** Force submit. */
  @Output()
  public readonly forceSubmit = new EventEmitter<void>();

  /** Stage validator function. */
  @Input()
  public stageValidator?: QuestionnaireStageValidator | null;

  /** Questionnaire stage form. */
  public readonly questionnaireStageForm: FormGroup;

  /** Questionnaire Next Button status. */
  public readonly isNextButtonDisabled$ = new BehaviorSubject<boolean>(true);

  /** Whether the questionnaire is loading. */
  public readonly isLoading$ = new BehaviorSubject<boolean>(false);

  public constructor(
    private readonly formBuilder: FormBuilder,
    protected override readonly changeDetectorRef: ChangeDetectorRef,
    private readonly questionnaireService: QuestionnaireService,
  ) {
    super(changeDetectorRef);
    this.questionnaireStageForm = this.formBuilder.group({
      questions: this.formBuilder.array([]),
    });
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.questionnaireService.isLoading$.pipe(
      takeUntilDestroy(this),
    )
      .subscribe(isLoading => this.isLoading$.next(isLoading));

    this.questionnaireStageForm.valueChanges.pipe(
      takeUntilDestroy(this),
    )
      .subscribe(({ questions }) => {
        assertNonNull(this.controlValue);

        this.controlValue = {
          ...this.controlValue,
          questions,
        };
      });
  }

  /** @inheritdoc */
  public override writeValue(value: QuestionnaireStage): void {
    super.writeValue(value);
    const { questions } = this.questionnaireStageForm.controls;
    assertIsFormArray(questions);

    value.questions.forEach(question => {
      questions.push(
        this.formBuilder.control({
          value: question,
          disabled: false,
        }),
      );
    });
  }

  /** Handles force submit form. */
  public onForceSubmit(): void {
    this.isLoading$.next(true);
    this.forceSubmit.emit();
  }

  /** Handles form submission. */
  public onFormSubmit(): void {
    assertNonNull(this.controlValue);

    (this.stageValidator ?? DEFAULT_QUESTIONNAIRE_STAGE_VALIDATOR)(this.controlValue)
      .pipe(
        takeUntilDestroy(this),
      )
      .subscribe({
        complete: () => {
          this.submitStage.emit();
        },
      });
  }

  /** @inheritdoc */
  public validate(): ValidationErrors | null {
    return this.questionnaireStageForm.invalid ?
      ZCValidators.buildAppError('Step contains invalid data, please check that you filled all the fields correctly') :
      null;
  }

  private validateField(field: QuestionFields.AnyField): boolean {
    if (!field.validations || field.type === QuestionFields.FieldType.ImageUpload) {
      // Bypass Image Upload
      return true;
    }

    const _control = new FormControl();
    _control.setValue(field?.value ?? '', { emitEvent: false });
    const validaroFnArr: ValidatorFn[] = field?.validations.map((item: AvailableValidation) => ZCValidators.getFormFieldValidator(item));
    _control.setValidators(validaroFnArr);
    _control.updateValueAndValidity();
    return _control.valid;
  }

  // TODO: Refactor this validation logic
  // 1. Remove it from specific control components
  // 2. Remove it from stage control (it is not their responsibility)
  // 3. Move logic below to field control component
  /**
   * Check validation of field if existed.
   * @param control : FormControl.
   */
  private validateControl(control: FormControl): void {
    const fieldsValidation: Boolean[] = control.value.fields.map((field: QuestionFields.AnyField) => {
      // Case: selected value have child question
      if (field.type === QuestionFields.FieldType.Checklist && field.value && field.value.length > 0) {
        const parentSelectedValues = field.value as QuestionFields.Specific.Checklist.Option[];
        const listValidationOfChild = parentSelectedValues.map((option: QuestionFields.Specific.Checklist.Option) => {
          const nestedOption = option as QuestionFields.Specific.Checklist.NestedOption;

          // Check NestedOption have child question
          if (nestedOption.child) {
            return this.validateField(nestedOption.child);
          }
          return true;
        });

        // Check child question list is there any field invalid
        return !listValidationOfChild.some(i => i === false);
      }

      // Case: selected value does not have child question
      return this.validateField(field);
    });

    // Check is there any field invalid
    if (fieldsValidation.some(i => i === false)) {
      this.isNextButtonDisabled$.next(true);
    } else {
      this.isNextButtonDisabled$.next(false);
    }
  }

  /** Question controls. */
  public get questionControls(): FormControl[] {
    const { questions } = this.questionnaireStageForm.controls;
    assertIsFormArray(questions);

    return questions.controls.map(control => {
      assertIsFormControl(control);
      this.validateControl(control);
      return control;
    });
  }
}
