<fieldset *ngIf="controlValue" [disabled]="disabled">
  <legend>{{ controlValue.label }}</legend>
  <ng-container *ngFor="let option of controlValue.options">
    <ng-container *ngIf="isNestedOption(option)">
      <ng-container
        [ngTemplateOutlet]="checkbox"
        [ngTemplateOutletContext]="{
          option: option,
          childFieldControl: childFieldControl
        }"
      ></ng-container>
      <!-- Intended cast since type-guarding isn't working on template -->
      <zc-checklist-field-control
        class="children"
        [ngClass]="{ 'is-active': isChecked(option) }"
        #childFieldControl
        [ngModel]="getNestedChild(option).child"
        (ngModelChange)="onChildChange($any(option), $event, childFieldControl)"
      ></zc-checklist-field-control>
    </ng-container>
    <ng-container *ngIf="!isNestedOption(option)">
      <ng-container
        [ngTemplateOutlet]="checkbox"
        [ngTemplateOutletContext]="{ option: option }"
      ></ng-container>
    </ng-container>
  </ng-container>
  <label *ngIf="controlValue.customValueField" class="disable-user-select">
    <input
      type="checkbox"
      [disabled]="true"
      [checked]="controlValue.customValueField.value"
    />
    <span class="checkmark"></span>
    <zc-text-field-control
      [ngModel]="controlValue.customValueField"
      (ngModelChange)="onCustomFieldChange($event)"
    ></zc-text-field-control>
  </label>
</fieldset>

<ng-template
  #checkbox
  let-childFieldControl="childFieldControl"
  let-option="option"
>
  <label class="disable-user-select">
    <input
      type="checkbox"
      [checked]="isChecked(option)"
      (change)="onToggle(option, childFieldControl)"
    />
    <span class="checkmark"></span>
    <span class="text">{{ option.label }}</span>
  </label>
</ng-template>
