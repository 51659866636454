import { Component, ChangeDetectionStrategy, EventEmitter, OnInit } from '@angular/core';

import { IDialog, IDialogOptions } from '../dialog';

/**
 * Alert options.
 */
export interface AlertOptions {

  /**
   * Dialog title.
   */
  readonly title?: string;

  /**
   * Alert message.
   */
  readonly message?: string;

  /**
   * Button title.
   */
  readonly buttonTitle?: string;
}

/**
 * Alert dialog component.
 */
@Component({
  selector: 'zc-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent implements IDialog<AlertOptions, void>, OnInit {
  /** @inheritdoc */
  public options: IDialogOptions = {};

  /** @inheritdoc */
  public readonly closed = new EventEmitter<void>();

  /** @inheritdoc */
  public readonly props!: AlertOptions;

  /** Default dialog property values. */
  public readonly defaultProps: AlertOptions = {
    title: 'Alert',
    buttonTitle: 'Ok',
  };

  /**
   * @inheritdoc
   */
  public ngOnInit(): void {
    this.options = {
      closable: true,
      title: this.props.title ?? this.defaultProps.title,
    };
  }
}
