<zcw-agent-header
  class="header"
  [contentOverlap]="true"
  [company]="company"
  [agent]="agent"
>
  <img [src]="heroImageSrc" class="header__image" alt="" />
  <span ngProjectAs="title" class="header__title">
    Sell Your Home. Your Way.
  </span>
  <span ngProjectAs="description" class="header__description">
    There are more ways to sell your home than ever. <br />
    Discover your options.
  </span>
</zcw-agent-header>
<main class="main">
  <zcw-agent-section id="how-it-works" class="section section__sellings">
    <h2 class="visually-hidden">Selling Steps</h2>
    <zcw-agent-section-title>
      Sell your home instantly without all the requirements a traditional sale
      may require.
    </zcw-agent-section-title>
    <zcw-agent-section-description>
      Get a no-obligation quick cash offer. Close on your schedule and eliminate
      property repairs, open houses, negotiations, and any unknown obstacle that
      may arise with a cash offer.
    </zcw-agent-section-description>
    <zcw-agent-selling-steps></zcw-agent-selling-steps>
  </zcw-agent-section>

  <div class="section">
    <zcw-agent-section>
      <h2 class="visually-hidden">Sale Tips</h2>
      <zcw-agent-section-title class="section__title">
        If our cash offer does not meet your financial goals that’s not a
        problem.
      </zcw-agent-section-title>
      <zcw-agent-section-description>
        I can also present you with a complete marketing plan to sell your home
        using traditional real estate practices.
      </zcw-agent-section-description>
    </zcw-agent-section>
  </div>

  <zcw-agent-video-box [gender]="gender" class="section">
    <ng-container ngProjectAs="title">Easy & transparent process.</ng-container>
    <ng-container ngProjectAs="description">
      Selling your home with an instant, all-cash offer can be a quick and easy
      process. As your real estate agent, I will be available every step of the
      way to guide and advise you throughout the entire process.
    </ng-container>
  </zcw-agent-video-box>
  <zcw-agent-sale-options id="home-selling" class="section">
    <ng-container ngProjectAs="title">
      Take control & sell your home on your terms
    </ng-container>
    <ng-container ngProjectAs="description">
      Compare your options & determine what's best for you
    </ng-container>
  </zcw-agent-sale-options>
  <zcw-agent-faq class="section"></zcw-agent-faq>
</main>

<zcw-agent-footer [agent]="agent" [company]="company"></zcw-agent-footer>
