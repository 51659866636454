import { Directive, ElementRef, HostBinding, Input } from '@angular/core';

import { DisabledDirective } from './disabled.directive';

/**
 * Loading state directive. Applies loading state on element.
 * @example
 * ```html
 * <button [zcLoading]="isLoading$ | async" (click)="isLoading$.next(true)">Submit</button>
 * ```
 */
@Directive({ selector: '[zcLoading]' })
export class LoadingDirective {

  /** Whether to keep element disabled after loading. */
  @Input('zcLoadingIsKeepDisabled')
  public isKeepDisabled: boolean | null = false;

  /** Loading beacon. */
  @Input()
  public set zcLoading(loading: boolean | null) {
    if (loading) {
      this.elementRef.nativeElement.classList.add('zc-loading');
      this.disabledDirective.disable();
    } else {
      this.elementRef.nativeElement.classList.remove('zc-loading');
      if (!this.isKeepDisabled) {
        this.disabledDirective.enable();
      }
    }
  }

  /** Disabled directive. */
  @HostBinding('attr.zcDisabled')
  public disabledDirective = new DisabledDirective(this.elementRef);

  /**
   * @param elementRef Element ref.
   */
  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
  ) { }
}
