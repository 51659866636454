import { Component, ChangeDetectionStrategy, EventEmitter, OnInit } from '@angular/core';

import { IDialog, IDialogOptions } from '../dialog';

/**
 * Confirm options.
 */
export interface ConfirmOptions {

  /**
   * Dialog title.
   */
  readonly title?: string;

  /**
   * Confirmation message.
   */
  readonly message?: string;

  /**
   * Confirm button title.
   */
  readonly confirmButtonTitle?: string;

  /**
   * Refuse button title.
   */
  readonly refuseButtonTitle?: string;

  /**
   * Whether the action is potentially dangerous. Applies styles for extra attention when `true`.
   */
  readonly isConfirmButtonDanger?: boolean;
}

/**
 * Confirm dialog component.
 */
@Component({
  selector: 'zc-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent implements IDialog<ConfirmOptions, boolean>, OnInit {

  /** @inheritdoc */
  public options: IDialogOptions = {};

  /** @inheritdoc */
  public readonly closed = new EventEmitter<boolean>();

  /** @inheritdoc */
  public readonly props!: ConfirmOptions;

  /** Default dialog property values. */
  public readonly defaultProps: ConfirmOptions = {
    title: 'Confirm the action',
    confirmButtonTitle: 'Confirm',
    refuseButtonTitle: 'Cancel',
  };

  /** @inheritdoc */
  public ngOnInit(): void {
    this.options = {
      closable: true,
      title: this.props.title ?? this.defaultProps.title,
    };
  }

  /** Apply styles to button depending on dialog props. */
  public applyButtonClass(): { [key: string]: boolean; } {
    if (this.props.isConfirmButtonDanger) {
      return { danger: true };
    }
    return { primary: true };
  }

}
