import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FileUploadService } from './file-upload';

const STORAGE_DESTINATION = `agent/`;

/** Service for uploading avatars. */
@Injectable({ providedIn: 'root' })
export class RegistrationAssetUploadService {

  public constructor(
    private readonly fileUploadService: FileUploadService,
  ) {}

  /**
   * Uploads a file required for the registration.
   * @param file Asset to upload.
   */
  public upload(file: File): Observable<string> {
    return this.fileUploadService.upload(file, STORAGE_DESTINATION);
  }
}
