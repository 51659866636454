import { Component, ChangeDetectionStrategy } from '@angular/core';

/** Section title for section on agent home page. */
@Component({
  selector: 'zcw-agent-section-title',
  templateUrl: './agent-section-title.component.html',
  styleUrls: ['./agent-section-title.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentSectionTitleComponent {

}
