<ng-container
  *ngIf="landingConfiguration$ | async as configuration; else loading"
  [ngSwitch]="configuration.templateType"
>
  <ng-container *ngSwitchCase="agentHomePageTemplates.First">
    <zcw-agent-first-template
      [agent]="agent$ | async"
      [company]="agentCompany$ | async"
      [configuration]="configuration"
    ></zcw-agent-first-template>
  </ng-container>

  <ng-container *ngSwitchCase="agentHomePageTemplates.Second">
    <zcw-agent-second-template
      [agent]="agent$ | async"
      [company]="agentCompany$ | async"
      [configuration]="configuration"
    ></zcw-agent-second-template>
  </ng-container>

  <ng-container *ngSwitchCase="agentHomePageTemplates.Third">
    <zcw-agent-third-template
      [agent]="agent$ | async"
      [company]="agentCompany$ | async"
      [configuration]="configuration"
    ></zcw-agent-third-template>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="loading" [zcLoading]="true"></div>
</ng-template>
