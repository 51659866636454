import { Directive, Inject, OnDestroy } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { WINDOW } from '@zc/common/core/injection-tokens/window-token';

/**
 * Directive will correctly position all material autocomplete inputs.
 * See https://github.com/angular/components/issues/10079.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[matAutocomplete]',
})
export class AutocompletePositionDirective implements OnDestroy {

  public constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly matAutocompleteTrigger: MatAutocompleteTrigger,
  ) {
    this.window.addEventListener('scroll', this.onScrollEvent, true);
  }

  /** @inheritDoc */
  public ngOnDestroy(): void {
    this.window.removeEventListener('scroll', this.onScrollEvent, true);
  }

  private onScrollEvent = (): void => {
    if (this.matAutocompleteTrigger == null) {
      return;
    }
    if (this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.updatePosition();
    }
  };
}
