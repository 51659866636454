import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuestionnaireModule } from '@zc/common/shared/features/questionnaire/questionnaire.module';
import { CommonSharedModule } from '@zc/common/shared/common-shared.module';
import { DEFAULT_DIALOG_HOST_PROVIDER } from '@zc/common/shared/modules/dialogs/dialogs.service';

import { DialogsModule } from '@zc/common/shared/modules/dialogs/dialogs.module';

import { FileUploadService } from '@zc/common/core/services/file-upload';

import { S3UploadService } from '@zc/common/core/services/s3-upload.service';

import { AuthInterceptor } from '@zc/common/core/interceptors/auth-interceptor';

import { RefreshTokenInterceptor } from '@zc/common/core/interceptors/refresh-token-interceptor';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgentPortalModule } from './features/agent-portal/agent-portal.module';

const httpInterceptorProviders = [
  // The refresh interceptor should be before the auth interceptor, otherwise refreshed bearer would not be updated
  { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

/** Base app module. */
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonSharedModule,
    QuestionnaireModule,
    AgentPortalModule,
    DialogsModule,
    NgxPageScrollCoreModule,

  ],
  providers: [
    ...httpInterceptorProviders,
    DEFAULT_DIALOG_HOST_PROVIDER,
    {
      provide: FileUploadService,
      useClass: S3UploadService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
