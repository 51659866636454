<ng-container *ngIf="controlValue">
  <ul>
    <li>
      <label class="label">
        <div class="label-content">
          <mat-icon fontSet="material-icons-outlined" class="icon">
            photo_camera
          </mat-icon>
          <span>Upload Photo Here</span>
        </div>
        <input
          class="file-input"
          (change)="onImageChange(fileInput)"
          type="file"
          multiple
          #fileInput
        />
      </label>
    </li>
    <li [zcLoading]="container.value === null" *ngFor="let container of controlValue.containers; trackBy: trackFile">
      <lightgallery *ngIf="container.value !== null" [settings]="settings" [onInit]="initializeGallery">
        <a
          *ngIf="canRemoveImage(container)"
          [ngStyle]="getImageBackground(container)"
          [class.with-image]="getImageBackground(container)"
          [href]="container.value"
          class="label"
        >
          <div
            class="label-content"
            [class.label-content_uploaded]="canRemoveImage(container)"
          >
            <button
              type="button"
              class="remove-button"
              (click)="onImageRemove(container)"
              title="Remove image"
            >
              <mat-icon fontSet="material-icons-outlined">
                highlight_off
              </mat-icon>
            </button>
          </div>
        </a>
      </lightgallery>
    </li>
  </ul>
</ng-container>
