import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { AgentInformation } from '@zc/common/core/models/agent-information';
import { TeamMember } from '@zc/common/core/models/team-member';
import { AppConfigService } from '@zc/common/core/services/app-config.service';

/** Represents agent footer. */
@Component({
  selector: 'zcw-agent-footer',
  templateUrl: './agent-footer.component.html',
  styleUrls: ['./agent-footer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentFooterComponent {

  /** Agent. */
  @Input()
  public agent: TeamMember | null = null;

  /** Agent company. */
  @Input()
  public company: AgentInformation.Company | null = null;

  /** Privacy policy url. */
  public readonly privacyPolicyUrl: URL;

  public constructor(appConfigService: AppConfigService) {
    this.privacyPolicyUrl = new URL(appConfigService.privacyPolicyUrl);
  }
}
