import { Injectable } from '@angular/core';

import { IMapper } from './mappers';

/** Phone mapper. */
@Injectable({ providedIn: 'root' })
export class PhoneMapper implements IMapper<string, string> {
  /** @inheritdoc */
  public fromDto(data: string): string {
    if (data && data.length > 10 && data.charAt(0) === '1') {
      return data.substring(1);
    }
    return data;

  }

  /** @inheritdoc */
  public toDto(data: string): string {
    return `1${data}`;
  }
}
