import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { OverlayModule } from '@angular/cdk/overlay';

import { OverlayMenuContentComponent } from './overlay-menu-content/overlay-menu-content.component';
import { OverlayMenuItemComponent } from './overlay-menu-item/overlay-menu-item.component';
import { OverlayMenuComponent } from './overlay-menu.component';

/** Overlay menu module. */
@NgModule({
  declarations: [
    OverlayMenuComponent,
    OverlayMenuContentComponent,
    OverlayMenuItemComponent,
  ],
  exports: [
    OverlayMenuComponent,
    OverlayMenuContentComponent,
    OverlayMenuItemComponent,
  ],
  imports: [
    OverlayModule,
    CommonModule,
    RouterModule,
  ],
})
export class OverlayMenuModule { }
