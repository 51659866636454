import { ColorTheme } from '@zc/common/shared/theme/color-theme';

import { enumToArray } from '../utils/enum-to-array';

export namespace AgentLanding {

  /** Type of custom hero image. */
  export type CustomHeroImage = string | null;

  /** Agent landing configuration. */
  export interface Configuration {

    /** Template type. */
    readonly templateType: AgentLanding.TemplateType;

    /** Hero image type. */
    readonly heroImageType: AgentLanding.HeroImageType | null;

    /** Custom hero image, uploaded by user. */
    readonly customHeroImage: CustomHeroImage;

    /** Theme selected for agent homepage. */
    readonly theme: ColorTheme;

    /** Asset video to preview. */
    readonly video?: string;

    /** Uploaded custom hero image. */
    readonly uploadedHeroImage: CustomHeroImage;
  }

  /** Types of agent home page template. */
  export enum TemplateType {

    // https://projects.invisionapp.com/d/#/console/21313976/452395794/preview
    First = 1,

    // https://projects.invisionapp.com/d/#/console/21313976/458133274/preview
    Second,

    // https://projects.invisionapp.com/d/#/console/21313976/458133275/preview
    Third,
  }

  /**
   * Types of hero images for agent home page template.
   * Starts with 1 to be consistent with hero image names.
   */
  export enum HeroImageType {
    First = 1,
    Second,
    Third,
    Fourth,
    Fifth,
    Sixth,
    Seventh,
    Eighth,
    Ninth,
    Tenth,
    Eleventh,
    Twelfth,
    Thirteenth,
    Fourteenth,
    Fifteenth,
    Sixteenth,
    Seventeenth,
    Eighteenth,
    Nineteenth,
    Twentieth,
    TwentyFirst,
    TwentySecond,
    TwentyThird,
    TwentyFourth,
  }

  export namespace TemplateType {
    const TO_READABLE_MAP: Readonly<Record<TemplateType, string>> = {
      [TemplateType.First]: 'First',
      [TemplateType.Second]: 'Second',
      [TemplateType.Third]: 'Third',
    };

    /**
     * Maps agent home page enum value to a human-readable representation.
     * @param template Template.
     */
    export function toReadable(template: TemplateType): string {
      return TO_READABLE_MAP[template];
    }

    /**
     * Type guard for template type.
     * @param templateType Possible template type.
     */
    export function isAgentHomePageTemplate(templateType: number): templateType is TemplateType {
      return enumToArray(TemplateType).includes(templateType);
    }
  }

  export namespace HeroImageType {
    const TO_READABLE_MAP: Readonly<Record<HeroImageType, string>> = {
      [HeroImageType.First]: 'First',
      [HeroImageType.Second]: 'Second',
      [HeroImageType.Third]: 'Third',
      [HeroImageType.Fourth]: 'Fourth',
      [HeroImageType.Fifth]: 'Fifth',
      [HeroImageType.Sixth]: 'Sixth',
      [HeroImageType.Seventh]: 'Seventh',
      [HeroImageType.Eighth]: 'Eighth',
      [HeroImageType.Ninth]: 'Ninth',
      [HeroImageType.Tenth]: 'Tenth',
      [HeroImageType.Eleventh]: 'Eleventh',
      [HeroImageType.Twelfth]: 'Twelfth',
      [HeroImageType.Thirteenth]: 'Thirteenth',
      [HeroImageType.Fourteenth]: 'Fourteenth',
      [HeroImageType.Fifteenth]: 'Fifteenth',
      [HeroImageType.Sixteenth]: 'Sixteenth',
      [HeroImageType.Seventeenth]: 'Seventeenth',
      [HeroImageType.Eighteenth]: 'Eighteenth',
      [HeroImageType.Nineteenth]: 'Nineteenth',
      [HeroImageType.Twentieth]: 'Twentieth',
      [HeroImageType.TwentyFirst]: 'TwentyFirst',
      [HeroImageType.TwentySecond]: 'TwentySecond',
      [HeroImageType.TwentyThird]: 'TwentyThird',
      [HeroImageType.TwentyFourth]: 'TwentyFourth',
    };

    const TYPE_TO_IMAGE_MAP: Readonly<Record<HeroImageType, string>> = {
      [HeroImageType.First]: 'assets/hero-images/first.jpg',
      [HeroImageType.Second]: 'assets/hero-images/second.jpg',
      [HeroImageType.Third]: 'assets/hero-images/third.jpg',
      [HeroImageType.Fourth]: 'assets/hero-images/fourth.jpg',
      [HeroImageType.Fifth]: 'assets/hero-images/fifth.jpg',
      [HeroImageType.Sixth]: 'assets/hero-images/sixth.jpg',
      [HeroImageType.Seventh]: 'assets/hero-images/seventh.jpg',
      [HeroImageType.Eighth]: 'assets/hero-images/eighth.jpg',
      [HeroImageType.Ninth]: 'assets/hero-images/ninth.jpg',
      [HeroImageType.Tenth]: 'assets/hero-images/tenth.jpg',
      [HeroImageType.Eleventh]: 'assets/hero-images/eleventh.jpg',
      [HeroImageType.Twelfth]: 'assets/hero-images/twelfth.jpg',
      [HeroImageType.Thirteenth]: 'assets/hero-images/thirteenth.jpg',
      [HeroImageType.Fourteenth]: 'assets/hero-images/fourteenth.jpg',
      [HeroImageType.Fifteenth]: 'assets/hero-images/fifteenth.jpg',
      [HeroImageType.Sixteenth]: 'assets/hero-images/sixteenth.jpg',
      [HeroImageType.Seventeenth]: 'assets/hero-images/seventeenth.jpg',
      [HeroImageType.Eighteenth]: 'assets/hero-images/eighteenth.jpg',
      [HeroImageType.Nineteenth]: 'assets/hero-images/nineteenth.jpg',
      [HeroImageType.Twentieth]: 'assets/hero-images/twentieth.jpg',
      [HeroImageType.TwentyFirst]: 'assets/hero-images/twentyfirst.jpg',
      [HeroImageType.TwentySecond]: 'assets/hero-images/twentysecond.jpg',
      [HeroImageType.TwentyThird]: 'assets/hero-images/twentythird.jpg',
      [HeroImageType.TwentyFourth]: 'assets/hero-images/twentyfourth.jpg',
    };

    /**
     * Maps hero image type enum value to a human-readable representation.
     * @param heroImage Hero image.
     */
    export function toReadable(heroImage: HeroImageType): string {
      return TO_READABLE_MAP[heroImage];
    }

    /**
     * Maps hero image type to src of the image.
     * @param heroImage Hero image.
     */
    export function toAssetSrc(heroImage: HeroImageType): string {
      return TYPE_TO_IMAGE_MAP[heroImage];
    }

    /**
     * Type guard for hero image type.
     * @param heroImageType Possible hero image type.
     */
    export function isHeroImageTemplate(heroImageType: number): heroImageType is HeroImageType {
      return enumToArray(HeroImageType).includes(heroImageType);
    }
  }

  /** Group of color themes. */
  export interface ThemeGroup {

    /** Name of the group. */
    readonly name: string;

    /** Themes in the group. */
    readonly themes: readonly ColorTheme[];
  }

  export const THEME_GROUPS: readonly ThemeGroup[] = [
    {
      name: 'Brokerages',
      themes: [
        ColorTheme.AllenTate,
        ColorTheme.BerkshireHathaway,
        ColorTheme.BetterHomesGarden,
        ColorTheme.Century21,
        ColorTheme.ColdwellBanker,
        ColorTheme.Compass,
        ColorTheme.EXITRealty,
        ColorTheme.Exp,
        ColorTheme.HomeSmart,
        ColorTheme.KellerWilliams,
        ColorTheme.NextHome,
        ColorTheme.ReMax,
        ColorTheme.RealtyOne,
        ColorTheme.UnitedRealEstate,
      ],
    },
    {
      name: 'Independent Brokerages',
      themes: [
        ColorTheme.Custom1,
        ColorTheme.Custom2,
        ColorTheme.Custom3,
        ColorTheme.Custom4,
        ColorTheme.Custom5,
      ],
    },
  ];

  export const DEFAULT_CONFIGURATION: AgentLanding.Configuration = {
    theme: ColorTheme.ReMax,
    templateType: AgentLanding.TemplateType.First,
    heroImageType: null,
    customHeroImage: null,
    uploadedHeroImage: null,
  };
}
