import { Component, ChangeDetectionStrategy } from '@angular/core';

/** Dialog container component. */
@Component({
  selector: 'zc-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContainerComponent { }
