import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { AppError } from '../models/app-error';
import { QuestionFields } from '../models/questionnaire/question-fields';
import { ValidationErrorCode } from '../models/validation-error-code';

import { assertNonNull } from './assert-non-null';

import Specific = QuestionFields.Specific;

import AvailableValidation = QuestionFields.AvailableValidation;

/** Url validator pattern. */
export enum UrlPattern {
  URL = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w.-]*/?',
  HTTPS = 'https?://([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w.-]*\\??([\\w-]+(=[\\w-]*)?(&[\\w-]+(=[\\w-]*)?)*)?',
}

const VALIDATOR_FUNCTIONS_MAP: Record<AvailableValidation, ValidatorFn> = {
  [Specific.Text.Validation.Email]: Validators.email,
  [Specific.Text.Validation.Required]: Validators.required,
  [Specific.Text.Validation.Phone]: Validators.pattern('[0-9]{10}'),
  [Specific.Select.Validation.Required]: Validators.required,
  [Specific.Checklist.Validation.Required]: Validators.required,
  [Specific.TileChecklist.Validation.Required]: Validators.required,
  [Specific.OrderedFieldList.Validation.Required]: Validators.required,
  [Specific.DayField.Validation.Required]: Validators.required,
  [Specific.DayField.Validation.Max2]: Validators.maxLength(2),
};

/**
 * Custom validators.
 */
export namespace ZCValidators {

  /**
   * Checks whether the current control matches another.
   * @param controlName Control name to check matching with.
   * @param controlTitle Control title to display for a user.
   */
  // eslint-disable-next-line
  export function matchControl(controlName: string, controlTitle: string = controlName): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.parent && control.parent.get(controlName)?.value !== control.value) {
        return {
          [ValidationErrorCode.Match]: {
            controlName,
            controlTitle,
          },
        };
      }
      return null;
    };
  }

  /**
   * Get validator from mapper.
   * @param item Enumeration of Validation.
   */
  export function getFormFieldValidator(item: AvailableValidation): ValidatorFn {
    const validatorFn = VALIDATOR_FUNCTIONS_MAP[item];
    assertNonNull(validatorFn);
    return validatorFn;
  }

  /**
   * Validates a control for empty trimmed string.
   * @param this Context.
   * @param control Control to validate.
   */
  export function requiredWithTrim(this: void, control: AbstractControl): ValidationErrors | null {
    if (control.value != null && typeof control.value !== 'string') {
      throw new AppError(`Expected string value, got: ${control.value} - ${typeof control.value}`);
    }

    return Validators.required(control) ??
      (control.value.trim() === '' ? buildAppError('Empty space is not allowed') : null);
  }

  /**
   * Create validation error from a message.
   * @param message Message to create an error from.
   */
  export function buildAppError(message: string): ValidationErrors {
    return {
      [ValidationErrorCode.AppError]: {
        message,
      },
    };
  }

  /**
   * Validates a control for a url pattern.
   * @param pattern Pattern.
   */
  export function urlValidator(pattern: UrlPattern): ValidatorFn {
    return Validators.pattern(pattern);
  }
}
