import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

import { CommonSharedModule } from '@zc/common/shared/common-shared.module';

import { FieldControlModule } from '@zc/common/shared/features/questionnaire/field-control/field-control.module';

import { AgentFooterComponent } from './components/agent-footer/agent-footer.component';
import { AgentHeaderComponent } from './components/agent-header/agent-header.component';
import { AgentHeaderFormComponent } from './components/agent-header/components/agent-header-content/agent-header-form.component';
import { AgentNavbarComponent } from './components/agent-header/components/agent-navbar/agent-navbar.component';
import { SidebarComponent } from './components/agent-header/components/agent-navbar/components/sidebar/sidebar.component';
import { AgentFaqComponent } from './components/agent-faq/agent-faq.component';
import {
  AgentNavListComponent,
} from './components/agent-header/components/agent-navbar/components/agent-nav-list/agent-nav-list.component';
import { AgentSaleOptionsComponent } from './components/agent-sale-options/agent-sale-options.component';
import { AgentSellingStepsComponent } from './components/agent-selling-steps/agent-selling-steps.component';
import { AgentVideoBoxComponent } from './components/agent-video-box/agent-video-box.component';
import { AgentSectionTitleComponent } from './components/agent-section-title/agent-section-title.component';
import { AgentSectionDescriptionComponent } from './components/agent-section-description/agent-section-description.component';
import { AgentSectionComponent } from './components/agent-section/agent-section.component';
import { AgentCardComponent } from './components/agent-card/agent-card.component';
import { SocialLinksComponent } from './components/social-links/social-links.component';
import { AgentFooterCardComponent } from './components/agent-footer/components/agent-footer-card/agent-footer-card.component';

/** Shared components for agent home page templates. */
@NgModule({
  declarations: [
    AgentHeaderComponent,
    AgentNavbarComponent,
    SidebarComponent,
    AgentHeaderFormComponent,
    AgentSellingStepsComponent,
    AgentVideoBoxComponent,
    AgentSaleOptionsComponent,
    AgentFaqComponent,
    AgentFooterComponent,
    AgentNavListComponent,
    AgentSectionTitleComponent,
    AgentSectionDescriptionComponent,
    AgentSectionComponent,
    AgentCardComponent,
    SocialLinksComponent,
    AgentFooterCardComponent,
  ],
  exports: [
    AgentHeaderComponent,
    AgentNavbarComponent,
    AgentFooterComponent,
    AgentSellingStepsComponent,
    AgentVideoBoxComponent,
    AgentSaleOptionsComponent,
    AgentFaqComponent,
    AgentSectionComponent,
    AgentSectionTitleComponent,
    AgentSectionDescriptionComponent,
    AgentCardComponent,
    AgentNavListComponent,
    SocialLinksComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CommonSharedModule,
    FormsModule,
    ReactiveFormsModule,
    FieldControlModule,
  ],
})
export class TemplatesSharedModule { }
