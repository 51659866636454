import { Component, ChangeDetectionStrategy } from '@angular/core';
import { QuestionFields } from '@zc/common/core/models/questionnaire/question-fields';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { controlProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

import Select = QuestionFields.Specific.Select;

/**
 * Select field control.
 */
@Component({
  selector: 'zc-select-field-control',
  templateUrl: './select-field-control.component.html',
  styleUrls: ['./select-field-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(SelectFieldControlComponent)],
})
export class SelectFieldControlComponent extends SimpleValueAccessor<Select> {
  /** Option comparator for select. */
  public optionComparator = Select.areOptionsEqual;

  /**
   * Handles value change.
   * @param option Option changed.
   */
  public onValueChanged(option: Select.Option): void {
    assertNonNull(this.controlValue);

    this.controlValue = {
      ...this.controlValue,
      value: option,
    };
  }
}
