<ng-container *ngIf="controlValue">
  <div class="checklist-container">
    <div
      class="checklist-option"
      [class.with-image]="option.backgroundImageUrl"
      *ngFor="let option of options; let i = index"
    >
      <input
        [ngStyle]="{ backgroundImage: getBackground(option) }"
        [checked]="isSelected(option)"
        (change)="onToggle(option)"
        type="checkbox"
        id="checklist-option-input-{{ i }}"
      />
      <label
        for="checklist-option-input-{{ i }}"
        class="checklist-option-label"
      >
        {{ option.label }}
      </label>
      <p class="checklist-option-description">
        {{ option.description }}
      </p>
      <ng-container *ngIf="option.extra">
        <ng-container *ngFor="let extraItem of option.extra; let i = index">
          <div class="extra-container">
            <ng-container *ngIf="extraItem.type === fieldType.Select">
              <zc-select-field-control
                *ngIf="isSelected(option)"
                class="extra-dropdown"
                [disabled]="!isSelected(option)"
                [ngModel]="extraItem"
                (ngModelChange)="onExtraOptionsChange(option, $event, i)"
              ></zc-select-field-control>
            </ng-container>
            <ng-container
              *ngIf="
                extraItem.type === fieldType.Checklist &&
                isSelected(option) &&
                isSelectedDropdown(option.extra)
              "
            >
              <zc-checklist-field-control
                class="extra-checkbox"
                [disabled]="
                  !isSelected(option) || !isSelectedDropdown(option.extra)
                "
                [ngModel]="extraItem"
                (ngModelChange)="onExtraOptionsChange(option, $event, i)"
              >
              </zc-checklist-field-control>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="custom-field-container" *ngIf="controlValue.customValueField">
    <zc-text-field-control
      [ngModel]="controlValue.customValueField"
      (ngModelChange)="onCustomFieldChange($event)"
    ></zc-text-field-control>
  </div>
</ng-container>
