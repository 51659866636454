import { Pipe, PipeTransform } from '@angular/core';
import { MaskPipe } from 'ngx-mask';

/** Phone prefix. C - Cellphone, O - office. */
type PhonePrefix = 'C' | 'O';

/**
 * Phone pipe.
 */
@Pipe({
  name: 'zcPhone',
})
export class PhonePipe implements PipeTransform {

  public constructor(
    private readonly maskPipe: MaskPipe,
  ) { }

  /**
   * Transforms phone number to (xxx) xxx-xxxx format.
   * @param value Phone number.
   * @param prefix Phone prefix.
   */
  public transform(value: string | null | undefined, prefix?: PhonePrefix): string | null {
    if (value != null) {
      const maskedNumber = this.maskPipe.transform(value, '(000) 000-0000');
      return prefix != null ? `${prefix}: ${maskedNumber}` : maskedNumber;
    }

    return null;
  }
}
