import { DOCUMENT } from '@angular/common';
import { Directive, HostListener, Inject } from '@angular/core';

/**
 * Directive will add style "position:fixed" to body.
 * Prevent elements with style "position:absolute" from moving (dropdown, popup, etc... ) when scroll.
 * Https://saritasa.atlassian.net/browse/ZCAPP-420?focusedCommentId=214553.
 */
@Directive({
  selector: '[zcFixedBody]',
})
export class FixedBodyDirective {

  /** Handle on focus event. */
  @HostListener('focus')
  public setInputFocus(): void {
    this.document.body.setAttribute('data-position', 'fixed');
  }

  /** Handle blur event. */
  @HostListener('blur')
  public setInputFocusOut(): void {
    this.document.body.removeAttribute('data-position');
  }

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}
}
