<zcw-agent-header [company]="company" [agent]="agent" class="header">
  <!-- TODO (Panov A.): Change image to video (image is used as temporary solution for V1). -->
  <img class="header__image" [src]="heroImageSrc" alt="" />
  <zcw-agent-card
    [flat]="true"
    class="header__agent-card"
    [agent]="agent"
    [company]="company"
  >
  </zcw-agent-card>
  <span ngProjectAs="title" class="header__title">
    Sell Your Home. Your Way with an Instant Home Offer
  </span>
  <ng-container ngProjectAs="description">
    You have options so let me share them with you
  </ng-container>
</zcw-agent-header>
<div class="main">
  <div class="inline-steps">
    <section class="selling-steps">
      <zcw-agent-section
        id="how-it-works"
        class="selling-steps__section section section_aligned-start"
      >
        <h2 class="visually-hidden">Selling Steps</h2>
        <zcw-agent-section-title>
          Sell your home instantly without all the requirements a traditional
          sale may require.
        </zcw-agent-section-title>
        <zcw-agent-section-description>
          Get a no-obligation quick cash offer. Close on your schedule and
          eliminate property repairs, open houses, negotiations, and any unknown
          obstacle that may arise with a cash offer.
        </zcw-agent-section-description>
        <span class="section__announce"> Next, the process </span>
      </zcw-agent-section>
      <zcw-agent-selling-steps [inline]="false" template="second">
      </zcw-agent-selling-steps>
    </section>
  </div>

  <div class="section">
    <zcw-agent-section>
      <h2 class="visually-hidden">Sale Tips</h2>
      <zcw-agent-section-title class="section__title">
        If our cash offer does not meet your financial goals that’s not a
        problem.
      </zcw-agent-section-title>
      <zcw-agent-section-description>
        I can also present you with a complete marketing plan to sell your home
        using traditional real estate practices.
      </zcw-agent-section-description>
    </zcw-agent-section>
  </div>

  <zcw-agent-video-box [gender]="gender" class="section">
    <ng-container ngProjectAs="title">Easy, transparent process.</ng-container>
    <ng-container ngProjectAs="description">
      If you are looking for more information on my Instant Offer program, watch
      this short video which explains the quick and easy process that will give
      you the best home selling options. Whether it's an instant offer or a
      traditional MLS real estate listing, I'll be here every step of the way to
      guide you through the entire process. Getting educated on selling your
      home has never been so easy!
    </ng-container>
  </zcw-agent-video-box>
  <zcw-agent-sale-options id="home-selling" class="section">
    <ng-container ngProjectAs="title">
      Take Control of Selling Your Home on Your Terms
    </ng-container>
    <ng-container ngProjectAs="description">Compare Your Options</ng-container>
  </zcw-agent-sale-options>
  <zcw-agent-faq class="section"></zcw-agent-faq>
</div>

<zcw-agent-footer [agent]="agent" [company]="company"></zcw-agent-footer>
