import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

type AssertionFunction<T, R extends T> = (data: T) => asserts data is R;

/**
 * Util to use assertion functions in rxjs workflow.
 * @param assertFunction Type-assertion function.
 */
export function mapAssert<T, R extends T>(assertFunction: AssertionFunction<T, R>): OperatorFunction<T, R> {
  return source => source.pipe(
    map(data => {
      assertFunction(data);
      return data;
    }),
  );
}
