import { Injectable } from '@angular/core';

import { User } from '../../models/user';

import { AddressMapper } from './address.mapper';

import { UserDto } from './dto/user.dto';
import { IMapperFromDto } from './mappers';
import { PhoneMapper } from './phone.mapper';
import { UserRoleMapper } from './user-role.mapper';

/** User mapper. */
@Injectable({
  providedIn: 'root',
})
export class UserMapper implements IMapperFromDto<UserDto, User> {

  public constructor(
    private readonly userRoleMapper: UserRoleMapper,
    private readonly addressMapper: AddressMapper,
    private readonly phoneMapper: PhoneMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: UserDto): User {
    return {
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.seller_email ?? data.email,
      avatarUrl: data.avatar ?? null,
      roleGroup: this.userRoleMapper.groupFromDto(data.role.group),
      profession: this.userRoleMapper.fromDto(data.role),
      phone: data.phone ? this.phoneMapper.fromDto(data.phone) : '',
      location: this.addressMapper.fromDto(data),
      id: data.slug,
    };
  }
}
