import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Gender } from '@zc/common/core/models/gender';
import { Destroyable, takeUntilDestroy } from '@zc/common/core/utils/destroyable';
import GLightbox, { GlightboxInstance, GlightboxOptions } from 'glightbox';
import { first, tap, timer } from 'rxjs';

const MAP_GENDER_TO_VIDEO = {
  [Gender.Female]: 'https://agent-voice-videos.s3.amazonaws.com/Female.mp4',
  [Gender.Male]: 'https://agent-voice-videos.s3.amazonaws.com/Male.mp4',
  [Gender.DiverseMale]: 'https://agent-voice-videos.s3.amazonaws.com/Diverse_Male.mp4',
  [Gender.DiverseFemale]: 'https://agent-voice-videos.s3.amazonaws.com/Diverse_Female.mp4',
};

/** Agent video box component. */
@Destroyable()
@Component({
  selector: 'zcw-agent-video-box',
  templateUrl: './agent-video-box.component.html',
  styleUrls: ['./agent-video-box.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgentVideoBoxComponent {

  /** Agent of gender. */
  @Input()
  public set gender(value: Gender | null) {
    if (value !== null) {
      this.videoSrc = MAP_GENDER_TO_VIDEO[value];

      // Workaround for GLightBox to wait for videoSrc change in template.
      timer(0).pipe(
        first(),
        tap(() => {
          const options: GlightboxOptions = {
            selector: '.video-box',
          };

          this.glightbox = GLightbox(options);
        }),
        takeUntilDestroy(this),
      )
        .subscribe();
    }
  }

  /** Link to the video. */
  public videoSrc: string | null = null;

  private glightbox: GlightboxInstance | null = null;
}
