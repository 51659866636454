import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { QuestionFields } from '@zc/common/core/models/questionnaire/question-fields';
import { assertNonNull } from '@zc/common/core/utils/assert-non-null';
import { Destroyable } from '@zc/common/core/utils/destroyable';
import { ZCValidators } from '@zc/common/core/utils/validators';
import { controlProviderFor, validatorProviderFor, SimpleValueAccessor } from '@zc/common/core/utils/value-accessor';

import OrderedFieldList = QuestionFields.Specific.OrderedFieldList;

/** Ordered list field control. */
@Destroyable()
@Component({
  selector: 'zc-ordered-list-field-control',
  templateUrl: './ordered-list-field-control.component.html',
  styleUrls: ['./ordered-list-field-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    controlProviderFor(OrderedListControlComponent),
    validatorProviderFor(OrderedListControlComponent),
  ],
})
export class OrderedListControlComponent extends SimpleValueAccessor<OrderedFieldList> implements Validator {
  /** Type guard for editable field. */
  public readonly isAutoCompleteItem = OrderedFieldList.isAutoCompleteItem;

  /** Field type options. */
  public readonly fieldType = QuestionFields.FieldType;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  /**
   * Handle changed field.
   * @param autocomplete Autocomplete field data.
   */
  public onFieldChanged(autocomplete: QuestionFields.Specific.GoogleAutocomplete): void {
    assertNonNull(this.controlValue);
    this.controlValue = {
      ...this.controlValue,
      value: autocomplete.value?.address,
    };

    this.changeDetectorRef.markForCheck();
  }

  /**
   * Track by function.
   * @param _ Idx.
   * @param option Track by option.
   */
  public trackOption(_: number, option: OrderedFieldList.ListItem): string {
    return option.label;
  }

  /** @inheritdoc */
  public validate(): ValidationErrors | null {
    if (!this.controlValue) {
      return null;
    }
    if (!this.controlValue.placeId) {
      return null;
    }
    const placeValidate = this.validateByFormControl(this.controlValue?.placeId ?? '', this.controlValue.validations);
    return placeValidate;
  }

  /**
   * Check validation of input by form control validation.
   * @param _value Input value.
   * @param validations List validation.
   * @returns Validation Errors or null.
   */
  private validateByFormControl(_value: string, validations?: OrderedFieldList.Validation[]): ValidationErrors | null {
    const _control = new FormControl();
    _control.setValue(_value ?? '', { emitEvent: false });

    if (validations && validations.length > 0) {
      const validaroFnArr: ValidatorFn[] = validations.map(item => ZCValidators.getFormFieldValidator(item));

      _control.setValidators(validaroFnArr);
      _control.updateValueAndValidity();
    }
    return _control.errors;
  }

}
