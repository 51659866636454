<zcw-agent-header
  class="header"
  [contentOverlap]="true"
  [company]="company"
  [agent]="agent"
>
  <img class="header__image" [src]="heroImageSrc" alt="" />
  <span ngProjectAs="title" class="header__title"> Sell Your Home. Your Way. </span>
  <span ngProjectAs="description" class="header__description">
    You have options so let me share them with you
  </span>
</zcw-agent-header>
<main class="main">
  <section class="intro">
    <zcw-agent-card
      class="intro__agent-card"
      [agent]="agent"
      [company]="company"
    >
    </zcw-agent-card>
    <zcw-agent-section id="how-it-works" class="intro__section section section_aligned-start">
      <h2 class="visually-hidden">Selling Steps</h2>
      <zcw-agent-section-title>
        Sell your home instantly without all the requirements a traditional sale
        may require.
      </zcw-agent-section-title>
      <zcw-agent-section-description>
        Get a no-obligation quick cash offer. Close on your schedule and
        eliminate property repairs, open houses, negotiations, and any unknown
        obstacle that may arise with a cash offer.
      </zcw-agent-section-description>
      <span class="section__announce"> Here are the steps </span>
    </zcw-agent-section>
  </section>

  <section class="selling-steps">
    <zcw-agent-section
      class="selling-steps__section section section_aligned-start"
    >
      <h2 class="visually-hidden">Selling Steps</h2>
      <zcw-agent-section-title>
        If our cash offer does not meet your financial goals that’s not a
        problem.
      </zcw-agent-section-title>
      <zcw-agent-section-description>
        I can also present you with a complete marketing plan to sell your home
        using traditional real estate practices.
      </zcw-agent-section-description>
      <span class="section__announce"> Next, the process </span>
    </zcw-agent-section>
    <zcw-agent-selling-steps [inline]="false" template="second">
    </zcw-agent-selling-steps>
  </section>

  <zcw-agent-video-box [gender]="gender" class="section">
    <ng-container ngProjectAs="title">Easy, transparent process.</ng-container>
    <ng-container ngProjectAs="description">
      If you are looking for more information on my Instant Offer program, watch
      this short video which explains the quick and easy process that will give
      you the best home selling options. Whether it's an instant offer or a
      traditional MLS real estate listing, I'll be here every step of the way to
      guide you through the entire process. Getting educated on selling your
      home has never been so easy!
    </ng-container>
  </zcw-agent-video-box>
  <zcw-agent-sale-options id="home-selling" class="section">
    <ng-container ngProjectAs="title"
      >Take Control of Selling Your Home on Your Terms</ng-container
    >
    <ng-container ngProjectAs="description">Compare Your Options</ng-container>
  </zcw-agent-sale-options>
  <zcw-agent-faq class="section"></zcw-agent-faq>
</main>

<zcw-agent-footer [agent]="agent" [company]="company"></zcw-agent-footer>
