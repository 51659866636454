import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

/** Represents side bar when on mobile devices. */
@Component({
  selector: 'zcw-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  /** Close side bar event. */
  @Output()
  public readonly closeSidebar = new EventEmitter<void>();
}
