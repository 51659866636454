import { inject, InjectionToken } from '@angular/core';

import { WINDOW } from './window-token';

/**
 * Injection token to use instead of global history object.
 */
export const HISTORY = new InjectionToken<History>(
  'An abstraction over window.history object',
  {
    factory: () => inject(WINDOW).history,
  },
);
