import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';

import { AppError } from '../models/app-error';
import { AuthData } from '../models/auth-data';
import { Maxa } from '../models/maxa';

import { ReCaptchaValidationResultMapper } from './mappers/re-captcha-validation-result.mapper';
import { ReCaptchaValidationResult } from './../models/re-captcha-validation-result';
import { ReCaptchaValidationResultDto } from './mappers/dto/re-captcha-validation-result.dto';

import { AppConfigService } from './app-config.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { AuthDto } from './mappers/dto/auth.dto';
import { MaxaDto } from './mappers/dto/maxa.dto';
import { PasswordChangeDto } from './mappers/dto/password-change.dto';
import { LoginDataMapper } from './mappers/login-data.mapper';
import { MaxaMapper } from './mappers/maxa.mapper';
import { PasswordChangeMapper } from './mappers/password-change.mapper';
import { ResetPasswordConfirmationMapper } from './mappers/reset-password-confirmation.mapper';
import { ResetPasswordMapper } from './mappers/reset-password.mapper';
import { UserSecretDataMapper } from './mappers/user-secret-data.mapper';

const AUTH_PREFIX = 'Token';

/**
 * Stateless service for handling the authorization requests.
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly loginUrl: URL;

  private readonly refreshSecretUrl: URL;

  private readonly resetPasswordUrl: URL;

  private readonly confirmPasswordResetUrl: URL;

  private readonly changePasswordUrl: URL;

  private readonly accountVerificationUrl: URL;

  private readonly maxaUrl: URL;

  private readonly recaptchaUrl: URL;

  public constructor(
    appConfig: AppConfigService,
    private readonly httpClient: HttpClient,
    private readonly loginDataMapper: LoginDataMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly userSecretMapper: UserSecretDataMapper,
    private readonly resetPasswordMapper: ResetPasswordMapper,
    private readonly resetPasswordConfirmationMapper: ResetPasswordConfirmationMapper,
    private readonly passwordChangeMapper: PasswordChangeMapper,
    private readonly maxaMapper: MaxaMapper,
    private readonly reCaptchaResultMapper: ReCaptchaValidationResultMapper,
  ) {
    this.resetPasswordUrl = new URL('auth/password-reset/', appConfig.apiUrl);
    this.confirmPasswordResetUrl = new URL('auth/password-reset-confirm/', appConfig.apiUrl);
    this.loginUrl = new URL('auth/login/', appConfig.apiUrl);
    this.refreshSecretUrl = new URL('auth/token/refresh/', appConfig.apiUrl);
    this.changePasswordUrl = new URL('users/change_password/', appConfig.apiUrl);
    this.accountVerificationUrl = new URL('auth/account-verify/', appConfig.apiUrl);
    this.maxaUrl = new URL('auth/maxa-sso/', appConfig.apiUrl);
    this.recaptchaUrl = new URL('auth/recaptcha-verify/', appConfig.apiUrl);
  }

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: AuthData.Login): Observable<AuthData.UserSecret> {
    return this.httpClient.post<AuthDto.UserSecret>(
      this.loginUrl.toString(),
      this.loginDataMapper.toDto(loginData),
    ).pipe(
      map(dto => this.userSecretMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.loginDataMapper),
    );
  }

  /**
   * Verify account registration.
   * @param verificationToken Account verification token.
   */
  public verifyAccount(verificationToken: string): Observable<AuthData.UserSecret> {
    const TOKEN_SEPARATOR = '-';
    const [uid, ...tokenTail] = verificationToken.split(TOKEN_SEPARATOR);

    return this.httpClient.post<AuthDto.UserSecret>(this.accountVerificationUrl.toString(), {
      uid,
      token: tokenTail.join(TOKEN_SEPARATOR),
    }).pipe(
      map(dto => this.userSecretMapper.fromDto(dto)),
      catchError((error: HttpErrorResponse) => throwError(() => new AppError(error.error.detail))),
    );
  }

  /**
   * Appends authorization header to a list of `headers`.
   * @param headers Headers list.
   * @param userSecret User secret.
   */
  public appendAuthorizationHeader(headers: HttpHeaders, userSecret: AuthData.UserSecret): HttpHeaders {
    return headers.set('Authorization', `${AUTH_PREFIX} ${userSecret.token}`);
  }

  /**
   * Refresh user's secret.
   * @param secret Secret data.
   */
  public refreshSecret(secret: AuthData.UserSecret): Observable<AuthData.UserSecret> {
    return this.httpClient.post<AuthDto.UserSecret>(
      this.refreshSecretUrl.toString(),
      this.userSecretMapper.toDto(secret),
    ).pipe(
      map(refreshedSecret => this.userSecretMapper.fromDto({
        ...secret,
        ...refreshedSecret,
      })),
    );
  }

  /**
   * Sends request to reset the password.
   * @param data Data for password reset.
   * @returns Success message.
   */
  public resetPassword(data: AuthData.PasswordReset): Observable<string> {
    return this.httpClient.post<{ detail: string; }>(
      this.resetPasswordUrl.toString(),
      this.resetPasswordMapper.toDto(data),
    ).pipe(
      map(result => result.detail),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.resetPasswordMapper),
    );
  }

  /**
   * Confirms password reset and applies new passwords to the account.
   * @param data New passwords data.
   * @returns Success message.
   */
  public confirmPasswordReset(data: AuthData.PasswordResetConfirmation): Observable<string> {
    return this.httpClient.post<{ detail: string; }>(
      this.confirmPasswordResetUrl.toString(),
      this.resetPasswordConfirmationMapper.toDto(data),
    ).pipe(
      map(result => result.detail),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.resetPasswordConfirmationMapper),
    );
  }

  /**
   * Changes password of current user.
   * @param data Data required for password changing.
   */
  public changePassword(data: AuthData.PasswordChange): Observable<void> {
    return this.httpClient.post<PasswordChangeDto>(
      this.changePasswordUrl.toString(),
      this.passwordChangeMapper.toDto(data),
    ).pipe(
      mapTo(void 0),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
        this.passwordChangeMapper,
      ),
    );
  }

  /**
   * Authenticate user to Maxa.
   */
  public loginToMaxa(): Observable<Maxa> {
    return this.httpClient.post<MaxaDto>(
      this.maxaUrl.toString(),
      null,
    ).pipe(
      map(dto => this.maxaMapper.fromDto(dto)),
    );
  }

  /**
   * Verify recaptcha.
   * @param token Recaptcha token.
   */
  public verifyRecaptcha(token: string): Observable<ReCaptchaValidationResult> {
    return this.httpClient.post<ReCaptchaValidationResultDto>(this.recaptchaUrl.toString(), { token }).pipe(
      map(dto => this.reCaptchaResultMapper.fromDto(dto)),
    );
  }
}
