import { enumToArray } from '../utils/enum-to-array';

/**
 * File formats.
 */
export enum FileFormat {
  JPG = '.jpg',
  JPEG = '.jpeg',
  PNG = '.png',
  GIF = '.gif',
  WEBP = '.webp',
  SVG = '.svg',
  DOC = '.doc',
  DOCS = '.docs',
  DOCX = '.docx',
  PDF = '.pdf',
  MP4 = '.mp4',
  MOV = '.mov',
  MKV = '.mkv',
  AVI = '.avi',
  WMV = '.wmv',
  XLS = '.xls',
  XLSX = '.xlsx',
  CSV = '.csv',
  Unknown = 'unknown',
}

export namespace FileFormat {

  /**
   * Maps format to human-readable one.
   * @param format Format to map.
   */
  export function toReadable(format: FileFormat): string {
    return format;
  }

  /** All available video formats. */
  export const VIDEO_FORMATS: readonly FileFormat[] = [
    FileFormat.MP4,
    FileFormat.MOV,
    FileFormat.AVI,
    FileFormat.WMV,
    FileFormat.MKV,
  ];

  /**
   * Whether file is video or not.
   * @param file File.
   */
  export function isVideo(file: string): boolean {
    return !!VIDEO_FORMATS.find(format => file.toLocaleLowerCase().endsWith(format));
  }

  /** All available image formats. */
  export const IMAGE_FORMATS: readonly FileFormat[] = [
    FileFormat.JPG,
    FileFormat.JPEG,
    FileFormat.PNG,
    FileFormat.GIF,
    FileFormat.WEBP,
    FileFormat.SVG,
  ];

  /**
   * Whether file is image or not.
   * @param file File.
   */
  export function isImage(file: string): boolean {
    return !!IMAGE_FORMATS.find(format => file.toLocaleLowerCase().endsWith(format));
  }

  /** All available document formats. */
  export const DOC_FORMATS: readonly FileFormat[] = [
    FileFormat.DOC,
    FileFormat.DOCS,
    FileFormat.DOCX,
    FileFormat.PDF,
    FileFormat.XLS,
    FileFormat.XLSX,
    FileFormat.CSV,
  ];

  /**
   * Whether provided type is PDF or not.
   * @param file File URI.
   */
  export function isPDF(file: string): boolean {
    return file.endsWith(FileFormat.PDF);
  }

  /**
   * @param file File.
   * @returns File format.
   */
  export function fromFile(file: File | string): FileFormat {
    const name = typeof file === 'string' ? file : file.name;
    const isSupportedFormat = !!enumToArray(FileFormat).find(format => name.toLocaleLowerCase().endsWith(format));
    if (isSupportedFormat) {
      const format = name
        .split('.')
        .splice(-1)
        .join('');
      return `.${format}` as FileFormat;
    }
    return FileFormat.Unknown;
  }
}
