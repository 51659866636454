<ng-container *ngIf="pagination; else skeleton">
  <ng-container *ngIf="pagination.totalCount > 0">
    <button
      class="pagination-button"
      type="button"
      title="Previous page"
      [disabled]="!pagination.havePrev"
      (click)="prev.emit()"
    >
      <mat-icon>navigate_before</mat-icon>
    </button>
    <span class="pagination-label">
      {{ firstElementNumber }}-{{ lastElementNumber }} of
      {{ pagination.totalCount }}
    </span>
    <button
      class="pagination-button"
      type="button"
      title="Next page"
      [disabled]="!pagination.haveNext"
      (click)="next.emit()"
    >
      <mat-icon>navigate_next</mat-icon>
    </button>
  </ng-container>
</ng-container>

<ng-template #skeleton>
  <button type="button" zcSkeleton disabled class="pagination-button"></button>
  <span zcSkeleton [zcSkeletonMinWidth]="10" [zcSkeletonMaxWidth]="10"></span>
  <button type="button" zcSkeleton disabled class="pagination-button"></button>
</ng-template>
