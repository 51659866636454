/* eslint-disable max-depth */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, EMPTY, finalize, Observable, of, timer } from 'rxjs';
import { map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { AppError } from '../models/app-error';
import { Project } from '../models/project';
import { GoogleLocation } from '../models/questionnaire/google-location';
import { Question } from '../models/questionnaire/question';
import { QuestionFields } from '../models/questionnaire/question-fields';
import { Questionnaire, QuestionnaireData } from '../models/questionnaire/questionnaire';
import { Section } from '../models/questionnaire/questionnaire-stage';

import { AppConfigService } from './app-config.service';
import { LocalStorageService } from './local-storage.service';
import { QuestionnaireDto } from './mappers/dto/questionnaire.dto';
import { PhoneMapper } from './mappers/phone.mapper';
import { QuestionnaireMapper } from './mappers/questionnaire.mapper';
import { getDefaultLocation, getMockData, getMockDataMarketing } from './questionnaire-questions';

const QUESTIONNAIRE_STORE_KEY = 'questionnaire';

const GOOGLE_LOCATION_KEY = 'googleLocation';

const QUESTIONNAIRE_MARKETING_STORE_KEY = 'questionnaireWithoutAgent';

/**
 * Parses the whole questionnaire data looking for a value of text field with a specified label.
 * If label is not unique, first value found value is used.
 * @param questionnaire Questionnaire.
 * @param type Text field label.
 */
export function parseQuestionnaireByDefinedField(
  questionnaire: Questionnaire,
  type: QuestionFields.DefinedFieldType,
): string | number {

  for (const stage of questionnaire.stages) {
    for (const question of stage.questions) {
      for (const field of question.fields) {
        if (field.definedType === type) {
          switch (field.type) {
            case QuestionFields.FieldType.Text:
              return field.value ?? '';
            case QuestionFields.FieldType.Number:
              return field.value ?? 0;
            case QuestionFields.FieldType.Select:
              return field.value?.value ?? '';
            default:
              return '';
          }
        }
      }
    }
  }

  throw new AppError(`Field with "${type}" is not found.`);
}

/** Questionnaire service. Manages saving and getting the questionnaires. */
@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {

  /** Whether the questionnaire is loading. */
  public readonly isLoading$: Observable<boolean>;

  private readonly _isLoading$: BehaviorSubject<boolean>;

  /** Whether the user is authorized. */
  public readonly isAuthorized$: Observable<boolean>;

  private readonly _isAuthorized$: BehaviorSubject<boolean>;

  private readonly questionnaireUrl: URL;

  /** Current user phone number. */
  private readonly currentPhoneNumber$ = new BehaviorSubject<string>('');

  public constructor(
    appConfigService: AppConfigService,
    private readonly storage: LocalStorageService,
    private readonly httpClient: HttpClient,
    private readonly questionnaireMapper: QuestionnaireMapper,
    private readonly phoneMapper: PhoneMapper,
  ) {
    this.questionnaireUrl = new URL(`projects/survey/`, appConfigService.apiUrl);
    this._isAuthorized$ = new BehaviorSubject<boolean>(false);
    this.isAuthorized$ = this._isAuthorized$.asObservable();
    this._isLoading$ = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this._isLoading$.asObservable();
  }

  /** Reset authorization status. */
  public resetAuthorized(): Observable<void> {
    return of(this._isAuthorized$.next(false));
  }

  /** @inheritdoc */
  public get getGoogleLocation(): Observable<GoogleLocation> {
    return defer(() =>
      this.storage.get<GoogleLocation>(GOOGLE_LOCATION_KEY))
      .pipe(map(googleLocation => googleLocation ?? getDefaultLocation()));
  }

  /** @inheritdoc */
  public setGoogleLocation(googleLocation: GoogleLocation): Observable<void> {
    return defer(() =>
      this.storage.save(GOOGLE_LOCATION_KEY, googleLocation));
  }

  /** @inheritdoc */
  public setUnit(unit: string): Observable<void> {
    return this.getGoogleLocation.pipe(
      take(1),
      switchMap(googleLocation => this.setGoogleLocation({ ...googleLocation, unit })),
    );
  }

  /** Load the questionnaire data for user enter via agent site. */
  public getQuestionnaire(): Observable<Questionnaire> {
    return defer(() =>
      this.storage.get<Questionnaire>(QUESTIONNAIRE_STORE_KEY))
      .pipe(map(questionnaire => questionnaire ?? getMockData()));
  }

  /** Load the questionnaire data for user enter via marketing site. */
  public getQuestionnaireMarketing(): Observable<Questionnaire> {
    return defer(() =>
      this.storage.get<Questionnaire>(QUESTIONNAIRE_MARKETING_STORE_KEY))
      .pipe(map(questionnaire => questionnaire ?? getMockDataMarketing()));
  }

  /**
   * Fetches questionnaire for specific project.
   * @param id Project id.
   */
  public fetchQuestionnaireByProject(id: Project['id']): Observable<QuestionnaireData> {
    const url = new URL(`${id}/`, this.questionnaireUrl).toString();

    return this.httpClient.get<QuestionnaireDto>(url).pipe(
      map(dto => this.questionnaireMapper.fromDto(dto)),
    );
  }

  /**
   * Save questionnaire to a storage.
   * @param questionnaire Questionnaire.
   */
  public saveQuestionnaire(questionnaire: Questionnaire): Observable<void> {
    return defer(() =>
      this.storage.save(QUESTIONNAIRE_STORE_KEY, questionnaire));
  }

  /**
   * Clear the questionnaire data from storage.
   */
  public clearQuestionnaire(): Observable<void> {
    return defer(() => this.storage.remove(QUESTIONNAIRE_STORE_KEY));
  }

  /**
   * Validates the questionnaire stage. If nothing is wrong stream is EMPTY, otherwise throws an error.
   * @param stage Stage to validate.
   */
  public validateQuestionnaireStage(): Observable<void> {
    return timer(300).pipe(switchMap(() => of(void 0)));
  }

  /**
   * Finish the questionnaire.
   * @param questionnaire Questionnaire data.
   * @param agentId Agent slug.
   */
  public finishQuestionnaire(questionnaire: Questionnaire, agentId: string): Observable<void> {
    return this.getQuestionnaireDto(questionnaire, agentId).pipe(
      tap(() => this._isLoading$.next(true)),
      switchMap(questionDto => this.httpClient.post<QuestionnaireDto>(this.questionnaireUrl.toString(), questionDto)),
      finalize(() => this._isLoading$.next(false)),
      mapTo(void 0),
    );
  }

  /**
   * Update seller phone and give permission to access questionnaires.
   * @param phone Seller phone.
   */
  public updateSellerPhone(phone: QuestionnaireData['sellerPhone']): Observable<void> {
    this.currentPhoneNumber$.next(phone);
    this._isAuthorized$.next(true);
    return EMPTY;
  }

  /**
   * Helper parse questionnaire to new DTO.
   * @param questionnaire Questionnaire data.
   * @param agentId Agent slug.
   */
  private getQuestionnaireDto(questionnaire: Questionnaire, agentId: string): Observable<QuestionnaireDto> {
    return this.getGoogleLocation.pipe(
      map(googleLocation => {
        const propertyInfo = this.questionnaireMapper.mappingPropertyInfoToDto(googleLocation, questionnaire);

        const questionnaireDto: QuestionnaireDto = {
          agent: agentId,
          seller_name: parseQuestionnaireByDefinedField(questionnaire, QuestionFields.DefinedFieldType.FullName).toString(),
          seller_phone: this.phoneMapper.toDto(this.currentPhoneNumber$.getValue()),
          seller_email: parseQuestionnaireByDefinedField(questionnaire, QuestionFields.DefinedFieldType.EmailAddress).toString(),
          content: this.getQuestions(questionnaire),
          note: '',
          property_info: { ...propertyInfo },
        };
        return questionnaireDto;
      }),
    );
  }

  /**
   * Obtains questions from questionnaire.
   * @param questionnaire Questionnaire.
   */
  private getQuestions(questionnaire: Questionnaire): Question[] {
    const questions: Question[] = [];
    const stages = questionnaire.stages.filter(stage => stage.questionExtraInfo.section !== Section.Ignore);
    for (const stage of stages) {
      for (const question of stage.questions) {
        questions.push(question);
      }
    }
    return questions;
  }
}
