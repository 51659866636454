<form (ngSubmit)="onFormSubmit()">
  <fieldset *ngFor="let control of questionControls">
    <legend class="question-text">{{ control.value.label }}</legend>
    <label class="question-description" *ngIf="control.value.description">{{
      control.value.description
    }}</label>
    <zc-question-control [formControl]="control"></zc-question-control>
  </fieldset>

  <footer
    class="stage-controls"
    *ngIf="controlValue?.questionnaireStageControls as controlsInfo"
  >
    <button
      *ngIf="controlsInfo.previousStageControl as previousStage"
      [zcLoading]="isLoading$ | async"
      [ngClass]="previousStage.type ? previousStage.type : 'secondary stage-controls__button-secondary'"
      type="button"
      (click)="prevStage.emit()"
    >
      {{ previousStage.label }}
    </button>
    <button
      *ngIf="controlsInfo.nextStageControl as nextStage"
      [zcLoading]="isLoading$ | async"
      [zcDisabled]="isNextButtonDisabled$ | async"
      [ngClass]="nextStage.type ? nextStage.type : 'primary stage-controls__button-primary'"
      type="submit"
    >
      {{ nextStage.label }}
    </button>
    <button
      *ngIf="controlsInfo.forceSubmitControl as forceSubmitStage"
      [zcLoading]="isLoading$ | async"
      [ngClass]="forceSubmitStage.type ? forceSubmitStage.type : 'secondary stage-controls__button-secondary'"
      type="button"
      (click)="onForceSubmit()"
    >
      {{ forceSubmitStage.label }}
    </button>
  </footer>
  <zc-validation-message [errors]="validate()"></zc-validation-message>
</form>
