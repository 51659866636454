import { FormGroup, FormGroupTyped } from '@angular/forms';
import { throwError, OperatorFunction, Subject, MonoTypeOperatorFunction, ObservableInput } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppError, AppValidationError, EntityValidationErrors } from '../models/app-error';
import { ZCValidators } from '../utils/validators';

/**
 * Util operator function to catch `AppValidationError` on presentational logic.
 * @param subjectOrForm Subject to emit data if it was there.
 */
export function catchValidationData<T, R>(
  subjectOrForm: Subject<EntityValidationErrors<T>> | FormGroupTyped<T>,
): OperatorFunction<R, R | never> {
  return source => source.pipe(
    catchError(error => {
      // In case error is what we want, pass it to provided subject and finish the stream
      if (error instanceof AppValidationError) {
        const { validationData } = error;
        if (subjectOrForm instanceof Subject) {
          subjectOrForm.next(validationData);
        } else {
          fillFormWithError(subjectOrForm, validationData);
        }
        return throwError(new AppError('Invalid form data.'));
      }

      // Otherwise, let the error go
      return throwError(error);
    }),
  );
}

/**
 * Fill the form with error data.
 * @param form Form to fill.
 * @param errors Array of errors.
 */
function fillFormWithError<T>(form: FormGroupTyped<T>, errors: EntityValidationErrors<T>): void {
  const controlKeys = Object.keys(form.controls) as (keyof T)[];
  controlKeys.forEach(key => {
    const error = errors[key];
    const control = form.controls[key];
    if (error && control) {
      // If error is not nested
      if (typeof error === 'string') {
        control.setErrors(ZCValidators.buildAppError(error));
      } else if (control instanceof FormGroup && typeof error === 'object') {
        // Since we checked the error type, help typescript with error typing
        fillFormWithError(
          control as FormGroupTyped<T[keyof T]>,
          error as Object as EntityValidationErrors<T[keyof T]>,
        );
      }
    }
  });
}

/**
 * Catch application validation error (instance of AppValidationError) operator.
 * Catches only AppValidationError<T> errors.
 * @param selector Selector.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function catchValidationError<T, O extends ObservableInput<any>, TEntity extends object = T extends object ? T : object>(
  selector: (error: AppValidationError<TEntity>) => O,
): MonoTypeOperatorFunction<T> {
  return catchError(error => {
    if (error instanceof AppValidationError) {
      return selector(error);
    }
    return throwError(error);
  });
}
