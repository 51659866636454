import { Observable, of } from 'rxjs';

import { AsyncPaginationOptions } from '../utils/paginate';

import { Pagination } from './pagination';
import { PaginationOptions } from './pagination-options';

export type ToReadableFunction<T> = (object: T) => string;
export type ComparatorFunction<T> = (object1: T, object2: T) => boolean;
export type PaginationQueryFunction<T> = (options: PaginationOptions) => Observable<Pagination<T>>;

/** Configuration for autocomplete-like components. */
export interface AutocompleteConfiguration<TItem> {

  /** Function that fetches the value with provided pagination options. */
  readonly fetch: PaginationQueryFunction<TItem>;

  /** Comparator function. */
  readonly comparator: ComparatorFunction<TItem>;

  /**
   * Function that makes option human-readable.
   * @param item Option.
   */
  readonly toReadable: ToReadableFunction<TItem>;
}

export namespace AutocompleteConfiguration {
  export const PAGINATION_DEFAULTS: Pick<AsyncPaginationOptions<PaginationOptions>, 'page' | 'pageSize'> = {
    pageSize: of(50),
    page: of(0),
  };
}
