<ng-container *ngIf="controlValue">
  <div role="group" class="radio-group">
    <label *ngFor="let option of dayOptions; let i = index; trackBy: trackBy">
      <time [dateTime]="option">
        <span class="weekday">
          {{ option | date: "EEE" }}
        </span>
        <span>
          {{ option | date: "MMM d" }}
        </span>
      </time>
      <input
        type="checkbox"
        name="day"
        [checked]="isSelected(option)"
        (change)="onToggle(option)"
      />
    </label>
  </div>
</ng-container>
