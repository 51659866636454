import { Question } from './question';

/** Enum for Section of question in WP Admin. */
export enum Section {
  Header = 'header',
  QuestionnaireList = 'questionnaire',
  Ignore = 'ignore',
}

/** Represents custom stage control. */
export interface StageControl {

  /** Control label. */
  readonly label: string;

  /** Control type, which override default style for button control. */
  readonly type?: string;
}

/** Questionnaire stage controls. Represents a configuration for controls of the questionnaire stage. */
export interface QuestionnaireStageControls {

  /** Control which go to pext stage . */
  readonly nextStageControl?: StageControl;

  /** Control which go to previous stage. */
  readonly previousStageControl?: StageControl;

  /** Control which submit questionnaire immediately. */
  readonly forceSubmitControl?: StageControl;
}

/** Represents how to show question in WP Admin and PDF export. */
export interface QuestionnaireExtraInfo {

  /** Section name in WP admin. */
  readonly section: string;

  /** Unique key of Question. */
  readonly key: string;

}

/** Represents a single page on questionnaire. May contain control configuration and several questions. */
export interface QuestionnaireStage {

  /** List of questions on page. */
  readonly questions: readonly Question[];

  /** Stage controls configuration. */
  readonly questionnaireStageControls: QuestionnaireStageControls;

  /** Extra info to show in WP Admin. */
  readonly questionExtraInfo: QuestionnaireExtraInfo;
}
