import { Address } from './address';
import { Profession } from './profession';
import { UserRoleGroup } from './user-role-group';

/** Base user interface. */
export interface BaseUser {

  /** First name. */
  readonly firstName: string;

  /** Last name. */
  readonly lastName: string;

  /** Location. */
  readonly location: Address;
}

/** Representation of a user within application. */
export interface User extends BaseUser {

  /** Unique human-readable identifier. */
  readonly id: string;

  /** Email. */
  readonly email: string;

  /** Avatar url. */
  readonly avatarUrl: string | null;

  /** User's phone. */
  readonly phone: string;

  /**
   * Role in the property selling process.
   * @example Plumber, painter, etc.
   */
  readonly profession: Profession;

  /**
   * Represents a role within the application.
   * @example admin, agent, back-office member etc.
   */
  readonly roleGroup: UserRoleGroup;

}

export namespace User {

  /**
   * Creates a human-readable placeholder of an unknown user.
   * @param user User to create a placeholder for.
   */
  function getUnknownUserPlaceholder(user: User): string {
    return `Unknown ${user.id}`;
  }

  /**
   * User comparator.
   * @param user1 User 1.
   * @param user2 User 2.
   */
  export function compare(user1: User, user2: User): boolean {
    return user1.id === user2.id;
  }

  /**
   * Returns human-readable username entity human-readable.
   * @param user User.
   */
  export function toReadable(user: User): string {
    const isUsernamePresent = (user.firstName + user.lastName) !== '';
    if (isUsernamePresent) {
      return `${user.firstName} ${user.lastName}`;
    }

    return getUnknownUserPlaceholder(user);
  }
}
