<ng-container *ngIf="user; else skeleton">
  <span class="name">{{ fullName }}</span>
</ng-container>

<ng-template #skeleton>
  <span
    zcSkeleton
    [zcSkeletonMaxWidth]="20"
    [zcSkeletonMinWidth]="15"
    class="name"
  ></span>
</ng-template>
