import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subscription } from '@zc/common/core/models/subscription';
import { AppConfigService } from '@zc/common/core/services/app-config.service';
import { SubscriptionDto } from '@zc/common/core/services/mappers/dto/subscription.dto';
import { SubscriptionMapper } from '@zc/common/core/services/mappers/subscription.mapper';
import { combineLatest, forkJoin, Observable, Subject, throwError } from 'rxjs';
import { catchError, first, map, repeatWhen, shareReplay, skip, switchMap, switchMapTo, tap } from 'rxjs/operators';

import { AppError, AppValidationError } from '../models/app-error';
import { AuthData } from '../models/auth-data';
import { SubscriptionDiscount, SubscriptionProduct } from '../models/subscription-product';
import { SubscriptionPurchaseData } from '../models/subscription-purchase-data';
import { filterNull } from '../rxjs/filter-null';

import { AuthService } from './auth.service';
import { CouponDto } from './mappers/dto/coupon-info.dto';
import { ProductDto } from './mappers/dto/product.dto';
import { SubscriptionPurchaseDto, SubscriptionUpgradeDto } from './mappers/dto/subscription-purchase.dto';
import { SubscriptionProductMapper } from './mappers/subscription-product.mapper';
import { SubscriptionPurchaseDataMapper } from './mappers/subscription-purchase-data.mapper';

const INVALID_COUPON_MESSAGE = 'Coupon is not valid';

/** Api service. */
@Injectable({
  providedIn: 'root',
})
class SubscriptionsApiService {
  public constructor(
    private readonly appConfigService: AppConfigService,
    private readonly httpClient: HttpClient,
  ) {}

  private readonly subscriptionsUrl = new URL('users/subscriptions/', this.appConfigService.apiUrl);

  private readonly createSubscriptionUrl = new URL('users/create_subscription/', this.appConfigService.apiUrl);

  private readonly updateSubscriptionUrl = new URL('users/update_subscription/', this.appConfigService.apiUrl);

  private readonly cancelSubscriptionUrl = new URL('users/cancel_subscription/', this.appConfigService.apiUrl);

  private readonly productsUrl = new URL('users/product/', this.appConfigService.apiUrl);

  private readonly couponUrl = new URL('users/coupon/', this.appConfigService.apiUrl);

  /**
   * Cancel subscription.
   * @param id Id of subscription.
   */
  public cancelSubscription(id: Subscription['id']): Observable<void> {
    return this.httpClient.post<void>(this.cancelSubscriptionUrl.toString(), { subscription_id: id });
  }

  /**
   * Creates a subscription entity.
   * @param subscriptionPurchaseDto Subscription purchase info.
   * @param headers Http headers.
   */
  public postCreateSubscription(subscriptionPurchaseDto: SubscriptionPurchaseDto, headers?: HttpHeaders): Observable<void> {
    return this.httpClient.post<void>(
      this.createSubscriptionUrl.toString(),
      subscriptionPurchaseDto,
      { headers },
    );
  }

  /**
   * Update subscription.
   * @param subscriptionPurchaseDto Subscription purchase info.
   */
  public postUpdateSubscription(subscriptionPurchaseDto: SubscriptionUpgradeDto): Observable<void> {
    return this.httpClient.post<void>(
      this.updateSubscriptionUrl.toString(),
      subscriptionPurchaseDto,
    );
  }

  /** Retuns subscriptions list. */
  public getProducts(): Observable<readonly ProductDto[]> {
    return this.httpClient.get<ProductDto[]>(this.productsUrl.toString());
  }

  /** Obtain a list of current subscriptions. */
  public getSubscriptions(): Observable<SubscriptionDto> {
    return this.httpClient.get<SubscriptionDto>(this.subscriptionsUrl.toString());
  }

  /**
   * Gets the coupon information.
   * @param coupon Coupon string.
   */
  public getCouponInfo(coupon: string): Observable<CouponDto> {
    const url = new URL(`${coupon}/`, this.couponUrl);
    return this.httpClient.get<CouponDto>(url.toString());
  }
}

/**
 * Service to work with current user subscriptions for the application.
 */
@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {

  /** Current subscription. */
  public readonly currentSubscription$: Observable<Subscription | null>;

  /** List of subscriptions (including the current one) represented as products. */
  public readonly subscriptionProducts$: Observable<readonly SubscriptionProduct[]>;

  private readonly subscriptionUpdated$ = new Subject<void>();

  public constructor(
    private readonly subscriptionMapper: SubscriptionMapper,
    private readonly authService: AuthService,
    private readonly productMapper: SubscriptionProductMapper,
    private readonly subscriptionPurchaseDataMapper: SubscriptionPurchaseDataMapper,
    private readonly subscriptionsApiService: SubscriptionsApiService,
  ) {
    this.subscriptionProducts$ = this.getProductsInfo().pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.currentSubscription$ = forkJoin([
      this.subscriptionsApiService.getSubscriptions(),
      this.subscriptionProducts$,
    ]).pipe(
      repeatWhen(() => this.subscriptionUpdated$),

      // User can have only one subscription as of now
      map(([subscription, products]) => this.subscriptionMapper.fromDto(subscription, products)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /** Get info about products. */
  private getProductsInfo(): Observable<SubscriptionProduct[]> {
    return this.subscriptionsApiService.getProducts().pipe(
      map(products => products.map(product => this.productMapper.fromDto(product))),
    );
  }

  /** Returns subscriptions that are better than the current one (including the current one). */
  public getBetterSubscriptionProducts(): Observable<readonly SubscriptionProduct[]> {
    return combineLatest([
      this.subscriptionProducts$,
      this.currentSubscription$,
    ]).pipe(
      map(([products, currentSubscription]) => {
        if (currentSubscription == null) {
          return products;
        }

        return products.filter(product => {
          const isBetter = product.isBetterThan(currentSubscription.product);
          const isCurrent = product.id !== currentSubscription.nextSubscriptionProduct?.id;

          return isBetter || isCurrent;
        });
      }),
    );
  }

  /**
   * Creates a subscription when the application doesn't have an information about the current user.
   * @param subscriptionPurchaseData Subscription to create.
   * @param userSecret User secret to authorize the subscription creation.
   */
  public createSubscriptionAsUnauthorized(
    subscriptionPurchaseData: SubscriptionPurchaseData,
    userSecret: AuthData.UserSecret,
  ): Observable<void> {
    return this.subscriptionsApiService.postCreateSubscription(
      this.subscriptionPurchaseDataMapper.toDto(subscriptionPurchaseData),
      this.authService.appendAuthorizationHeader(new HttpHeaders(), userSecret),
    ).pipe(

      catchError((error: HttpErrorResponse) => {
        if (error.status === 400) {
          return throwError(() => new AppValidationError<SubscriptionPurchaseData>(error.error.details, {
            promocode: error.error?.data?.[0] ?? INVALID_COUPON_MESSAGE,
          }));
        }
        return throwError(() => error);
      }),
    );
  }

  /**
   * Checks whether the provided is applicable to the product.
   * @param coupon Promocode/coupon.
   * @param product Subscription.
   */
  public validateCoupon(coupon: string, product: SubscriptionProduct): Observable<SubscriptionDiscount> {
    return this.subscriptionsApiService.getCouponInfo(coupon).pipe(
      map(couponDto => {
        const discountAmount = product.price.multiply((couponDto.percent_off ?? 0) / 100);

        return ({
          newPrice: product.price.subtract(discountAmount),
          product,
        } as SubscriptionDiscount);
      }),
      catchError((error: HttpErrorResponse) =>
        throwError(() => new AppError(error.error?.data?.[0] ?? INVALID_COUPON_MESSAGE))),
    );
  }

  /**
   * Upgrade subscription to a better one.
   * @param subscriptionPurchaseData Data required for subscription upgrade.
   */
  public upgradeSubscription(subscriptionPurchaseData: SubscriptionPurchaseData): Observable<Subscription> {
    return this.currentSubscription$.pipe(
      first(),
      tap(currentSubscription => {
        // Assert the subscription is better than the current one, otherwise it's a bug
        if (currentSubscription?.product.isBetterThan(subscriptionPurchaseData.product)) {
          throw new AppError('Current subscription is better than the requested one.');
        }
      }),

      switchMap(currentSubscription => {
        if (currentSubscription == null) {
          return this.subscriptionsApiService.postCreateSubscription(
            this.subscriptionPurchaseDataMapper.toDto(subscriptionPurchaseData),
          );
        }

        return this.subscriptionsApiService.postUpdateSubscription(
          this.subscriptionPurchaseDataMapper.upgradeDataToDto(subscriptionPurchaseData, currentSubscription),
        );
      }),
      tap(() => this.subscriptionUpdated$.next()),
      switchMapTo(this.currentSubscription$),

      // In order to skip the current and wait for next one
      skip(1),
      filterNull(),
      first(),
    );
  }

  /** Cancels the current subscription. */
  public cancelCurrentSubscription(): Observable<void> {
    return this.currentSubscription$.pipe(
      first(),
      filterNull(),
      switchMap(({ id }) => this.subscriptionsApiService.cancelSubscription(id)),
      tap(() => this.subscriptionUpdated$.next()),
    );
  }
}
